import { AccountType } from '../../../types'
import { IMovements } from '../types'
import { FSA, IFSAData } from '../../FSA'

const domain = `platform-positions`

export const FETCH_MOVEMENTS = `${domain}/FETCH_MOVEMENTS`
export const FETCH_MOVEMENTS_FULFILL = `${domain}/FETCH_MOVEMENTS_FULFILL`

export interface IFetchMovementsActionPayload {
  accountId: string
  version: string
  participantCode: string
  page?: number
  pageSize?: number
}

export interface IFetchMovementsAction
  extends FSA<string, IFetchMovementsActionPayload> {
  type: typeof FETCH_MOVEMENTS
}

export interface IFetchMovementsFulfillAction<Payload>
  extends IFSAData<Payload> {
  type: typeof FETCH_MOVEMENTS_FULFILL
}

export interface IFetchMovementsFulfillActionSuccess
  extends IFetchMovementsFulfillAction<{
    accountType: AccountType
    movements: IMovements
  }> {
  error: false
}

export interface IFetchMovementsFulfillActionFail
  extends IFetchMovementsFulfillAction<Error> {
  error: true
}
