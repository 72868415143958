import * as React from 'react'

import { FormInputItem } from '.'
import { Checkbox } from 'antd'

import FormItem from '@ant-design/compatible/es/form/FormItem'
import { ICheckboxGroupItemConfig } from '../../configuration'

const CheckboxGroup = Checkbox.Group

export interface ICheckboxGroupFormItemProps
  extends FormInputItem<ICheckboxGroupItemConfig> {}

export const CheckboxGroupFormItem = (props: ICheckboxGroupFormItemProps) => {
  let initialValue: string[] = []
  if (
    props.value &&
    !props.value.length &&
    typeof Object.keys(props.value)[0] === 'string'
  ) {
    initialValue = Object.keys(props.value)
  }
  const group =
    props.parentKey && props.index !== undefined && props.index !== null
      ? {
          key: props.parentKey,
          index: props.index
        }
      : undefined
  return (
    <FormItem
      {...props.formItemLayout}
      label={
        <span style={{ whiteSpace: 'normal', fontWeight: 700 }}>
          {props.label}
        </span>
      }
    >
      {props.form.getFieldDecorator(props.itemKey, {
        initialValue,
        rules: props.rules
      })(
        <CheckboxGroup>
          {props.children
            ? props.children.map((o, i) => {
                if (o.type === 'option') {
                  if (o.displayIf && !o.displayIf(props.answers, group)) {
                    return undefined
                  }
                  return (
                    <Checkbox
                      style={{ width: '100%', marginLeft: 8 }}
                      key={o.value}
                      value={o.value}
                      disabled={o.disabled}
                      onChange={o.onChange ? o.onChange(props.form) : undefined}
                    >
                      {o.label}
                    </Checkbox>
                  )
                } else {
                  return (
                    <div
                      key={i}
                      style={{
                        marginTop: 18,
                        marginBottom: 6,
                        fontWeight: 600,
                        fontSize: 14
                      }}
                    >
                      {o.label}
                    </div>
                  )
                }
              })
            : null}
        </CheckboxGroup>
      )}
    </FormItem>
  )
}

export default CheckboxGroupFormItem
