import * as React from 'react'
import { IWithParticipantComponentProps } from '../components/participant/WithParticipant'
import { DEFAULT_PAGE_SIZE } from '../constants'
import { ITransferRequest } from '../transfers/components/TransfersPage'
import { Spin, Tabs, Divider, message, Button, Pagination } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import dateFormatter from '../utils/dateFormatter'
import RestService from '../RestService'
import Title from 'seed-shared-components/lib/components/Title'
import { numberFormatter } from 'seed-shared-components/lib/utils/CurrencyFormatter'
import qs from 'qs'

interface IPlatformPageProps extends IWithParticipantComponentProps {}

export interface ITransferRequestWithNames extends ITransferRequest {
  from_participant_name: string
  to_participant_name: string
}

export default function PlatformPage(props: IPlatformPageProps) {
  const [tab, setTab] = React.useState('pending')
  const [transfers, setTransfers] = React.useState<{
    count: number
    data: ITransferRequestWithNames[]
  }>({ count: 0, data: [] })
  const [pagination, setPagination] = React.useState({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE
  })

  const fetchTransfersEffect = async () => {
    setLoading(true)

    const transferRequests = await getTransfers(
      props.restService,
      {
        pending: ['pending'],
        processed: ['approved', 'settled'],
        rejected: ['rejected', 'canceled']
      }[tab],
      pagination
    )

    setTransfers({
      count: +transferRequests.count,
      data: transferRequests.message
    })

    setLoading(false)
  }

  React.useEffect(() => {
    fetchTransfersEffect()
  }, [pagination])

  React.useEffect(() => {
    setPagination((p) => ({ ...p, page: 0 }))
  }, [tab])

  const [loading, setLoading] = React.useState<boolean>(true)
  const [updating, setUpdatingTransfer] = React.useState<boolean>(false)

  const updateTransfer = async (id: number, status: string) => {
    if (!updating) {
      setUpdatingTransfer(true)
      const closeLoading = message.loading('Updating transfer', 0)

      try {
        await props.restService.route(`transfers/${id}`).put({ status })
        message.success('Update Successful', 2.5)
      } catch (e) {
        // message.error('Update failed', 2.5)
      }

      closeLoading()
      setUpdatingTransfer(false)

      fetchTransfersEffect()
    }
  }

  const pendingColumns = [
    ...columns,
    {
      title: 'Action',
      render: (transfer: ITransferRequestWithNames) => (
        <>
          {props.participant.isAdmin ? (
            <div>
              <Button
                disabled={updating}
                type="link"
                onClick={() => updateTransfer(transfer.id, 'approved')}
              >
                Approve
              </Button>
              <Divider type="vertical" />
              <Button
                disabled={updating}
                type="link"
                onClick={() => updateTransfer(transfer.id, 'rejected')}
              >
                Reject
              </Button>
            </div>
          ) : null}
        </>
      )
    }
  ]

  return (
    <Spin spinning={loading}>
      <Title level={2}>Platform Transfers</Title>
      <div style={{ marginBottom: 24 }}>
        Here you can see all allocations made into and out of your platform, as
        well as any transfers between participants within your platform. Some
        platforms may elect to lock assets that customers and counterparties
        allocate to them. If configured, customers and counterparties will
        periodically request to transfer assets away (withdraw) from your
        platform. You can use this page to approve any such pending requests.
        Learn more about what access rights are provided to you as part of being
        a platform operator:{' '}
        <a
          href="https://zerohash.zendesk.com/hc/en-us/articles/360021160534-Participant-Relationships-and-Access-Rights"
          target="_blank"
        >
          Participant Relationships and Access Rights
        </a>{' '}
      </div>

      <Tabs onChange={setTab} defaultActiveKey="pending">
        <Tabs.TabPane tab="Pending" key="pending">
          <TransferRequestTable
            dataSource={transfers.data}
            columns={pendingColumns}
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Processed" key="processed">
          <TransferRequestTable dataSource={transfers.data} columns={columns} />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Rejected" key="rejected">
          <TransferRequestTable dataSource={transfers.data} columns={columns} />
        </Tabs.TabPane>
      </Tabs>

      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}
      >
        <Pagination
          pageSize={pagination.pageSize}
          current={pagination.page + 1}
          total={transfers.count}
          onChange={(current, pageSize) =>
            setPagination({ pageSize: pageSize, page: current - 1 })
          }
        />
      </div>
    </Spin>
  )
}

const columns = [
  {
    title: 'Transfer ID',
    dataIndex: 'id'
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    render: (item: number) => dateFormatter(item)
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    render: (item: number) => dateFormatter(item)
  },
  {
    title: 'Administrative',
    dataIndex: 'admin_transfer',
    render: (text: boolean) => (text === true ? 'Yes' : 'No')
  },
  {
    title: 'Asset',
    dataIndex: 'currency'
  },
  {
    title: 'From',
    render: (row: ITransferRequestWithNames) => {
      if (row.from_participant_name) {
        return `${row.from_participant_name} (${row.from_participant_code})`
      }

      return row.from_participant_code
    }
  },
  {
    title: 'To',
    render: (row: ITransferRequestWithNames) => {
      if (row.to_participant_name) {
        return `${row.to_participant_name} (${row.to_participant_code})`
      }

      return row.to_participant_code
    }
  },
  {
    title: 'Direction',
    dataIndex: 'platform_transfer_direction'
  },
  {
    title: 'Amount',
    render: (row: ITransferRequestWithNames) => numberFormatter(row.amount)
  },
  {
    title: 'Status',
    dataIndex: 'status'
  },
  {
    title: 'Movement ID',
    dataIndex: 'movement_id'
  }
]

function TransferRequestTable({
  dataSource,
  columns
}: {
  dataSource: ITransferRequestWithNames[] | undefined
  columns: ColumnProps<ITransferRequestWithNames>[] | undefined
}): JSX.Element {
  return (
    <Table
      dataSource={dataSource}
      rowKey={(transfer: ITransferRequestWithNames) => String(transfer.id)}
      columns={columns}
      pagination={false}
    />
  )
}

/* Helpers */

const getTransfers = async (
  restService: RestService,
  status: string[],
  { page, pageSize }: any
) =>
  restService
    .route(
      `portal-platform-allocation-transfers?${qs.stringify({
        status,
        page,
        pageSize
      })}`
    )
    .get<{
      message: ITransferRequest[]
      count: string
      page: number
      pageSize: number
    }>()
