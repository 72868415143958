import React from 'react'
import { Card } from 'antd'

import Title from 'seed-shared-components/lib/components/Title'

export const Widget = ({ title, children, ...props }) => (
  <Card {...props}>
    <Title level={4} style={{ marginBottom: 12 }}>
      {title}
    </Title>
    {children}
  </Card>
)

export const WidgetsContainer = ({ children }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'flex-start',
      backgroundColor: 'transparent'
    }}
  >
    {children}
  </div>
)

export const WidgetsStack = ({ stacked, children, ...props }) =>
  stacked ? <div {...props}>{children}</div> : children
