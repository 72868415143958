import { createAction, PrepareAction } from '@reduxjs/toolkit'
import fsaPrepareActionHandler from '../../fsaPrepareActionHandler'
import {
  FETCH_PARTICIPANT_POSITIONS,
  FETCH_PARTICIPANT_POSITIONS_FULFILL,
  IFetchParticipantPositionsAction,
  IFetchParticipantPositionsFulfillActionSuccess,
  IFetchParticipantPositionsFulfillActionFail
} from './types'

export const fetchParticipantPositions = createAction<
  IFetchParticipantPositionsAction['payload']
>(FETCH_PARTICIPANT_POSITIONS)
export const fetchParticipantPositionsFulfill = createAction<
  PrepareAction<
    | IFetchParticipantPositionsFulfillActionSuccess
    | IFetchParticipantPositionsFulfillActionFail
  >
>(FETCH_PARTICIPANT_POSITIONS_FULFILL, fsaPrepareActionHandler)
