import { IDropdownItemConfig } from '../../configuration'
import { FormInputItem } from '.'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { AutoComplete } from 'antd'
import FormItem from '@ant-design/compatible/es/form/FormItem'
import { STATE_FIELDS, validateState } from './form-utils'

export interface IDropdownFormItemProps
  extends FormInputItem<IDropdownItemConfig> {}

const DropdownFormItem = (props: IDropdownFormItemProps) => {
  /** Checks if the field is a state by looking up the itemKey or if the isState attribute
   *  was set to true on new-world-configuration.tsx
   */
  const isStateField = STATE_FIELDS.includes(props.itemKey) || props.isState
  const [opts, setOpts] = useState(props.options)

  const stateFieldRules = [
    {
      required: true,
      validator: (_, value: string | null) => validateState(value, opts)
    }
  ]

  useEffect(() => {
    // component can handle data fetching if a fetchOptions function is provided
    if (props.fetchOptions) {
      props
        .fetchOptions(
          props.restService,
          props.answers,
          props.itemKey,
          props.additional
        )
        .then((options) => {
          if (!!options && options?.length !== 0) {
            setOpts(options)
          }
        })
    }
  }, [props.answers])

  const fieldDecoratorProps: any = {
    initialValue: props.value,
    rules: props.rules,
    validateTrigger: 'onBlur'
  }
  if (props.normalize) {
    fieldDecoratorProps.normalize = props.normalize
  }
  return (
    <FormItem
      {...props.formItemLayout}
      label={
        <span data-testid={props.itemKey} style={{ whiteSpace: 'normal' }}>
          {props.label}
        </span>
      }
    >
      {props.form.getFieldDecorator(props.itemKey, {
        ...fieldDecoratorProps,
        rules: isStateField ? stateFieldRules : props.rules
      })(
        <AutoComplete
          options={opts}
          disabled={!opts?.length}
          placeholder={`Choose a ${props.label}`}
          defaultActiveFirstOption={true}
          filterOption={(inputValue, option: any) => {
            return (
              option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            )
          }}
        />
      )}
    </FormItem>
  )
}

export default DropdownFormItem
