import * as React from 'react'

import { IWithParticipantComponentProps } from '../components/participant/WithParticipant'
import DocumentFormItem from '../onboarding-application/components/FormItems/Document'
import { Alert } from 'antd'
import { IParticipant } from 'seed-shared-components/lib/types'
import RestService from '../RestService'
import { IAuthUser } from '../auth/WithAuthentication'
import { Form } from '@ant-design/compatible'
import { WrappedFormUtils } from '@ant-design/compatible/es/form/Form'
import Title from 'seed-shared-components/lib/components/Title'

interface UploadDocumentProps {
  form: WrappedFormUtils
  value: any
  formItemLayout: any
  answers: any
  participant: IParticipant
  restService: RestService
  authUser: IAuthUser
  parentKey?: string
  onAdd?: () => void
  onRemove?: (index: number) => void
  isAllowedToUploadDocuments: boolean
}

interface IUserPageState {
  loading: boolean
  isAllowedToUploadDocuments: boolean
}
export default class DocumentsPage extends React.Component<
  IWithParticipantComponentProps,
  IUserPageState
> {
  constructor(props: IWithParticipantComponentProps) {
    super(props)
    this.state = {
      loading: true,
      isAllowedToUploadDocuments: false
    }
  }

  async componentDidMount() {
    const { authUser, participant } = this.props
    const isAllowedToUploadDocuments = await authUser.isAdmin(participant.code)
    this.setState({ isAllowedToUploadDocuments })
  }

  render() {
    return (
      <UploadDocumentsForm
        {...this.props}
        isAllowedToUploadDocuments={this.state.isAllowedToUploadDocuments}
      />
    )
  }
}

class SingleUploadDocumentForm extends React.Component<UploadDocumentProps> {
  render() {
    return (
      <div>
        <div className="space-between">
          <Title level={2}>Documents Upload</Title>
        </div>
        {!this.props.isAllowedToUploadDocuments && (
          <Alert
            message="Only admins can upload documents. Please work with the administrators on your participant to upload any needed documents."
            type="warning"
          />
        )}
        {this.props.isAllowedToUploadDocuments && (
          <DocumentFormItem
            type="document"
            itemKey="requested_document"
            application_document={false}
            minimumCount={() => 1}
            label={undefined}
            {...this.props}
          />
        )}
      </div>
    )
  }
}

const UploadDocumentsForm = Form.create<any>()(SingleUploadDocumentForm)
