import React, { useEffect } from 'react'
import * as uuid from 'uuid'
import { assets as assetConfigs } from 'seed-shared-components/lib/static-data/assetConfig'
import { IFundsServiceProps } from './props'
import { notification } from 'antd'
import connect from './connector'
import { IAsset } from 'seed-shared-components/lib/static-data/assetConfig'

const FundsService = (props: IFundsServiceProps) => {
  const fetchAssets = async () => {
    try {
      const cryptoAssets: IAsset[] = await props.restService
        .route(`assets?requestId=${uuid.v4()}&asset_type=CRYPTO`)
        .get()
      const fiatCurrencies = assetConfigs.filter(
        (asset) => asset.type === 'Fiat'
      )
      const funds = await props.loadFunds()
      props.onFundsLoadSuccess(
        funds,
        [...cryptoAssets, ...fiatCurrencies].sort((a, b) =>
          a.asset.localeCompare(b.asset)
        )
      )
    } catch (errorMessage) {
      props.onFundsLoadFailure()
      notification.error({
        description: errorMessage,
        duration: 0,
        message: 'Failed to fetch Funds'
      })
    }
  }

  useEffect(() => {
    fetchAssets()
  }, [])

  return null
}

export default connect(FundsService)
