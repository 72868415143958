import { ReportType } from '../store/reports/types'

export const ReportTypeToTitle = {
  [ReportType.AccountBalances]: 'Account Balances',
  [ReportType.Deposits]: 'Deposits',
  [ReportType.LoanInterest]: 'Loan Interest',
  [ReportType.Loans]: 'Loans',
  [ReportType.NetworkFees]: 'Network Fees',
  [ReportType.Withdrawals]: 'Withdrawals',
  [ReportType.Trades]: 'Trades'
}
