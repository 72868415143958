import { call, select } from 'typed-redux-saga'
import portalApi from '../../api/portalApi'

import { IApplicationState } from '../state'

const getUser = (state: IApplicationState) => state.user

function* requestSaga(options) {
  const { token } = yield select(getUser)
  const { data } = yield call(portalApi.request, {
    headers: {
      Authorization: `Bearer ${token || ''}`
    },
    ...options
  })

  return data
}

export { requestSaga }
