import { Application } from '../onboarding-application/participant'

const booleanStringsToParse = [
  'bankruptcy',
  'entity_dodd',
  'legal_disqual',
  'legal_officer',
  'us_person_dodd',
  'nfa',
  'finra',
  'swap_dealer',
  'major_swap_participant'
]
export const formatBooleanStrings = (payload: Application['payload']) => {
  for (const bool of booleanStringsToParse) {
    if (payload[bool] && typeof payload[bool] === 'string') {
      payload[bool] = payload[bool] === 'true'
    }
  }
  return payload
}
const numberStringsToConvert = ['fin_income', 'net_assets']
export const formatNumberStrings = (payload: Application['payload']) => {
  for (const num of numberStringsToConvert) {
    if (payload[num] && typeof payload[num] === 'string') {
      payload[num] = Number(payload[num])
    }
  }
  return payload
}
