import * as React from 'react'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import ParticipantMenu from './participant-menu'
import { IAuthUser } from '../../auth/WithAuthentication'

export interface ISidebarProps extends RouteComponentProps {
  user: IAuthUser
}

class SideBar extends React.Component<ISidebarProps> {
  constructor(props: any) {
    super(props)
  }

  render() {
    const selectedMenuKeys = [this.props.location.pathname.split('/')[2]]

    return (
      <ParticipantMenu
        getTradeUrl={this.props.user.getTradeUrl}
        selectedMenuKeys={selectedMenuKeys}
        {...this.props}
      />
    )
  }
}

export default withRouter(SideBar)
