import { createContext } from 'react'

interface IVariant {
  name: string
  enabled: boolean
  payload?: {
    type: string
    value: string
  }
}

export interface IToggle {
  name: string
  enabled: boolean
  variant: IVariant
  impressionData: boolean
}
interface IFlagContext {
  toggles: IToggle[]
  isLoadingFlags: boolean
}

export const FlagContext = createContext<IFlagContext>({
  toggles: [],
  isLoadingFlags: true
})
