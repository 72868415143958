import axios, {
  AxiosRequestConfig,
  AxiosInstance,
  AxiosResponse,
  Method
} from 'axios'
import * as uuid from 'uuid'
import Logger from '../utils/logger'
import handleError from './handleError'

export interface PortalApiRequestConfig extends AxiosRequestConfig {
  method?: Method | any
  omitNotification?: boolean
  customErrorMessage?: string | { [key: string]: string }
}

export interface PortalApiInstance extends AxiosInstance {
  request<T = any, R = AxiosResponse<T>>(
    config: PortalApiRequestConfig
  ): Promise<R>
  get<T = any>(url: string, config?: PortalApiRequestConfig): Promise<T>
  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: PortalApiRequestConfig
  ): Promise<R>
  head<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: PortalApiRequestConfig
  ): Promise<R>
  post<T = any>(
    url: string,
    data?: any,
    config?: PortalApiRequestConfig
  ): Promise<T>
  put<T = any>(
    url: string,
    data?: any,
    config?: PortalApiRequestConfig
  ): Promise<T>
  patch<T = any>(
    url: string,
    data?: any,
    config?: PortalApiRequestConfig
  ): Promise<T>
}

const portalApi: PortalApiInstance = axios.create({
  baseURL: window._env_.REACT_APP_PORTAL_API_URI,
  maxContentLength: Infinity,
  maxBodyLength: Infinity
})

export const logger = new Logger({
  baseData: {},
  send: (data) => portalApi.put(`logs`, data)
})

const onRejected = (error) => {
  handleError(
    logger,
    error,
    error.config.omitNotification,
    error.config.customErrorMessage
  )
  return Promise.reject(error)
}

portalApi.interceptors.request.use((config) => {
  config.headers.common = config.headers.common || {}
  config.headers.common['X-Request-Id'] =
    config.headers['X-Request-Id'] || uuid()
  return config
}, onRejected)

portalApi.interceptors.response.use((res) => res, onRejected)

export default portalApi
