import { ISelectItemConfig } from '../../configuration'
import { FormInputItem } from '.'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Select } from 'antd'
import FormItem from '@ant-design/compatible/es/form/FormItem'
import { STATE_FIELDS, validateState } from './form-utils'

export interface ISelectFormItemProps
  extends FormInputItem<ISelectItemConfig> {}

const SelectFormItem = (props: ISelectFormItemProps) => {
  const isStateField = STATE_FIELDS.includes(props.itemKey) || props.isState
  const [opts, setOpts] = useState(props.options)

  const stateFieldRules = [
    {
      required: true,
      validator: (_, value: string | null) => validateState(value, opts)
    }
  ]

  useEffect(() => {
    // component can handle data fetching if a fetchOptions function is provided
    if (props.fetchOptions) {
      props
        .fetchOptions(
          props.restService,
          props.answers,
          props.itemKey,
          props.additional
        )
        .then((options) => {
          if (!!options && options?.length !== 0) {
            setOpts(options)
          }
        })
    }
  }, [props.answers])

  return (
    <FormItem
      {...props.formItemLayout}
      label={<span style={{ whiteSpace: 'normal' }}>{props.label}</span>}
    >
      {props.form.getFieldDecorator(props.itemKey, {
        // ...fieldDecoratorProps,
        rules: isStateField ? stateFieldRules : props.rules
      })(
        <Select
          options={opts}
          disabled={!opts}
          showSearch
          placeholder={`Choose a ${props.label}`}
          filterOption={(inputValue, option: any) => {
            return (
              option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            )
          }}
        />
      )}
    </FormItem>
  )
}

export default SelectFormItem
