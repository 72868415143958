import {
  IAsset,
  IFund
} from 'seed-shared-components/lib/static-data/assetConfig'

export const FUNDS_ASSET_BALANCES_LEAVE = 'FUNDS/ASSET_BALANCES_LEAVE'
export const FUNDS_ASSET_BALANCES_LOAD = 'FUNDS/ASSET_BALANCES_LOAD'
export const FUNDS_LOAD_SUCCESS = 'FUNDS/LOAD_SUCCESS'
export const FUNDS_LOAD_FAILURE = 'FUNDS/LOAD_FAILURE'
export const FUNDS_LOAD = 'FUNDS/LOAD'

export interface ILoadFundsSuccessPayload {
  funds: IFund[]
  assets: IAsset[]
}

export interface ILoadFundsSuccessAction {
  type: typeof FUNDS_LOAD_SUCCESS
  payload: ILoadFundsSuccessPayload
}
