import { Checkbox } from 'antd'
import React from 'react'
import KycTypographyText from './KycTypographyText'
import KycTypographyLink from './KycTypographyLink'

type KycCheckboxProps = {
  fontSize: number
  colorText: string
  colorPrimary: string
}

const KycCheckbox = ({
  fontSize,
  colorText = '#303133',
  colorPrimary
}: KycCheckboxProps) => {
  const CHECKBOX_TEXT_SIZE = fontSize ? fontSize - 2 : 10
  const style = {
    '--custom-primary-color': colorPrimary || '#2253FF',
    '--custom-color-text': colorText || '#303133'
  } as React.CSSProperties
  return (
    <div className="kyc-checkbox-prefix">
      <Checkbox
        style={{
          fontSize: CHECKBOX_TEXT_SIZE,
          color: 'var(--custom-color-text)',
          ...style
        }}
      >
        <div style={{ lineHeight: '125%', fontSize: CHECKBOX_TEXT_SIZE }}>
          <KycTypographyText
            colorText={colorText}
            style={{
              fontSize: CHECKBOX_TEXT_SIZE,
              opacity: 0.7,
              lineHeight: '125%'
            }}
            strong
          >
            I agree to the (1) Zero Hash and Zero Hash Liquidity Services{' '}
          </KycTypographyText>
          <KycTypographyLink
            underline
            linkHoverColor={colorText}
            style={{
              fontSize: CHECKBOX_TEXT_SIZE,
              opacity: 0.7,
              lineHeight: '125%',
              color: 'var(--custom-color-text)'
            }}
          >
            User Agreement
          </KycTypographyLink>
          <KycTypographyText
            colorText={colorText}
            style={{
              fontSize: CHECKBOX_TEXT_SIZE,
              opacity: 0.7,
              lineHeight: '125%'
            }}
          >
            , and I have read and understand the Zero Hash{' '}
          </KycTypographyText>
          <KycTypographyLink
            underline
            style={{
              fontSize: CHECKBOX_TEXT_SIZE,
              color: 'var(--custom-color-text)',
              opacity: 0.7,
              lineHeight: '125%'
            }}
          >
            Privacy Policy
          </KycTypographyLink>{' '}
          <KycTypographyText
            colorText={colorText}
            style={{
              fontSize: CHECKBOX_TEXT_SIZE,
              opacity: 0.7,
              lineHeight: '125%'
            }}
          >
            and{' '}
          </KycTypographyText>
          <KycTypographyLink
            underline
            style={{
              fontSize: CHECKBOX_TEXT_SIZE,
              opacity: 0.7,
              color: 'var(--custom-color-text)',
              lineHeight: '125%'
            }}
          >
            Regulatory Disclosures
          </KycTypographyLink>
          <KycTypographyText
            colorText={colorText}
            style={{
              fontSize: CHECKBOX_TEXT_SIZE,
              opacity: 0.7,
              lineHeight: '125%'
            }}
          >
            , and (2){' '}
          </KycTypographyText>
          <KycTypographyLink
            underline
            style={{
              fontSize: CHECKBOX_TEXT_SIZE,
              opacity: 0.7,
              color: 'var(--custom-color-text)',
              lineHeight: '125%'
            }}
          >
            KYC Terms
          </KycTypographyLink>
          .
          <br />
          <br />
          <KycTypographyText
            colorText={colorText}
            style={{
              fontSize: CHECKBOX_TEXT_SIZE,
              opacity: 0.7,
              lineHeight: '125%'
            }}
          >
            I understand that the value of any cryptocurrency, including digital
            assets pegged to fiat currency, commodities, or any other asset, may
            go to zero.
          </KycTypographyText>
        </div>
      </Checkbox>
    </div>
  )
}

export default KycCheckbox
