import * as React from 'react'
import {
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Popover,
  Radio,
  Row,
  Tooltip
} from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { RadioChangeEvent } from 'antd/lib/radio'
import WithdrawalsLabelInfo from './WithdrawalsLabelInfo'
import validateWithdrawalsAccountLabel from '../../validateWithdrawalsAccountLabel'
import { mapResourceNameToDisplayString } from '../../types'
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons'
import { useEffect } from 'react'
import { IAuthUser } from '../../../auth/WithAuthentication'
import { IParticipant } from 'seed-shared-components/lib/types'

interface IPermissionsGroupSelectProps {
  resource: string
  readId: number
  writeId: number
  onChange: (data: {
    resource: string
    values: number[]
    withdrawalsAccountLabel?: string
  }) => void
  transfersUsdOnly: boolean
  onTransfersUsdOnlyChange: (isChecked) => void
  authUser: IAuthUser
  participant: IParticipant
  marketDataEnabled: boolean
  participantSanctionScreeningInfoEnabled: boolean
  participantFullInfoEnabled: boolean
}

export default (props: IPermissionsGroupSelectProps) => {
  const [isEnabled, setIsEnabled] = React.useState(false)
  const [values, setValues] = React.useState([])
  const [withdrawalsAccountLabel, setWithdrawalsAccountLabel] = React.useState(
    ''
  )
  const [
    isWithdrawalsAccountLabelValid,
    setIsWithdrawalsAccountLabelValid
  ] = React.useState(true)
  const [marketDataDisabled, setMarketDataDisabled] = React.useState(false)
  const [
    participantSanctionScreeningInfoDisabled,
    setParticipantSanctionScreeningInfoDisabled
  ] = React.useState(false)
  const [
    participantFullInfoDisabled,
    setParticipantFullInfoDisabled
  ] = React.useState(false)

  useEffect(() => {
    if (props.resource === 'market_data') {
      setMarketDataDisabled(!props.marketDataEnabled)
    }
    if (props.resource === 'sanction_screening_info') {
      setParticipantSanctionScreeningInfoDisabled(
        !props.participantSanctionScreeningInfoEnabled
      )
    }
    if (props.resource === 'full_info') {
      setParticipantFullInfoDisabled(!props.participantFullInfoEnabled)
    }
  }, [
    props.marketDataEnabled,
    props.participantSanctionScreeningInfoEnabled,
    props.participantFullInfoEnabled
  ])

  const handleEnableChange = React.useCallback(
    (e: CheckboxChangeEvent) => {
      const isChecked = e.target.checked
      const values = isChecked ? [props.readId] : []

      setIsEnabled(isChecked)
      setValues(values)

      props.onChange({ resource: props.resource, values })
    },
    [props.readId, props.resource, props.onChange]
  )

  const handlePermissionSelect = React.useCallback(
    (e: RadioChangeEvent) => {
      const values = [props.readId]

      if (e.target.value === props.writeId) {
        values.push(props.writeId)
      }

      setValues(values)

      props.onChange({ resource: props.resource, values })
    },
    [props.writeId, props.resource, props.onChange]
  )

  const handleWithdrawalsAccountLabelChange = React.useCallback(
    (e) => {
      const withdrawalsAccountLabel = e.target.value
      setWithdrawalsAccountLabel(withdrawalsAccountLabel)

      props.onChange({
        resource: props.resource,
        values,
        withdrawalsAccountLabel
      })

      setIsWithdrawalsAccountLabelValid(
        validateWithdrawalsAccountLabel(withdrawalsAccountLabel)
      )
    },
    [values, props.writeId, props.resource, props.onChange]
  )

  const withdrawalsAccountLabelValidationProps = React.useMemo<any>(() => {
    if (isWithdrawalsAccountLabelValid) {
      return {}
    }

    return {
      hasFeedback: true,
      validateStatus: 'error',
      help: (
        <div>
          <p style={{ marginBottom: 4 }}>
            Account label should meet the following requirements:
          </p>
          <p style={{ paddingLeft: 6, marginBottom: 0 }}>
            - only letters, numbers, colon, underscores ( _ ) or dashes (-)
          </p>
          <p style={{ paddingLeft: 6, marginBottom: 0 }}>
            - underscores, colon or dashes can not start or end the label string
          </p>
          <p style={{ paddingLeft: 6, marginBottom: 0 }}>
            - cannot be longer than 40 characters
          </p>
          <p style={{ paddingLeft: 6, marginBottom: 0 }}>
            - should no contain spaces
          </p>
        </div>
      )
    }
  }, [isWithdrawalsAccountLabelValid])

  const isDisabled = (resource: string) =>
    (resource === 'market_data' && marketDataDisabled) ||
    (resource === 'sanction_screening_info' &&
      participantSanctionScreeningInfoDisabled) ||
    (resource === 'full_info' && participantFullInfoDisabled)

  return (
    <div>
      <Row justify="start" align="middle">
        <Checkbox
          data-test-id="permissions-group-checkbox"
          checked={isEnabled}
          onChange={handleEnableChange}
          style={{ minWidth: 180 }}
          disabled={isDisabled(props.resource)}
        >
          {mapResourceNameToDisplayString[props.resource]}:
        </Checkbox>
        <Radio.Group
          disabled={!isEnabled || isDisabled(props.resource)}
          value={
            values.length === 2
              ? props.writeId
              : values.length === 1
              ? props.readId
              : undefined
          }
          onChange={handlePermissionSelect}
        >
          {props.readId && <Radio value={props.readId}>Read</Radio>}
          {props.writeId && <Radio value={props.writeId}>Read and Write</Radio>}
        </Radio.Group>

        {props.resource === 'market_data' && marketDataDisabled && (
          <Tooltip
            title={
              'To enable these endpoints, please contact your Zero Hash representative.'
            }
            placement="right"
          >
            <InfoCircleOutlined style={{ margin: 0 }} />
          </Tooltip>
        )}

        {props.resource === 'sanction_screening_info' &&
          participantSanctionScreeningInfoDisabled && (
            <Tooltip
              title={
                'To enable this endpoint, please contact your Zero Hash representative.'
              }
              placement="right"
            >
              <InfoCircleOutlined style={{ margin: 0 }} />
            </Tooltip>
          )}

        {props.resource === 'full_info' && participantFullInfoDisabled && (
          <Tooltip
            title={
              'To enable this endpoint, please contact your Zero Hash representative.'
            }
            placement="right"
          >
            <InfoCircleOutlined style={{ margin: 0 }} />
          </Tooltip>
        )}

        {props.resource === 'transfers' && (
          <Checkbox
            disabled={!isEnabled || values.length !== 2}
            checked={props.transfersUsdOnly}
            onChange={(e) => props.onTransfersUsdOnlyChange(e.target.checked)}
            style={{ minWidth: 115 }}
          >
            <Tooltip
              title={
                'If checked, this API key will only be able to make transfers for USD. All other assets will be rejected and no movements will take place'
              }
              placement="right"
            >
              USD only&nbsp;
              <InfoCircleOutlined />
            </Tooltip>
          </Checkbox>
        )}
      </Row>

      {props.resource === 'withdrawals' && values.length === 2 && (
        <Card
          style={{ width: 308, marginTop: 6, marginBottom: 6 }}
          size="small"
        >
          <Row style={{ marginBottom: 4 }} justify="start" align="top">
            <Col>
              <label htmlFor={'withdrawals_account_label'}>
                Account label{' '}
                <Popover content={<WithdrawalsLabelInfo />} trigger="hover">
                  <InfoCircleTwoTone
                    twoToneColor="#00f197"
                    style={{ marginTop: 2, marginLeft: 2, fontSize: 16 }}
                  />
                </Popover>
              </label>
            </Col>
          </Row>
          <Row
            style={{ paddingBottom: 0, marginBottom: 0 }}
            justify="start"
            align="top"
          >
            <Col>
              <Form.Item
                style={{ paddingBottom: 0, marginBottom: 0 }}
                htmlFor={'withdrawals_account_label'}
                {...withdrawalsAccountLabelValidationProps}
              >
                <Input
                  style={{ width: 282 }}
                  id={'withdrawals_account_label'}
                  value={withdrawalsAccountLabel}
                  onChange={handleWithdrawalsAccountLabelChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      )}
    </div>
  )
}
