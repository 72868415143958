import * as React from 'react'
import { ErrorInfo } from 'react'
import * as uuid from 'uuid'
import * as Sentry from '@sentry/react'

import RestService from '../RestService'
import Title from 'seed-shared-components/lib/components/Title'

interface IErrorBoundaryProps {
  restService: RestService
}
interface IErrorBoundaryState {
  hasError: boolean
  referenceId?: string | undefined
}

class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }
  componentDidCatch(error: Error, info: ErrorInfo) {
    const referenceId = uuid()
    const { name, message, stack } = error || {}
    this.props.restService.logger.error(
      { name, message, stack, info, referenceId },
      info?.componentStack
    )
    // Display fallback UI
    this.setState({ hasError: true, referenceId })
  }

  render() {
    if (this.state.hasError) {
      // fallback UI. If there was a serious error, they cant use the app any longer.
      return (
        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
          <div style={{ margin: 96 }}>
            <Title level={2}>Something went wrong.</Title>
            <Title level={3}>Reference {this.state.referenceId}</Title>
            <Title level={3}>
              The details have been sent to the technical team.
            </Title>
            <Title level={4}>
              You may try again, or
              <a
                href={`mailto:support@zerohash.com?subject=Assistance required. Reference: ${this.state.referenceId}`}
                target="_blank"
              >
                {` `}
                contact support
              </a>
            </Title>
          </div>
        </Sentry.ErrorBoundary>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
