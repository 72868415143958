import React, { useEffect, useState } from 'react'
import { Button, Layout, Menu, message, notification, Spin } from 'antd'
import { Form } from '@ant-design/compatible'
import Sider from 'antd/lib/layout/Sider'
import {
  captureAnswers,
  OnboardingApplicationFormItemSwitch
} from '../../onboarding-application/components/OnboardingApplicationStep'
import { getValueFromAnswers } from '../../onboarding-application/components/FormItems'
import uuid from 'uuid'
import { IDefaultComponentProps } from '../../App'
import { FormComponentProps } from '@ant-design/compatible/es/form'
import { IAuthUser } from '../../auth/WithAuthentication'
import { withRouter } from 'react-router-dom'
import {
  fetchCountries,
  ICountry
} from '../../onboarding-application/fetchCountries'
import getApplicationSteps, {
  BeneficialOwner,
  DocumentType,
  IdNumberType,
  OnboardingApplicationStepConfig
} from '../../onboarding-application/new-world-configuration'
import { fetchSubdivisions } from '../../onboarding-application/fetchSubdivisions'
import RestService from '../../RestService'
import { memoize } from 'lodash'
import { IParticipant } from 'seed-shared-components/lib/types'

const { Content } = Layout

// fetches a key from a form by a path
// path format: "form_field_name" or "persons[0][form_field_name]" (if the field is nested)
const getValueByPath = (obj: any, path: string) => {
  // Split the path by either '.' or '[' and ']' characters
  const parts = path.replace(/\]/g, '').split(/\.|\[/)
  // Iterate over the parts to access the desired property
  return parts.reduce((acc, part) => acc && acc[part], obj)
}

/*
  Checks the 'state' field name for a pattern indicating it is part of a nested
  structure (e.g., "persons[0][address_state]"). If such a pattern is found, the 'country'
  field name is adjusted to match this pattern (e.g., "persons[0][address_country]").
 */
const normalizeFormDropdownPaths = (country: string, state: string) => {
  const match = state.match(/^(.*\[\d+\])\[/)

  // If a pattern is found, use it to modify the country string
  if (match) {
    return `${match[1]}[${country}]`
  } else {
    // If no specific pattern around the state, return the country string as is
    return country
  }
}

interface ICreateParticipantPageProps
  extends IDefaultComponentProps,
    FormComponentProps {
  history: any
  user: IAuthUser
  onSave: () => Promise<void>
  steps: any[]
}

export const fetchSubdivisionDropdownOptions = async (
  restService: RestService,
  formAnswers: any,
  itemKey: string,
  additional: { [key: string]: any }
) => {
  // each state dropdown has a country dropdown associated with it
  // the value of the country dropdown is used to fetch the options for the state dropdown
  if (!additional?.countryDropdownItemKey) {
    return
  }

  // countries are necessary to fetch a country code based on a country name
  if (!additional?.countries) {
    return
  }

  // path of the country input in the AntD Form
  const countryFormPath = normalizeFormDropdownPaths(
    additional.countryDropdownItemKey,
    itemKey
  )
  const selectedCountry = getValueByPath(formAnswers, countryFormPath)
  if (!selectedCountry) {
    return
  }

  const selectedCountryCode = additional.countries.find(
    (country: { countryName: any }) => country.countryName === selectedCountry
  )

  if (!selectedCountryCode) {
    return []
  }

  // fetch the subdivision options based on the selected country code
  // results will be returned as options to be used in the state dropdown
  return (
    await fetchSubdivisions(restService, selectedCountryCode.countryCode)
  ).map((s) => ({ label: s.subdivisionName, value: s.subdivisionCode }))
}

// memoizedSubdivisions is a memoized version of fetchSubdivisionDropdownOptions
const memoizedSubdivisions = memoize(
  fetchSubdivisionDropdownOptions,
  (
    restService: RestService,
    formAnswers: any,
    itemKey: string,
    additional: { [key: string]: any }
  ) => {
    // the caching key is the selected country
    // this means that the cache is shared between all state dropdowns
    if (!additional?.countryDropdownItemKey) {
      return
    }

    if (!additional?.countries) {
      return
    }

    const countryFormPath = normalizeFormDropdownPaths(
      additional.countryDropdownItemKey,
      itemKey
    )
    const selectedCountry = getValueByPath(formAnswers, countryFormPath)
    if (!selectedCountry) {
      return
    }

    return selectedCountry
  }
)

const CreateParticipantPage = ({
  history,
  user,
  onSave,
  form,
  restService
}: ICreateParticipantPageProps) => {
  const [loading, setLoading] = useState(false)
  const [answers, setAnswers] = useState<any>({})
  const [applicationSteps, setApplicationSteps] = useState([])
  const [
    currentStep,
    setCurrentStep
  ] = useState<OnboardingApplicationStepConfig>()
  const [selectedKeys, setSelectedKeys] = useState([])
  const [countries, setCountries] = useState<ICountry[]>([])
  const [platforms, setPlatforms] = useState<IParticipant[]>([])

  const [requestId] = useState(uuid.v4())

  useEffect(() => {
    if (!currentStep) {
      fetchCountries(restService).then((countries) => {
        setCountries(countries)
      })
      setPlatforms(
        user.participants.filter(
          (participant: IParticipant) => participant.isAdmin
        )
      )
    }
  }, [])

  useEffect(() => {
    if (!!countries && countries.length > 0) {
      loadApplicationSteps()
    }
  }, [countries])

  useEffect(() => {
    // reloading the application steps can trigger a re-fetch on components that handle their own fetching
    reloadApplicationSteps()
  }, [answers])

  const loadApplicationSteps = () => {
    getApplicationSteps(countries, memoizedSubdivisions, platforms).then(
      (applicationSteps) => {
        setCurrentStep(applicationSteps[0])
        setApplicationSteps(applicationSteps)
        setSelectedKeys([applicationSteps[0].route])
      }
    )
  }

  // same fetch as loadApplicationSteps, but does not reset current step
  const reloadApplicationSteps = () => {
    getApplicationSteps(countries, memoizedSubdivisions, platforms).then(
      (applicationSteps) => {
        setApplicationSteps(applicationSteps)
      }
    )
  }

  const handleChange = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setAnswersState()
  }

  const setAnswersState = (loading = false) => {
    const newAnswers = captureAnswers(form, currentStep)
    const updatedAnswers = { ...answers, ...newAnswers }
    setAnswers(updatedAnswers)
    setLoading(loading)
  }

  const buildPayload = () => {
    const {
      applicant_name,
      applicant_email,
      applicant_dob,
      applicant_contact_number,
      applicant_address_line1,
      applicant_address_line2,
      applicant_address_city,
      applicant_address_state,
      applicant_address_zip,
      applicant_address_country,
      applicant_has_ssn,
      applicant_citizenship,
      applicant_ssn,
      applicant_non_usa_tax_id_number,
      applicant_non_usa_tax_id_country,
      entity_type,
      org_contact_number,
      org_legal_name,
      org_type,
      org_address_line1,
      org_address_line2,
      org_address_city,
      org_address_state,
      org_address_zip,
      org_address_country,
      org_website,
      org_date_established,
      org_ein,
      org_non_usa_entity_tax_id,
      org_has_beneficial_owner,
      persons,
      beneficial_owner,
      platform_code
    } = form.getFieldsValue()

    const payload = {
      user: null,
      naturalPerson: null,
      entityAndUsers: null
    }

    payload.user = {
      name: applicant_name,
      email: applicant_email,
      date_of_birth: formatDate(applicant_dob),
      contact_number: applicant_contact_number,
      address_one: applicant_address_line1,
      address_two: applicant_address_line2,
      city: applicant_address_city,
      state: applicant_address_state,
      zip: applicant_address_zip,
      country: applicant_address_country,
      id_number: applicant_has_ssn
        ? applicant_ssn
        : applicant_non_usa_tax_id_number,
      id_number_type: applicant_has_ssn
        ? IdNumberType.ID_NUMBER_TYPE_SSN
        : IdNumberType.ID_NUMBER_TYPE_NON_US_OTHER,
      id_issuing_authority: applicant_has_ssn
        ? 'United States'
        : applicant_non_usa_tax_id_country
    }

    if (entity_type === 'individual') {
      payload.naturalPerson = {
        request_id: requestId,
        first_name: applicant_name.split(' ')[0],
        last_name: applicant_name.split(' ').slice(1).join(' '),
        email: applicant_email,
        contact_number: applicant_contact_number,
        address_one: applicant_address_line1,
        address_two: applicant_address_line2,
        city: applicant_address_city,
        state: applicant_address_state,
        postal_code: applicant_address_zip,
        country: applicant_address_country,
        date_of_birth: formatDate(applicant_dob),
        legal_name: applicant_name,
        citizenship: applicant_has_ssn
          ? 'United States'
          : applicant_citizenship,
        id_number_type: applicant_has_ssn
          ? IdNumberType.ID_NUMBER_TYPE_SSN
          : IdNumberType.ID_NUMBER_TYPE_NON_US_OTHER,
        non_us_other_type: !applicant_has_ssn ? 'NON US DOCUMENT' : '',
        id_number: applicant_has_ssn
          ? applicant_ssn
          : applicant_non_usa_tax_id_number,
        metadata: '{}',
        id_issuing_authority: applicant_has_ssn
          ? 'United States'
          : applicant_non_usa_tax_id_country,
        signed_timestamp: Date.now().toString(),
        applicant: {
          isAdmin: true,
          isPrimaryContact: true,
          isControlPerson: true,
          isBeneficialOwner: false,
          beneficialOwner: BeneficialOwner.BENEFICIAL_OWNER_OVER_UNKNOWN,
          isTradeSubmitter: false
        },
        platform_code: platform_code
      }
    } else {
      payload.entityAndUsers = {
        request_id: requestId,
        name: org_legal_name,
        email: `portal-application_${uuid.v4()}@zerohash.fake`,
        contact_number: org_contact_number,
        website: org_website,
        address_one: org_address_line1,
        address_two: org_address_line2,
        city: org_address_city,
        state: org_address_state,
        postal_code: org_address_zip,
        country: org_address_country,
        date_established: formatDate(org_date_established),
        legal_name: org_legal_name,
        type: org_type,
        id_number_type: org_ein
          ? IdNumberType.ID_NUMBER_TYPE_EIN
          : IdNumberType.ID_NUMBER_TYPE_NON_US_OTHER,
        non_us_other_type: !org_ein ? 'NON US DOCUMENT' : '',
        id_number:
          org_address_country === 'United States of America'
            ? org_ein
            : org_non_usa_entity_tax_id,
        non_us_entity_tax_id: org_non_usa_entity_tax_id,
        metadata: '{}',
        id_issuing_authority: org_ein ? 'United States' : org_address_country,
        signed_timestamp: Date.now().toString(),
        beneficial_owner_exemption: !org_has_beneficial_owner,
        users: [],
        applicant: {
          isAdmin: true,
          isBeneficialOwner:
            beneficial_owner &&
            beneficial_owner !== BeneficialOwner.BENEFICIAL_OWNER_OVER_UNKNOWN,
          isPrimaryContact: true,
          isTradeSubmitter: true,
          beneficialOwner:
            beneficial_owner &&
            beneficial_owner !== BeneficialOwner.BENEFICIAL_OWNER_OVER_UNKNOWN
              ? beneficial_owner
              : null,
          isControlPerson: true
        }
      }

      persons.forEach((person: any) => {
        payload.entityAndUsers.users.push({
          email: person.email,
          contact_number: person.contact_number,
          name: person.name,
          address_one: person.address_line1,
          address_two: person.address_line2,
          city: person.address_city,
          state:
            person.address_country === 'United States of America'
              ? person.address_state
              : null,
          zip: person.address_zip,
          country: person.address_country,
          id_number: person.has_ssn ? person.ssn : person.non_usa_tax_id_number,
          id_number_type: person.has_ssn
            ? IdNumberType.ID_NUMBER_TYPE_SSN
            : IdNumberType.ID_NUMBER_TYPE_NON_US_PASSPORT,
          id_issuing_authority: person.has_ssn
            ? 'United States'
            : person.non_usa_tax_id_country,
          date_of_birth: formatDate(person.dob),
          non_us_other_type: person.has_ssn ? null : 'NON US DOCUMENT',
          title: person.title,
          beneficial_owner: person.beneficial_owner,
          is_control_person: person.type.includes('Control Person')
        })
      })
    }

    return payload
  }

  const submitDocuments = async (
    participantCode: any,
    userCode: string,
    usersFromEntity: Array<any> = []
  ) => {
    const {
      business_license,
      business_name_filing_document,
      partnership_agreement,
      operating_agreement,
      articles_of_organization,
      certificate_of_organization,
      certificate_of_incorporation,
      articles_of_incorporation,
      corporate_bylaws,
      org_proof_address,
      applicant_proof_address,
      applicant_non_usa_tax_id_document,
      persons
    } = form.getFieldsValue()

    const entityDocuments: Array<any> = []
    const usersDocuments: Array<any> = []

    // Applicant user documents
    if (applicant_proof_address) {
      entityDocuments.push({
        document: applicant_proof_address.file,
        name: applicant_proof_address.file.name,
        type: DocumentType.DOCUMENT_PROOF_OF_ADDRESS,
        mime: applicant_proof_address.file.type
      })
      usersDocuments.push({
        document: applicant_proof_address.file,
        name: applicant_proof_address.file.name,
        type: DocumentType.DOCUMENT_PROOF_OF_ADDRESS,
        mime: applicant_proof_address.file.type,
        userCode
      })
    }
    if (applicant_non_usa_tax_id_document) {
      entityDocuments.push({
        document: applicant_non_usa_tax_id_document.file,
        name: applicant_non_usa_tax_id_document.file.name,
        type: DocumentType.DOCUMENT_NON_US_OTHER,
        mime: applicant_non_usa_tax_id_document.file.type
      })
      usersDocuments.push({
        document: applicant_non_usa_tax_id_document.file,
        name: applicant_non_usa_tax_id_document.file.name,
        type: DocumentType.DOCUMENT_NON_US_OTHER,
        mime: applicant_non_usa_tax_id_document.file.type,
        userCode
      })
    }

    // Entity Documents
    if (business_license) {
      entityDocuments.push({
        document: business_license.file,
        name: business_license.file.name,
        type: DocumentType.DOCUMENT_BUSINESS_LICENSE,
        mime: business_license.file.type
      })
    }
    if (partnership_agreement) {
      entityDocuments.push({
        document: partnership_agreement.file,
        name: partnership_agreement.file.name,
        type: DocumentType.DOCUMENT_PARTNERSHIP_AGREEMENT,
        mime: partnership_agreement.file.type
      })
    }
    if (operating_agreement) {
      entityDocuments.push({
        document: operating_agreement.file,
        name: operating_agreement.file.name,
        type: DocumentType.DOCUMENT_OPERATING_AGREEMENT,
        mime: operating_agreement.file.type
      })
    }
    if (articles_of_organization) {
      entityDocuments.push({
        document: articles_of_organization.file,
        name: articles_of_organization.file.name,
        type: DocumentType.DOCUMENT_ARTICLES_OF_ORGANIZATION,
        mime: articles_of_organization.file.type
      })
    }
    if (certificate_of_organization) {
      entityDocuments.push({
        document: certificate_of_organization.file,
        name: certificate_of_organization.file.name,
        type: DocumentType.DOCUMENT_CERTIFICATE_OF_ORGANIZATION,
        mime: certificate_of_organization.file.type
      })
    }
    if (certificate_of_incorporation) {
      entityDocuments.push({
        document: certificate_of_incorporation.file,
        name: certificate_of_incorporation.file.name,
        type: DocumentType.DOCUMENT_CERTIFICATE_OF_INCORPORATION,
        mime: certificate_of_incorporation.file.type
      })
    }
    if (articles_of_incorporation) {
      entityDocuments.push({
        document: articles_of_incorporation.file,
        name: articles_of_incorporation.file.name,
        type: DocumentType.DOCUMENT_ARTICLES_OF_INCORPORATION,
        mime: articles_of_incorporation.file.type
      })
    }
    if (corporate_bylaws) {
      entityDocuments.push({
        document: corporate_bylaws.file,
        name: corporate_bylaws.file.name,
        type: DocumentType.DOCUMENT_BY_LAWS,
        mime: corporate_bylaws.file.type
      })
    }
    if (business_name_filing_document) {
      entityDocuments.push({
        document: business_name_filing_document.file,
        name: business_name_filing_document.file.name,
        type: DocumentType.DOCUMENT_BUSINESS_NAME_FILLING_DOCUMENT,
        mime: business_name_filing_document.file.type
      })
    }
    if (org_proof_address) {
      entityDocuments.push({
        document: org_proof_address.file,
        name: org_proof_address.file.name,
        type: DocumentType.DOCUMENT_PROOF_OF_ADDRESS,
        mime: org_proof_address.file.type
      })
    }

    // Documents from Users created from Entity form
    if (usersFromEntity.length > 0) {
      usersFromEntity.forEach((user) => {
        const [userFromPersons] = persons.filter(
          (person: any) => person.email === user.email
        )

        if (!userFromPersons.has_ssn) {
          usersDocuments.push({
            document: userFromPersons.non_usa_tax_id_document.file,
            name: userFromPersons.non_usa_tax_id_document.file.name,
            type: userFromPersons.united_states_ssn
              ? DocumentType.DOCUMENT_SSN
              : DocumentType.DOCUMENT_NON_US_OTHER,
            mime: userFromPersons.non_usa_tax_id_document.file.type,
            userCode: user.userCode
          })
        }

        usersDocuments.push({
          document: userFromPersons.proof_address.file,
          name: userFromPersons.proof_address.file.name,
          type: DocumentType.DOCUMENT_PROOF_OF_ADDRESS,
          mime: userFromPersons.proof_address.file.type,
          userCode: user.userCode
        })
      })
    }

    await Promise.all([
      ...usersDocuments.map(async (doc) => {
        return restService
          .route(`users/${doc.userCode}/application_document`)
          .sendDocument({
            file: doc.document,
            documentType: doc.type,
            userCode: doc.userCode
          })
      }),
      ...entityDocuments.map(async (doc) => {
        return restService
          .route(`${participantCode}/application_document`)
          .sendDocument({
            file: doc.document,
            documentType: doc.type,
            participantCode
          })
      })
    ])
  }

  const formatDate = (date: string) => {
    const dateArr = date.split('/')

    return dateArr[2] + '-' + dateArr[0] + '-' + dateArr[1]
  }

  const onSubmitApplication = () => {
    form.validateFields(async (err: any, values: any) => {
      if (err) {
        message.error(
          'Please insert all required information before submitting'
        )
        return
      }

      const participantType = form.getFieldValue('entity_type')

      if (participantType === 'business') {
        const persons = form.getFieldValue('persons') ?? []
        const beneficialOwnerExemption = !form.getFieldValue(
          'org_has_beneficial_owner'
        )

        const hasAtLeastOneControlPerson = persons?.filter((person) =>
          person.type.includes('Control Person')
        )

        if (
          hasAtLeastOneControlPerson &&
          hasAtLeastOneControlPerson.length === 0
        ) {
          message.error('There must be at least one control person')
          return
        }

        if (!beneficialOwnerExemption) {
          const applicantBeneficialOwner = form.getFieldValue(
            'beneficial_owner'
          )

          const isApplicantABeneficialOwner =
            typeof applicantBeneficialOwner !== 'undefined' &&
            applicantBeneficialOwner !==
              BeneficialOwner.BENEFICIAL_OWNER_OVER_UNKNOWN

          const hasAtLeastOneBeneficialOwner = persons?.filter((person) =>
            person.type.includes('Beneficial Owner')
          )

          if (
            hasAtLeastOneBeneficialOwner &&
            hasAtLeastOneBeneficialOwner.length === 0 &&
            !isApplicantABeneficialOwner
          ) {
            message.error('There must be at least one beneficial owner')
            return
          }
        }

        persons
          .map((elem) => elem.email)
          .forEach((email, i, arr) => {
            if (arr.indexOf(email) !== i) {
              notification.warning({
                message: `You have entered a person with ${email} email twice!`
              })
            }
          })
      }

      setLoading(true)

      try {
        const payload = buildPayload()

        // Get Or Create User
        const getOrCreateUser = await restService
          .route('users/create/self')
          .post(payload.user)

        const userCode = getOrCreateUser.data.user.userCode

        let submitApplicationRequest = null
        let participantCode = null
        let usersFromEntity = []

        if (payload.naturalPerson) {
          submitApplicationRequest = await restService
            .route('natural_person')
            .post(payload.naturalPerson)

          participantCode = submitApplicationRequest.data.participantCode
        } else {
          submitApplicationRequest = await restService
            .route('entity_and_users')
            .post(payload.entityAndUsers)

          usersFromEntity = submitApplicationRequest.data.usersList
          participantCode = submitApplicationRequest.data.entity.participantCode
        }

        await submitDocuments(participantCode, userCode, usersFromEntity)

        message.success('Participant created successfully!')

        // get the latest data from participant_users before loading the dashboard.
        await onSave()

        history.push(`/${participantCode}/dashboard`)
        return
      } catch (err) {
        console.log(err)

        throw err
      } finally {
        setLoading(false)
      }
    })
  }

  return (
    <>
      <Layout hasSider={true}>
        <Sider>
          {applicationSteps.length !== 0 && (
            <Menu
              defaultSelectedKeys={[applicationSteps[0].route]}
              selectedKeys={selectedKeys}
            >
              {applicationSteps.map((step) => (
                <Menu.Item
                  key={step.route}
                  onClick={() => {
                    setCurrentStep(step)
                    setSelectedKeys([step.route])
                  }}
                >
                  {step.label}
                </Menu.Item>
              ))}
            </Menu>
          )}
        </Sider>
        <Layout>
          <Content>
            <Spin spinning={loading}>
              <Form
                layout="vertical"
                data-testid="create-participant-form"
                onChange={handleChange}
                // special case for AutoComplete fields. onChange is not triggered when the value is changed
                onSelect={handleChange}
              >
                {currentStep?.items
                  .filter((item) =>
                    item.displayIf ? item.displayIf(answers) : true
                  )
                  .map((item) => (
                    <div style={{ margin: 8 }} key={item.itemKey}>
                      <OnboardingApplicationFormItemSwitch
                        value={getValueFromAnswers(item.itemKey, answers)}
                        form={form}
                        formItemLayout={null}
                        answers={answers}
                        restService={restService}
                        participant={null}
                        authUser={user}
                        {...item}
                      />
                      {currentStep.stepId === 'directions' &&
                        item.itemKey === 'directions_4' && (
                          <Button
                            style={{ float: 'right' }}
                            type="primary"
                            onClick={() => {
                              setCurrentStep(applicationSteps[1])
                              setSelectedKeys([applicationSteps[1].route])
                            }}
                          >
                            Next
                          </Button>
                        )}
                      {currentStep.stepId === 'applicant-information' &&
                        item.itemKey === 'review_head' && (
                          <Button
                            type="primary"
                            onClick={() => onSubmitApplication()}
                            disabled={loading}
                          >
                            Submit Application
                          </Button>
                        )}
                    </div>
                  ))}
              </Form>
            </Spin>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}

const CreateParticipantPageWithRouter = withRouter<
  ICreateParticipantPageProps,
  any
>(CreateParticipantPage)
const CreateParticipantPageWithForm = Form.create<ICreateParticipantPageProps>()(
  CreateParticipantPageWithRouter
)
export default CreateParticipantPageWithForm
