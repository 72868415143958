import * as React from 'react'

const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  month: 'long',
  second: '2-digit',
  weekday: 'short'
}
const dateFormatter = (n: number | string | Date): JSX.Element => (
  <span>{new Date(n).toLocaleString('en-US', options)}</span>
)

export default dateFormatter
