import * as React from 'react'
import * as Redux from 'react-redux'

import * as positionsActions from '../../../../store/platform-positions/actions'

export function usePullPlatformPositions({
  platformCode,
  page,
  pageSize
}: {
  platformCode: string
  page: number
  pageSize?: number
}) {
  const dispatch = Redux.useDispatch()

  React.useEffect(() => {
    const pullInterval = setInterval(() => {
      dispatch(
        positionsActions.fetchPlatformPositions({
          platformCode,
          page,
          pageSize
        })
      )
    }, 10000)

    return () => {
      clearInterval(pullInterval)
    }
  }, [platformCode, page, pageSize])
}
