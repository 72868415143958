import { createReducer } from '@reduxjs/toolkit'
import { IParticipantPositionsState } from './state'
import * as ParticipantPositionsActions from './actions'
import {
  IFetchParticipantPositionsFulfillActionSuccess,
  IFetchParticipantPositionsFulfillActionFail
} from './actions/types'

const initialState: IParticipantPositionsState = {
  positions: {
    value: [],
    loading: false
  }
}

export default createReducer(initialState, {
  [ParticipantPositionsActions.fetchParticipantPositions.type]: (state) => {
    return {
      ...state,
      positions: {
        ...state.positions,
        loading: true
      }
    }
  },
  [ParticipantPositionsActions.fetchParticipantPositionsFulfill.type]: (
    state,
    action:
      | IFetchParticipantPositionsFulfillActionSuccess
      | IFetchParticipantPositionsFulfillActionFail
  ) => {
    const nextState = {
      ...state,
      positions: {
        ...state.positions,
        loading: false
      }
    }

    if (action.error) {
      return nextState
    } else {
      return {
        ...nextState,
        positions: {
          value: (action as IFetchParticipantPositionsFulfillActionSuccess)
            .payload,
          loading: false
        }
      }
    }
  }
})
