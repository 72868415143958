export enum API_KEY_STATE_ACTION {
  CREATE = 'CREATE',
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  DISABLE = 'DISABLE'
}

export enum API_KEY_STATE_STATUS {
  CREATED = 'CREATED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  DISABLED = 'DISABLED',
  PENDING_APPROVAL = 'PENDING_APPROVAL'
}

export interface IApiKeyPermissionDefinition {
  action: string
  resource: string
}

export interface IApiKeyPermission {
  id: number
  type: string
  definition: IApiKeyPermissionDefinition
}

export interface IOldApiKeyPermissionDefinition {
  action: string
  resource: string
}

export interface IOldApiKeyPermission {
  id: number
  created_at: string
  definition: IOldApiKeyPermissionDefinition
  type: string
}

export interface IApiKeyHistoryRecord {
  created_at: string
  executed_by_user_email: string
  action: API_KEY_STATE_ACTION
  status: API_KEY_STATE_STATUS
  timestamp: string
}

export interface IApiKeyLedger {
  key_id: string
  api_key_id_old: number
  name: string
  status: API_KEY_STATE_STATUS
  public_key: string
  private_key: string
  passphrase_salt: string
  passphrase_hash: string
  created_at: string
  expire_at?: string
  timestamp: string
  participant_id: number
  participant_code: string
  participant_name: string
  permissions: IApiKeyPermission[]
  history: IApiKeyHistoryRecord[]
  allowed_ips: string[]
  withdrawals_account_label: string | null
  approvals_count: number
}

export interface IApiKeyLedgerTableItemPermission {
  actions: string[]
  resource: string
}

export interface IApiKeyLedgerTableItem
  extends Omit<IApiKeyLedger, 'private_key' | 'permissions'> {
  permissions: IApiKeyLedgerTableItemPermission[]
}

export const mapResourceNameToDisplayString = {
  positions: 'Positions',
  trades: 'Trades',
  accounts: 'Accounts',
  participants: 'Participants',
  withdrawals: 'Withdrawals',
  deposits: 'Deposits',
  liquidity: 'Liquidity',
  transfers: 'Transfers',
  loans: 'Loans',
  rewards: 'Rewards',
  index: 'Index',
  awards: 'Awards',
  assets: 'Assets',
  convert_withdraw: 'Convert and Withdraw',
  movements: 'Movements',
  market_data: 'Market Data',
  fund: 'Fund',
  payments: 'Payments',
  basic_info: 'Participant basic_info',
  sanction_screening_info: 'Participant sanction_screening_info',
  full_info: 'Participant full_info'
}

export type ApiKeyLedgerTableItemHistory = {
  action: string
  created_at: string
  executed_by_user_email: string
  status: string
  timestamp: string
  transfers_usd_only: boolean
}

export type NewApiKeyLedgerTableItem = {
  allowed_ips: string[]
  api_key_id_old: number
  approvals_count: number
  created_at: string
  expire_at: string
  history: ApiKeyLedgerTableItemHistory[]
  key_id: string
  name: string
  participant_code: string
  participant_id: number
  participant_name: string
  passphrase_hash: string
  passphrase_salt: string
  permissions: IOldApiKeyPermission[]
  private_key: string
  public_key: string
  status: string
  timestamp: string
  transfers_usd_only: boolean
  withdrawals_account_label: string
}

export interface ParsedApiKeys
  extends Omit<NewApiKeyLedgerTableItem, 'permissions'> {
  permissions: IApiKeyLedgerTableItemPermission[]
}
