import * as React from 'react'
import { Button, Layout } from 'antd'
import { Link } from 'react-router-dom'
import { IOnboardingApplicationStepProps } from './OnboardingApplicationStep'

const { Footer } = Layout

interface IOnboardingApplicatonFooterProps
  extends IOnboardingApplicationStepProps {
  nextStepTrigger: () => void
  submitApplication: () => Promise<void>
}

const OnboardingApplicationStepFooter = (
  props: IOnboardingApplicatonFooterProps
) => {
  const {
    nextStep,
    previousStep,
    currentStep,
    participant,
    submitApplication,
    loading
  } = props

  const style = { margin: 2 }

  return (
    <Footer
      style={{ display: 'flex', flexDirection: 'row-reverse', paddingRight: 8 }}
    >
      {!nextStep ? (
        <Button
          data-testid="onboarding-button-submit"
          style={{ ...style, float: 'right' }}
          type="primary"
          onClick={submitApplication}
          htmlType="submit"
          disabled={loading}
        >
          Submit Application
        </Button>
      ) : null}
      {!currentStep.readonly && nextStep ? (
        <Button
          data-testid="onboarding-button-continue"
          style={{ ...style, float: 'right' }}
          onClick={props.nextStepTrigger}
          type="primary"
          htmlType="submit"
          disabled={loading}
        >
          Save & Continue
        </Button>
      ) : null}
      {currentStep.readonly && nextStep ? (
        <Link to={`/${participant.code}/application/${nextStep.route}`}>
          <Button
            data-testid="onboarding-button-next"
            style={{ ...style, float: 'right' }}
            type="primary"
            id="btn_next"
          >
            Next
          </Button>
        </Link>
      ) : null}
      {previousStep ? (
        <Link to={`/${participant.code}/application/${previousStep.route}`}>
          <Button
            data-testid="onboarding-button-previous"
            style={{ ...style, float: 'right' }}
            type="primary"
          >
            Previous Page
          </Button>
        </Link>
      ) : null}
    </Footer>
  )
}

export default OnboardingApplicationStepFooter
