import { connect } from 'react-redux'
import {
  IAssetBalancesDownloadInputProps,
  IAssetBalancesDownloadExplicitProps
} from './props'
import selectors from '../../../store/selectors'
import { IApplicationState } from '../../../store/state'

export default connect<
  IAssetBalancesDownloadInputProps,
  null,
  IAssetBalancesDownloadExplicitProps,
  IApplicationState
>((state) => ({
  assetBalances: selectors.funds.getBalances(state) || []
}))
