import React from 'react'
import { Button, Modal } from 'antd'
import Title from 'seed-shared-components/lib/components/Title'
import { PendingParticipantUser } from './types'

interface PermissionsAlertProps {
  newRecord: PendingParticipantUser
  onApprove: () => void
  onReject: () => void
  onCancel: () => void
  isSubmitting: boolean
}

export const PendingUserReviewConfirmation = ({
  newRecord,
  onApprove,
  onReject,
  onCancel,
  isSubmitting
}: PermissionsAlertProps) => (
  <Modal
    title={<Title level={3}>Review Pending User</Title>}
    onCancel={onCancel}
    open
    width={650}
    footer={[
      <Button
        loading={isSubmitting}
        disabled={isSubmitting}
        danger
        key="reject"
        onClick={onReject}
      >
        Reject
      </Button>,
      <Button
        loading={isSubmitting}
        disabled={isSubmitting}
        type="primary"
        key="approve"
        onClick={onApprove}
      >
        Approve
      </Button>
    ]}
  >
    <>
      <div style={{ margin: 8 }}>
        <p className="justify-text">
          <b>User Email:</b> {newRecord.userEmail}
        </p>
        <p className="justify-text">
          <b>Submitted by:</b>{' '}
          {newRecord.request.participantuser.actionUserEmail}
        </p>
      </div>
      <div style={{ margin: 8 }}>
        <p className="justify-text">
          <i>
            Approving this pending user will result in them becoming an user of
            your platform. Please be careful when reviewing this information.
          </i>
        </p>
      </div>
    </>
  </Modal>
)
