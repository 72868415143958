import { FSA, IFSAData } from '../../FSA'
import {
  IPortalApiWithdrawalAccount,
  IPortalApiWithdrawalRequest
} from '../../../withdrawal/withdrawalTypes'
import { ITransferRequest } from '../../../transfers/components/TransfersPage'

const domain = 'WITHDRAWALS'

export const WITHDRAWAL_GET_ACCOUNTS = `${domain}/GET_ACCOUNTS`
export const WITHDRAWAL_GET_ACCOUNTS_SUCCESS = `${domain}/GET_ACCOUNTS_SUCCESS`
export const WITHDRAWAL_GET_ACCOUNTS_FAILURE = `${domain}/GET_ACCOUNTS_FAILURE`
export const WITHDRAWAL_GET_WITHDRAWAL = `${domain}/GET_WITHDRAWAL`
export const WITHDRAWAL_GET_WITHDRAWAL_SUCCESS = `${domain}/GET_WITHDRAWAL_SUCCESS`
export const WITHDRAWAL_GET_WITHDRAWAL_FAILURE = `${domain}/GET_WITHDRAWAL_FAILURE`
export const GET_PLATFORM_TRANSFERS = `${domain}/GET_PLATFORM_TRANSFERS`
export const GET_PLATFORM_TRANSFERS_SUCCESS = `${domain}/GET_PLATFORM_TRANSFERS_SUCCESS`
export const GET_PLATFORM_TRANSFERS_FAILURE = `${domain}/GET_PLATFORM_TRANSFERS_FAILURE`
export const FETCH_WITHDRAWAL_REQUEST_HISTORY = `${domain}/FETCH_WITHDRAWAL_REQUEST_HISTORY`
export const FETCH_WITHDRAWAL_REQUEST_HISTORY_FULFILL = `${domain}/FETCH_WITHDRAWAL_REQUEST_HISTORY_FULFILL`

export interface IGetWithdrawalAccountsPayload {
  participantCode: string
}

export interface IGetWithdrawalAccountsAction {
  type: typeof WITHDRAWAL_GET_ACCOUNTS
  payload: IGetWithdrawalAccountsPayload
}

export interface IGetWithdrawalAccountsSuccessPayload {
  accounts: IPortalApiWithdrawalAccount[]
}

export interface IGetWithdrawalAccountsSuccessAction {
  type: typeof WITHDRAWAL_GET_ACCOUNTS_SUCCESS
  payload: IGetWithdrawalAccountsSuccessPayload
}

export interface IGetWithdrawalsPayload {
  participantCode: string
}

export interface IGetWithdrawalsAction {
  type: typeof WITHDRAWAL_GET_WITHDRAWAL
  payload: IGetWithdrawalsPayload
}

export interface IGetWithdrawalsSuccessPayload {
  withdrawals: IPortalApiWithdrawalRequest[]
}

export interface IGetWithdrawalsSuccessAction {
  type: typeof WITHDRAWAL_GET_WITHDRAWAL_SUCCESS
  payload: IGetWithdrawalsSuccessPayload
}

export interface IGetPlatformTransfersPayload {
  participantCode: string
}

export interface IGetPlatformTransfersAction {
  type: typeof GET_PLATFORM_TRANSFERS
  payload: IGetPlatformTransfersPayload
}

export interface IGetPlatformTransfersSuccessPayload {
  platformTransfers: ITransferRequest[]
}

export interface IGetPlatformTransfersSuccessAction {
  type: typeof GET_PLATFORM_TRANSFERS_SUCCESS
  payload: IGetPlatformTransfersSuccessPayload
}

export interface IFetchWithdrawalRequestHistoryActionPayload {
  participantCode: string
  withdrawalRequestId: number | string
}

export type IFetchWithdrawalRequestHistoryFulfillActionSuccessPayload = IPortalApiWithdrawalRequest[]

export interface IFetchWithdrawalRequestHistoryAction
  extends FSA<string, IFetchWithdrawalRequestHistoryActionPayload> {
  type: typeof FETCH_WITHDRAWAL_REQUEST_HISTORY
}

export interface IFetchWithdrawalRequestHistoryFulfillAction<Payload, Meta>
  extends IFSAData<Payload, boolean, Meta> {
  type: typeof FETCH_WITHDRAWAL_REQUEST_HISTORY_FULFILL
}

export interface IFetchWithdrawalRequestHistoryFulfillActionSuccess
  extends IFetchWithdrawalRequestHistoryFulfillAction<
    IFetchWithdrawalRequestHistoryFulfillActionSuccessPayload,
    { withdrawalRequestId: string }
  > {
  error: false
}

export interface IFetchWithdrawalRequestHistoryFulfillActionFail
  extends IFetchWithdrawalRequestHistoryFulfillAction<
    Error,
    { withdrawalRequestId: string }
  > {
  error: true
}
