import { FormInputItem } from '.'
import * as React from 'react'
import { Upload, message, Tooltip } from 'antd'
import FormItem from '@ant-design/compatible/es/form/FormItem'
import { UploadFile } from 'antd/lib/upload/interface'
const Dragger = Upload.Dragger
import AuthContext from '../../../auth/AuthUserContext'
import { IParticipant } from 'seed-shared-components/lib/types'
import { ICustomRequestDocumentItemConfig } from '../../new-world-configuration'
import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons'

export interface IDocumentFormItemProps
  extends FormInputItem<ICustomRequestDocumentItemConfig> {
  participant: IParticipant
  application_document: boolean
}
interface IDocumentFormItemState {
  filesForDisplay: any[]
  fileObjects: PayloadDocument
  uploadInProgress: boolean
  uploadRoute: string
}

interface PayloadDocument {
  [fileName: string]: {
    progress: number
    state: string // "Upload error: 403",
    size: number
    type: string // "text/plain"
    uploadedName: string
  }
}

const turnPayloadFormatIntoAntdFiles = (files: PayloadDocument): UploadFile[] =>
  files
    ? Object.keys(files).reduce((accum: UploadFile[], fileName) => {
        // fix to support half-finished applications started on Portal1
        if (fileName === 'error' || Number(fileName) >= 0) {
          return accum
        }
        const file: UploadFile = {
          uid: fileName,
          name: fileName,
          percent: 100,
          size: files[fileName].size,
          type: files[fileName].type
        }
        return [...accum, file]
      }, [])
    : []

class CustomRequestDocumentFormItem extends React.Component<
  IDocumentFormItemProps,
  IDocumentFormItemState
> {
  static context = AuthContext
  context!: React.ContextType<typeof AuthContext>
  uploadUrl = `/documents`

  constructor(props: IDocumentFormItemProps) {
    super(props)

    this.state = {
      filesForDisplay: [],
      fileObjects: typeof this.props.value === 'object' ? this.props.value : {},
      uploadInProgress: false,
      uploadRoute: this.props.application_document
        ? 'application_document'
        : 'supplemental_document'
    }

    this.validateFileSizeBeforeUpload = this.validateFileSizeBeforeUpload.bind(
      this
    )
  }

  validateFileSizeBeforeUpload(file: any, fileList: any[]) {
    if (file && file.size) {
      const isValidSize = file.size / 1024 / 1024 < 20
      if (!isValidSize) {
        message.error('File must be smaller than 20mb')
      }
      return isValidSize
    }
    return true
  }

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        // removes meta info of file, leaving only content
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4))
        }
        resolve(encoded)
      }
      reader.onerror = (error) => reject(error)
    })
  }

  customRequest = async (options) => {
    const { file, data } = options

    this.props.form.setFieldsValue({
      [this.props.itemKey]: {
        file
      }
    })

    this.setState({
      filesForDisplay: [
        {
          uid: file.name,
          name: file.name,
          percent: 100,
          size: file.size,
          type: file.type
        }
      ]
    })
  }

  render() {
    if (!this.props.restService) {
      return null
    }
    const uploadProps = {
      name: 'file',
      multiple: false,
      action: `${this.props.restService.getUrlBase()}${this.state.uploadRoute}`,
      headers: { Authorization: `Bearer ${this.context.idToken}` }
    }
    // support documents nested in arrays
    let documentType = this.props.itemKey
    if (this.props.parentKey) {
      const lastProp = this.props.itemKey
        .split('[')
        [this.props.itemKey.split('[').length - 1].slice(0, -1)
      documentType = lastProp
    }
    return (
      <div>
        <FormItem
          {...this.props.formItemLayout}
          label={
            this.props.label ? (
              this.props.tooltip ? (
                <Tooltip title={this.props.tooltip} placement="leftTop">
                  <span style={{ whiteSpace: 'normal' }}>
                    {this.props.label}
                    &nbsp;
                    <QuestionCircleOutlined />
                  </span>
                </Tooltip>
              ) : (
                <span style={{ whiteSpace: 'normal' }}>{this.props.label}</span>
              )
            ) : undefined
          }
        >
          {this.props.form.getFieldDecorator(this.props.itemKey, {
            initialValue: this.props.value,
            rules: [...(this.props.rules || [])]
          })(
            <div className="upload-item">
              <Dragger
                {...uploadProps}
                fileList={this.state.filesForDisplay}
                beforeUpload={this.validateFileSizeBeforeUpload}
                customRequest={this.customRequest}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drop a file here to upload
                </p>
                <p className="ant-upload-hint">
                  most common file types supported, up to 20mb
                </p>
              </Dragger>
            </div>
          )}
        </FormItem>
      </div>
    )
  }
}
CustomRequestDocumentFormItem.contextType = AuthContext
export default CustomRequestDocumentFormItem
