import { put, takeEvery, call, select } from 'typed-redux-saga'
import { notification } from 'antd'
import {
  IGetWithdrawalAccountsAction,
  IGetWithdrawalsAction,
  IFetchWithdrawalRequestHistoryAction
} from './actions/types'
import { IApplicationState } from '../state'
import * as WithdrawalActions from './actions'
import { withdrawalAPI } from './api'

const getUser = (state: IApplicationState) => state.user

function* getAccountsSaga(action: IGetWithdrawalAccountsAction) {
  try {
    const { token, userIdentifier } = yield select(getUser)
    const { getAccounts } = withdrawalAPI({ token, userIdentifier })
    const result = yield* call(getAccounts, action.payload.participantCode)

    yield put(
      WithdrawalActions.getAccountsSuccess({
        accounts: result.message
      })
    )
  } catch (ex) {
    const error: Error = ex
    yield put(
      WithdrawalActions.getAccountsFailure({
        error
      })
    )

    notification.error({
      description: 'Please try again, contact support.',
      duration: 0,
      message: 'Failed fetching Accounts'
    })
  }
}

function* watchGetAccounts() {
  yield takeEvery(WithdrawalActions.getAccounts.type, getAccountsSaga)
}

function* getWithdrawalsSaga(action: IGetWithdrawalsAction) {
  try {
    const { token, userIdentifier } = yield select(getUser)
    const { getWithdrawals } = withdrawalAPI({ token, userIdentifier })
    const { result } = yield* call(
      getWithdrawals,
      action.payload.participantCode
    )
    yield put(
      WithdrawalActions.getWithdrawalsSuccess({
        withdrawals: result
      })
    )
  } catch (ex) {
    const error: Error = ex
    yield put(
      WithdrawalActions.getWithdrawalsFailure({
        error
      })
    )

    notification.error({
      description: 'Please try again, contact support.',
      duration: 0,
      message: 'Failed fetching Withdrawals'
    })
  }
}

function* watchGetWithdrawals() {
  yield takeEvery(WithdrawalActions.getWithdrawals.type, getWithdrawalsSaga)
}

function* fetchWithdrawalRequestHistorySaga(
  action: IFetchWithdrawalRequestHistoryAction
) {
  const { participantCode, withdrawalRequestId } = action.payload
  const { token, userIdentifier } = yield select(getUser)

  try {
    const { getWithdrawalRequestHistory } = withdrawalAPI({
      token,
      userIdentifier
    })
    const { result: history } = yield* call(
      getWithdrawalRequestHistory,
      participantCode,
      withdrawalRequestId
    )
    yield put(
      WithdrawalActions.fetchWithdrawalRequestHistoryFulfill({
        payload: history,
        meta: {
          withdrawalRequestId
        },
        error: false
      })
    )
  } catch (error) {
    yield put(
      WithdrawalActions.fetchWithdrawalRequestHistoryFulfill({
        payload: error as Error,
        meta: {
          withdrawalRequestId
        },
        error: true
      })
    )

    notification.error({
      description: 'Please try again, contact support.',
      duration: 0,
      message: 'Failed fetching Withdrawal Request History'
    })
  }
}

function* watchFetchWithdrawalRequestHistory() {
  yield takeEvery(
    WithdrawalActions.fetchWithdrawalRequestHistory.type,
    fetchWithdrawalRequestHistorySaga
  )
}

function* getplatformTransfersSaga(action: IGetWithdrawalsAction) {
  try {
    const { token, userIdentifier } = yield select(getUser)
    const { getplatformTransfers } = withdrawalAPI({
      token,
      userIdentifier,
      customErrorMessage: 'Failed fetching Platform Transfers'
    })
    const result = yield* call(
      getplatformTransfers,
      action.payload.participantCode
    )
    yield put(
      WithdrawalActions.getplatformTransfersSuccess({
        platformTransfers: result.message
      })
    )
  } catch (ex) {
    const error: Error = ex
    yield put(
      WithdrawalActions.getplatformTransfersFailure({
        error
      })
    )
  }
}

function* watchGetplatformTransfers() {
  yield takeEvery(
    WithdrawalActions.getplatformTransfers.type,
    getplatformTransfersSaga
  )
}

export {
  watchGetAccounts,
  watchGetWithdrawals,
  watchFetchWithdrawalRequestHistory,
  watchGetplatformTransfers
}
