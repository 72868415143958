import { createReducer } from '@reduxjs/toolkit'
import { IPlatformPositionsState } from './state'
import * as PlatformPositionsActions from './actions'
import {
  IFetchPlatformPositionsFulfillActionSuccess,
  IFetchPlatformPositionsFulfillActionFail
} from './actions/types'

const initialState: IPlatformPositionsState = {
  positions: {
    value: [],
    loading: false
  }
}

export default createReducer(initialState, {
  [PlatformPositionsActions.fetchPlatformPositions.type]: (state) => {
    return {
      ...state,
      positions: {
        ...state.positions,
        loading: true
      }
    }
  },
  [PlatformPositionsActions.fetchPlatformPositionsFulfill.type]: (
    state,
    action:
      | IFetchPlatformPositionsFulfillActionSuccess
      | IFetchPlatformPositionsFulfillActionFail
  ) => {
    const nextState = {
      ...state,
      positions: {
        ...state.positions,
        loading: false
      }
    }

    if (action.error) {
      return nextState
    } else {
      return {
        ...nextState,
        positions: {
          value: (action as IFetchPlatformPositionsFulfillActionSuccess)
            .payload,
          loading: false
        }
      }
    }
  }
})
