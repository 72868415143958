import { IAccountHistory, IAccount } from '../state'

export const HISTORY_LOAD = 'ACCOUNT_HISTORY/LOAD'
export const HISTORY_LOAD_FAILURE = 'ACCOUNT_HISTORY/LOAD_FAILURE'
export const HISTORY_LOAD_SUCCESS = 'ACCOUNT_HISTORY/LOAD_SUCCESS'
export const HISTORY_SELECT_ACCOUNT = 'ACCOUNT_HISTORY/SELECT_ACCOUNT'
export const HISTORY_UNMOUNT = 'ACCOUNT_HISTORY/UNMOUNT'

export interface ILoadHistorySuccessPayload {
  history: IAccountHistory
}

export interface ISelectHistoryAccountPayload {
  account: IAccount
}

export interface ILoadHistorySuccessAction {
  type: typeof HISTORY_LOAD_SUCCESS
  payload: ILoadHistorySuccessPayload
}

export interface ISelectHistoryAccountAction {
  type: typeof HISTORY_SELECT_ACCOUNT
  payload: ISelectHistoryAccountPayload
}
