import * as React from 'react'
import { Table, notification } from 'antd'
import { connect } from 'react-redux'

import { IWithParticipantComponentProps } from '../../components/participant/WithParticipant'
import { numberFormatter } from 'seed-shared-components/lib/utils/CurrencyFormatter'
import Title from 'seed-shared-components/lib/components/Title'
import dateFormatter from '../../utils/dateFormatter'
import DownloadData from '../components/DownloadData'

import * as actions from '../../store/platform-balances/actions'
import * as selectors from '../../store/platform-balances/selectors'

const mapStateToProps = (state) => ({
  runs: selectors.getRuns(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchRuns: (accountId: string) => dispatch(actions.fetchRuns({ accountId })),
  setPage: (page: number) => dispatch(actions.setRuns({ runs: { page } }))
})

type PlatformAccountProps = IWithParticipantComponentProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

function PlatformAccount(props: PlatformAccountProps) {
  const accountId = (props.match.params as any).accountId
  const asset = (props.match.params as any).asset
  const selectedParticipant = (props.match.params as any).code
  const runs = props.runs

  React.useEffect(() => {
    props.fetchRuns(accountId)
  }, [accountId, runs.page])

  const navigateToMovements = (version) =>
    props.history.push(
      `/${props.participant.code}/platform_balances/${selectedParticipant}/accounts/${asset}/${accountId}/movements/${version}`
    )

  const TABLE_COLUMNS = [
    {
      render: (item) => dateFormatter(item.settleTimestamp),
      title: 'Settlement Time',
      key: 'settleTimestamp'
    },
    { render: renderRunType, title: 'Description', key: 'runType' },
    {
      render: (item) => renderChange(asset, item.change),
      title: 'Change',
      key: 'change',
      width: '20%'
    },
    {
      render: (item) =>
        renderBalance(asset, item.amount, () =>
          navigateToMovements(item.version)
        ),
      title: 'Balance',
      key: 'amount',
      width: '20%'
    }
  ]

  const fetchAllPages = () =>
    props.restService
      .route(
        `accounts/${accountId}/runs?page=${0}&pageSize=${runs.count}`,
        true
      )
      .get()
      .then(({ body }) => body)

  return (
    <div>
      <div className="space-between">
        <Title level={2}>Run History</Title>
        <div>
          <DownloadData
            disabled={runs.loading}
            onData={fetchAllPages}
            description={`${asset} Run History for ${selectedParticipant}`}
          />
        </div>
      </div>

      <Table
        columns={TABLE_COLUMNS as any}
        dataSource={runs.value}
        pagination={{
          current: runs.page + 1,
          total: runs.count,
          pageSize: runs.pageSize,
          onChange: (page, pageSize) => props.setPage(page - 1)
        }}
        loading={runs.loading}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformAccount)

/**
 * Table render helpers
 */
const renderRunType = ({ runType }): string => {
  switch (runType) {
    case 'deposit':
      return 'Deposit'
    case 'execution_fee':
      return 'Execution fee incurred'
    case 'network_fee':
      return 'Network fee incurred'
    case 'settlement':
      return 'Processed settlement run'
    case 'transfer':
      return 'Account transfer'
    case 'withdrawal':
      return 'Withdrawal'
    default:
      return runType
  }
}

const renderChange = (asset, change) => {
  return <div>{numberFormatter(change)}</div>
}

const renderBalance = (asset, change, onClick) => {
  return <a onClick={onClick}>{numberFormatter(change)}</a>
}
