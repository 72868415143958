import { IApplicationState } from '../state'

export const getPlatformParticipants = (state: IApplicationState) =>
  state.platformBalances.participants

export const getFunds = (state: IApplicationState) =>
  state.platformBalances.funds

export const getRuns = (state: IApplicationState) => state.platformBalances.runs

export const getMovements = (state: IApplicationState) =>
  state.platformBalances.movements
