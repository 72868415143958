import { connect } from 'react-redux'
import {
  IAccountHistoryInputProps,
  IAccountHistoryOutputProps,
  IAccountHistoryExplicitProps
} from './props'
import { IApplicationState } from '../../../store/state'
import selectors from '../../../store/selectors'
import * as HistoryActions from '../../../store/accountHistory/actions'

export default connect<
  IAccountHistoryInputProps,
  IAccountHistoryOutputProps,
  IAccountHistoryExplicitProps,
  IApplicationState
>(
  (state) => ({
    funds: selectors.funds.getFunds(state) || [],
    accountHistory: selectors.accountHistory.getHistory(state),
    loading: selectors.accountHistory.isLoading(state)
  }),
  (dispatch) => ({
    onLoad: (account) => dispatch(HistoryActions.selectAccount({ account }))
  })
)
