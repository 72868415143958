import { Col, Row } from 'antd'
import * as React from 'react'
import DataDownloadButton from 'seed-shared-components/lib/components/DownloadDataButton'

import PageTitle from 'seed-shared-components/lib/components/Title'
import PositionsTable from './PositionsTable'
import { useConnect } from './hooks/useConnect'

function PlatformPositions() {
  const { positions, isSomeDataLoaded, isPositionsBeingLoaded } = useConnect()

  return (
    <div>
      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <Col style={{ padding: 0 }}>
          <PageTitle level={2}>Platform Positions</PageTitle>
        </Col>
        <Col style={{ padding: 0 }}>
          <DataDownloadButton
            data={positions}
            disabled={positions.length === 0}
            description="participant-positions"
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ padding: 0 }}>
          <div style={{ marginBottom: 24 }}>
            This page allows Platform Operators to view all customer positions
            in a centralized location. You can learn more about Zero Hash's
            various transaction states here:{' '}
            <a
              href="https://zerohash.zendesk.com/hc/en-us/articles/360050412634"
              target="_blank"
            >
              Transaction States
            </a>{' '}
          </div>
        </Col>
      </Row>
      <PositionsTable
        loading={!isSomeDataLoaded ? isPositionsBeingLoaded : false}
        dataSource={positions}
      />
    </div>
  )
}

export default PlatformPositions
