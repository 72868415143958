import React from 'react'
import { Form, Input, Select, Switch } from 'antd'

import { FiatTabType } from './types'
import { requiredRule } from './utils'
import useFlag from '../../../unleash/useFlag'

const networkOptionsWithoutCBIT = [{ value: 'CUBIX', label: 'CUBIX' }]
const networkOptionsWithCBIT = [
  { value: 'CUBIX', label: 'CUBIX' },
  { value: 'CBIT', label: 'CBIT' }
]
const correspondentBankCodeTypeOptions = [
  { value: 'Routing', label: 'ABA/Routing' },
  { value: 'SWIFT', label: 'SWIFT' },
  { value: 'CHIPS', label: 'CHIPS' }
]
interface FiatFormItemsProps {
  tab: FiatTabType
  intermediaryBankValue?: boolean
  correspondentBankValue?: boolean
}

const FiatFormItems = ({
  tab,
  intermediaryBankValue,
  correspondentBankValue
}: FiatFormItemsProps) => {
  const isCBITEnabled = useFlag('portal-app.cbit-enabled.SET-3709')
  const isNetwork = tab === 'network'
  const isInternational = tab === 'international'
  return (
    <>
      {isNetwork && (
        <Form.Item
          name="networkBankName"
          label="Transfer Network"
          initialValue="CUBIX"
          rules={[requiredRule]}
        >
          <Select
            // If CBIT is not enabled, only show CUBIX as an option
            options={
              !isCBITEnabled
                ? networkOptionsWithoutCBIT
                : networkOptionsWithCBIT
            }
          />
        </Form.Item>
      )}
      {!isNetwork && (
        <Form.Item name="bankName" label={'Bank Name'} rules={[requiredRule]}>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="beneficiary"
        label="Beneficiary Name"
        rules={[requiredRule]}
      >
        <Input />
      </Form.Item>
      {!isInternational && (
        <>
          <Form.Item
            name="number"
            rules={[requiredRule]}
            label="Account Number"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="recipientInstructions"
            label="Recipient Instructions"
          >
            <Input />
          </Form.Item>
          {/*routing only exists for DOMESTIC wires (FIAT && DOMESTIC) */}
          {!isNetwork && (
            <Form.Item
              name="routing"
              rules={[
                requiredRule,
                {
                  min: 9,
                  max: 9,
                  message: 'Routing number must be 9 characters'
                }
              ]}
              label="Routing Number"
            >
              <Input />
            </Form.Item>
          )}
        </>
      )}
      {isInternational && (
        <>
          <Form.Item
            name="number"
            rules={[requiredRule]}
            label="IBAN or Account Number"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="swift"
            rules={[
              requiredRule,
              {
                max: 12,
                message: '12 characters max'
              }
            ]}
            label="SWIFT Code"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="recipientInstructions"
            label="Recipient Instructions"
          >
            <Input />
          </Form.Item>
        </>
      )}
      {!isNetwork && (
        <>
          <Form.Item
            valuePropName="checked"
            name="intermediaryBank"
            label="Intermediary Bank"
          >
            <Switch />
          </Form.Item>
          {intermediaryBankValue && (
            <>
              <Form.Item
                name="intermediaryBankName"
                rules={[requiredRule]}
                label="Intermediary Bank Name"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="intermediaryBankCodeType"
                rules={[requiredRule]}
                label="Intermediary Bank Identifier"
                initialValue="Routing"
              >
                <Select
                  defaultValue="Routing"
                  options={correspondentBankCodeTypeOptions}
                />
              </Form.Item>
              <Form.Item
                name="intermediaryBankCode"
                rules={[requiredRule]}
                label="Intermediary Bank Code"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="intermediaryBankAccountNumber"
                rules={[requiredRule]}
                label="Intermediary Bank IBAN"
              >
                <Input />
              </Form.Item>
            </>
          )}
          <Form.Item
            valuePropName="checked"
            name="correspondentBank"
            label="Correspondent Bank"
          >
            <Switch />
          </Form.Item>
          {correspondentBankValue && (
            <>
              <Form.Item
                name="correspondentBankName"
                rules={[requiredRule]}
                label="Correspondent Bank Name"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="correspondentBankCodeType"
                rules={[requiredRule]}
                label="Correspondent Bank Identifier"
                initialValue={'Routing'}
              >
                <Select
                  options={correspondentBankCodeTypeOptions}
                  defaultValue="Routing"
                />
              </Form.Item>
              <Form.Item
                name="correspondentBankCode"
                rules={[requiredRule]}
                label="Correspondent Bank Code"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="correspondentBankAccountNumber"
                rules={[requiredRule]}
                label="Correspondent Bank IBAN"
              >
                <Input />
              </Form.Item>
            </>
          )}
        </>
      )}
    </>
  )
}

export default FiatFormItems
