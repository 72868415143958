import React, { useCallback, useEffect, useState } from 'react'
import * as qs from 'qs'
import { Table, notification } from 'antd'
import Title from 'seed-shared-components/lib/components/Title'

import {
  IApiKeyLedgerTableItem,
  IApiKeyLedgerTableItemPermission,
  IApiKeyPermission,
  NewApiKeyLedgerTableItem,
  ParsedApiKeys
} from '../types'

import ApiKeyDetailsModal from './ApiKeyDetailsModal'
import NewApiKeyModal from './NewApiKeyModal'

import { DEFAULT_PAGE_SIZE, IP_LIMIT } from '../../constants'
import { getParticipantFilteredSettings } from '../../api/participantsApi'
import { IWithParticipantComponentProps } from '../../components/participant/WithParticipant'
import { columns, renderActionColumn } from './utils'

interface IPagination {
  current: number
  pageSize: number
}

interface IApiKeysSorter {
  field: string
  order: string
}

const SORT_ORDERS = {
  descend: 'desc',
  ascend: 'asc'
}

const ApiKeysPage = ({
  restService,
  authUser,
  participant,
  participantDetails
}: IWithParticipantComponentProps) => {
  const [apiKeysLoading, setApiKeysLoading] = useState(true)
  const [apiKeys, setApiKeys] = useState<ParsedApiKeys[]>([])
  const [apiKeysPagination, setApiKeysPagination] = useState({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE
  })
  const [apiKeysSorter, setApiKeysSorter] = useState({
    field: 'created_at',
    order: 'desc'
  })
  const [permissions, setPermissions] = useState([])
  const [marketDataEnabled, setMarketDataEnabled] = useState(false)
  const [
    participantSanctionScreeningInfoEnabled,
    setParticipantSanctionScreeningInfoEnabled
  ] = useState(false)
  const [participantFullInfoEnabled, setParticipantFullInfoEnabled] = useState(
    false
  )
  const [apiKeyDetailsInfo, setApiKeyDetailsInfo] = useState(null)

  const fetchApiKeysPermissions = useCallback(async () => {
    try {
      const permissionsResponse = await restService
        .route('permissions', true)
        .get<{ message: IApiKeyPermission[] }>()

      setPermissions(permissionsResponse.message)
    } catch (err) {
      notification.error({
        description: 'Something went wrong, please try again later.',
        duration: 0,
        message: err.message
      })
    }
  }, [restService])

  const fetchApiKeys = useCallback(
    async (pagination: IPagination, sorter: IApiKeysSorter) => {
      try {
        const queryParams: {
          page: number
          page_size: number
          sort_by?: string
          sort_order?: string
        } = {
          page: pagination.current,
          page_size: pagination.pageSize
        }

        if (!!sorter.field && !!sorter.order) {
          queryParams.sort_by = sorter.field
          queryParams.sort_order = sorter.order
        }

        return restService
          .route(`api_keys?${qs.stringify(queryParams)}`)
          .get<NewApiKeyLedgerTableItem[]>()
      } catch (err) {
        notification.error({
          description: 'Please try again or contact support.',
          duration: 0,
          message: err.message
        })
        return []
      }
    },
    [restService]
  )

  const loadApiKeys = useCallback(async () => {
    setApiKeysLoading(true)

    const apiKeys = await fetchApiKeys(apiKeysPagination, apiKeysSorter)

    const apiKeysParsed = apiKeys.map((key) => {
      const permissions: Record<string, IApiKeyLedgerTableItemPermission> = {}

      key.permissions
        .map((permission) => ({
          ...permission,
          definition: {
            ...permission.definition,
            resource: permission.definition.resource.replace('/', '')
          }
        }))
        .forEach((permission) => {
          if (permission.definition.resource in permissions) {
            const permissionResult = {
              ...permissions[permission.definition.resource]
            }
            permissionResult.actions.push(permission.definition.action)
            permissions[permission.definition.resource] = {
              ...permissionResult
            }
          } else {
            permissions[permission.definition.resource] = {
              resource: permission.definition.resource,
              actions: [permission.definition.action]
            }
          }
        })

      return {
        ...key,
        permissions: Object.values(permissions)
      }
    })

    setApiKeys(apiKeysParsed)
    setApiKeysLoading(false)
  }, [apiKeysPagination, apiKeysSorter, fetchApiKeys])

  const getPlatformSettings = useCallback(async () => {
    const platformSettingsResponse = await getParticipantFilteredSettings(
      authUser.idToken,
      participant.code
    )
    setMarketDataEnabled(platformSettingsResponse.marketDataEnabled)
    setParticipantSanctionScreeningInfoEnabled(
      platformSettingsResponse.participantSanctionScreeningInfoEnabled
    )
    setParticipantFullInfoEnabled(
      platformSettingsResponse.participantSanctionScreeningInfoEnabled
    )
  }, [authUser.idToken, participant.code])

  useEffect(() => {
    fetchApiKeysPermissions()
    loadApiKeys()
    getPlatformSettings()
  }, [fetchApiKeysPermissions, getPlatformSettings, loadApiKeys])

  const updateAllowedIPs = useCallback(
    async (apiKeyId: string, allowed_ips: string[] | null) => {
      try {
        if (allowed_ips === null || allowed_ips.length <= IP_LIMIT) {
          /*
          TODO Check why await loadApiKeys() is returning an
          empty list from the backend after updating an IP address.
          If we refresh the page or do "repeat XHR" via the network tab,
          the request will successfully bring the data
        */
          await restService.route(`api_keys/${apiKeyId}`).patch({ allowed_ips })

          notification.success({
            description: 'Api Keys updated successfully.',
            duration: 0,
            message: 'Api Keys updated'
          })

          setTimeout(async () => await loadApiKeys(), 1500) // workaround to make it work
        } else {
          notification.error({
            description: `The limit of allowed IPs is ${IP_LIMIT}, please contact support if you need to add more IPs.`,
            message: 'Failed to update allowed IPs',
            duration: 0
          })
        }
      } catch (e) {}
    },
    [loadApiKeys, restService]
  )
  const apiKeysColumns = columns(
    marketDataEnabled,
    participantSanctionScreeningInfoEnabled,
    participantFullInfoEnabled,
    updateAllowedIPs
  )

  const rejectApiKey = useCallback(
    async (apiKey: IApiKeyLedgerTableItem) => {
      setApiKeysLoading(true)

      try {
        await restService.route(`api_keys/${apiKey.key_id}/reject`).post({})

        await loadApiKeys()
      } catch (err) {
        notification.error({
          description: 'Please try again or contact support.',
          duration: 0,
          message: 'Unable to reject API key'
        })
      }

      setApiKeysLoading(false)
    },
    [loadApiKeys, restService]
  )

  const approveApiKey = useCallback(
    async (apiKey: IApiKeyLedgerTableItem) => {
      setApiKeysLoading(true)
      try {
        await restService.route(`api_keys/${apiKey.key_id}/approve`).post({})

        await loadApiKeys()
      } catch (err) {
        // 400 is returned when the user created or already approved this api key
        if (err.response?.status != 400) {
          notification.error({
            description: 'Please try again or contact support.',
            duration: 0,
            message: 'Unable to approve API key'
          })
          console.error(err.message)
        }
      }
      setApiKeysLoading(false)
    },
    [loadApiKeys, restService]
  )

  const disableApiKey = useCallback(
    async (apiKey: IApiKeyLedgerTableItem) => {
      setApiKeysLoading(true)

      try {
        await restService.route(`api_keys/${apiKey.key_id}/disable`).post({})

        await loadApiKeys()
      } catch (err) {
        notification.error({
          description: 'Please try again or contact support.',
          duration: 0,
          message: 'Unable to disable API key'
        })
        console.error(err.message)
      }
      setTimeout(async () => await loadApiKeys(), 200)
      setApiKeysLoading(false)
    },
    [loadApiKeys, restService]
  )

  const handleApiKeysParamsChange = ({ current, pageSize }, _, sorter) => {
    setApiKeysPagination({
      current,
      pageSize
    })
    setApiKeysSorter({ field: sorter.field, order: SORT_ORDERS[sorter.order] })
    loadApiKeys()
  }

  if (participant.isAdmin) {
    apiKeysColumns.push({
      title: 'Action',
      fixed: 'right',
      render: (apiKeyRecord: IApiKeyLedgerTableItem) =>
        renderActionColumn(
          authUser.email,
          participantDetails.api_key_required_approvers,
          apiKeyRecord,
          approveApiKey,
          rejectApiKey,
          disableApiKey,
          setApiKeyDetailsInfo,
          apiKeys
        ),
      width: 140
    })
  }

  return (
    <>
      <div style={{ marginRight: 14 }}>
        <div className="space-between">
          <Title level={2}>API Keys</Title>

          {participant.isAdmin && (
            <NewApiKeyModal
              loadApiKeys={loadApiKeys}
              restService={restService}
              permissions={permissions}
              participant={participant}
              authUser={authUser}
              marketDataEnabled={marketDataEnabled}
              participantSanctionScreeningInfoEnabled={
                participantSanctionScreeningInfoEnabled
              }
              participantFullInfoEnabled={participantFullInfoEnabled}
            />
          )}
        </div>

        <Table
          loading={apiKeysLoading}
          rowKey="public_key"
          className="api-keys"
          columns={apiKeysColumns}
          dataSource={apiKeys}
          onChange={handleApiKeysParamsChange}
          pagination={{
            pageSize: apiKeysPagination.pageSize,
            current: apiKeysPagination.current
          }}
          scroll={{ x: '100%', y: '65vh' }}
          bordered
        />
      </div>

      {apiKeyDetailsInfo && (
        <ApiKeyDetailsModal
          apiKey={apiKeyDetailsInfo}
          onOk={() => setApiKeyDetailsInfo(null)}
          apiKeyApprovalsRequiredCount={
            (participantDetails as any).api_key_required_approvers
          }
        />
      )}
    </>
  )
}

export default ApiKeysPage
