import { connect } from 'react-redux'

import { IHistoryServiceOutputProps } from './props'
import { IDefaultComponentProps } from '../../../App'
import { IApplicationState } from '../../../store/state'
import * as HistoryActions from '../../../store/accountHistory/actions'
import { IAccountHistory } from '../../../store/accountHistory/state'

export default connect<
  null,
  IHistoryServiceOutputProps,
  IDefaultComponentProps,
  IApplicationState
>(null, (dispatch, props) => ({
  loadHistory: (accountId: string) => {
    dispatch(HistoryActions.loadHistory())
    return props.restService
      .route(`withdrawal_accounts/${accountId}/history?page=0`)
      .get<IAccountHistory>()
  },
  onLoadHistorySuccess: (history) =>
    dispatch(HistoryActions.loadHistorySuccess({ history })),
  onHistoryLoadFailure: () => dispatch(HistoryActions.loadHistoryFailure()),
  onUnmount: () => dispatch(HistoryActions.unmountHistory())
}))
