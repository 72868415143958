import { Table, Button, Tooltip } from 'antd'
import * as React from 'react'
import * as _ from 'lodash'
import Decimal from 'decimal.js'

import {
  IPortalApiWithdrawalRequest,
  IPortalApiWithdrawalAccount
} from '../withdrawalTypes'

import dateFormatter from '../../utils/dateFormatter'
import { getTransactionUrl } from '../../utils/transactionsHelper'
import { Link } from 'react-router-dom'

interface IWithdrawalsTableProps {
  requests: IPortalApiWithdrawalRequest[]
  loading: boolean
  accounts: IPortalApiWithdrawalAccount[]
  pagination: {
    total: number
    current: number
    pageSize: number
  }
  userEmail: string
  onApprove: (id: number | string) => void
  onReject: (id: number | string) => void
  onDetails: (id: number) => void
  approves: number
  isAdmin: boolean
  isSubmitting: boolean
  setPagination?: (pagination) => void
}

const WithdrawalsTable = ({
  requests,
  loading,
  accounts,
  pagination,
  userEmail,
  onApprove,
  onReject,
  onDetails,
  approves,
  isAdmin,
  isSubmitting,
  setPagination
}: IWithdrawalsTableProps) => {
  const handlePaginationChange = (page: number, pageSize) => {
    setPagination({ ...pagination, current: page - 1, pageSize })
  }

  const columns = [
    {
      title: 'Timestamp',
      dataIndex: 'createdAt',
      render: (value: number) => dateFormatter(value)
    },
    {
      title: 'Request ID',
      dataIndex: 'withdrawalRequestUuid',
      // ANTD 3.x does not support multiple dataIndexes, so custom render is required
      render: (text: string, record: IPortalApiWithdrawalRequest) =>
        `${record.withdrawalRequestId || record.withdrawalRequestUuid}`
    },
    { title: 'Asset', dataIndex: 'currency' },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (a: any, b: any) => renderStatus(approves, a, b)
    },
    {
      title: 'Withdrawal Account',
      dataIndex: 'withdrawalAccountId',
      render: (id: number) => renderAcccount(accounts, id)
    },
    { title: 'Requested Amount', dataIndex: 'amount', render: renderAmount },
    { title: 'Settled Amount', dataIndex: 'settled', render: renderAmount },
    { title: 'TX ID', dataIndex: 'transactionId', render: renderTxId }
  ]

  if (isAdmin) {
    columns.push({
      dataIndex: 'actions',
      title: 'Actions',
      render: (nope: any, record: IPortalApiWithdrawalRequest) =>
        renderActions(
          userEmail,
          record,
          isSubmitting,
          () => onDetails(record.id),
          () =>
            onApprove(
              record.withdrawalRequestId || record.withdrawalRequestUuid
            ),
          () =>
            onReject(record.withdrawalRequestId || record.withdrawalRequestUuid)
        )
    })
  }

  return (
    <Table
      loading={loading}
      dataSource={requests}
      pagination={{
        ...pagination,
        current: pagination.current + 1,
        onChange: handlePaginationChange
      }}
      rowKey="id"
      columns={columns}
    />
  )
}

/**
 * Rendering helpers
 */
const renderAcccount = (
  accounts: IPortalApiWithdrawalAccount[],
  id: number
) => {
  const account = _.find(
    accounts,
    (account) => account.withdrawalAccountId === id
  )

  return account ? (
    <Tooltip title={account.address}>{account.name}</Tooltip>
  ) : (
    'null'
  )
}

const renderAmount = (value: any, record: IPortalApiWithdrawalRequest) =>
  _.isUndefined(value) || _.isNull(value) ? '' : new Decimal(value).toString()

const ONE_TIME_ACTIONS = [
  'REQUEST',
  'APPROVAL',
  'CREATION_APPROVAL',
  'CHANGE_APPROVAL'
]

const renderActions = (
  userEmail: string,
  record: IPortalApiWithdrawalRequest,
  isSubmitting: boolean,
  onDetails: any,
  onApprove: any,
  onReject: any
) => {
  const actions: any[] = [{ label: 'Details', onClick: onDetails }]

  if (record.status === 'PENDING') {
    const actionsDisabled = !!_.find(
      record.history,
      (entry) =>
        ONE_TIME_ACTIONS.includes(entry.action) && entry.userEmail === userEmail
    )

    actions.unshift({
      label: 'Reject',
      disabled: actionsDisabled,
      onClick: onReject
    })
    actions.unshift({
      label: 'Approve',
      disabled: actionsDisabled,
      onClick: onApprove
    })

    if (actionsDisabled) {
      actions.push({ label: 'Cancel', disabled: false, onClick: onReject })
    }
  }

  return (
    <>
      {actions.map((action, index) => (
        <Button
          onClick={action.onClick}
          key={index}
          size="small"
          style={{ marginRight: 12 }}
          disabled={action.disabled || isSubmitting}
        >
          {action.label}
        </Button>
      ))}
    </>
  )
}

const renderStatus = (
  approvalsRequired: number,
  status: IPortalApiWithdrawalRequest['status'],
  record: IPortalApiWithdrawalRequest
) => {
  if (status === 'PENDING') {
    return `${status} (${record.numTotalAdminSignatures}/${record.numRequiredAdminSignatures})`
  }

  return status
}

const renderTxId = (id: string, record: IPortalApiWithdrawalRequest) => {
  const transactionUrl = getTransactionUrl(record.currency)
  const idLabel = prepareIdLabelToRender(id || '')

  return !!transactionUrl && !!id ? (
    <Link to={`${transactionUrl}/${id}`}>{idLabel}</Link>
  ) : (
    idLabel
  )
}

/* Other helpers */

const prepareIdLabelToRender = (id) => {
  if (id.length < 66) {
    return id
  }

  const prefix = id.substr(0, 30)
  const postfix = id.substr(-30)

  return (
    <Tooltip title={id}>
      {prefix}...{postfix}
    </Tooltip>
  )
}

export default WithdrawalsTable
