import * as React from 'react'
import { Button } from 'antd'

import downloadCSV from 'seed-shared-components/lib/utils/CSVTools'
import { DownloadOutlined } from '@ant-design/icons'

export default ({ disabled, description, onData }) => {
  const [loading, setLoading] = React.useState(false)

  const download = async () => {
    setLoading(true)
    const data = await onData()
    setLoading(false)

    downloadCSV(data, description)
  }

  return (
    <Button
      data-testid="button-download-data"
      disabled={disabled || loading}
      onClick={download}
      loading={loading}
      type="ghost"
    >
      Export Data
      <DownloadOutlined />
    </Button>
  )
}
