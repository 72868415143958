import * as React from 'react'
import { Modal } from 'antd'

type IDepositNewAssetsRequestModalProps = {
  showModal: boolean
  onClose: () => void
}

const DepositNewAssetsRequestModal = (
  props: IDepositNewAssetsRequestModalProps
) => {
  const { showModal, onClose } = props

  return (
    <Modal
      title="Request Access to New Assets"
      visible={showModal}
      onOk={onClose}
      onCancel={onClose}
    >
      <p>
        Zero Hash supports an ever-growing list of digital and fiat assets, some
        of which are not made available to you by default. Don’t see what you’re
        looking for?{' '}
        <a
          href="mailto: support@zerohash.com?subject=New Asset Request"
          target="_blank"
        >
          Reach out
        </a>{' '}
        with the specific asset of interest and we’ll be able to get it
        configured for you.
      </p>
      <p>
        You can see a full list of assets we currently support{' '}
        <a
          href="https://zerohash.zendesk.com/hc/en-us/articles/360018576254-What-assets-do-you-support-"
          target="_blank"
        >
          here
        </a>
        {'. '}
        If we don’t support a particular asset you're looking for, let us know
        we’ll keep you informed when it becomes available.
      </p>
    </Modal>
  )
}

export default DepositNewAssetsRequestModal
