import * as React from 'react'

import { Table } from 'antd'

import { numberFormatter } from 'seed-shared-components/lib/utils/CurrencyFormatter'
import { DEFAULT_PAGE_SIZE_OPTIONS } from '../../../constants'
import dateFormatter from '../../../utils/dateFormatter'

import DataDownloadButton from 'seed-shared-components/lib/components/DownloadDataButton'
import { MovementsPageProps } from './props'
import connect from './connector'
import { Link } from 'react-router-dom'
import Title from 'seed-shared-components/lib/components/Title'
import { IMovement } from '../../../store/account-movements/types'

// TODO: make generic since it's used in multiple places
export const movementTypeRender = (item: string, isAdmin: boolean): string => {
  switch (item) {
    case 'collateral_return':
      return 'Margin collateral returned'
    case 'collateralize_loan':
      return 'Transfer collateral to the lender'
    case 'collateralize_trade':
      return 'Margin collateral posted'
    case 'deposit':
      return 'A deposit of additional assets into Zero Hash'
    case 'execution_fee':
      return 'Execution fee related to your trading activity on a Seed CX liquidity venue'
    case 'final_settlement_default_fallback':
      return `Financial settlement for the portion of a physically-settled trade's final settlement obligation that was not fully delivered due to a default`
    case 'final_settlement_default_partial':
      return 'Partial physical delivery of a physically-settled trade whose final settlement obligation could not be fully delivered due to a default'
    case 'final_settlement_default':
      return 'A financially-settled trade whose final settlement obligation could not be fully settled due to a default'
    case 'final_settlement_outstanding':
      return 'Full delivery and final settlement of a previously submitted trade whose obligations were outstanding'
    case 'final_settlement':
      return 'Full delivery and final settlement of a trade'
    case 'interest_payment':
      return 'Payment of interest for a loan'
    case 'network_fee':
      return 'A blockchain network fee incurred due to an on-chain movement related to your wallet'
    case 'otc_final_settlement':
      return 'Full delivery and final settlement of a trade'
    case 'principal_swap':
      return 'Initial swap of assets for a loan'
    case 'repayment':
      return 'Repayment of lent/borrowed assets for a loan that has terminated'
    case 'sef_collateral_return':
      return 'Margin collateral returned'
    case 'sef_collateralize_trade':
      return 'Margin collateral posted'
    case 'transfer':
      return isAdmin
        ? 'An administrative transfer made by Zero Hash'
        : 'An allocation transfer between your account and another at Zero Hash'
    case 'variation_margin_call':
      return 'Settlement to cover a previously outstanding variation margin obligation'
    case 'variation_margin':
      return 'Variation margin settlement to mark a position to market'
    case 'withdrawal_confirmed':
      return 'Reversal of the temporary hold due to a withdrawal being processed'
    case 'withdrawal_pending':
      return 'Temporary hold whilst a withdrawal is being processed'
    case 'withdrawal':
      return 'A processed withdrawal from Zero Hash'
    default:
      return item
  }
}

const referenceIdHeaderName = (runType: string): string => {
  switch (runType) {
    case 'deposit':
      return 'External Reference ID'
    case 'network_fee':
      return 'Network Fee ID'
    case 'settlement':
      return 'Trade / Loan ID'
    case 'transfer':
      return 'Transfer ID'
    case 'withdrawal':
      return 'Withdrawal ID'
    default:
      return 'Reference ID'
  }
}

export interface IMovementsPageState {}

const isGuid = (guid: string) => guid.length >= 36

class MovementsPage extends React.Component<
  MovementsPageProps,
  IMovementsPageState
> {
  async componentDidMount() {
    const {
      movements: {
        value: { page, pageSize }
      }
    } = this.props

    this.fetchMovements(page, pageSize)
  }

  componentDidUpdate(prevProps: MovementsPageProps) {
    if (this.props.funds !== prevProps.funds) {
      this.setAccount()
    }
  }

  fetchMovements = (page?: number, pageSize?: number) => {
    const { match, participantCode } = this.props
    const { accountId, version } = match.params

    this.props.fetchMovements({
      participantCode,
      accountId,
      version,
      page,
      pageSize
    })
  }

  setAccount() {
    const { funds, accountType } = this.props

    if (funds && accountType) {
      const { accountId } = this.props.match.params

      for (const fund of funds) {
        if (fund[`${accountType}_id`] === accountId) {
          this.props.onLoad({
            type: accountType,
            id: accountId,
            participantName: fund.name || null,
            account_group: fund.account_group || null
          })
          break
        }
      }
    }
  }

  stepTypeRender = (item: IMovement): string => {
    return movementTypeRender(item.stepType, item.isAdminRun)
  }

  get runType() {
    const { version } = this.props.match.params
    if (this.props.accountHistory && version !== undefined) {
      const run = this.props.accountHistory.settlementRuns.find(
        (run) => run.version === Number(this.props.match.params.version)
      )
      if (run) {
        return run.runType
      }
      return ''
    }
    return ''
  }

  handleTableChange = ({ current: page, pageSize }) => {
    this.fetchMovements(Math.max(0, page - 1), pageSize)
  }

  render() {
    const accountType = this.props.accountType
    const columns = [
      {
        dataIndex: 'settleTimestamp',
        render: (item: string) => dateFormatter(item),
        title: 'Settlement Time'
      },
      {
        dataIndex: 'stepId',
        title: 'Movement ID',
        render: (movementId: string) => <div id={movementId}>{movementId}</div>
      },
      {
        render: this.stepTypeRender,
        title: 'Type'
      },
      {
        className: 'column-money',
        render: (item: IMovement) =>
          item.change
            ? numberFormatter(item.change)
            : item.outstanding
            ? numberFormatter(item.outstanding)
            : numberFormatter(0),
        title: 'Change',
        width: '15%'
      },
      {
        render: (item: IMovement) => {
          if (item.runType === 'deposit') {
            if (!item.depositReferenceId || !item.asset) {
              return 'N/A'
            }

            return (
              <Link
                to={`/${this.props.participantCode}/deposits/${item.asset}/${item.depositReferenceId}`}
              >
                {item.depositReferenceId}
              </Link>
            )
          }

          if (item.runType === 'withdrawal') {
            if (
              !(item.withdrawalRequestId || item.withdrawalRequestUuid) ||
              !item.asset
            ) {
              return 'N/A'
            }

            return (
              <Link
                to={`/${this.props.participantCode}/withdrawals/${item.asset}`}
              >
                {item.withdrawalRequestId || item.withdrawalRequestUuid}
              </Link>
            )
          }

          if (['settlement', 'execution_fee'].includes(item.runType)) {
            return item.tradeId ? item.tradeId : 'N/A'
          }

          return (
            <>
              {item.movementId && isGuid(item.movementId) ? (
                <Link to={`${this.props.location.pathname}/${item.movementId}`}>
                  {item.movementId}
                </Link>
              ) : (
                'N/A'
              )}
            </>
          )
        },
        title: referenceIdHeaderName(this.runType),
        width: '20%'
      }
    ]
    const title = `${
      accountType
        ? accountType.charAt(0).toUpperCase() + accountType.substr(1)
        : 'No'
    } Movements`
    const movements = this.props.movements

    return (
      <div>
        <div className="space-between">
          <Title level={2}>{title}</Title>
          <div>
            <DataDownloadButton
              data={movements.value.result}
              description={`${this.props.participantCode} ${this.props.match.params.asset} Movements`}
              style={{ zIndex: 1, marginBottom: -16 }}
            />
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={movements.value.result}
          // expandIconAsCell={false}
          expandRowByClick={true}
          loading={movements.loading}
          pagination={{
            total: movements.value.total,
            current: movements.value.page + 1,
            pageSize: movements.value.pageSize,
            pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
            showSizeChanger: true
          }}
          onChange={this.handleTableChange}
          rowKey={(record) => `${record.movementId}.${record.stepType}`}
        />
      </div>
    )
  }
}

export default connect(MovementsPage)
