import { createAction, PrepareAction } from '@reduxjs/toolkit'
import fsaPrepareActionHandler from '../fsaPrepareActionHandler'
import {
  FETCH_REPORTS,
  FETCH_REPORTS_FULFILL,
  IFetchReportsAction,
  IFetchReportsFulfillActionSuccess,
  IFetchReportsFulfillActionFail,
  GENERATE_REPORT,
  GENERATE_REPORT_FULFILL,
  IGenerateReportAction,
  IGenerateReportFulfillActionSuccess,
  IGenerateReportFulfillActionFail,
  FETCH_REPORT,
  FETCH_REPORT_FULFILL,
  IFetchReportAction,
  IFetchReportFulfillActionSuccess,
  IFetchReportFulfillActionFail,
  FETCH_ACCOUNT_LABELS,
  FETCH_ACCOUNT_LABELS_FULFILL,
  IFetchAccountLabelsAction,
  IFetchAccountLabelsFulfillActionSuccess,
  IFetchAccountLabelsFulfillActionFail
} from './types'

// prettier-ignore
export const fetchReport = createAction<IFetchReportAction['payload']>(FETCH_REPORT)
export const fetchReportFulfill = createAction<
  // prettier-ignore
  PrepareAction<IFetchReportFulfillActionSuccess | IFetchReportFulfillActionFail>
>(FETCH_REPORT_FULFILL, fsaPrepareActionHandler)

// prettier-ignore
export const fetchReports = createAction<IFetchReportsAction['payload']>(FETCH_REPORTS)
export const fetchReportsFulfill = createAction<
  // prettier-ignore
  PrepareAction<IFetchReportsFulfillActionSuccess | IFetchReportsFulfillActionFail>
>(FETCH_REPORTS_FULFILL, fsaPrepareActionHandler)

// prettier-ignore
export const fetchAccountLabels = createAction<IFetchAccountLabelsAction['payload']>(
  FETCH_ACCOUNT_LABELS
)
export const fetchAccountLabelsFulfill = createAction<
  // prettier-ignore
  PrepareAction<IFetchAccountLabelsFulfillActionSuccess | IFetchAccountLabelsFulfillActionFail>
>(FETCH_ACCOUNT_LABELS_FULFILL, fsaPrepareActionHandler)

// prettier-ignore
export const generateReport = createAction<IGenerateReportAction['payload']>(GENERATE_REPORT)
export const generateReportFulfill = createAction<
  // prettier-ignore
  PrepareAction<IGenerateReportFulfillActionSuccess | IGenerateReportFulfillActionFail>
>(GENERATE_REPORT_FULFILL, fsaPrepareActionHandler)
