import { createSelector } from '@reduxjs/toolkit'
import { IApplicationState } from '../state'
import { IPositionsState } from './state'

export const getRoot = (state: IApplicationState) => state.platformPositions

export const getStatsSummary = createSelector(
  getRoot,
  (positions): IPositionsState => positions.positions
)
