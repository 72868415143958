export const fontFamilies = [
  'Syne',
  'Roboto',
  'Arial',
  'Arial Black',
  'Arial Narrow',
  'Arial Rounded MT Bold',
  'Avant Garde',
  'Calibri',
  'Candara',
  'Century Gothic',
  'Franklin Gothic Medium',
  'Futura',
  'Geneva',
  'Gill Sans',
  'Helvetica Neue',
  'Impact',
  'Lucida Grande',
  'Optima',
  'Segoe UI',
  'Tahoma',
  'Trebuchet MS',
  'Verdana',
  'Georgia',
  'Palatino',
  'Times New Roman',
  'Courier New',
  'Lucida Console',
  'Poppins',
  'Manrope'
].map((font) => ({ label: font, value: font }))
