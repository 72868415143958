import * as React from 'react'
import { IOrderedListItemConfig } from '../../configuration'

export interface IOrderedListFormItemProps extends IOrderedListItemConfig {}

export const OrderedListFormItem = (props: IOrderedListFormItemProps) => (
  <ol>
    {props.items.map((s, i) => (
      <li key={i}>{s}</li>
    ))}
  </ol>
)
