import React, { useState } from 'react'
import { Typography, TypographyProps } from 'antd'
import { LinkProps } from 'antd/lib/typography/Link'

interface KycTypographyProps extends LinkProps {
  linkHoverColor?: string
  linkColor?: string
  typographyProps?: TypographyProps
  children?: React.ReactNode
  fontSize?: number
}

const KycTypographyLink = ({
  children = 'Link',
  linkColor,
  linkHoverColor,
  style,
  fontSize,
  ...typographyProps
}: KycTypographyProps) => {
  const [isHover, setIsHover] = useState(false)
  const handleMouseEnter = () => {
    setIsHover(true)
  }
  const handleMouseLeave = () => {
    setIsHover(false)
  }
  return (
    <Typography.Link
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        color: isHover ? linkHoverColor : linkColor,
        fontSize: fontSize ? fontSize : '12px',
        ...style
      }}
      {...typographyProps}
    >
      {children}
    </Typography.Link>
  )
}

export default KycTypographyLink
