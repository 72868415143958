import React from 'react'
import { Typography, TypographyProps } from 'antd'
import { TextProps } from 'antd/lib/typography/Text'

interface KycTypographyProps extends TextProps {
  colorText?: string
  typographyProps?: TypographyProps
  children?: React.ReactNode
  strong?: boolean
  fontSize?: number
}

const KycTypographyText = ({
  children = 'Text',
  colorText,
  strong,
  style,
  fontSize,
  ...typographyProps
}: KycTypographyProps) => {
  return (
    <Typography.Text
      style={{
        color: colorText || '#303133',
        fontSize: fontSize ? fontSize : '12px',
        ...style
      }}
      strong={strong}
      {...typographyProps}
    >
      {children}
    </Typography.Text>
  )
}

export default KycTypographyText
