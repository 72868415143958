import * as React from 'react'
import * as Redux from 'react-redux'

import * as reportsActions from '../../store/reports/actions'

export function usePullPendingReports({ reports, participantCode }) {
  const dispatch = Redux.useDispatch()

  React.useEffect(() => {
    let pullInterval

    if (reports.length > 0) {
      const pendingReports = reports.filter(({ status }) => {
        return status === 'pending'
      })

      if (pendingReports.length > 0) {
        pullInterval = setInterval(() => {
          pendingReports.forEach(({ id, type }) => {
            dispatch(
              reportsActions.fetchReport({
                id,
                type,
                participantCode
              })
            )
          })
        }, 3500)
      }
    }

    return () => {
      clearInterval(pullInterval)
    }
  }, [reports, participantCode])
}
