import { all } from 'typed-redux-saga'
import * as withdrawalsSagas from './withdrawal/sagas'
import * as platformBalancesSagas from './platform-balances/sagas'
import * as platformPositionsSagas from './platform-positions/sagas'
import * as participantPositionsSagas from './participant-positions/sagas'
import * as netDeliveryObligationsSagas from './net-delivery-obligations/sagas'
import * as portfolioSagas from './portfolio/sagas'
import * as userSettingsSagas from './user-settings/sagas'
import * as movementsSagas from './account-movements/sagas'
import * as reportsSagas from './reports/sagas'

//eslint-disable-next-line
export default function* rootSaga() {
  yield all([
    withdrawalsSagas.watchGetAccounts(),
    withdrawalsSagas.watchGetWithdrawals(),
    withdrawalsSagas.watchFetchWithdrawalRequestHistory(),
    withdrawalsSagas.watchGetplatformTransfers(),

    platformBalancesSagas.watchFetchRelationships(),
    platformBalancesSagas.watchFetchFunds(),
    platformBalancesSagas.watchFetchRuns(),
    platformBalancesSagas.watchFetchMovements(),

    platformPositionsSagas.watchPlatformPositionsSaga(),
    participantPositionsSagas.watchParticipantPositionsSaga(),

    netDeliveryObligationsSagas.watchFetchNetDeliveryObligations(),
    portfolioSagas.watchFetchPortfolio(),

    userSettingsSagas.watchInit(),
    userSettingsSagas.watchSet(),

    movementsSagas.watchFetchMovementsSaga(),

    reportsSagas.watchReportsSagas()
  ])
}
