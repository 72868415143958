import { put, takeEvery, call, select } from 'typed-redux-saga'
import { notification } from 'antd'
import {
  IFetchPlatformParticipantsAction,
  IFetchFundsAction,
  IFetchRunsAction,
  IFetchMovementsAction
} from './actions/types'

import * as actions from './actions'
import { getRuns } from './selectors'
import { getUser } from '../selectors'

import { requestSaga } from './helpers'
import * as participantsApi from '../../api/participantsApi'

function* fetchRelationshipsSaga(action: IFetchPlatformParticipantsAction) {
  yield put(
    actions.setPlatformParticipants({ participants: { loading: true } })
  )

  const { token } = yield select(getUser)

  try {
    const participantCode = action.payload.participantCode
    const searchString = action.payload.searchString || ''
    const basic = action.payload.basic || false
    const relationshipsTypes = action.payload.relationshipsTypes

    const { message: value } = yield call(
      participantsApi.getPaginatedPlatformParticipants,
      token,
      participantCode,
      {
        page: 1,
        pageSize: 50,
        searchString,
        includePlatformParticipant: true,
        basic,
        relationshipsTypes
      }
    )

    yield put(actions.setPlatformParticipants({ participants: { value } }))
  } catch (e) {
    notification.error({
      duration: 5,
      message: 'Failed fetching Relationships',
      description: 'Please try again, contact support.'
    })
  }

  yield put(
    actions.setPlatformParticipants({ participants: { loading: false } })
  )
}

function* fetchFundsSaga(action: IFetchFundsAction) {
  yield put(actions.setFunds({ funds: { loading: true } }))

  if (!action.payload.platformCode) {
    yield put(actions.setFunds({ funds: { value: [], loading: false } }))
    return
  }

  try {
    const value = yield call(requestSaga, {
      method: 'get',
      url: `/${action.payload.platformCode}/funds`
    })

    yield put(actions.setFunds({ funds: { value } }))
  } catch (e) {
    notification.error({
      duration: 5,
      message: 'Failed to fetch participants funds',
      description: 'Please try again, contact support.'
    })
  }

  yield put(actions.setFunds({ funds: { loading: false } }))
}

function* fetchRunsSaga(action: IFetchRunsAction) {
  yield put(actions.setRuns({ runs: { loading: true } }))

  try {
    const { accountId } = action.payload
    const { page, pageSize } = yield select(getRuns)
    const { body, count } = yield call(requestSaga, {
      method: 'get',
      url: `/accounts/${accountId}/runs?page=${page}&pageSize=${pageSize}`
    })

    yield put(actions.setRuns({ runs: { value: body, count } }))
  } catch (e) {
    notification.error({
      duration: 5,
      message: 'Failed to fetch runs history!',
      description: 'Please try again, contact support.'
    })
  }

  yield put(actions.setRuns({ runs: { loading: false } }))
}

function* fetchMovementsSaga(action: IFetchMovementsAction) {
  yield put(actions.setMovements({ movements: { loading: true } }))

  try {
    const { accountId, version, page, pageSize } = action.payload
    const movements = yield call(requestSaga, {
      method: 'get',
      url: `/accounts/${accountId}/runs/${version}/movements`,
      params: { page, pageSize }
    })

    yield put(actions.setMovements({ movements: { value: movements } }))
  } catch (e) {
    notification.error({
      duration: 5,
      message: 'Failed to fetch runs movements!',
      description: 'Please try again, contact support.'
    })
  }

  yield put(actions.setMovements({ movements: { loading: false } }))
}

function* watchFetchRelationships() {
  yield takeEvery(
    actions.fetchPlatformParticipants.type,
    fetchRelationshipsSaga
  )
}

function* watchFetchFunds() {
  yield takeEvery(actions.fetchFunds.type, fetchFundsSaga)
}

function* watchFetchRuns() {
  yield takeEvery(actions.fetchRuns.type, fetchRunsSaga)
}

function* watchFetchMovements() {
  yield takeEvery(actions.fetchMovements.type, fetchMovementsSaga)
}

export {
  watchFetchRelationships,
  watchFetchFunds,
  watchFetchRuns,
  watchFetchMovements
}
