import * as React from 'react'
import { Modal, notification, Row, Col, message, Button } from 'antd'
import {
  ITradeSubmissionDetails,
  ITradeSubmissionParty
} from './TradeSubmission'
import { IWithParticipantComponentProps } from '../components/participant/WithParticipant'
import dateFormatter from '../utils/dateFormatter'
import NumberFormat from 'react-number-format'
import { ITrade } from 'seed-shared-components/lib/components/TradeBlotter/types'

interface ITradeConfirmationProps extends IWithParticipantComponentProps {
  trade: ITradeSubmissionDetails | null
  close: (clearForm?: boolean) => void
}

interface ITradeConfirmationState {
  submitting: boolean
}

class TradeConfirmation extends React.Component<
  ITradeConfirmationProps,
  ITradeConfirmationState
> {
  constructor(props: ITradeConfirmationProps) {
    super(props)
    this.state = {
      submitting: false
    }
    this.redirectToTrade = this.redirectToTrade.bind(this)
  }
  async submitTrade() {
    this.setState({ submitting: true })
    try {
      const response = await this.props.restService
        .route('trades')
        .post<{ message: ITrade }>(this.formatTrade(this.props.trade!))
      this.setState({ submitting: false })
      const { trade_id } = response.data.message
      message.success(
        <>
          Trade Submitted
          <Row>
            Trade ID:
            <Button type="link" onClick={() => this.redirectToTrade(trade_id)}>
              {trade_id}
            </Button>
          </Row>
        </>
      )
      this.props.close(true)
    } catch (error) {
      this.setState({ submitting: false })
      notification.error({
        description: error,
        duration: 0,
        message: 'Failed to submit trade, please contact support.'
      })
      console.error(error)
    }
  }

  redirectToTrade(tradeId: string) {
    this.props.history.push(`/${this.props.participant.code}/trades/${tradeId}`)
    message.destroy()
  }

  formatTrade(trade: ITradeSubmissionDetails) {
    return {
      symbol: `${trade.baseCurrency}/${trade.quotedCurrency}`,
      trade_reporter: this.props.participant.code,
      trade_price: String(trade.price),
      trade_quantity: String(trade.quantity),
      client_trade_id: trade.clientTradeId,
      platform_code: this.props.participant.code,
      parties_anonymous: trade.anonymous,
      transaction_timestamp: trade.transactionTime.valueOf(),
      product_type: trade.productType,
      settlement_price_index_id: trade.settlementPriceIndexId,
      expiry_timestamp:
        trade.expiryTimestamp && trade.expiryTimestamp.valueOf(),
      settlement_timestamp:
        trade.settlementTimestamp && trade.settlementTimestamp.valueOf(),
      trade_type: 'regular',
      physical_delivery: true,
      parties: [trade.buyer, trade.seller].map(this.formatParty)
    }
  }

  formatParty(party: ITradeSubmissionParty) {
    return {
      participant_code: party.code,
      asset: party.asset,
      side: party.side,
      settling: party.settling
    }
  }

  render() {
    const tradeReporter = `${this.props.participant.name} (${this.props.participant.code})`
    const trade = this.props.trade!

    return (
      <Modal
        title="Trade Summary"
        onOk={this.submitTrade.bind(this)}
        onCancel={() => this.props.close()}
        confirmLoading={this.state.submitting}
        visible={this.props.trade !== null}
      >
        <DisplayField label="Platform" value={tradeReporter} />
        <DisplayField
          label="Trade Reporter"
          value={this.props.authUser.email}
        />
        <DisplayField
          label="Client Trade ID"
          value={trade.clientTradeId}
          defaultText="Not Provided - will be generated by Zero Hash"
        />
        <DisplayField
          label="Symbol"
          value={`${trade.baseCurrency}/${trade.quotedCurrency}`}
        />
        <DisplayField label="Underlying" value={trade.baseCurrency} />
        <DisplayField label="Quoted Currency" value={trade.quotedCurrency} />
        <DisplayField
          label="Price"
          value={
            <NumberFormat
              value={trade.price}
              thousandSeparator={true}
              displayType={'text'}
            />
          }
        />
        <DisplayField
          label="Quantity"
          value={
            <NumberFormat
              value={trade.quantity}
              thousandSeparator={true}
              displayType={'text'}
            />
          }
        />
        <DisplayField label="Product Type" value={trade.productType} />
        <DisplayField label="Delivery Type" value="Physical" />
        <DisplayField
          label="Transaction Timestamp"
          value={dateFormatter(trade.transactionTime.valueOf())}
        />
        {trade.productType === 'forward' &&
        trade.expiryTimestamp &&
        trade.settlementTimestamp ? (
          <>
            <DisplayField
              label="Expiration / Fixing Timestamp"
              value={dateFormatter(trade.expiryTimestamp.valueOf())}
            />
            <DisplayField
              label="Settlement Timestamp"
              value={dateFormatter(trade.settlementTimestamp.valueOf())}
            />
          </>
        ) : null}
        <DisplayField
          label="Anonymous"
          value={trade.anonymous ? 'Yes' : 'No'}
        />
        <SideField label={'Buyer'} side={trade.buyer} />
        <SideField label={'Seller'} side={trade.seller} />
      </Modal>
    )
  }
}

const DisplayField = ({
  label,
  value,
  defaultText = 'N/A'
}: {
  label: string
  value: string | number | JSX.Element | undefined
  defaultText?: string
}) => (
  <Row>
    <Col xs={9} style={{ fontWeight: 700 }}>
      {label}:
    </Col>
    <Col xs={13}>{value || defaultText}</Col>
  </Row>
)

const SideField = ({
  label,
  side
}: {
  label: string
  side: ITradeSubmissionParty
}) => (
  <Row>
    <Row style={{ fontWeight: 700 }}>{label}:</Row>
    <Row style={{ marginRight: 40 }}>
      <Col>
        <ul>
          <li>{side.name}</li>
          <li>Asset Receiving: {side.asset}</li>
          <li>
            Amount Receiving:{' '}
            <NumberFormat
              value={side.amount}
              thousandSeparator={true}
              displayType={'text'}
            />
          </li>
        </ul>
      </Col>
    </Row>
  </Row>
)

export default TradeConfirmation
