import { put, takeEvery, call, select } from 'typed-redux-saga'
import { notification } from 'antd'
import * as appSelectors from '../selectors'
import { IFetchMovementsAction } from './actions/types'
import * as actions from './actions'
import * as api from './api'

export function* fetchMovementsSaga(action: IFetchMovementsAction) {
  const {
    payload: { accountId, version, participantCode, page, pageSize }
  } = action
  const { token } = yield select(appSelectors.getUser)

  try {
    const { accountType, movements } = yield call(api.fetchMovements, {
      token,
      accountId,
      version,
      participantCode,
      page,
      pageSize
    })

    yield put(
      actions.fetchMovementsFulfill({
        error: false,
        payload: { accountType, movements }
      })
    )
  } catch (error) {
    notification.error({
      duration: 5,
      message: 'Failed fetching Movements',
      description: 'Please try again or contact support.'
    })

    yield put(
      actions.fetchMovementsFulfill({
        error: true,
        payload: error
      })
    )
  }
}

export function* watchFetchMovementsSaga() {
  yield takeEvery(actions.fetchMovements.type, fetchMovementsSaga)
}
