import * as React from 'react'
import {
  IOnboardingApplicationStepProps,
  OnboardingApplicationFormItemSwitch,
  captureAnswers,
  navigateToNext
} from '../OnboardingApplicationStep'
import {
  Layout,
  Menu,
  Spin,
  Card,
  Row,
  Progress,
  Col,
  notification,
  Alert
} from 'antd'

import Sider from 'antd/lib/layout/Sider'
import { Link } from 'react-router-dom'
import OnboardingApplicationStepFooter from '../OnboardingApplicationStepFooter'
import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/es/form'
import { getValueFromAnswers } from '../FormItems'
import { isBusiness } from '../../configuration'
import {
  AuthorizedSigner,
  ControlPerson,
  BeneficialOwner,
  Person,
  personTypes,
  IBasePerson
} from '../../participant'
import * as uuid from 'uuid'
import Title from 'seed-shared-components/lib/components/Title'
const { Content } = Layout

//#region Person Indicator

////////////////////////////////////////////////////////////
// Person Indicator- UI component to show progress         //
////////////////////////////////////////////////////////////
interface IPersonIndicatorProps {
  requiredCount: number
  count: number
  name: string
  description: string
  display: boolean
}

const PersonIndicator = (props: IPersonIndicatorProps) => {
  if (!props.display) {
    return null
  }

  return (
    <Row style={{ marginBottom: 24 }}>
      <Col xs={2}>
        {props.requiredCount ? (
          <Progress
            type="circle"
            percent={(props.count / props.requiredCount) * 100}
            width={40}
            {...(props.count < props.requiredCount
              ? { format: () => `${props.count} / ${props.requiredCount}` }
              : {})}
            style={{ marginRight: 12 }}
          />
        ) : undefined}
      </Col>
      <Col xs={20}>
        <Row>
          <div style={{ fontWeight: 600 }}>{props.name}</div>
        </Row>
        <Row>{props.description}</Row>
      </Col>
    </Row>
  )
}
//#endregion

const getPersonsCount = (persons: Person[], type: personTypes) => {
  return persons.filter((person) => person.type && person.type[type]).length
}
interface IAnswers {
  authorized_signers: AuthorizedSigner[]
  control_persons: ControlPerson[]
  beneficial_owners: BeneficialOwner[]
  persons: IBasePerson[]
}

interface IPersonsOnboardingApplicationStepState {
  persons: Person[]
  errors: string[]
}

type IPersonsOnboardingApplicationStepProps = IOnboardingApplicationStepProps &
  FormComponentProps
class PersonsOnboardingApplicationStep extends React.Component<
  IPersonsOnboardingApplicationStepProps,
  IPersonsOnboardingApplicationStepState
> {
  private readonly requiredCount = {
    authorizedSigners: 1,
    controlPersons: 1,
    beneficialOwners: 0
  }

  constructor(props: IPersonsOnboardingApplicationStepProps) {
    super(props)
    let persons = this.mapAnswersToPersons(this.props.answers)
    if (!persons || !persons.length) {
      persons = [{ id: uuid(), sort_timestamp: new Date().getTime() }]
    }
    this.state = {
      persons,
      errors: []
    }

    this.saveInProgressApplication = this.saveInProgressApplication.bind(this)
    this.onChange = this.onChange.bind(this)
    this.submitApplication = this.submitApplication.bind(this)
    this.addItem = this.addItem.bind(this)
    this.removeItem = this.removeItem.bind(this)
  }

  componentDidUpdate(previousProps: IPersonsOnboardingApplicationStepProps) {
    if (this.props.answers !== previousProps.answers) {
      this.setState({
        persons: this.mapAnswersToPersons(this.props.answers)
      })
    }
  }

  get controlPersonsCount(): number {
    if (this.props.form) {
      const formFields: any = this.props.form.getFieldsValue()
      if (formFields && formFields.persons) {
        getPersonsCount(formFields.persons, personTypes.control_person)
      }
    }
    return getPersonsCount(this.state.persons, personTypes.control_person)
  }

  get authorizedSignerCount(): number {
    if (this.props.form) {
      const formFields: any = this.props.form.getFieldsValue()
      if (formFields && formFields.persons) {
        getPersonsCount(formFields.persons, personTypes.authorized_signer)
      }
    }
    return getPersonsCount(this.state.persons, personTypes.authorized_signer)
  }

  get beneficialOwnersCount(): number {
    if (this.props.form) {
      const formFields: any = this.props.form.getFieldsValue()
      if (formFields && formFields.persons) {
        getPersonsCount(formFields.persons, personTypes.beneficial_owner)
      }
    }
    return getPersonsCount(this.state.persons, personTypes.beneficial_owner)
  }

  mapPersonsToAnswers(persons: Person[]) {
    const answers: IAnswers = {
      authorized_signers: [],
      control_persons: [],
      beneficial_owners: [],
      persons: []
    }

    persons.forEach((person) => {
      if (person.type && person.type[personTypes.authorized_signer]) {
        const detailedPerson = person as AuthorizedSigner
        const signer: AuthorizedSigner = {
          id: detailedPerson.id,
          sort_timestamp: detailedPerson.sort_timestamp,
          name: detailedPerson.name,
          title: detailedPerson.title,
          dob: detailedPerson.dob,
          email: detailedPerson.email,
          ssn: detailedPerson.ssn,
          phone: detailedPerson.phone,
          contact_person: detailedPerson.contact_person,
          united_states_ssn: detailedPerson.united_states_ssn,
          passport_country: detailedPerson.passport_country,
          passport_document: detailedPerson.passport_document
        }
        answers.authorized_signers.push(signer)
      }

      if (person.type && person.type[personTypes.control_person]) {
        const detailedPerson = person as ControlPerson
        const personDetails = {
          id: detailedPerson.id,
          sort_timestamp: detailedPerson.sort_timestamp,
          name: detailedPerson.name,
          title: detailedPerson.title,
          dob: detailedPerson.dob,
          ssn: detailedPerson.ssn,
          address_line1: detailedPerson.address_line1,
          address_line2: detailedPerson.address_line2,
          address_city: detailedPerson.address_city,
          address_state: detailedPerson.address_state,
          address_zip: detailedPerson.address_zip,
          address_country: detailedPerson.address_country,
          united_states_ssn: detailedPerson.united_states_ssn,
          passport_country: detailedPerson.passport_country,
          passport_document: detailedPerson.passport_document
        }
        answers.control_persons.push({ ...personDetails })
      }

      if (person.type && person.type[personTypes.beneficial_owner]) {
        const detailedPerson = person as BeneficialOwner
        const personDetails = {
          id: detailedPerson.id,
          sort_timestamp: detailedPerson.sort_timestamp,
          name: detailedPerson.name,
          dob: detailedPerson.dob,
          ssn: detailedPerson.ssn,
          address_line1: detailedPerson.address_line1,
          address_line2: detailedPerson.address_line2,
          address_city: detailedPerson.address_city,
          address_state: detailedPerson.address_state,
          address_zip: detailedPerson.address_zip,
          address_country: detailedPerson.address_country,
          united_states_ssn: detailedPerson.united_states_ssn,
          passport_country: detailedPerson.passport_country,
          passport_document: detailedPerson.passport_document
        }
        answers.beneficial_owners.push({ ...personDetails })
      }

      answers.persons.push(person)
    })

    return answers
  }

  mapAnswersToPersons(answers: any): Person[] {
    if (answers) {
      const {
        authorized_signers,
        control_persons,
        beneficial_owners,
        persons
      } = answers
      const personsMap: { [ssn: string]: Person } = {}

      if (authorized_signers && authorized_signers.length) {
        authorized_signers.forEach((signer: AuthorizedSigner) => {
          personsMap[signer.id] = {
            ...signer,
            type: {
              ...signer.type,
              [personTypes.authorized_signer]: true
            }
          }
        })
      }

      if (control_persons && control_persons.length) {
        control_persons.forEach((person: ControlPerson) => {
          personsMap[person.id] = {
            ...personsMap[person.id],
            ...person,
            type: {
              ...(personsMap[person.id] ? personsMap[person.id].type : {}),
              [personTypes.control_person]: true
            }
          }
        })
      }

      if (beneficial_owners && beneficial_owners.length) {
        beneficial_owners.forEach((owner: BeneficialOwner) => {
          personsMap[owner.id] = {
            ...personsMap[owner.id],
            ...owner,
            type: {
              ...(personsMap[owner.id] ? personsMap[owner.id].type : {}),
              [personTypes.beneficial_owner]: true
            }
          }
        })
      }

      if (persons && persons.length) {
        persons.forEach((person: IBasePerson) => {
          personsMap[person.id] = {
            ...personsMap[person.id],
            ...person
          }
        })
      }
      const obj = Object.values(personsMap).sort(
        (a, b) => a.sort_timestamp - b.sort_timestamp
      )
      return obj
    }

    return []
  }

  saveInProgressApplication(e?: any, callback?: () => Promise<any>) {
    if (e) {
      e.preventDefault()
    }

    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      this.validatePersons(async () => {
        if (this.state.errors && this.state.errors.length >= 1) {
          return
        }

        await this.saveAnswers()
        navigateToNext(
          this.props.nextStep,
          this.props.participant.code,
          this.props.history
        )

        if (callback) {
          callback()
        }
      })
    })
  }

  validatePersons(callback: () => void) {
    const errors: string[] = []

    const controlPersonCount = this.controlPersonsCount
    if (controlPersonCount < this.requiredCount.controlPersons) {
      errors.push(
        `${controlPersonCount} of ${this.requiredCount.controlPersons} Control Persons have been assigned`
      )
    }

    const authorizedSignerCount = this.authorizedSignerCount
    if (authorizedSignerCount < this.requiredCount.authorizedSigners) {
      errors.push(
        `${authorizedSignerCount} of ${this.requiredCount.authorizedSigners} Authorized Signers have been assigned`
      )
    }

    const beneficialOwnersCount = this.beneficialOwnersCount
    if (beneficialOwnersCount < this.requiredCount.beneficialOwners) {
      errors.push(
        `${beneficialOwnersCount} of ${this.requiredCount.beneficialOwners} Beneficial Owners have been assigned`
      )
    }

    // One of the authorized signers must be a primary contact person
    const hasContactPerson = this.state.persons
      .filter(
        (person) => person.type && person.type[personTypes.authorized_signer]
      )
      .some((person) => (person as AuthorizedSigner).contact_person === true)
    if (!hasContactPerson) {
      errors.push(
        'At least one of the authorized signers must be designated as the primary contact person'
      )
    }
    this.setState({ errors }, callback)
  }

  async submitApplication() {
    return this.saveInProgressApplication(
      undefined,
      this.validateAndSubmitApplication
    )
  }

  setAnswersState() {
    const formAnswers = captureAnswers(this.props.form, this.props.currentStep)

    if (formAnswers && formAnswers.persons) {
      const persons = formAnswers.persons.map((person: any, i: number) => ({
        ...person,
        id: this.state.persons[i].id,
        sort_timestamp: this.state.persons[i].sort_timestamp
      }))
      const answers = this.mapPersonsToAnswers(persons)
      this.props.setAnswersState(answers)
    }
  }

  onChange = (e: any) => {
    e.preventDefault()
    this.setState({ errors: [] })
    this.setAnswersState()
  }

  saveAnswers() {
    const answers = captureAnswers(this.props.form, this.props.currentStep)
    const mappedAnswers = this.mapPersonsToAnswers(answers.persons)
    return this.props.saveAnswers(mappedAnswers)
  }

  async validateAndSubmitApplication() {
    try {
      await this.saveInProgressApplication(
        undefined,
        this.props.submitApplication
      )
    } catch (e) {
      console.error('failed to save application. e: ', e)
      const key = `open${Date.now()}`
      notification.error({
        description:
          'There was a problem submitting your request. Please try again or contact support.',
        duration: 0,
        key,
        message: 'Failed to Submit Application'
      })
    }
  }

  addItem() {
    const persons = [...this.state.persons]
    persons.push({ id: uuid(), sort_timestamp: new Date().getTime() })
    this.setState({ persons }, this.setAnswersState)
  }

  removeItem() {
    this.setAnswersState()
  }

  render() {
    const { currentStep, form } = this.props
    const formItemLayout = null

    const business = isBusiness(this.props.answers)

    return (
      <div>
        <Layout hasSider={true}>
          <Sider>
            <Menu selectedKeys={[currentStep.route]}>
              {this.props.applicationSteps.map((step) => (
                <Menu.Item key={step.route}>
                  <Link
                    to={`/${this.props.participant.code}/application/${step.route}`}
                  >
                    {step.label}
                  </Link>
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
          <Layout>
            <Content>
              <Spin spinning={this.props.loading}>
                <Title level={3} style={{ padding: 8 }}>
                  {currentStep.label}
                </Title>
                <Card style={{ margin: 8 }}>
                  <Col
                    style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Title level={4} style={{ marginBottom: 16 }}>
                      Please provide:
                    </Title>
                    <PersonIndicator
                      name={`${this.requiredCount.controlPersons} Control Person`}
                      description={
                        'Identify one Control Person which defined by their position within your business. A Control Person is defined as a single individual with significant responsibility to control, manage, or direct a legal entity customer, including an executive officer or senior manager (e.g., a Chief Executive Officer, Chief Financial Officer, Chief Operating Officer, Managing Member, General Partner, President, Vice President, or Treasurer); or any other individual who regularly performs similar functions. Only one Control Person needs to be identified.'
                      }
                      display={business}
                      requiredCount={this.requiredCount.controlPersons}
                      count={this.controlPersonsCount}
                    />
                    <PersonIndicator
                      name={`${this.requiredCount.authorizedSigners} Authorized Signer`}
                      description={
                        'We require at least one Authorized Signer who meet the definition of being an Authorized Signer as defined in the Electronic Signature Agreement, a signature on behalf of the Applicant. If the Applicant only has one Authorized Signer then simply add one, however we will review and may require you to authorize a second user.'
                      }
                      display={true}
                      requiredCount={this.requiredCount.authorizedSigners}
                      count={this.authorizedSignerCount}
                    />
                    <PersonIndicator
                      name={'All Benifical Owners'}
                      description={
                        'A Beneficial Owner is defined as an individual who has, directly or indirectly, 25% or more equity interest in the Applicant.'
                      }
                      display={business}
                      requiredCount={this.requiredCount.beneficialOwners}
                      count={this.beneficialOwnersCount}
                    />
                  </Col>
                </Card>
                <Form
                  onSubmit={this.saveInProgressApplication}
                  layout="vertical"
                  onChange={this.onChange}
                >
                  {currentStep.items
                    .filter((item) =>
                      item.displayIf ? item.displayIf(this.state.persons) : true
                    )
                    .map((item) => (
                      <div style={{ margin: 8 }} key={item.itemKey}>
                        <OnboardingApplicationFormItemSwitch
                          value={getValueFromAnswers(
                            item.itemKey,
                            this.state.persons
                          )}
                          form={form}
                          formItemLayout={formItemLayout}
                          answers={{ persons: this.state.persons }}
                          restService={this.props.restService}
                          participant={this.props.participant}
                          authUser={this.props.authUser}
                          onAdd={this.addItem}
                          onRemove={this.removeItem}
                          {...item}
                        />
                      </div>
                    ))}
                </Form>
                <div id="requirements-missing">
                  {this.state.errors.map((error, index) => (
                    <Alert key={index} type="error" message={error} banner />
                  ))}
                </div>
                <OnboardingApplicationStepFooter
                  {...this.props}
                  nextStepTrigger={this.saveInProgressApplication}
                  submitApplication={this.submitApplication}
                />
              </Spin>
            </Content>
          </Layout>
        </Layout>
      </div>
    )
  }
}

const PersonsStepForm = Form.create<IPersonsOnboardingApplicationStepProps>()(
  PersonsOnboardingApplicationStep
)

export default PersonsStepForm
