import React, { useState } from 'react'
import { Input, InputProps } from 'antd'

interface KycInputProps extends InputProps {
  primaryColor?: string
  textColor?: string
  inputProps?: InputProps
  children?: React.ReactNode
}

const KycInput = ({
  primaryColor = '#2253FF',
  textColor,
  children = 'Submit',
  ...inputProps
}: KycInputProps) => {
  const [isHover, setIsHover] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const handleMouseEnter = () => {
    setIsHover(true)
  }
  const handleMouseLeave = () => {
    setIsHover(false)
  }
  const handleBlur = () => {
    setIsFocused(false)
  }
  const handleFocus = () => {
    setIsFocused(true)
  }

  return (
    <Input
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onBlur={handleBlur}
      onFocus={handleFocus}
      {...inputProps}
      style={{
        borderColor: isHover || isFocused ? primaryColor : null,
        color: textColor,
        boxShadow: isFocused ? `0 0 0 4px ${primaryColor}1a` : null, // 1a is 0.1 opacity in hex
        ...inputProps.style
      }}
    />
  )
}

export default KycInput
