import * as React from 'react'
import Title from 'seed-shared-components/lib/components/Title'
import { IAsset } from 'seed-shared-components/lib/static-data/assetConfig'
import { Button, Card, Checkbox, notification, Spin, Typography } from 'antd'
import { Form } from '@ant-design/compatible'
import FormItem from '@ant-design/compatible/es/form/FormItem'
import { useState } from 'react'
import RestService from '../../RestService'
import TAG_BASED_ASSETS from '../../utils/tagBasedAssets'
import copyTextToClipboard from '../../copyTextToClipboard'
import FiatAddressInformation from './FiatAddressInformation'
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'

type IDepositAssetsAndCurrenciesProps = {
  restService: RestService
  assetsAndCurrenciesList: IAsset[]
  selectedAsset?: IAsset
}

interface IDigitalDepositAddress {
  address: string
  createdAt: string // "2018-12-18T04:12:19.345Z"
  currency: string // "BTC"
  id: number
  participantCode: string
}

interface IDepositAddressResponse {
  count: number
  depositAddresses: IDigitalDepositAddress[]
}

const DepositAddress = (props: IDepositAssetsAndCurrenciesProps) => {
  const { selectedAsset, restService } = props

  const [
    hasAgreementBeenSatisfied,
    setOnAgreementSatisfied
  ] = useState<boolean>(false)
  const [
    isLoadingDepositsAddresses,
    setLoadingDepositsAddresses
  ] = useState<boolean>(false)
  const [getDepositAddresses, setDepositAddresses] = useState<
    IDigitalDepositAddress[]
  >([])
  const [
    didLoadingDepositsAddressesFail,
    setLoadingDepositsAddressesFail
  ] = useState<boolean>()
  const [showAddress, setShowAddress] = useState<boolean>(false)
  const [getCopyResult, setCopyResult] = useState<string[]>([])

  const noSelectedAsset = () => {
    if (!selectedAsset) {
      return (
        <Card style={{ marginBottom: 24 }}>
          <div style={{ fontSize: 16 }}>
            Select an asset above to get started
          </div>
        </Card>
      )
    }

    return <></>
  }

  const Address = ({
    digitalAddress,
    asset
  }: {
    digitalAddress?: string
    asset: string
  }) => {
    const upperAsset = asset.toUpperCase()

    if (digitalAddress) {
      const tagBasedAssetConfig = TAG_BASED_ASSETS[upperAsset]
      const isTagBasedAsset = !!tagBasedAssetConfig

      let topSection

      if (isTagBasedAsset) {
        const [address, tag] = digitalAddress.split(
          `?${tagBasedAssetConfig.tag}=`
        )

        topSection = (
          <Card
            size="small"
            style={{
              display: 'inline-block',
              borderStyle: 'none'
            }}
          >
            <code>{address}</code>
            <br />
            <span>
              {tagBasedAssetConfig.label}: {tag}
            </span>
          </Card>
        )
      } else {
        topSection = <code>{digitalAddress}</code>
      }

      return <>{topSection}</>
    } else {
      return <>No Deposit Address found for this asset.</>
    }
  }

  const onCopyCryptoAddress = async (address: string) => {
    const result = await copyTextToClipboard(address)

    setCopyResult(result ? [...getCopyResult, address] : [])
  }

  const warningAboutAddress = () => {
    return (
      <Card
        actions={
          didLoadingDepositsAddressesFail
            ? []
            : [
                <Button
                  disabled={
                    !hasAgreementBeenSatisfied ||
                    showAddress ||
                    isLoadingDepositsAddresses
                  }
                  onClick={fetchAddress}
                >
                  {isLoadingDepositsAddresses
                    ? 'Generating a new address...'
                    : 'Show Deposit Address'}
                </Button>
              ]
        }
        style={{ marginBottom: 24 }}
        bodyStyle={{ padding: 0, minHeight: 80 }}
        className="transparent"
      >
        <Spin spinning={isLoadingDepositsAddresses}>
          {hasAgreementBeenSatisfied && showAddress ? (
            <div style={{ textAlign: 'center', margin: 4 }}>
              <div>{selectedAsset.name}</div>
              {getDepositAddresses.map((digitalAddress, idx) => (
                <div key={idx}>
                  <div style={{ margin: 8 }}>
                    <Address
                      digitalAddress={digitalAddress?.address}
                      asset={selectedAsset.asset}
                    />
                  </div>

                  {digitalAddress ? (
                    <Button
                      type="primary"
                      onClick={() => {
                        onCopyCryptoAddress(digitalAddress?.address || '')
                      }}
                    >
                      Copy Address to Clipboard
                    </Button>
                  ) : null}
                  {!getCopyResult.includes(digitalAddress?.address) ? (
                    <CloseOutlined className="copy-result" />
                  ) : (
                    <CheckOutlined className="copy-result" />
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <div style={{ background: 'rgba(222, 193, 140, 1)' }}>
                <div style={{ padding: 4 }}>
                  <div style={{ marginTop: 8 }}>
                    <InfoCircleOutlined
                      style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.65)' }}
                    />{' '}
                    <span>Warning</span>
                  </div>
                  <p>
                    Send only{' '}
                    <span style={{ fontWeight: 700 }}>
                      {selectedAsset.name}
                    </span>{' '}
                    to this address. Sending other coins or tokens to this
                    address may result in the loss of funds.
                  </p>
                </div>
              </div>

              {didLoadingDepositsAddressesFail ? (
                <div style={{ margin: '20px 0' }}>
                  <Typography.Text>
                    Our wallet is currently working on some other requests and
                    your request is delayed. Please check again shortly.
                  </Typography.Text>
                </div>
              ) : (
                <Form layout="inline">
                  <FormItem>
                    <Checkbox
                      onChange={(e) =>
                        setOnAgreementSatisfied(e.target.checked)
                      }
                    >
                      I understand the risks.
                    </Checkbox>
                  </FormItem>
                </Form>
              )}
            </div>
          )}
        </Spin>
      </Card>
    )
  }

  const fetchAddress = async () => {
    setLoadingDepositsAddresses(true)

    try {
      const resp = await restService
        .route(`deposit_addresses/${selectedAsset.asset}`)
        .get<IDepositAddressResponse>()

      const { depositAddresses } = resp

      if (depositAddresses.length > 0) {
        setDepositAddresses(depositAddresses)
        setShowAddress(true)
      } else {
        // successful empty response means there are no wallets for this participant/asset combo
        setDepositAddresses([])
        setLoadingDepositsAddressesFail(true)
        setShowAddress(false)
      }
    } catch (error) {
      console.log('failed fetching deposit address. error: ', error)
      notification.error({
        description: 'Please try again or contact support.',
        duration: 0,
        message: 'Failed to load deposit Address'
      })
    } finally {
      setLoadingDepositsAddresses(false)
    }
  }

  const renderCryptoAsset = () => {
    return (
      <>
        <div style={{ textAlign: 'center' }}>{warningAboutAddress()}</div>
      </>
    )
  }

  const renderFiatCurrency = () => {
    return (
      <FiatAddressInformation
        restService={restService}
        selectedAsset={selectedAsset}
      />
    )
  }

  const renderContent = () => {
    if (selectedAsset) {
      return (
        <>
          <Title level={3}>{`Deposit ${selectedAsset.name}`}</Title>
          {selectedAsset.type === 'Crypto'
            ? renderCryptoAsset()
            : renderFiatCurrency()}
        </>
      )
    } else {
      return <></>
    }
  }

  return (
    <>
      {!selectedAsset && noSelectedAsset()}
      {renderContent()}
    </>
  )
}

export default DepositAddress
