import { IPlatformPositionsResult } from './types'
import portalApi from '../../api/portalApi'

export const fetchPlatformPositions = async ({
  token = '',
  platformCode
}: {
  token: string
  platformCode: string
}): Promise<IPlatformPositionsResult> => {
  const { data } = await portalApi.get(`/platforms/${platformCode}/positions`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return data
}
