import React, { useState } from 'react'
import { Button, Form, Input, Modal } from 'antd'
import { MailOutlined, UserOutlined } from '@ant-design/icons'

interface IAddParticipantUserProps {
  addUser: (name: string, email: string) => Promise<void>
}

const AddParticipantUser = ({ addUser }: IAddParticipantUserProps) => {
  const [form] = Form.useForm()
  const [showModal, setShowModal] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const handleClick = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    form.resetFields()
    setShowModal(false)
  }

  const handleSubmit = async (values) => {
    setSubmitting(true)
    try {
      await addUser(values.name, values.email)
      form.resetFields()
    } catch (e) {
      console.warn('Failed to submit form')
    }
    setShowModal(false)
    setSubmitting(false)
  }

  return (
    <div>
      <Button type="primary" onClick={handleClick}>
        Submit Pending User
      </Button>
      <Modal
        visible={showModal}
        title="Submit Pending User"
        onCancel={closeModal}
        confirmLoading={submitting}
        onOk={form.submit}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          hideRequiredMark={true}
        >
          <Form.Item rules={[{ required: true, whitespace: true }]} name="name">
            <Input
              placeholder="Name"
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Form.Item>
          <Form.Item
            validateTrigger="onBlur"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter a vaild email address',
                whitespace: true,
                type: 'email'
              }
            ]}
          >
            <Input
              placeholder="Email"
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default AddParticipantUser
