import * as React from 'react'
import { IWithParticipantComponentProps } from '../components/participant/WithParticipant'
import {
  ITrade,
  IMovement
} from 'seed-shared-components/lib/components/TradeBlotter/types'
import TradeDetails from 'seed-shared-components/lib/components/TradeBlotter/TradeDetails'

interface ITradeDetailsContainerProps
  extends IWithParticipantComponentProps<{
    id?: string
    tradeId?: string
    participantCode?: string
  }> {
  participantCode: string
  trades?: ITrade[]
}

export default class TradeDetailsContainer extends React.Component<ITradeDetailsContainerProps> {
  constructor(props: ITradeDetailsContainerProps) {
    super(props)
    this.loadTrades = this.loadTrades.bind(this)
    this.loadMovements = this.loadMovements.bind(this)
  }

  async loadTrades(): Promise<ITrade[]> {
    if (this.props.trades) {
      return this.props.trades
    }
    const { id, tradeId } = this.props.match.params
    const trades = await this.props.restService
      .route(`trades/${id || tradeId}`)
      .get<ITrade[]>()

    return trades
  }

  async loadMovements(): Promise<IMovement[]> {
    const { id, tradeId } = this.props.match.params
    const movements = await this.props.restService
      .route(`trades/${id || tradeId}/movements`)
      .get<IMovement[]>()

    return movements
  }

  render() {
    const Details = TradeDetails({
      participantCode:
        this.props.match.params.participantCode || this.props.participantCode,
      loadTrades: this.loadTrades,
      loadMovements: this.loadMovements
    })

    return <Details />
  }
}
