import { IApplicationState } from '../state'
import { IFundsState, IAssetBalancesState, ITotalAssetBalance } from './state'
import {
  IAsset,
  IFund
} from 'seed-shared-components/lib/static-data/assetConfig'

export const getState = (state: IApplicationState): IFundsState => state.funds

export const getFunds = (state: IApplicationState): IFund[] | null => {
  return getState(state).funds
}

export const getLoading = (state: IApplicationState): boolean => {
  return getState(state).loading
}

export const getAssetBalances = (
  state: IApplicationState
): IAssetBalancesState => {
  return getState(state).assetBalances
}

export const getBalances = (
  state: IApplicationState
): ITotalAssetBalance[] | null => {
  return getAssetBalances(state).balances
}

export const getEnabledAssetsBalances = (
  state: IApplicationState
): ITotalAssetBalance[] => {
  const assetBalances = getAssetBalances(state)
  const balances = (assetBalances && assetBalances.balances) || []

  return balances.slice()
}

export const getAssets = (state: IApplicationState): IAsset[] => {
  return getState(state).assets
}
