import React from 'react'
import { List } from 'antd'

import Title from 'seed-shared-components/lib/components/Title'
import { IApiKeyLedger } from '../../types'

interface ApiKeyDetailsProps {
  apiKey: IApiKeyLedger | undefined
}

const ApiKeyDetails = ({ apiKey }: ApiKeyDetailsProps) => {
  return (
    <div>
      <div>
        <Title level={4} style={{ color: 'red' }}>
          Warning
        </Title>
        <div style={{ margin: 8 }}>
          <strong>We do not save your passphrase or private key.</strong> Once
          you navigate away from this page you will be unable to recover them.
          MAKE SURE to write them down in a secure location or you will not be
          able to use this API key.
        </div>
      </div>
      <List
        bordered
        dataSource={[apiKey]}
        renderItem={(apiKey: IApiKeyLedger) => (
          <>
            <List.Item>
              <strong style={{ width: '20%' }}>Public Key:</strong>
              <span style={{ width: '80%', textAlign: 'right' }}>
                {apiKey.public_key}
              </span>
            </List.Item>
            <List.Item>
              <strong style={{ width: '20%' }}>Private Key:</strong>
              <span style={{ width: '80%', textAlign: 'right' }}>
                {apiKey.private_key}
              </span>
            </List.Item>
            <List.Item>
              <strong style={{ width: '20%' }}>Passphrase:</strong>
              <span style={{ width: '80%', textAlign: 'right' }}>
                {' '}
                {(apiKey as any).passphrase}
              </span>
            </List.Item>
          </>
        )}
      />
    </div>
  )
}

export default ApiKeyDetails
