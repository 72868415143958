import qs from 'qs'
import * as _ from 'lodash'
import { DEFAULT_PAGE_SIZE } from '../constants'
import RestService from '../RestService'
import { IParticipantRelationship } from '../types'
import { IParticipantBasic } from 'seed-ts/types/participants'

export interface IPaginatedParticipantRelationshipsParams {
  page?: number
  pageSize?: number
  lastRecordId?: number
  [key: string]: string | number | boolean
}

export type IParticipantRelationshipsResult = IParticipantRelationship[]

export interface IPaginatedRelationshipsServiceResult {
  message: IParticipantRelationship[]
  page: number
  page_size: number
  total_pages: number
  count: number
}

export const getAccumulatedRelationships = async (
  authToken: string,
  participantCode: string
): Promise<IParticipantRelationshipsResult> => {
  const restService = new RestService(authToken, participantCode)

  // otherwise accumulate result using multiple requests
  let accumulatedResult: IParticipantRelationship[] = []
  let paginatedResponse

  do {
    const lastRecordId =
      accumulatedResult.length > 0
        ? accumulatedResult[accumulatedResult.length - 1].id
        : 0
    const queryString = qs.stringify({
      pageSize: DEFAULT_PAGE_SIZE,
      lastRecordId
    })
    paginatedResponse = await restService
      .route(`relationships?${queryString}`)
      .get<IPaginatedRelationshipsServiceResult>()

    if (paginatedResponse.message.length === 0) {
      return paginatedResponse.message
    }

    accumulatedResult = [...accumulatedResult, ...paginatedResponse.message]
  } while (paginatedResponse.count > accumulatedResult.length)

  return accumulatedResult
}

export type IParticipantsNamesResult = { [key: string]: string }
/*
 * IParticipantSettings is a Partial Settings object.
 * To check which Settings are being
 * exposed you can check portal-api code here https://github.com/seedcx/portal-api/blob/068719e6e0b20ffaffd9638b855f5fcd92e90640/src/participants/participant-router.ts#L869
 */
export type IParticipantSettings = {
  marketDataEnabled: boolean
  participantSanctionScreeningInfoEnabled: boolean
  participantFullInfoEnabled: boolean
}

export const getParticipantsNames = async (
  authToken: string,
  participantCode: string,
  participantsCodes: string[]
): Promise<IParticipantsNamesResult> => {
  const restService = new RestService(authToken, participantCode)
  const queryString = qs.stringify({ participants_codes: participantsCodes })

  return restService
    .route(`participants/names?${queryString}`)
    .get<IParticipantsNamesResult>()
}

export const getParticipantFilteredSettings = async (
  authToken: string,
  participantCode: string
): Promise<IParticipantSettings> => {
  const restService = new RestService(authToken, participantCode)

  return restService.route(`settings`).get<IParticipantSettings>()
}

export interface IPaginatedPlatformParticipantsParams {
  page?: number
  pageSize?: number
  lastRecordId?: number
  searchString?: string
  includePlatformParticipant?: boolean
  includeRelationships?: boolean
  relationshipsTypes?: string[]
  basic?: boolean
}

export interface IPlatformParticipantResult extends IParticipantBasic {
  relationships?: IParticipantRelationship[]
}

export interface IPaginatedPlatformParticipantsServiceResult {
  message: IPlatformParticipantResult[]
  page?: number
  page_size: number
  total_pages: number
  count: number
}

export const getPaginatedPlatformParticipants = async (
  authToken: string,
  participantCode: string,
  params: IPaginatedPlatformParticipantsParams = {}
): Promise<IPaginatedPlatformParticipantsServiceResult> => {
  if (!_.has(params, 'page') && !_.has(params, 'lastRecordId')) {
    throw new Error(`At least 'page' or 'lastRecordId' should be defined.`)
  }

  const restService = new RestService(authToken, participantCode)

  return restService
    .route(`related-participants?${qs.stringify(params)}`)
    .get<IPaginatedPlatformParticipantsServiceResult>()
}

export interface IHasRelationshipParams {
  primaryParticipantCode: string
  secondaryParticipantCode: string
  relationType: string
}

export interface IHasRelationshipResult {
  hasRelationship: boolean
}

export const hasRelationship = async (
  authToken: string,
  participantCode: string,
  params: IHasRelationshipParams
): Promise<IHasRelationshipResult> => {
  const restService = new RestService(authToken, participantCode)

  return restService
    .route(`has-relationship?${qs.stringify(params)}`)
    .get<IHasRelationshipResult>()
}
