import { Button, Input, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { HexColorPicker } from 'react-colorful'

type ColorPickerProps = {
  color: string
  setColor: React.Dispatch<React.SetStateAction<string>>
  visible?: boolean
  title?: string
}

const ColorPicker = ({ color, setColor, visible }: ColorPickerProps) => {
  const ref = useRef(null)
  const [colorPickerVisible, setColorPickerVisible] = useState(visible)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setColorPickerVisible(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <div ref={ref}>
      <Button
        style={{
          width: '100%',
          textAlign: 'left'
        }}
        onClick={() => setColorPickerVisible((prev) => !prev)}
      >
        <div style={{ minWidth: '100%' }}>
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <div
              style={{
                width: '22px',
                height: '22px',
                borderRadius: 4,
                backgroundColor: color,
                border: '1px solid #d9d9d9'
              }}
            />
            <div style={{ marginLeft: '8px' }}>
              <Typography.Text>{color}</Typography.Text>
            </div>
          </div>
        </div>
      </Button>
      {colorPickerVisible ? (
        <div
          style={{
            marginTop: '0.5em',
            padding: 8,
            position: 'absolute',
            zIndex: 10,
            border: '1px solid #d9d9d9',
            borderRadius: 4,
            backgroundColor: '#fffef7'
          }}
        >
          <HexColorPicker color={color} onChange={setColor} />
          <div style={{ marginTop: '0.25em' }}>
            <Typography.Text>Hex value</Typography.Text>
          </div>
          <Input
            style={{ marginTop: '0.25em' }}
            value={color}
            onChange={(e) => setColor(e.target.value)}
            placeholder="#D0D0D0"
          />
        </div>
      ) : null}
    </div>
  )
}

export default ColorPicker
