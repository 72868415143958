import * as React from 'react'

import PageTitle from 'seed-shared-components/lib/components/Title'
import GenerateReportForm from './GenerateReportForm'
import ReportsTable from './ReportsTable'

function Reports() {
  return (
    <div>
      <PageTitle level={2}>Reports</PageTitle>
      <div style={{ marginBottom: 24 }}>
        <GenerateReportForm />
      </div>
      <div style={{ marginBottom: 24 }}>
        <ReportsTable />
      </div>
    </div>
  )
}

export default Reports
