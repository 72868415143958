import { combineReducers } from 'redux'
import { IApplicationState } from './state'
import funds from './funds'
import accountHistory from './accountHistory'
import sign from './sign'
import participant from './participant'
import user from './user'
import withdrawal from './withdrawal'
import platformBalances from './platform-balances'
import platformPositions from './platform-positions'
import participantPositions from './participant-positions'
import netDeliveryObligations from './net-delivery-obligations'
import portfolio from './portfolio'
import userSettings from './user-settings'
import accountMovements from './account-movements'
import reports from './reports'

export default combineReducers<IApplicationState>({
  funds,
  accountHistory,
  sign,
  participant,
  user,
  withdrawal,
  platformBalances,
  platformPositions,
  participantPositions,
  netDeliveryObligations,
  portfolio,
  userSettings,
  accountMovements,
  reports
})
