const participantUserRoleText = {
  is_admin: {
    displayName: 'Is Administrator',
    tooltip:
      'Admins manage users, funds, accounts, trades and API keys for the participant.',
    onMessage:
      'Admins manage users, funds, accounts, trades and API keys for the participant.',
    offMessage:
      'Removing the Admin privilege will prevent the user from being able to edit the participant.'
  },
  is_authorized_signer: {
    displayName: 'Is Authorized Signer',
    tooltip:
      window._env_.REACT_APP_SEED_ENV !== 'prod'
        ? 'Authorized Signers are a special type of Admin that can sign on behalf of the participant. This is used in onboarding and other legal situations.'
        : 'Authorized Signers are a special type of Admin that can sign on behalf of the participant. This is used in onboarding and other legal situations. Feel free to manually assign this privilege to users in the Certification environment, but please note that adding or removing Authorized Signers in production must be done by support.',
    disabled: window._env_.REACT_APP_SEED_ENV === 'prod',
    onMessage:
      'This user will be able to sign agreements on the befalf of, and legally bind, the participant.',
    offMessage:
      'This user will not be able to sign agreements on the befalf of, or legally bind, the participant.'
  },
  is_trade_submitter: {
    displayName: 'Is Trade Submitter',
    tooltip:
      'Enables a user to submit trades & loans for settlement on Zero Hash',
    onMessage:
      'Users with the Trade Submitter permission can submit trades & loans for settlement on Zero Hash.',
    offMessage:
      'Removing the Trade Submitter privilege will prevent the user submitting trades or loans for settlement on Zero Hash.'
  }
}
export default participantUserRoleText
