import * as React from 'react'

import { Application } from '../../../onboarding-application/participant'
import { Card, Col, Divider, Row, Table, Spin } from 'antd'

import { ColumnProps } from 'antd/lib/table'
import {
  ApplicationPayload,
  AuthorizedSigner,
  BeneficialOwner,
  IDate,
  ApplicationFileProp,
  ControlPerson
} from '../../../onboarding-application/participant'

import { applicationPropertyLabels } from '../../utils/applicationPropertyUtils'
import { propToEnumMap } from '../../utils/applicationEnumFormatters'
import { getPeopleFromPayload } from '../../utils/parseApplicationPayload'
import {
  formatBooleanStrings,
  formatNumberStrings
} from '../../../utils/applicationPayloadUtils'
import { ReviewOnboardingApplicationProps } from './props'
import connect from './connector'
import Title from 'seed-shared-components/lib/components/Title'

const handlePrimativeValue = (value: any): string => {
  if (value !== undefined && value !== null) {
    return String(value)
  }
  return ''
}

const files = (payload: ApplicationPayload, property: ApplicationFileProp) => {
  const files = payload[property]
  if (!files) {
    return null
  }
  return Object.keys(files).map((fileName) => {
    if (fileName === 'error') {
      // Portal1 always had an 'error' prop where a fileName should be
      return null
    }
    const key = `${property}.${fileName}`
    const file = files[fileName]
    if (file.signedUrl) {
      return (
        <a href={file.signedUrl} key={key}>
          {fileName}
        </a>
      )
    } else {
      return <div key={key}>{`${fileName} - ${file.state}`}</div>
    }
  })
}

const mapAndEnumFormatter = (
  payload: ApplicationPayload,
  property: keyof ApplicationPayload,
  type: 'map' | 'enum',
  mapOrEnum: number | string
) => {
  const formatter = propToEnumMap(property) as any
  const values = payload[property]

  if (values && type === 'map') {
    const v2 = values as any
    if (v2[mapOrEnum] === true || v2[mapOrEnum] === 'true') {
      const key = `${property}.${mapOrEnum}`
      return <div key={key}>{formatter[mapOrEnum]}</div>
    }
  }

  if (formatter) {
    return formatter[Number(mapOrEnum)]
  }
  return mapOrEnum
}

interface IGetApplicationReviewResponse {
  status: 'signed' | 'unsigned'
  application: Application
}

interface IReviewOnboardingApplicationState {
  applicationId?: string
  payload?: Application['payload']
}

class ReviewOnboardingApplication extends React.Component<
  ReviewOnboardingApplicationProps,
  IReviewOnboardingApplicationState
> {
  authorizedSignersColumns: ColumnProps<AuthorizedSigner>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      key: 'dob',
      render: (dob: IDate) =>
        dob ? (
          <span className="dob">
            {dob.month}-{dob.day}-{dob.year}
          </span>
        ) : null
    },
    {
      title: 'SSN/Passport',
      dataIndex: 'ssn',
      key: 'ssn'
    },
    {
      title: 'Contact Person',
      dataIndex: 'contact_person',
      key: 'contact_person',
      render: (bool: boolean) => (bool ? `${bool}` : null)
    }
  ]
  beneficialOwnersColumns: ColumnProps<BeneficialOwner>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: ''
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      key: 'dob',
      render: (dob: IDate) =>
        dob ? (
          <span className="dob">
            {dob.month}-{dob.day}-{dob.year}
          </span>
        ) : null
    },
    {
      title: 'SSN/Passport Number',
      dataIndex: 'ssn',
      key: 'ssn'
    },
    {
      title: 'Address',
      render: (item: BeneficialOwner) => (
        <>
          {item.address ? (
            <div>{item.address}</div>
          ) : (
            <div>
              <div>{item.address_line1}</div>
              <div>{item.address_line2}</div>
              <div>
                {item.address_city} {item.address_state}, {item.address_zip}
              </div>
              <div>{item.address_country}</div>
            </div>
          )}
        </>
      )
    },
    {
      title: 'Passport Document (non-US)',
      dataIndex: 'passport_document',
      render: (docs: ControlPerson['passport_document']) =>
        docs
          ? Object.keys(docs).map((filename) => (
              <a href={docs[filename].signedUrl}>{filename}</a>
            ))
          : null
    }
  ]
  controlPersonColumns: ColumnProps<ControlPerson>[] = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Title',
      dataIndex: 'title'
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      key: 'dob',
      render: (dob: IDate) =>
        dob ? (
          <span className="dob">
            {dob.month}-{dob.day}-{dob.year}
          </span>
        ) : null
    },
    {
      title: 'Address',
      render: (item: ControlPerson) => (
        <>
          <div>
            <div>{item.address_line1}</div>
            <div>{item.address_line2}</div>
            <div>
              {item.address_city} {item.address_state}, {item.address_zip}
            </div>
            <div>{item.address_country}</div>
          </div>
        </>
      )
    },
    {
      title: 'SSN/Passport number',
      dataIndex: 'ssn',
      key: 'ssn'
    },
    {
      title: 'Passport Document (non-US)',
      dataIndex: 'passport_document',
      render: (docs: ControlPerson['passport_document']) =>
        docs
          ? Object.keys(docs).map((filename) => (
              <a href={docs[filename].signedUrl}>{filename}</a>
            ))
          : null
    }
  ]

  constructor(props: ReviewOnboardingApplicationProps) {
    super(props)
    this.state = { payload: undefined, applicationId: undefined }
  }

  async componentWillMount() {
    try {
      this.props.onLoadApplication()
      const response = (await this.props.restService
        .route('application_for_review')
        .get()) as IGetApplicationReviewResponse
      const { status, application } = response

      const newState: IReviewOnboardingApplicationState = {}
      if (application.payload) {
        const payloadWithFixedBooleanStrings = formatBooleanStrings(
          application.payload
        )
        const parsedPayload = formatNumberStrings(
          payloadWithFixedBooleanStrings
        )
        newState.payload = parsedPayload
      }
      newState.applicationId = application.id

      this.setState(() => newState)
      this.props.onLoadApplicationSuccess(status)
    } catch (e) {
      console.error('get application error: ', e)
      this.props.onLoadApplicationFailure()
    }
  }

  async sign() {
    try {
      const body = {
        id: this.state.applicationId,
        participantName: this.props.participant.name
      }
      const signed = await this.props.restService
        .route('participant_application_signatures')
        .post(body)
      console.log('success: ', signed)

      this.props.onSignSuccess()
    } catch (e) {
      console.error('failed to sign. e: ', e)
      this.props.onSignFailure()
    }
  }

  componentDidUpdate(previousProps: ReviewOnboardingApplicationProps) {
    if (
      previousProps.submitted !== this.props.submitted &&
      this.props.submitted
    ) {
      this.sign()
    }
  }

  render() {
    if (this.props.error || !this.state.payload) {
      if (this.props.loading) {
        return (
          <Spin spinning>
            <span style={{ marginTop: 48, marginLeft: 24 }}>
              Loading onboarding application...
            </span>
          </Spin>
        )
      }
      return (
        <span style={{ marginTop: 48, marginLeft: 24 }}>
          No Signatures needed at this time.
        </span>
      )
    }

    const { payload } = this.state
    const { authorized_signers, beneficial_owners } = getPeopleFromPayload(
      payload
    )
    const { entity_type, control_persons } = payload

    const genericProps = applicationPropertyLabels.filter(
      (item) => !item.entityTypes && item.dataType !== 'file'
    )
    const entitiySpecificProps = applicationPropertyLabels.filter(
      (item) =>
        item.entityTypes &&
        item.entityTypes.includes(entity_type) &&
        item.dataType !== 'file'
    )
    const documents = applicationPropertyLabels.filter(
      (item) =>
        item.dataType === 'file' &&
        (!item.entityTypes || item.entityTypes.includes(entity_type))
    )

    return (
      <div>
        <Spin spinning={this.props.loading}>
          {this.props.status === 'unsigned' &&
          this.state.payload &&
          Object.keys(this.state.payload).length >= 1 ? (
            <Card>
              <div>
                {genericProps
                  ? genericProps.map((item) => (
                      <Row
                        gutter={32}
                        justify="center"
                        align="bottom"
                        key={item.property}
                      >
                        <Col span={9}>
                          <span>{item.label}:</span>
                        </Col>
                        <Col span={14}>
                          {item.dataType === 'map' ? (
                            <div>
                              {payload[item.property]
                                ? Object.keys(
                                    payload[item.property] as any
                                  ).map(
                                    mapAndEnumFormatter.bind(
                                      this,
                                      payload,
                                      item.property,
                                      'map'
                                    )
                                  )
                                : null}
                            </div>
                          ) : item.dataType === 'enum' ? (
                            <div>
                              {mapAndEnumFormatter(
                                payload,
                                item.property,
                                'enum',
                                payload[item.property] as any
                              )}
                            </div>
                          ) : (
                            handlePrimativeValue(payload[item.property])
                          )}
                        </Col>
                        <Divider
                          style={{
                            margin: '4px 0',
                            width: '94%',
                            minWidth: '94%'
                          }}
                        />
                      </Row>
                    ))
                  : null}

                {entitiySpecificProps.map((item) => (
                  <Row
                    gutter={32}
                    justify="center"
                    align="bottom"
                    key={item.property}
                  >
                    <Col span={9}>
                      <div>{item.label}:</div>
                    </Col>
                    <Col span={14}>
                      {item.dataType === 'map' ? (
                        <div>
                          {payload[item.property]
                            ? Object.keys(payload[item.property] as any).map(
                                mapAndEnumFormatter.bind(
                                  this,
                                  payload,
                                  item.property,
                                  'map'
                                )
                              )
                            : null}
                        </div>
                      ) : item.dataType === 'enum' ? (
                        <div>
                          {mapAndEnumFormatter(
                            payload,
                            item.property,
                            'enum',
                            payload[item.property] as any
                          )}
                        </div>
                      ) : (
                        handlePrimativeValue(payload[item.property])
                      )}
                    </Col>
                    <Divider
                      style={{ margin: '4px 0', width: '94', minWidth: '94%' }}
                    />
                  </Row>
                ))}

                {documents.map((item) => (
                  <Row
                    gutter={32}
                    justify="center"
                    align="bottom"
                    key={item.property}
                  >
                    <Col span={9}>
                      <span>{item.label}:</span>
                    </Col>
                    <Col span={14}>
                      <div>
                        {files(payload, item.property as ApplicationFileProp)}
                      </div>
                    </Col>
                    <Divider
                      style={{ margin: '4px 0', width: '94%', minWidth: '94%' }}
                    />
                  </Row>
                ))}

                <Card type="inner" bordered={false}>
                  <Title level={4}>Authorized Signers</Title>
                  <Table
                    rowKey="name"
                    columns={this.authorizedSignersColumns}
                    dataSource={authorized_signers}
                    pagination={false}
                    size="middle"
                  />
                </Card>

                {entity_type === 'business' && beneficial_owners ? (
                  <Card type="inner" bordered={false}>
                    <Title level={4}>Beneficial Owners</Title>
                    <Table
                      rowKey="name"
                      columns={this.beneficialOwnersColumns}
                      dataSource={beneficial_owners}
                      pagination={false}
                    />
                  </Card>
                ) : null}
                {entity_type === 'business' && control_persons ? (
                  <Card type="inner" bordered={false}>
                    <Title level={4}>Control Person</Title>
                    <Table
                      rowKey="name"
                      columns={this.controlPersonColumns}
                      dataSource={control_persons}
                      pagination={false}
                    />
                  </Card>
                ) : null}
              </div>
            </Card>
          ) : null}

          <div>
            {this.props.status === 'unsigned' ? (
              <p style={{ margin: 32 }}>
                The Authorized Signer represents and warrants that he or she is
                duly authorized and has the legal authority to execute
                agreements on behalf of the Applicant. By electronically
                pressing the “Sign” button, the undersigned represents, for
                itself and on behalf of its Authorized Traders (if applicable),
                Account Admin, and any other Person interacting on or with Seed
                on behalf of the Participant, that the Participant has read and
                agrees to the user agreements of Seed Digital Commodities Market
                LLC, Seed SEF LLC and Zero Hash LLC as they shall be in effect
                from time to time.
              </p>
            ) : null}
            {this.props.status === 'signed' ? (
              <span style={{ marginTop: 48, marginLeft: 24 }}>
                No Signatures needed at this time.
              </span>
            ) : null}
          </div>
        </Spin>
      </div>
    )
  }
}

export default connect(ReviewOnboardingApplication)
