import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import portalApi from '../api/portalApi'
import { notification, Spin } from 'antd'
import AuthUserContext from './AuthUserContext'
import * as auth from './auth'
import { IParticipant } from 'seed-shared-components/lib/types'
import { withRouter } from 'react-router-dom'
import * as userActions from '../store/user/actions'
import Title from 'seed-shared-components/lib/components/Title'
import { IUser } from '../store/user/actions/types'

type AuthStatus = 'AUTHENTICATED' | 'ANONYMOUS' | 'FAILED' | 'AUTHENTICATING'

export interface IAuthUser {
  authState?: AuthStatus
  username?: string
  tradingAccounts?: string[]
  accessToken?: string
  idToken?: string
  email: string
  newUser?: boolean
  participants: IParticipant[]
  expiry?: number
  emailUnverified?: boolean
  authErrorDescription?: string
  isAuthSigner: (participantCode: string) => Promise<boolean>
  isAdmin: (participantCode: string) => Promise<boolean>
  isTradeSubmitter: (participantCode: string) => Promise<boolean>
  getTradeUrl: () => string
  logout: () => void
  changePassword: () => void
  participant: (participantCode: string) => Promise<IParticipant | undefined>
  refreshUser: () => Promise<void>
}

interface IAuthProps {
  history: any
  setUser: (user: IUser) => void
}

interface DispatchProps {
  setUser: (user: IUser) => void
}

const withAuthentication = (Component: any) => {
  class WithAuthentication extends React.Component<IAuthProps, IAuthUser> {
    public authState: AuthStatus
    public username: string
    public tradingAccounts: string[]

    constructor(props: any) {
      super(props)
      this.state = {
        ...this.determineAuthStatus(),
        isAuthSigner: this.isAuthSigner.bind(this),
        isAdmin: this.isAdmin.bind(this),
        isTradeSubmitter: this.isTradeSubmitter.bind(this),
        getTradeUrl: this.getTradeUrl.bind(this),
        logout: this.logout.bind(this),
        changePassword: this.changePassword.bind(this),
        participant: this.getParticipant.bind(this),
        participants: [],
        refreshUser: this.refreshUser.bind(this)
      }
      this.createUser = this.createUser.bind(this)
    }

    determineAuthStatus(): {
      authState: AuthStatus
      email: string
      idToken?: string
    } {
      let authResult: any
      auth
        .authenticate()
        .then(
          (res) => (authResult = res),
          (response) => {
            if (
              response &&
              response.error &&
              response.error === 'login_required' &&
              (response.error_description === 'Login required' ||
                response.error_description ===
                  'Multifactor authentication required')
            ) {
              auth.login({})
            } else {
              this.setState(() => ({
                authErrorDescription: response.error_description,
                authState: 'FAILED'
              }))
              console.error('authenticate error. response: ', response)
              if (response.error_description === 'Consent required') {
                auth.login({})
              }
              if (
                response.error_description ===
                'Please verify your email before logging in.'
              ) {
                this.setState({ emailUnverified: true })
              }
              return
            }
          }
        )
        .then(() =>
          this.verifyUser(
            authResult.idToken,
            authResult.idTokenPayload.email_verified
          )
        )
        .then(
          () => {
            if (!this.state.newUser) {
              return this.getParticipants(authResult.idToken)
            }
            return []
          },
          (error) => {
            console.error(
              'Failed to verify user, not loading participants. error: ',
              error
            )
            throw error
          }
        )
        .then((participants) => {
          this.setState(() => ({
            accessToken: authResult.accessToken,
            authState: 'AUTHENTICATED',
            email: authResult.idTokenPayload.email,
            expiry: authResult.idTokenPayload.exp,
            idToken: authResult.idToken,
            participants: participants || []
          }))

          this.props.setUser({
            token: authResult.idToken,
            userIdentifier: authResult.idTokenPayload.nickname
          })

          // take the user to the page they were attempting to visit before Auth0 redirect
          // also, clear the auth0 response data from the url

          // authResult.state could be two posibilities:
          // a route from first load: /ASDFGH/sign
          // a quid from Auth0
          let newWindowLocation = null
          if (authResult.state && authResult.state.startsWith('/')) {
            newWindowLocation = authResult.state
          }
          history.pushState(null, '', newWindowLocation)
        })
        .catch((error) =>
          console.error('failed setting state AUTHENTICATED. error: ', error)
        )
      return {
        authState: 'AUTHENTICATING',
        email: ''
      }
    }
    async getParticipants(idToken: string): Promise<IParticipant[]> {
      const options = {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        json: true,
        method: 'GET',
        url: 'participants'
      }
      try {
        const { data } = await portalApi.request(options)
        return data
      } catch (e) {
        console.error(
          'There was an error retrieving participant information',
          e
        )
        if (e) {
          notification.error({
            description: e.message,
            duration: 0,
            message: 'Failed to fetch Participants'
          })
        }
        return []
      }
    }
    async getParticipant(
      participantCode: string
    ): Promise<IParticipant | undefined> {
      let participant = this.state.participants.find(
        (p) => p.code === participantCode
      )
      if (!participant) {
        const participants = await this.getParticipants(
          this.state.idToken || ''
        )
        participant = participants.find((p) => p.code === participantCode)
        this.setState(() => ({
          participants
        }))
      }
      return participant
    }
    async refreshUser(): Promise<void> {
      const participants = await this.getParticipants(this.state.idToken || '')
      this.setState(() => ({
        participants
      }))
    }
    async verifyUser(idToken: string, emailVerified?: boolean): Promise<any> {
      const options = {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        json: true,
        method: 'GET',
        url: `users/verify/self`
      }

      try {
        await portalApi.request(options).then((response) => {
          this.setState({ newUser: response.data.newUser })
        })
        return
      } catch (e) {
        console.log(e)
        throw e
      }
    }

    async isAuthSigner(participantCode: string): Promise<boolean> {
      const participant = await this.getParticipant(participantCode)
      return Boolean(participant && participant.isAuthSigner)
    }
    async isAdmin(participantCode: string): Promise<boolean> {
      const participant = await this.getParticipant(participantCode)
      return Boolean(participant && participant.isAdmin)
    }

    async isTradeSubmitter(participantCode: string): Promise<boolean> {
      const participant = await this.getParticipant(participantCode)
      return Boolean(participant && participant.isTradeSubmitter)
    }

    async changePassword(): Promise<void> {
      await auth.changePassword(this.state.email)
    }
    getTradeUrl(): string {
      return `${window._env_.REACT_APP_TRADE_URI}?id_token=${this.state.idToken}`
    }

    logout(): void {
      auth.logout()
    }

    async createUser(name: string) {
      const options = {
        data: {
          name: name
        },
        headers: {
          Authorization: `Bearer ${this.state.idToken}`
        },
        json: true,
        method: 'POST',
        url: `users/create/self`
      }
      await portalApi.request(options)
      this.setState(() => ({ newUser: false }))
      return
    }

    render() {
      if (this.state.emailUnverified) {
        this.props.history.push('/verify_email')
        return null
      }

      const ConnectedComponent = withRouter<any, any>(Component)

      // only render the app if authenticated
      if (this.state.authState === 'AUTHENTICATED') {
        return (
          <AuthUserContext.Provider value={this.state}>
            <ConnectedComponent authUser={this.state} />
          </AuthUserContext.Provider>
        )
      }

      return (
        <>
          {this.state.authErrorDescription ? (
            <>
              <Title level={4}>Authentication issue:</Title>
              <Title level={4}>{this.state.authErrorDescription}</Title>
            </>
          ) : (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-50px',
                marginLeft: '-50px',
                width: '100px',
                height: '100px'
              }}
            >
              <Spin />
            </div>
          )}
        </>
      )
    }
  }

  const mapDispatchToProps = (
    dispatch: Dispatch<{ type: string; payload: IUser }>
  ) => {
    return {
      setUser: (user: IUser) => dispatch(userActions.setUser(user))
    }
  }

  return connect<null, DispatchProps>(
    null,
    mapDispatchToProps
  )(WithAuthentication)
}
export default withAuthentication
