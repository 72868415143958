import { IWithParticipantComponentProps } from '../../components/participant/WithParticipant'
import React, { useEffect, useState } from 'react'
import { IAsset } from 'seed-shared-components/lib/static-data/assetConfig'
import DepositInformation from './DepositInformation'
import AssetsAndCurrenciesTab from './AssetsAndCurrenciesTab'
import * as uuid from 'uuid'
import { assets as assetConfigs } from 'seed-shared-components/lib/static-data/assetConfig'
import DepositNewAssetsRequestModal from './DepositNewAssetsRequestModal'
import DepositAddress from './DepositAddress'
import { notification, Spin } from 'antd'
import DepositHistory from './DepositHistory'
import { Route, Switch } from 'react-router-dom'
import DepositDetails from './DepositDetails'

export interface IDeposit {
  deposit_id: string // "6257c4c0-89f8-4d5e-920c-0c5e69292c9a",
  submit_timestamp: number // 1548461987789,
  settle_timestamp: number // 1548461987817,
  participant_code: string // "95Z5CW",
  amount: number // 5000.0,
  currency: string // "ETH",
  reference_id: string // "9f8b1415-e8c7-463a-9f1b-20587b876448",
  version: number
  account_id: string
}

export interface IPagination {
  total: number
  page: number
  pageSize: number
}

const DepositPage = (props: IWithParticipantComponentProps) => {
  const { asset: assetPathParam } = props.match.params as any
  const { restService } = props

  const [getAssetManagerAssets, setAssetManagerAssets] = useState<IAsset[]>([])
  const [getSelectedAsset, setSelectedAsset] = useState<IAsset>(null)
  const [
    shouldShowNewAssetsRequestModal,
    setShowNewAssetsRequestModal
  ] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [getPagination, setPagination] = useState<IPagination>({
    page: 1,
    total: 0,
    pageSize: 25
  })
  const [getDeposits, setDeposits] = useState<IDeposit[]>([])

  useEffect(() => {
    ;(async function (assetPathParam) {
      setLoading(true)

      let assets = getAssetManagerAssets

      if (!assets.length) {
        const cryptoAssets: IAsset[] = await props.restService
          .route(`assets?requestId=${uuid.v4()}&asset_type=CRYPTO`)
          .get()

        const fiatCurrencies = assetConfigs.filter(
          (asset) => asset.type === 'Fiat'
        )

        assets = [...cryptoAssets, ...fiatCurrencies].sort((a, b) =>
          a.asset.localeCompare(b.asset)
        )

        setAssetManagerAssets(assets)
      }

      if (assetPathParam && assets.length > 0) {
        const filteredAssetsByParam = assets.filter(
          (elem) => elem.asset.toUpperCase() === assetPathParam.toUpperCase()
        )

        if (filteredAssetsByParam.length > 0) {
          const selectedAsset = filteredAssetsByParam[0]
          setSelectedAsset(selectedAsset)

          await fetchDeposits({
            asset: selectedAsset.asset
          })
        } else {
          // User wrongly typed asset on URL, going to redirect back to Deposit's main page
          props.history.push(`/${restService.participantCode}/deposits`)
          notification.warning({
            message: `${assetPathParam} not supported`,
            description: 'You have been redirected to the Deposits page'
          })
        }
      } else {
        await fetchDeposits({})
      }
      setLoading(false)
    })(assetPathParam)
  }, [assetPathParam])

  const fetchDeposits = async ({
    asset,
    page = 1
  }: {
    asset?: string
    page?: number
  }) => {
    try {
      const fetchDepositsGetParams = new URLSearchParams({
        page: String(page - 1),
        pageSize: String(getPagination.pageSize)
      })

      if (asset) {
        fetchDepositsGetParams.append('asset', asset)
      }

      const {
        message: deposits,
        total_items: totalItems,
        page: currentPage
      } = await restService
        .route(`deposits?${fetchDepositsGetParams.toString()}`)
        .get<{
          message: IDeposit[]
          page: number
          total_items: number
        }>()

      setDeposits(deposits)

      setPagination({
        ...getPagination,
        page: currentPage + 1,
        total: totalItems
      })
    } catch (e) {
      setDeposits([])

      throw e
    }
  }

  const onTabClick = (key: string) => {
    if (key === 'add') {
      setShowNewAssetsRequestModal(true)
    } else {
      props.history.push(`/${restService.participantCode}/deposits/${key}`)
    }
  }

  const closeNewAssetsRequestModal = () => {
    setShowNewAssetsRequestModal(false)
  }

  const renderPage = () => {
    return (
      <>
        <DepositInformation />
        <AssetsAndCurrenciesTab
          assetsAndCurrenciesList={getAssetManagerAssets}
          onChange={onTabClick}
          selectedAsset={getSelectedAsset}
        />
        <DepositNewAssetsRequestModal
          onClose={closeNewAssetsRequestModal}
          showModal={shouldShowNewAssetsRequestModal}
        />
        <DepositAddress
          restService={props.restService}
          assetsAndCurrenciesList={getAssetManagerAssets}
          selectedAsset={getSelectedAsset}
        />
        <DepositHistory
          detailsBaseUrl={props.match.url}
          selectedAsset={getSelectedAsset}
          pagination={getPagination}
          deposits={getDeposits}
          onChangePage={(page: number) =>
            fetchDeposits({ asset: getSelectedAsset?.asset, page })
          }
        />
      </>
    )
  }

  return (
    <Spin spinning={isLoading}>
      <Route
        path={`${props.match.path}`}
        exact={true}
        render={() => renderPage()}
      />
      <Switch>
        <Route
          path={`${props.match.url}/:asset/:referenceId`}
          exact={true}
          render={(props: any) => {
            return (
              <DepositDetails
                restService={restService}
                asset={props.match.params.asset}
                referenceId={props.match.params.referenceId}
              />
            )
          }}
        />
        <Route
          path={`${props.match.url}/:referenceId`}
          exact={true}
          render={(props: any) => {
            return (
              <DepositDetails
                restService={restService}
                asset={props.match.params.asset}
                referenceId={props.match.params.referenceId}
              />
            )
          }}
        />
      </Switch>
    </Spin>
  )
}

export default DepositPage
