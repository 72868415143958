import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  Tooltip,
  Typography,
  notification
} from 'antd'

import { IWithParticipantComponentProps } from '../components/participant/WithParticipant'
import ColorPicker from './ColorPicker'
import { ThemeColors, ThemeConfig } from './types'
import BasicInfo from './BasicInfo'
import TermsAndConditions from './TermsAndConditions'
import KycPhoneVerification from './KycPhoneVerification'
import { fontFamilies } from './utils'
import ZHSubmitButton from '../components/ZHSubmitButton'
import useFlag from '../unleash/useFlag'

type ThemeResponse = {
  themeConfig: ThemeConfig
  themedLogoUrl: string
}

const Theme = ({
  restService,
  participant,
  authUser
}: IWithParticipantComponentProps) => {
  const [theme, setTheme] = useState<ThemeResponse>()
  const [colors, setColors] = useState<ThemeColors>()
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)

  const [form] = Form.useForm()
  const fontSize = Form.useWatch(['themeConfig', 'fontSize'], form)
  const fontFamily = Form.useWatch(['themeConfig', 'fontFamily'], form)

  const isFontSizeEnabled = useFlag('enable-font-size-theme.portal-app.CGL-587')

  const mapThemeToColors = (config: ThemeConfig) => {
    setColors({
      colorLinkHover: config.colorLinkHover,
      colorText: config.colorText,
      colorPrimary: config.colorPrimary,
      colorLink: config.colorLink
    })
  }
  const handleResetToDefault = () => {
    form.setFieldsValue({
      themeConfig: {
        colorPrimary: '',
        colorText: '',
        colorLink: '',
        colorLinkHover: '',
        fontFamily: '',
        fontSize: 0
      },
      themedLogoUrl: ''
    })
    setColors({
      colorLinkHover: '',
      colorLink: '',
      colorPrimary: '',
      colorText: ''
    })
  }

  const fetchTheme = async () => {
    setIsLoading(true)
    const isAdmin = await authUser.isAdmin(participant.code)
    if (!isAdmin) {
      setIsLoading(false)
      setError('You do not have permission to access this page')
      return
    }
    try {
      const response = await restService
        .route('theme')
        .get<ThemeResponse>({ omitNotification: true })

      setTheme(response)
      mapThemeToColors(response.themeConfig)
    } catch (e) {
      notification.error({
        message: `${e.response.status} - Could not fetch theme config`,
        description: e.response?.data?.errors?.join(', ')
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchTheme()
  }, [])

  const onFinish = async (values) => {
    setIsSubmitting(true)
    try {
      await restService.route('theme').post(
        {
          ...values,
          themeConfig: {
            ...theme.themeConfig,
            ...values.themeConfig,
            colorLinkHover: colors.colorLinkHover,
            colorText: colors.colorText,
            colorPrimary: colors.colorPrimary,
            colorLink: colors.colorLink
          },
          themedLogoUrl: theme.themedLogoUrl
        },
        { omitNotification: true }
      )
      notification.success({
        message: 'Theme config updated successfully'
      })
    } catch (e) {
      notification.error({
        message: `${e.response.status} - Could not save theme config`,
        description: e.response?.data?.errors?.join(', ')
      })
    }
    setIsSubmitting(false)
  }

  if (error) return <span>{error}</span>
  if (isLoading)
    return <Card loading={isLoading} title="App customizations"></Card>

  return (
    <Card title="App customizations" loading={isLoading}>
      <Typography.Paragraph style={{ marginBottom: '1.5rem' }}>
        Here, you can customize various settings to tailor the appearance of
        your User Interfaces (Onboarding, Crypto Buy, Crypto Sell, Crypto
        Withdrawals, Fiat Withdrawals, Fiat Deposits) to your preference. Please
        note that these settings will only take effect after you click 'Submit'.
        If there are any settings you do not wish to customize, simply leave
        them blank; the apps will then use their default values. Be aware that
        these changes will apply to all Apps utilized by your platform.
      </Typography.Paragraph>
      <Form
        form={form}
        initialValues={theme}
        onFinish={onFinish}
        layout="vertical"
      >
        <div style={{ marginBottom: 8 }}>
          <Tooltip title="This will clear all unsaved local changes and set the values back to their original state. You'll still need to click 'Submit' to confirm this action.">
            <Button
              style={{ marginRight: 8 }}
              onClick={() => {
                mapThemeToColors(theme.themeConfig)
                form.resetFields()
              }}
            >
              Discard local changes
            </Button>
          </Tooltip>
          <Tooltip title="This will reset all fields to their Zero Hash default. Your Apps will work with default values as if there is no custom theme applied. You'll still need to click 'Submit' to confirm this action.">
            <Button onClick={handleResetToDefault}>Reset do default</Button>
          </Tooltip>
        </div>
        <Row gutter={16}>
          <Col xs={6}>
            <Form.Item
              label="Primary color"
              name={['themeConfig', 'colorPrimary']}
              tooltip="This setting affects most of the UI elements. Which includes Buttons, Input fields, select fields, checkboxes, etc. (Default is #2253FF)"
            >
              <ColorPicker
                color={colors.colorPrimary}
                setColor={(v: string) => {
                  // When the user clicks the on the slider to select a color,
                  // without having a pre-selected color 'react-colorful' returns
                  // this "NaN" color, so we replace it with "" to improve UX.
                  setColors((prev) => ({
                    ...prev,
                    colorPrimary: v === '#NaNNaNNaN' ? '' : v
                  }))
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item
              label="Text color"
              name={['themeConfig', 'colorText']}
              tooltip="This setting changes the base color of all text present in the App. This includes input field text color, titles, button labels, etc. (Default is #303133)"
            >
              <ColorPicker
                title="Text color"
                color={colors.colorText}
                setColor={(v: string) =>
                  setColors((prev) => ({
                    ...prev,
                    colorText: v === '#NaNNaNNaN' ? '' : v
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item
              label="Link color"
              name={['themeConfig', 'colorLink']}
              tooltip="This is the color of the links present on the App. Notice that this setting will not affect the links on the Terms and Conditions page. It will affect links on the Phone verification screen and on the Review page. (Default is #3A15A0)"
            >
              <ColorPicker
                color={colors.colorLink}
                setColor={(v: string) =>
                  setColors((prev) => ({
                    ...prev,
                    colorLink: v === '#NaNNaNNaN' ? '' : v
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item
              label="Link hover color"
              name={['themeConfig', 'colorLinkHover']}
              tooltip="This color will affect the same elements as the Link color setting, the difference is that this color will be applied to the element when the user hovers the mouse over it. (Default is #3A15A0)"
            >
              <ColorPicker
                color={colors.colorLinkHover}
                setColor={(v: string) =>
                  setColors((prev) => ({
                    ...prev,
                    colorLinkHover: v === '#NaNNaNNaN' ? '' : v
                  }))
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={6}>
            <Form.Item
              tooltip="We currently support a limited set of font families. The font family will be applied to all text present on the App, such as titles, descriptions, input values, etc. If you need a different font, please reach out to us via support@zerohash.com. (Default is Inter)"
              label="Font family"
              name={['themeConfig', 'fontFamily']}
            >
              <Select
                options={fontFamilies}
                placeholder="Font family"
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>
          {isFontSizeEnabled && (
            <Col xs={6}>
              <Form.Item
                label="Font size"
                name={['themeConfig', 'fontSize']}
                tooltip="Changing this setting affects all texts, including links, titles, descriptions, labels, etc. The size of the elements will resize in a proportional way. (Default is 12px)"
              >
                <InputNumber
                  placeholder="Font size"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <ZHSubmitButton disabled={isSubmitting} loading={isSubmitting}>
          Submit
        </ZHSubmitButton>
      </Form>
      <Typography.Title level={5} style={{ marginBottom: '1rem' }}>
        App preview
      </Typography.Title>
      <Row gutter={16}>
        <Col xs={8}>
          <TermsAndConditions
            colors={colors}
            fontSize={fontSize}
            fontFamily={fontFamily}
          />
        </Col>
        <Col xs={8}>
          <KycPhoneVerification
            colors={colors}
            fontSize={fontSize}
            fontFamily={fontFamily}
          />
        </Col>
        <Col xs={8}>
          <BasicInfo
            colors={colors}
            fontSize={fontSize}
            fontFamily={fontFamily}
          />
        </Col>
      </Row>
    </Card>
  )
}

export default Theme
