import { IDocumentItemConfig } from '../../configuration'
import { FormInputItem } from '.'
import * as React from 'react'
import { Upload, message, notification, Tooltip } from 'antd'
import FormItem from '@ant-design/compatible/es/form/FormItem'
import { UploadFile } from 'antd/lib/upload/interface'
const Dragger = Upload.Dragger
import AuthContext from '../../../auth/AuthUserContext'
import { IParticipant } from 'seed-shared-components/lib/types'
import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons'

export interface IDocumentFormItemProps
  extends FormInputItem<IDocumentItemConfig> {
  participant: IParticipant
  application_document: boolean
}
interface IDocumentFormItemState {
  filesForDisplay: any[]
  fileObjects: PayloadDocument
  uploadInProgress: boolean
  uploadRoute: string
}

interface PayloadDocument {
  [fileName: string]: {
    progress: number
    state: string // "Upload error: 403",
    size: number
    type: string // "text/plain"
    uploadedName: string
  }
}

const turnPayloadFormatIntoAntdFiles = (files: PayloadDocument): UploadFile[] =>
  files
    ? Object.keys(files).reduce((accum: UploadFile[], fileName) => {
        // fix to support half-finished applications started on Portal1
        if (fileName === 'error' || Number(fileName) >= 0) {
          return accum
        }
        const file: UploadFile = {
          uid: fileName,
          name: fileName,
          percent: 100,
          size: 9001,
          type: files[fileName].type
        }
        return [...accum, file]
      }, [])
    : []

class DocumentFormItem extends React.Component<
  IDocumentFormItemProps,
  IDocumentFormItemState
> {
  static context = AuthContext
  context!: React.ContextType<typeof AuthContext>
  uploadUrl = `/documents`

  constructor(props: IDocumentFormItemProps) {
    super(props)

    this.state = {
      filesForDisplay: this.props.value
        ? turnPayloadFormatIntoAntdFiles(this.props.value)
        : [],
      fileObjects: typeof this.props.value === 'object' ? this.props.value : {},
      uploadInProgress: false,
      uploadRoute: this.props.application_document
        ? 'application_document'
        : 'supplemental_document'
    }

    this.uploaderOnChange = this.uploaderOnChange.bind(this)
    this.getUploadData = this.getUploadData.bind(this)
    this.validateFileSizeBeforeUpload = this.validateFileSizeBeforeUpload.bind(
      this
    )
  }

  setSerializedFilesToState(serializedFile: any) {
    if (typeof serializedFile !== 'string') {
      return this.setState({
        filesForDisplay: turnPayloadFormatIntoAntdFiles(serializedFile)
      })
    }
  }

  componentWillReceiveProps(nextProps: IDocumentFormItemProps) {
    if (nextProps.value && this.props.value !== nextProps.value) {
      this.setSerializedFilesToState(nextProps.value)
    }
  }

  uploaderOnChange() {
    return (info: any) => {
      const status = info.file.status

      this.setState(() => ({
        filesForDisplay: info.fileList
      }))

      if (status === 'removed') {
        this.props.form.setFieldsValue({
          [this.props.itemKey]: []
        })
      } else if (status === 'uploading') {
        this.setState(() => ({
          uploadInProgress: true
        }))
        this.props.form.setFieldsValue({
          [this.props.itemKey]: []
        })
      } else if (status === 'done' && info.file.response) {
        // need to update form value and state
        const allFiles = {
          ...this.state.fileObjects,
          ...info.file.response
        }
        this.props.form.setFieldsValue({
          [this.props.itemKey]: allFiles
        })
        this.setState(() => ({
          fileObjects: allFiles,
          uploadInProgress: false
        }))

        message.success(
          `${this.props.label} ${info.file.name} uploaded successfully.`,
          2.5
        )
      } else if (status === 'error') {
        if (this.props.restService) {
          this.props.restService.logger.error({
            message: 'Error uploading file',
            fileInfo: info
          })
        }
        notification.error({
          message: `${this.props.label} upload failed.`,
          description: `${info.file.name} failed to upload. Please try again, or contact support`,
          duration: null
        })
        this.setState(() => ({
          uploadInProgress: false
        }))
      } else if (status === 'removed') {
        const files = this.state.fileObjects
        delete files[info.file.uid]
        this.setState(() => ({
          fileObjects: files
        }))
      } else {
        const files = info.fileList
        const fileIndex = files.findIndex(
          (f: UploadFile) => f.uid === info.file.uid
        )
        files[fileIndex].status = 'error'
        this.setState({ filesForDisplay: files })
      }
    }
  }
  validateFileSizeBeforeUpload(file: any, fileList: any[]) {
    if (file && file.size) {
      const isValidSize = file.size / 1024 / 1024 < 15
      if (!isValidSize) {
        message.error('File must be smaller than 15mb')
      }
      return isValidSize
    }
    return true
  }
  getUploadData(type: string) {
    return (file: any) => {
      if (this.props.restService) {
        this.props.restService.logger.info({
          message: 'Going to upload file.',
          file: {
            uid: file.uid,
            name: file.name,
            file: file.size,
            type: file.type
          }
        })
      }
      const data = {
        participant_code: this.props.participant.code,
        document_type: type
      }
      return data
    }
  }

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        // removes meta info of file, leaving only content
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4))
        }
        resolve(encoded)
      }
      reader.onerror = (error) => reject(error)
    })
  }

  uploadFile = async (options) => {
    const { file } = options

    const document = await this.getBase64(file)

    try {
      await this.props.restService.route(this.state.uploadRoute).post({
        name: file.name,
        mime: file.type,
        document
      })

      this.setState({
        filesForDisplay: [
          {
            uid: file.name,
            name: file.name,
            percent: 100,
            size: file.size,
            type: file.type
          }
        ]
      })
    } catch (err) {
      console.log('Error uploading file: ', err)
    }
  }

  render() {
    if (!this.props.restService) {
      return null
    }
    const uploadProps = {
      name: 'file',
      multiple: false,
      action: `${this.props.restService.getUrlBase()}${this.state.uploadRoute}`,
      headers: { Authorization: `Bearer ${this.context.idToken}` }
    }
    // support documents nested in arrays
    let documentType = this.props.itemKey
    if (this.props.parentKey) {
      const lastProp = this.props.itemKey
        .split('[')
        [this.props.itemKey.split('[').length - 1].slice(0, -1)
      documentType = lastProp
    }
    return (
      <Tooltip title={this.props.tooltip} placement="leftTop">
        {/* Need an element wrapping the FormItem for the Tooltip to properly detect mouse hover */}
        <div>
          <FormItem
            {...this.props.formItemLayout}
            label={
              this.props.label ? (
                this.props.tooltip ? (
                  <span style={{ whiteSpace: 'normal' }}>
                    {this.props.label}
                    &nbsp;
                    <QuestionCircleOutlined />
                  </span>
                ) : (
                  <span style={{ whiteSpace: 'normal' }}>
                    {this.props.label}
                  </span>
                )
              ) : undefined
            }
          >
            {this.props.form.getFieldDecorator(this.props.itemKey, {
              initialValue: this.props.value,
              rules: [...(this.props.rules || [])]
            })(
              <div className="upload-item">
                <Dragger
                  {...uploadProps}
                  data={this.getUploadData(documentType)}
                  customRequest={this.uploadFile}
                  fileList={this.state.filesForDisplay}
                  beforeUpload={this.validateFileSizeBeforeUpload}
                  accept=".pdf, image/png, image/jpeg, image/jpg"
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drop a file here to upload
                  </p>
                  <p className="ant-upload-hint">
                    supported types are: .pdf, .png, .jpeg and .jpg, up to 15mb
                  </p>
                </Dragger>
              </div>
            )}
          </FormItem>
        </div>
      </Tooltip>
    )
  }
}
DocumentFormItem.contextType = AuthContext
export default DocumentFormItem
