import React from 'react'
import { Row } from 'antd'

import KycCheckbox from './kyc-customized-components/KycCheckbox'
import KycTypographyTitle from './kyc-customized-components/KycTypographyTitle'
import KycButton from './kyc-customized-components/KycButton'
import { ThemeColors } from './types'
import KycTypographyParagraph from './kyc-customized-components/KycTypographyParagraph'

interface TermsAndConditionsProps {
  colors: ThemeColors
  fontSize: number
  fontFamily: string
}

const TermsAndConditions = ({
  colors,
  fontSize,
  fontFamily
}: TermsAndConditionsProps) => {
  return (
    <div
      style={{
        maxWidth: '500px',
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        padding: '1rem',
        minHeight: '540px',
        fontFamily: fontFamily,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <KycTypographyTitle
        fontSize={fontSize}
        colorText={colors.colorText || '#303133'}
      >
        Verify your identity
      </KycTypographyTitle>
      <div style={{ lineHeight: '125%' }}>
        <KycTypographyParagraph
          fontSize={fontSize}
          colorText={colors.colorText}
        >
          To ensure the security of your account and comply with regulatory
          requirements, our crypto partner, Zero Hash, verifies your identity.
        </KycTypographyParagraph>
        <KycTypographyParagraph
          fontSize={fontSize}
          colorText={colors.colorText}
          style={{ fontWeight: 600 }}
        >
          You may be asked to provide a government issued photo ID.
        </KycTypographyParagraph>
        <KycTypographyParagraph
          fontSize={fontSize}
          colorText={colors.colorText}
          style={{ fontWeight: 600 }}
        >
          You must be at least 18 years old and not on a VPN to continue.
        </KycTypographyParagraph>
      </div>
      <KycCheckbox
        colorText={colors.colorText}
        colorPrimary={colors.colorPrimary}
        fontSize={fontSize}
      />
      <Row
        style={{
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'flex-end'
        }}
      >
        <KycButton
          primaryColor={colors.colorPrimary}
          textColor={colors.colorText}
          style={{
            width: '100%',
            marginTop: '3rem',
            fontSize: fontSize ? fontSize * 1.33 : '16px',
            height: '3rem', // 48px,
            fontWeight: 800
          }}
        >
          Continue
        </KycButton>
      </Row>
    </div>
  )
}

export default TermsAndConditions
