export const SET_USER = 'USER/SET_USER'

export interface ISetUserAction {
  type: typeof SET_USER
  payload: IUser
}

export interface IUser {
  token: string
  userIdentifier: string
}
