import React, { useEffect } from 'react'
import { Menu } from 'antd'
import MenuItem from '../MenuItem'
import { Link } from 'react-router-dom'
import portalApi from '../../../api/portalApi'
import * as participantsApi from '../../../api/participantsApi'
import { IParticipantMenuProps } from './props'
import connect from './connector'
import { IParticipant } from 'seed-shared-components/lib/types'

const { SubMenu } = Menu

const ParticipantMenu = (props: IParticipantMenuProps) => {
  useEffect(() => {
    if (!props.participant) {
      loadParticipant()
    }
  }, [])

  const loadParticipantDetails = async (
    maybeParticipant: IParticipant | undefined
  ) => {
    if (!maybeParticipant) {
      return
    }
    const options = {
      headers: {
        Authorization: `Bearer ${props.user.idToken}`
      },
      method: 'GET',
      url: `${maybeParticipant.code}/basic`
    }

    const res = await portalApi.request(options)
    props.onLoadDetails(res.data)
  }

  const loadIsPlatformOperator = async () => {
    const maybeParticipantCode = props.location.pathname.split('/')[1]

    if (!maybeParticipantCode) {
      return
    }

    const authToken = props.user.idToken
    const hasRelationshipResult = await participantsApi.hasRelationship(
      authToken,
      maybeParticipantCode,
      {
        primaryParticipantCode: maybeParticipantCode,
        relationType: 'operates_platform_for',
        secondaryParticipantCode: ''
      }
    )

    props.onLoadIsPlatformOperator(hasRelationshipResult.hasRelationship)
  }

  const loadParticipant = async () => {
    const maybeParticipantCode = props.location.pathname.split('/')[1]
    const maybeParticipant = props.user.participants.find(
      (p) => p.code === maybeParticipantCode
    )

    props.onLoadParticipant(maybeParticipant)
    if (props.user.idToken && maybeParticipant) {
      try {
        await Promise.all([
          loadParticipantDetails(maybeParticipant),
          loadIsPlatformOperator()
        ])
      } catch (e) {
        console.log(e)
      }
    }
  }

  const { participant, selectedMenuKeys } = props
  if (!participant) {
    return null
  }

  const display = {
    dashboard: true,
    transactions: props.approved,
    trades: props.approved,
    newTrade: props.approved && props.participant.isTradeSubmitter,
    loans: props.approved,
    newLoan: props.approved && props.participant.isTradeSubmitter,
    loanExposure: props.approved,
    loanInterestAccruals: props.approved,
    loanCredit: props.approved,
    loanPortfolios: props.approved,
    loanPortfoliosCreate: props.approved,
    balances: props.approved,
    allocations: props.approved,
    deposits: props.approved,
    withdrawals: props.approved,
    platform: props.approved && props.isPlatformOperator,
    platformBalances: props.approved && props.isPlatformOperator,
    platformTransfers: props.approved && props.isPlatformOperator,
    platformPositions: props.approved && props.isPlatformOperator,
    participantPositions: props.approved,
    relationships: props.approved,
    users: props.approved,
    settings: props.approved,
    submitDocuments: props.approved,
    apiKeys: props.approved,
    reports: props.approved,
    theme:
      props.approved && props.isPlatformOperator && props.participant.isAdmin
  }

  return (
    <Menu
      selectedKeys={selectedMenuKeys}
      mode="horizontal"
      style={{
        top: 12,
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      theme="dark"
    >
      <Menu.Item disabled={!display.dashboard} key="dashboard">
        <MenuItem
          title="Dashboard"
          disabled={!display.dashboard}
          link={`/${
            props.participant ? props.participant.code : undefined
          }/dashboard`}
        />
      </Menu.Item>

      <SubMenu key="trades" title="Trades">
        <Menu.Item disabled={!display.trades} key="trades">
          <MenuItem
            title="Trade Blotter"
            disabled={!display.trades}
            link={`/${
              props.participant ? props.participant.code : undefined
            }/trades`}
          />
        </Menu.Item>
        <Menu.Item disabled={!display.newTrade} key="new_trade">
          <MenuItem
            title="New Trade"
            disabled={!display.newTrade}
            link={`/${
              props.participant ? props.participant.code : undefined
            }/trades/trade_submission`}
          />
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="participant"
        title="Participant"
        data-testid="participant-menuitem"
      >
        <Menu.Item disabled={!display.balances} key="balances">
          <MenuItem
            title="Balances"
            disabled={!display.balances}
            link={`/${
              props.participant ? props.participant.code : undefined
            }/balances`}
          />
        </Menu.Item>
        <Menu.Item disabled={!display.allocations} key="allocations">
          <MenuItem
            title="Allocations"
            disabled={!display.allocations}
            link={`/${
              props.participant ? props.participant.code : undefined
            }/allocations`}
          />
        </Menu.Item>
        <Menu.Item disabled={!display.deposits} key="deposits">
          <MenuItem
            title="Deposits"
            disabled={!display.deposits}
            link={`/${
              props.participant ? props.participant.code : undefined
            }/deposits`}
          />
        </Menu.Item>
        {display.withdrawals ? (
          <Menu.Item disabled={!props.approved} key="withdrawals">
            <Link
              data-testid="withdrawals-menuitem"
              to={`/${
                props.participant ? props.participant.code : undefined
              }/withdrawals`}
            >
              Withdrawals
            </Link>
          </Menu.Item>
        ) : null}
        <Menu.Item
          disabled={!display.participantPositions}
          key="participant_positions"
        >
          <MenuItem
            title="Positions"
            disabled={!display.participantPositions}
            link={`/${props.participant?.code}/participant_positions`}
          />
        </Menu.Item>
      </SubMenu>

      <SubMenu key="platform" title="Platform">
        <Menu.Item disabled={!display.platformBalances} key="platform_balances">
          <MenuItem
            title="Balances"
            disabled={!display.platformBalances}
            tooltipText="This page is reserved for Platform Operators.
              If you'd like to learn more, please reach out to support@zerohash.com"
            link={`/${
              props.participant ? props.participant.code : undefined
            }/platform_balances`}
          />
        </Menu.Item>
        <Menu.Item
          disabled={!display.platformTransfers}
          key="platform_transfers"
        >
          <MenuItem
            title="Transfers"
            disabled={!display.platformTransfers}
            tooltipText="This page is reserved for Platform Operators.
              If you'd like to learn more, please reach out to support@zerohash.com"
            link={`/${
              props.participant ? props.participant.code : undefined
            }/platform_transfers`}
          />
        </Menu.Item>
        <Menu.Item
          disabled={!display.platformPositions}
          key="platform_positions"
        >
          <MenuItem
            title="Positions"
            disabled={!display.platformPositions}
            link={`/${props.participant?.code}/platform_positions`}
          />
        </Menu.Item>
      </SubMenu>

      <SubMenu key="administration" title="Administration">
        <Menu.Item disabled={!display.users} key="users">
          <MenuItem
            title="Users"
            disabled={!display.users}
            link={`/${
              props.participant ? props.participant.code : undefined
            }/users`}
          />
        </Menu.Item>
        <Menu.Item disabled={!display.settings} key="settings">
          <MenuItem
            title="Settings"
            disabled={!display.settings}
            link={`/${
              props.participant ? props.participant.code : undefined
            }/settings`}
          />
        </Menu.Item>
        <Menu.Item disabled={!display.submitDocuments} key="documents">
          <MenuItem
            title="Documents"
            disabled={!display.submitDocuments}
            link={`/${
              props.participant ? props.participant.code : undefined
            }/documents`}
          />
        </Menu.Item>
        <Menu.Item disabled={!display.apiKeys} key="api_keys">
          <MenuItem
            title="API Keys"
            disabled={!display.apiKeys}
            link={`/${
              props.participant ? props.participant.code : undefined
            }/api_keys`}
          />
        </Menu.Item>
        <Menu.Item disabled={!display.theme} key="theme">
          <MenuItem
            title="Theme Configuration"
            disabled={!display.theme}
            link={`/${
              props.participant ? props.participant.code : undefined
            }/theme`}
          />
        </Menu.Item>
      </SubMenu>
      <Menu.Item disabled={!display.reports} key="reports">
        <MenuItem
          title="Reports"
          disabled={!display.reports}
          link={`/${
            props.participant ? props.participant.code : undefined
          }/reports`}
          tooltipText={
            <>
              Reach out to{' '}
              <a href="mailto:support@zerohash.com">support@zerohash.com</a> if
              you’d like to inquire about our reporting suite.
            </>
          }
        />
      </Menu.Item>
    </Menu>
  )
}

export default connect(ParticipantMenu)
