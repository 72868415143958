import React from 'react'
import moment from 'moment'
import AllowedIpsContainer from '../containers/AllowedIpsContainer'
import {
  API_KEY_STATE_STATUS,
  IApiKeyLedgerTableItem,
  IApiKeyLedgerTableItemPermission,
  ParsedApiKeys
} from '../types'
import { ColumnsType } from 'antd/lib/table'
import { Button, Popconfirm, Tag, Tooltip } from 'antd'
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  StopOutlined
} from '@ant-design/icons'

export const renderActionColumn = (
  email: string,
  api_key_required_approvers: number,
  apiKeyRecord: IApiKeyLedgerTableItem,
  approveApiKey: (apiKey: IApiKeyLedgerTableItem) => Promise<void>,
  rejectApiKey: (apiKey: IApiKeyLedgerTableItem) => Promise<void>,
  disableApiKey: (apiKey: IApiKeyLedgerTableItem) => Promise<void>,
  setApiKeyDetailsInfo: React.Dispatch<any>,
  apiKeys: ParsedApiKeys[]
) => {
  const iApiKeyAlreadyProcessedByCurrentUser = apiKeyRecord.history.find(
    ({ executed_by_user_email, status }) => {
      return (
        executed_by_user_email === email &&
        [
          API_KEY_STATE_STATUS.CREATED,
          API_KEY_STATE_STATUS.APPROVED,
          API_KEY_STATE_STATUS.REJECTED,
          API_KEY_STATE_STATUS.PENDING_APPROVAL
        ].includes(status)
      )
    }
  )

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap'
      }}
    >
      {(apiKeyRecord.status === API_KEY_STATE_STATUS.CREATED ||
        apiKeyRecord.status === API_KEY_STATE_STATUS.PENDING_APPROVAL) &&
        !iApiKeyAlreadyProcessedByCurrentUser && (
          <div>
            <Popconfirm
              title={`Are you sure you want to approve API this key?`}
              onConfirm={() => approveApiKey(apiKeyRecord)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip
                placement="top"
                title={
                  apiKeyRecord.approvals_count >= api_key_required_approvers &&
                  'This API key requires final approval. It was submitted when the platform required a higher number of approvals for API keys.'
                }
              >
                <Button
                  type="default"
                  icon={<CheckOutlined />}
                  size="small"
                  style={{
                    minWidth: 100,
                    marginRight: 5,
                    marginBottom: 5
                  }}
                >
                  Approve
                </Button>
              </Tooltip>
            </Popconfirm>

            <Popconfirm
              title={`Are you sure you want to reject API this key?`}
              onConfirm={() => rejectApiKey(apiKeyRecord)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="default"
                icon={<CloseOutlined />}
                size="small"
                style={{
                  minWidth: 100,
                  marginRight: 5,
                  marginBottom: 5
                }}
              >
                Reject
              </Button>
            </Popconfirm>
          </div>
        )}

      <div>
        <Popconfirm
          title={`Are you sure you want to permanently remove this key?`}
          onConfirm={() => disableApiKey(apiKeyRecord)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            danger
            icon={<StopOutlined />}
            size="small"
            style={{ minWidth: 100, marginRight: 5, marginBottom: 5 }}
          >
            Remove
          </Button>
        </Popconfirm>

        <Button
          type="primary"
          icon={<InfoCircleOutlined />}
          size="small"
          onClick={() =>
            setApiKeyDetailsInfo(
              apiKeys.find(({ key_id }) => key_id === apiKeyRecord.key_id)
            )
          }
          style={{ minWidth: 100, marginRight: 5, marginBottom: 5 }}
        >
          Details
        </Button>
      </div>
    </div>
  )
}

export const columns = (
  marketDataEnabled: boolean,
  participantSanctionScreeningInfoEnabled: boolean,
  participantFullInfoEnabled: boolean,
  updateAllowedIPs
): ColumnsType<any> => [
  {
    title: 'Nickname',
    dataIndex: 'name',
    width: 250
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    sorter: true,
    defaultSortOrder: 'descend',
    width: 120,
    render: (createdAt) => {
      if (!createdAt) {
        return null
      }

      return (
        <>
          <strong>{moment(createdAt).format('YYYY-MM-DD')}</strong>{' '}
          <span>{moment(createdAt).format('h:mm:ss')}</span>
        </>
      )
    }
  },
  {
    title: 'Expire At',
    dataIndex: 'expire_at',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    width: 120,
    render: (expireAt) => {
      if (!expireAt) {
        return <span style={{ display: 'block', textAlign: 'center' }}>-</span>
      }

      return (
        <>
          <strong>{moment(expireAt).format('YYYY-MM-DD')}</strong>{' '}
          <span>{moment(expireAt).format('h:mm:ss')}</span>
        </>
      )
    }
  },
  {
    title: 'Public Key',
    dataIndex: 'public_key',
    width: 250
  },
  {
    title: 'Permissions',
    width: 350,
    render: ({ permissions }: IApiKeyLedgerTableItem) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: -10
          }}
        >
          {permissions
            // ignore market data permissions if platform is not enabled for these endpoints anymore
            .filter(
              (p) =>
                !(p.resource === 'market_data' && !marketDataEnabled) &&
                !(
                  p.resource === 'sanction_screening_info' &&
                  !participantSanctionScreeningInfoEnabled
                ) &&
                !(p.resource === 'full_info' && !participantFullInfoEnabled)
            )
            .map(({ resource, actions }: IApiKeyLedgerTableItemPermission) => (
              <div key={resource} style={{ marginTop: 10 }}>
                <Tag style={{ cursor: 'inherit' }}>
                  <span className="bold-text" style={{ fontSize: 12 }}>
                    {`${resource}:`}
                  </span>
                  <span>
                    {` ${actions.map((action, i) =>
                      i === 0 ? action : ` ${action}`
                    )}`}
                  </span>
                </Tag>
              </div>
            ))}
        </div>
      )
    }
  },
  {
    title: 'Transfer USD only',
    dataIndex: 'transfers_usd_only',
    width: 150,
    render: (transfersUsdOnly) => {
      return (
        <>
          <strong>{String(transfersUsdOnly)}</strong>
        </>
      )
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 120
  },
  {
    title: 'Allowed IPs',
    dataIndex: '',
    width: 300,
    render: (apiKeyRecord: IApiKeyLedgerTableItem) => {
      const { key_id, allowed_ips } = apiKeyRecord

      return (
        <AllowedIpsContainer
          updateAllowedIPs={updateAllowedIPs}
          key_id={key_id}
          allowed_ips={allowed_ips}
        />
      )
    }
  }
]
