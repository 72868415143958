import { connect } from 'react-redux'
import {
  IAssetBalancesInputProps,
  IAssetBalancesOutputProps,
  IAssetBalancesExplicitProps
} from './props'
import selectors from '../../store/selectors'
import { IApplicationState } from '../../store/state'
import * as FundsActions from '../../store/funds/actions'

export default connect<
  IAssetBalancesInputProps,
  IAssetBalancesOutputProps,
  IAssetBalancesExplicitProps,
  IApplicationState
>(
  (state, props) => {
    const enabledAssetsBalances = selectors.funds
      .getEnabledAssetsBalances(state)
      .sort((a, b) => {
        if (a.asset < b.asset) {
          return -1
        }
        if (a.asset > b.asset) {
          return 1
        }
        return 0
      })

    const BTCBalance = enabledAssetsBalances.find(
      (assetBalance) => assetBalance.asset === 'BTC'
    )
    if (BTCBalance) {
      enabledAssetsBalances.unshift(BTCBalance)
    }

    const USDBalance = enabledAssetsBalances.find(
      (assetBalance) => assetBalance.asset === 'USD'
    )
    if (USDBalance) {
      enabledAssetsBalances.unshift(USDBalance)
    }

    return {
      // USD and BTC first, then others...
      assetBalances: Array.from(new Set(enabledAssetsBalances)) as [],
      loading:
        selectors.funds.getLoading(state) || enabledAssetsBalances.length === 0,
      assets: selectors.funds.getAssets(state)
    }
  },
  (dispatch) => ({
    viewAssetBalances: () => dispatch(FundsActions.viewAssetBalances()),
    leaveAssetBalances: () => dispatch(FundsActions.leaveAssetBalances())
  })
)
