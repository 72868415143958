import portalApi, { PortalApiRequestConfig } from '../../api/portalApi'
import {
  IPortalApiWithdrawalAccount,
  IPortalApiWithdrawalRequest
} from '../../withdrawal/withdrawalTypes'
import { IPortalApiWithdrawalRequestHistoryPayload } from './types'
import { ITransferRequest } from '../../transfers/components/TransfersPage'

interface IWithdrawalsAPI {
  getAccounts: (participantCode: string) => Promise<IGetAccountsResult>
  getWithdrawals: (participantCode: string) => Promise<IGetWithdrawalsResult>
  getWithdrawalRequestHistory: (
    participantCode: string,
    withdrawalRequestId: number | string
  ) => Promise<IPortalApiWithdrawalRequestHistoryPayload>
  getplatformTransfers: (
    participantCode: string
  ) => Promise<IGetPlatformTransfersResult>
}

export interface IGetAccountsResult {
  message: IPortalApiWithdrawalAccount[]
}

export interface IGetWithdrawalsResult {
  result: IPortalApiWithdrawalRequest[]
}

export interface IGetPlatformTransfersResult {
  message: ITransferRequest[]
}

interface IAPIOptions extends PortalApiRequestConfig {
  token: string
  userIdentifier: string
  query?: string
}

const mergeOptions = (options: IAPIOptions) => ({
  headers: {
    Authorization: `Bearer ${options.token || ''}`
  },
  json: true,
  query: ''
})

export const withdrawalAPI = (options: IAPIOptions): IWithdrawalsAPI => ({
  getAccounts(participantCode: string) {
    const mergedOptions = mergeOptions(options)
    return portalApi
      .request<IGetAccountsResult>({
        method: 'get',
        url: `${participantCode}/withdrawal_accounts`,
        ...mergedOptions
      })
      .then(({ data }) => data)
  },
  getWithdrawals(participantCode: string) {
    const mergedOptions = mergeOptions(options)
    return portalApi
      .request<IGetWithdrawalsResult>({
        method: 'get',
        url: `${participantCode}/withdrawal_requests`,
        ...mergedOptions
      })
      .then(({ data }) => data)
  },
  getWithdrawalRequestHistory(
    participantCode: string,
    withdrawalRequestId: number | string
  ) {
    const mergedOptions = mergeOptions(options)
    return portalApi
      .request<IPortalApiWithdrawalRequestHistoryPayload>({
        method: 'get',
        url: `${participantCode}/withdrawal_requests/${withdrawalRequestId}/history`,
        ...mergedOptions
      })
      .then(({ data }) => data)
  },
  getplatformTransfers(participantCode: string) {
    const mergedOptions = mergeOptions(options)
    return portalApi
      .request<IGetPlatformTransfersResult>({
        method: 'get',
        url: `${participantCode}/transfers/platform`,
        ...mergedOptions
      })
      .then(({ data }) => data)
  }
})
