import * as React from 'react'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Table } from 'antd'
import { IWithParticipantComponentProps } from '../../components/participant/WithParticipant'
import {
  assets as assetConfigs,
  IAsset,
  IFund
} from 'seed-shared-components/lib/static-data/assetConfig'

import * as actions from '../../store/platform-balances/actions'
import { getFunds } from '../../store/platform-balances/selectors'
import { newNumberFormatter } from '../utils'
import * as uuid from 'uuid'
import { Widget } from '../../dashboard/components/Widget'
import {
  renderBalanceWithAccountLink,
  sortWithPriorities
} from '../pages/balances'

const mapStateToProps = (state) => ({
  funds: getFunds(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchFunds: (platformCode: string) =>
    dispatch(actions.fetchFunds({ platformCode }))
})

type PlatformBalancesWidgetProps = IWithParticipantComponentProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

function PlatformBalancesWidget(props: PlatformBalancesWidgetProps) {
  const { funds, restService } = props
  const participantCode = props.participant.code
  const [assets, setAssets] = useState<IAsset[]>([])

  React.useEffect(() => {
    fetchAssets().then(() => {
      props.match.params = { code: participantCode }
    })
  }, [participantCode])

  const fetchAssets = useCallback(async () => {
    try {
      const responseCryptoAssets: IAsset[] = await restService
        .route(`assets?requestId=${uuid.v4()}&asset_type=CRYPTO`)
        .get()
      const fiatCurrencies = assetConfigs.filter(
        (asset) => asset.type === 'Fiat'
      )
      setAssets([...responseCryptoAssets, ...fiatCurrencies])
    } catch (e) {
      console.error('Failed to fetch assets for Platform Balances', e)
    }
  }, [])

  React.useEffect(() => {
    props.fetchFunds(participantCode)
  }, [participantCode])

  const navigateToAccount = (asset, id) =>
    props.history.push(
      `/${participantCode}/platform_balances/${participantCode}/accounts/${asset}/${id}`
    )

  const TABLE_COLUMNS = [
    {
      render: (item) => item.asset.toUpperCase(),
      title: 'Asset',
      key: 'asset',
      width: '20%'
    },
    {
      dataIndex: 'account_label',
      title: 'Account Label',
      key: 'account_label',
      width: '30%'
    },
    {
      align: 'right',
      title: 'Available',
      width: '25%',
      key: 'available',
      render: (item: IFund) => {
        return renderBalanceWithAccountLink(
          item['available_id'],
          item['available'],
          (id) => navigateToAccount(item.asset, id),
          getPrecision(item.asset),
          true
        )
      }
    },
    {
      align: 'right',
      title: 'Net Delivery Obligation',
      width: '25%',
      key: 'ndo',
      render: (item: IFund) => {
        const ndo = item.ndo || 0
        const precision = getPrecision(item.asset)

        return (
          <span className={ndo > 0 ? 'ndo-exception' : ''}>
            {newNumberFormatter(ndo, precision || 2, precision || 5, true)}
          </span>
        )
      }
    }
  ]

  const getPrecision = (asset: string) => {
    return assets.filter((e) => e.asset === asset).pop()?.precision
  }

  const filteredAndSortedFunds = sortWithPriorities(
    funds.value
      .filter((a) => a.account_group === participantCode)
      .filter((a) => participantCode !== '00SCXM' || a.asset === 'USD')
      // for the widget version of this component, also filter empty funds
      .filter((a) => a.available > 0),
    (v) => ({ USD: 2, BTC: 1 }[v.asset] || 0),
    (a, b) => (a.asset < b.asset ? -1 : 1)
  )

  return (
    <Widget
      style={{ margin: 5 }}
      data-test-id="platform-balances"
      title="Platform Balances"
    >
      <Table
        style={{ marginTop: 24 }}
        columns={TABLE_COLUMNS as any}
        dataSource={filteredAndSortedFunds}
        pagination={false}
        loading={funds.loading}
      />
      <Link
        style={{
          display: 'block',
          marginTop: 12,
          width: 'fit-content'
        }}
        to="balances"
      >
        Explore my balances in detail
      </Link>
    </Widget>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlatformBalancesWidget)
