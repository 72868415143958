import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Decimal from 'decimal.js'

import * as withdrawalsActions from '../../store/withdrawal/actions'
import * as withdrawalsSelectors from '../../store/withdrawal/selectors'
import {
  IPortalApiWithdrawalRequest,
  IPortalApiWithdrawalAccount
} from '../withdrawalTypes'

import { Button, Modal, Typography as T, Row, Col, Table } from 'antd'

const mapStateToProps = (state, { request }) => ({
  history: withdrawalsSelectors.getWithdrawalRequestHistory(state, {
    withdrawalRequestId:
      request?.withdrawalRequestId || request?.withdrawalRequestUuid
  })
})

const mapDispatchToProps = (dispatch) => ({
  fetchHistory: (
    participantCode: string,
    withdrawalRequestId: number | string
  ) =>
    dispatch(
      withdrawalsActions.fetchWithdrawalRequestHistory({
        participantCode,
        withdrawalRequestId
      })
    )
})

interface Props {
  participantCode: string
  request: IPortalApiWithdrawalRequest | null
  account: IPortalApiWithdrawalAccount
  history: {
    loading: boolean
    result: IPortalApiWithdrawalRequest[]
  }
  onClose: () => void
}

const AUDIT_TRAIL_COLUMNS = [
  {
    key: 'timestamp',
    title: 'Timestamp',
    dataIndex: 'createdAt',
    render: (v: string) => (
      <T.Text style={{ fontSize: 12 }}>{moment(v).format('YYYY-DD-MM')}</T.Text>
    )
  },
  {
    key: 'user',
    title: 'User',
    dataIndex: 'userEmail',
    render: (v: string) => <T.Text style={{ fontSize: 12 }}>{v}</T.Text>
  },
  {
    key: 'action',
    title: 'Action',
    dataIndex: 'action',
    render: (v: string) => (
      <T.Text strong style={{ fontSize: 12 }}>
        {v}
      </T.Text>
    )
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (v: string) => <T.Text style={{ fontSize: 12 }}>{v}</T.Text>
  }
]

type RequestDetailsProps = Props &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const RequestDetails: React.FC<RequestDetailsProps> = ({
  account,
  request,
  history,
  participantCode,
  fetchHistory,
  onClose
}) => {
  const accountName = account ? account.name : 'unknown'
  const labelledValues = [
    {
      label: 'Withdrawal Request ID',
      value: request.withdrawalRequestId || request.withdrawalRequestUuid
    },
    { label: 'Withdrawal Account ID', value: request.withdrawalAccountId },
    { label: 'Status', value: request.status },
    { label: 'Asset', value: request.currency },
    {
      label: 'Withdrawal Account Nickname',
      value: accountName,
      copyable: !!account
    },
    {
      label: 'Requested Amount',
      value: new Decimal(request.amount).toString()
    },
    { label: 'Settled Amount', value: request.settled || 'null' },
    { label: 'Transaction ID', value: request.transactionId } // link to blockchain or N/A for fiat
    // { label: 'Withdrawal Movement ID', value: 'TODO' }, // hyperlink to funds history page
    // { label: 'Run ID', value: 'TODO' } // null if not settled
    // Settlement Agent Name
    // Settlement Agent Contact
    // Settlement Agent Address
  ]

  React.useEffect(() => {
    fetchHistory(
      participantCode,
      request.withdrawalRequestId || request.withdrawalRequestUuid
    )
  }, [
    participantCode,
    request.withdrawalRequestId,
    request.withdrawalRequestUuid
  ])

  return (
    <Modal
      width={700}
      visible={true}
      closable={false}
      onCancel={onClose}
      footer={
        <Button key="back" onClick={onClose}>
          Close
        </Button>
      }
    >
      <T.Title level={4}>Details</T.Title>

      {labelledValues.map(({ label, value, copyable = true }) => (
        <Row key={label} style={{ marginBottom: 12 }}>
          <Col span={10}>
            <T.Text>{label}</T.Text>
          </Col>
          <Col span={14}>
            <T.Text copyable={copyable && value && value !== 'null'} code>
              {value}
            </T.Text>
          </Col>
        </Row>
      ))}

      <T.Title style={{ marginTop: 32 }} level={4}>
        Audit Trail
      </T.Title>

      <div style={{ margin: '0 -8px' }}>
        <Table
          size="small"
          loading={history.loading}
          dataSource={history.result}
          columns={AUDIT_TRAIL_COLUMNS}
          pagination={
            history.result.length > 10
              ? { total: history.result.length, pageSize: 10 }
              : false
          }
        />
      </div>
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestDetails)
