import * as React from 'react'

import { Route } from 'react-router-dom'

import FundingPage from './asset-balances'
import AccountHistoryPage from './history/account-history'
import MovementsPage from './history/movements'
import { IWithParticipantComponentProps } from '../components/participant/WithParticipant'
import FundsService from './funds-service'
import HistoryService from './history/history-service'
import MovementDetails from './history/movements/Details'

const FundsMasterPage = (props: IWithParticipantComponentProps) => {
  const { authUser, participant, restService, hasRelationship } = props

  if (!authUser.idToken) {
    return null
  }

  const genericPropsForChildComponents = {
    authUser,
    participant,
    participantCode: participant.code,
    restService,
    hasRelationship
  }

  return (
    <div>
      <Route
        path={`${props.match.path}`}
        render={(props: any) => (
          <FundsService {...genericPropsForChildComponents} {...props} />
        )}
      />
      <Route
        path={`${props.match.path}`}
        exact={true}
        render={(props: any) => (
          <FundingPage {...genericPropsForChildComponents} {...props} />
        )}
      />

      <Route
        path={`${props.match.path}/:asset/history/:accountId/movements/:version`}
        exact={true}
        render={(props: any) => (
          <MovementsPage
            {...genericPropsForChildComponents}
            match={props.match}
            location={props.location}
            {...props}
          />
        )}
      />
      <Route
        path={`${props.match.path}/:asset/history/:accountId`}
        render={(props: any) => (
          <>
            <HistoryService
              {...genericPropsForChildComponents}
              match={props.match}
              location={props.location}
              {...props}
            />
          </>
        )}
      />
      <Route
        path={`${props.match.path}/:asset/history/:accountId/movements/:version/:id`}
        exact={true}
        render={(props: any) => (
          <>
            <MovementDetails
              {...genericPropsForChildComponents}
              match={props.match}
              {...props}
            />
          </>
        )}
      />
      <Route
        path={`${props.match.path}/:asset/history/:accountId`}
        exact={true}
        render={(props: any) => (
          <AccountHistoryPage
            {...genericPropsForChildComponents}
            match={props.match}
            {...props}
          />
        )}
      />
    </div>
  )
}

export default FundsMasterPage
