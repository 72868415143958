import React, { useContext, useEffect, useState } from 'react'
import * as uuid from 'uuid'
import _ from 'lodash'
import * as participantsApi from '../../api/participantsApi'
import { DEFAULT_PAGE_SIZE } from '../../constants'
import {
  IPortalApiWithdrawalRequest,
  IPortalApiWithdrawalAccount
} from '../withdrawalTypes'
import { notification, message, Button } from 'antd'

import RestService from '../../RestService'

import { assets as assetConfigs } from 'seed-shared-components/lib/static-data/assetConfig'
import NewAccountModal from './AccountModal/NewAccountModal'
import AccountDetailsModal from '../components/AccountDetails'
import RequestDetailsModal from '../components/RequestDetails'
import NewWithdrawalModal from './NewWithdrawalModal'
import WithdrawalRequestsTable from '../components/WithdrawalRequestsTable'
import WithdrawalAccountsTable from '../components/WithdrawalAccountsTable'

import Title from 'seed-shared-components/lib/components/Title'
import { IAuthUser } from '../../auth/WithAuthentication'
import AssetsAndCurrenciesTab from '../../deposit/v2/AssetsAndCurrenciesTab'
import { IAsset } from 'seed-shared-components/lib/static-data/assetConfig'
import { useHistory, useParams } from 'react-router-dom'
import AuthUserContext from '../../auth/AuthUserContext'
import { IAssetWithNewProps } from './types'

interface IWithdrawalsPageProps {
  restService: RestService
  getParticipantsNames: (
    participantsCodes: string[]
  ) => Promise<participantsApi.IParticipantsNamesResult>
  authUser: IAuthUser
  participantDetails: any
}

type AssetWithdrawalsParams = {
  asset: string
  participantCode: string
}

const NewAssetWithdrawals = ({
  restService,
  getParticipantsNames,
  participantDetails
}: IWithdrawalsPageProps) => {
  const history = useHistory()
  const authUser = useContext(AuthUserContext)

  const {
    withdrawal_account_required_approvers,
    withdrawal_request_required_approvers
  } = participantDetails
  const approves = {
    account: withdrawal_account_required_approvers,
    request: withdrawal_request_required_approvers
  }

  const params = useParams<AssetWithdrawalsParams>()
  const { asset, participantCode } = params
  const [isLoadingRequests, setIsLoadingRequests] = useState(false)
  const [requests, setRequests] = useState([])
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0
  })
  const [newAccountModal, setNewAccountModal] = useState({
    visible: false,
    loading: false
  })
  const [newWithdrawalModal, setNewWithdrawalModal] = useState({
    visible: false,
    loading: false
  })
  const [accountDetailsModal, setAccountDetailsModal] = useState({
    visible: false,
    id: null
  })
  const [requestDetailsModal, setRequestDetailsModal] = useState({
    visible: false,
    request: null
  })
  const [isAdmin, setIsAdmin] = useState(false)
  const [isAuthSigner, setIsAuthSigner] = useState(false)
  const [assets, setAssets] = useState<IAsset[]>([])
  const [selectedAsset, setSelectedAsset] = useState<IAsset>(null)

  useEffect(() => {
    fetchRequests()
  }, [pagination.current, pagination.pageSize])

  const fetchRequests = async () => {
    setIsLoadingRequests(true)
    const { current, pageSize } = pagination
    try {
      const { result, total } = await restService
        .route(
          `withdrawal_requests/${asset}?page=${current}&pageSize=${pageSize}`
        )
        .get<{ result: IPortalApiWithdrawalRequest[]; total: number }>()
      const wrWithHistory = await Promise.all([
        ...result.map(async (wr) => {
          if (wr.status === 'PENDING') {
            const { result: wrHistory } = await restService
              .route(
                `withdrawal_requests/${
                  wr.withdrawalRequestUuid ?? wr.withdrawalRequestId
                }/history`
              )
              .get()

            return {
              ...wr,
              history: wrHistory
            }
          }

          return wr
        })
      ])
      setRequests(wrWithHistory)
      setPagination({
        ...pagination,
        total
      })
    } catch (e) {
      notification.error({
        message: 'There was an error while loading withdrawal requests'
      })
    }
    setIsLoadingRequests(false)
  }

  const fetchAccounts = async () => {
    setIsLoadingAccounts(true)
    try {
      const message = await restService
        .route(`withdrawal_accounts/${asset}`)
        .get<IPortalApiWithdrawalAccount[]>()

      setAccounts(
        message
          .filter(({ status }) => status !== 'DELETED')
          .sort((a, b) =>
            new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
              ? -1
              : 1
          )
      )
    } catch (e) {
      notification.error({
        message: 'There was an error while loading withdrawal accounts',
        description: e.response?.data?.message
      })
    }
    setIsLoadingAccounts(false)
  }

  const fetchAssets = async () => {
    const cryptoAssets: IAsset[] = await restService
      .route(`assets?requestId=${uuid.v4()}&asset_type=CRYPTO`)
      .get()
    const fiatCurrencies = assetConfigs.filter((asset) => asset.type === 'Fiat')

    const joinedAssets = [...cryptoAssets, ...fiatCurrencies].sort((a, b) =>
      a.asset.localeCompare(b.asset)
    )
    setAssets(joinedAssets)

    if (asset && joinedAssets.length > 0) {
      const filteredAssetsByParam = joinedAssets.find(
        (elem) => elem.asset.toUpperCase() === asset.toUpperCase()
      )

      if (filteredAssetsByParam) {
        setSelectedAsset(filteredAssetsByParam)
      } else {
        // User wrongly typed asset on URL, going to redirect back to Withdrawal's main page
        notification.warning({
          message: `${asset.toUpperCase()} is not supported`,
          description: 'You have been redirected to the Withdrawals page',
          duration: 5
        })
        history.push(`/${restService.participantCode}/withdrawals`)
      }
    } else {
      await fetchRequests()
    }
  }

  useEffect(() => {
    fetchAssets()
  }, [asset])

  useEffect(() => {
    if (asset !== asset.toUpperCase()) {
      history.push(
        `/${restService.participantCode}/withdrawals/${asset.toUpperCase()}`
      )
    }
    fetchRequests()
    fetchAccounts()

    authUser.isAdmin(participantCode).then((isAdmin) => {
      setIsAdmin(isAdmin)
    })
    authUser.isAuthSigner(participantCode).then((isAuthSigner) => {
      setIsAuthSigner(isAuthSigner)
    })
  }, [])

  const { request: requestDetailsModalRequest } = requestDetailsModal

  const canCreateWithdrawalRequestsAndAccounts = isAdmin || isAuthSigner
  const openNewAccountModal = () =>
    setNewAccountModal({ ...newAccountModal, visible: true })

  const approve = (id: number | string, entity: string) => {
    setIsSubmitting(true)
    message.loading({ content: 'Approving...', key: 'approve-request' })

    restService
      .route(`withdrawal_${entity}s/${id}/approve`)
      .post({}, { omitNotification: true })
      .then(() => {
        message.success({ content: 'Approved!', key: 'approve-request' })

        if (entity === 'request') {
          fetchRequests()
        } else if (entity === 'account') {
          fetchAccounts()
        }
      })
      .catch((e) => {
        message.error({ content: 'Failed', key: 'approve-request' })
        notification.error({
          message: `There was an error while approving the withdrawal ${entity}. Status code: ${e.response.status}`,
          description: e.response?.data?.message,
          duration: 0
        })
      })
      .finally(() => setIsSubmitting(false))
  }

  const reject = (
    id: number | string,
    entity: string,
    label = ['Rejecting...', 'Rejected!', 'rejecting']
  ) => {
    setIsSubmitting(true)
    message.loading({ content: label[0], key: 'reject-request' })

    restService
      .route(`withdrawal_${entity}s/${id}/reject`)
      .post({})
      .then(() => {
        message.success({ content: label[1], key: 'reject-request' })

        if (entity === 'request') {
          fetchRequests()
        } else if (entity === 'account') {
          fetchAccounts()
        }
      })
      .catch((error) => {
        message.error({ content: 'Failed', key: 'reject-request' })
        notification.error({
          message: `There was an error while ${label[2]} withdrawal ${entity}`,
          description: error.response?.data?.message
        })
      })
      .finally(() => setIsSubmitting(false))
  }

  const deleteAccount = (id: number) => {
    setIsSubmitting(true)
    message.loading({ content: 'Removing account...', key: 'delete-request' })

    restService
      .route(`withdrawal_accounts/${id}`)
      .delete()
      .then(() => {
        message.success({ content: 'Deleted!', key: 'delete-request' })
        fetchAccounts()
      })
      .catch((error) => {
        message.error({ content: 'Failed', key: 'delete-request' })
        notification.error({
          message: `There was an error while deletion withdrawal account`,
          description: error.response?.data?.message
        })
      })
      .finally(() => setIsSubmitting(false))
  }

  /* Modals */

  const closeNewAccountModal = () =>
    setNewAccountModal({ ...newAccountModal, visible: false })

  const openNewRequestModal = () =>
    setNewWithdrawalModal({ ...newWithdrawalModal, visible: true })

  const closeNewRequestModal = (shouldFetch = false) => {
    setNewWithdrawalModal({ ...newWithdrawalModal, visible: false })
    if (shouldFetch) {
      fetchRequests()
    }
  }

  const openAccountDetailsModal = (id: number) =>
    setAccountDetailsModal({ visible: true, id })

  const closeAccountDetailsModal = () =>
    setAccountDetailsModal({ visible: false, id: null })

  const openRequestDetailsModal = (id: number) =>
    setRequestDetailsModal({
      visible: true,
      request: _.find(requests as any[], (r) => r.id === id) || null
    })
  const closeRequestDetailsModal = () =>
    setRequestDetailsModal({ visible: false, request: null })

  const createNewAccount = async (account: any) => {
    setNewAccountModal({ ...newAccountModal, loading: true })
    message.loading({
      content: 'Creating new account...',
      key: 'create-account'
    })
    try {
      await restService.route(`withdrawal_accounts/${asset}`).post({ account })

      message.success({ content: 'Created!', key: 'create-account' })
      fetchAccounts()
      closeNewAccountModal()
    } catch (e) {
      message.error({ content: 'Failed!', key: 'create-account' })
      notification.error({
        message: 'There was an error while creating withdrawal account'
      })
      setNewAccountModal({ ...newAccountModal, loading: false })
    }
  }

  return (
    <>
      <Title level={2}>Withdrawals</Title>
      <div style={{ marginBottom: 24 }}>
        All withdrawals must be made to whitelisted accounts. Please refer to
        our FAQ for more information:{' '}
        <a
          href="https://zerohash.zendesk.com/hc/en-us/articles/360008817314-How-are-withdrawal-accounts-set-up-"
          target="_blank"
        >
          How are withdrawal accounts set up?
        </a>{' '}
      </div>
      <AssetsAndCurrenciesTab
        includeAddCurrency={false}
        selectedAsset={selectedAsset}
        onChange={(key: string) =>
          history.push(`/${restService.participantCode}/withdrawals/${key}`)
        }
        assetsAndCurrenciesList={assets}
      />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 24
        }}
      >
        <Title level={3}>{asset} Withdrawal Accounts</Title>
        <Button
          disabled={!canCreateWithdrawalRequestsAndAccounts}
          onClick={openNewAccountModal}
        >
          Create New Account
        </Button>
      </div>
      <WithdrawalAccountsTable
        selectedAsset={selectedAsset}
        userEmail={authUser.email}
        accounts={accounts}
        loading={isLoadingAccounts}
        onApprove={(id) => approve(id, 'account')}
        onReject={(id) => reject(id, 'account')}
        isSubmitting={isSubmitting}
        onCancel={(id) =>
          reject(id, 'account', ['Cancelling...', 'Cancelled!', 'cancelling'])
        }
        onDetails={(id) => openAccountDetailsModal(id)}
        onDelete={(id) => deleteAccount(id)}
        approves={approves.account}
        isAdmin={isAdmin}
      />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 32
        }}
      >
        <Title level={3}>{asset} Withdrawal Requests</Title>
        <Button
          disabled={!canCreateWithdrawalRequestsAndAccounts}
          onClick={openNewRequestModal}
        >
          Create New Withdrawal
        </Button>
      </div>

      <WithdrawalRequestsTable
        pagination={pagination}
        userEmail={authUser.email}
        requests={requests}
        accounts={accounts}
        loading={isLoadingRequests}
        onApprove={(id) => approve(id, 'request')}
        onReject={(id) => reject(id, 'request')}
        isSubmitting={isSubmitting}
        onDetails={(id) => openRequestDetailsModal(id)}
        setPagination={setPagination}
        approves={approves.request}
        isAdmin={isAdmin}
      />

      {/*
          This modal is not implemented in a manner that gets account from this
          "container" component - it fetches it by itself. (Have to redo). It's
          required to make it refetch data when new account is created - that's
          why we destroy the component completely instead of hiding it
        */}
      {newWithdrawalModal.visible && (
        <NewWithdrawalModal
          visible={newWithdrawalModal.visible}
          loading={true}
          onClose={() => closeNewRequestModal(false)}
          onCreate={() => closeNewRequestModal(true)}
          authUser={authUser}
          asset={asset}
          getParticipantsNames={getParticipantsNames}
          participantCode={participantCode}
          restService={restService}
          history={history}
          params={params}
          newSelectedAsset={selectedAsset as IAssetWithNewProps}
        />
      )}

      {newAccountModal.visible && (
        <NewAccountModal
          asset={selectedAsset}
          visible={newAccountModal.visible}
          loading={newAccountModal.loading}
          onClose={closeNewAccountModal}
          onCreate={createNewAccount}
        />
      )}

      <AccountDetailsModal
        visible={accountDetailsModal.visible}
        id={accountDetailsModal.id}
        accounts={accounts}
        onClose={closeAccountDetailsModal}
      />

      {requestDetailsModalRequest && (
        <RequestDetailsModal
          participantCode={participantCode}
          request={requestDetailsModalRequest}
          account={accounts.find((a) => {
            return (
              a.withdrawalAccountId ===
              requestDetailsModalRequest.withdrawalAccountId
            )
          })}
          onClose={closeRequestDetailsModal}
        />
      )}
    </>
  )
}

export default NewAssetWithdrawals
