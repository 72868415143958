import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import ApplicationStep from './OnboardingApplicationStep'
import applicationSteps, {
  OnboardingApplicationStepConfig
} from '../configuration'
import * as _ from 'lodash'
import { Application, ApplicationPayload } from '../participant'
import { IWithParticipantComponentProps } from '../../components/participant/WithParticipant'
import { RouteComponentProps } from 'react-router-dom'
import { getPeopleFromPayload } from '../../sign/utils/parseApplicationPayload'
import {
  formatBooleanStrings,
  formatNumberStrings
} from '../../utils/applicationPayloadUtils'
import { Spin, notification } from 'antd'
import Title from 'seed-shared-components/lib/components/Title'

export interface IApplicationStepInfo {
  id: string
  route: (participantCode: string) => string
  label: string
  component: any
}

interface IOnboardingApplicationPageState {
  answers?: ApplicationPayload
  loading: boolean
}

const payloadIsIncomplete = (answers: ApplicationPayload): boolean => {
  let sectionName = undefined

  // check in reverse order of the actual steps, to show the first step missing data
  if (!answers.eligibility || Object.keys(answers.eligibility).length === 0) {
    sectionName = 'Eligibility'
  }
  if (!answers.legal_disqual === undefined || answers.legal_disqual === null) {
    sectionName = 'Legal'
  }
  if (answers.bankruptcy === undefined || answers.bankruptcy === null) {
    sectionName = 'Financial Information'
  }
  if (answers.entity_type === 'business') {
    if (!answers.control_persons || !answers.control_persons[0]) {
      sectionName = 'Persons'
    }
  }
  if (answers.entity_type === 'individual') {
    if (!answers.authorized_signers || !answers.authorized_signers[0]) {
      sectionName = 'Authorized Signers'
    }
  }
  if (!answers.address_line1) {
    sectionName = 'Application Information'
  }
  if (!answers.disclosure_affirmation) {
    sectionName = 'Disclosures'
  }
  if (sectionName) {
    notification.warn({
      description: `All sections of the application must be completed. Please see the "${sectionName}" section.`,
      duration: 0,
      message: 'Application incomplete'
    })
    return true
  }
  return false
}

export class OnboardingApplicationPage extends React.Component<
  IWithParticipantComponentProps,
  IOnboardingApplicationPageState
> {
  steps: OnboardingApplicationStepConfig[] = []
  constructor(props: any) {
    super(props)
    this.saveAnswers = this.saveAnswers.bind(this)
    this.setAnswersState = _.debounce(this.setAnswersState.bind(this), 50, {
      trailing: true
    })
    this.state = {
      loading: true
    }
    this.submitApplication = this.submitApplication.bind(this)
  }
  async componentWillMount() {
    try {
      const res = (await this.props.restService
        .route('in_progress_application')
        .get()) as Application

      // set application steps
      let steps = applicationSteps
      const { payload } = res
      if (payload && payload.entity_type) {
        // remove the step asking for entity_type since we already have it for this participant
        steps = applicationSteps.filter(
          (step) => step.stepId !== 'application-type'
        )

        if (payload.entity_type === 'business') {
          steps = steps.filter((step) => step.stepId !== 'authorized-signers')
        } else {
          steps = steps.filter((step) => step.stepId !== 'persons')
        }
      }
      this.steps = steps

      if (payload) {
        const payloadWithFixedBooleanStrings = formatBooleanStrings(payload)
        const parsedPayload = formatNumberStrings(
          payloadWithFixedBooleanStrings
        )

        const { authorized_signers, beneficial_owners } = getPeopleFromPayload(
          parsedPayload
        )
        if (!parsedPayload.authorized_signers) {
          payload.authorized_signers = authorized_signers
        }
        if (!res.payload.beneficial_owners) {
          payload.beneficial_owners = beneficial_owners
        }
      }

      this.setAnswersState(payload)
    } catch (e) {
      console.error('failed to save application. error: ', e)
    }
  }
  makeLegacyPayload(answers: any) {
    const badPayload = { ...answers }

    // add the ill-formed strings
    const signers = answers.authorized_signers
    if (signers && signers.length >= 1) {
      signers.forEach((signer: any, index: number) => {
        Object.keys(signer).forEach((key) => {
          badPayload[`authorized_signer[${index}][${key}]`] = signer[key]
        })
      })
    }
    const owners = answers.beneficial_owners
    if (owners && owners.length >= 1) {
      owners.forEach((owner: any, index: number) => {
        Object.keys(owner).forEach((key) => {
          badPayload[`beneficial_owner[${index}][${key}]`] = owner[key]
        })
      })
    }
    return badPayload
  }

  mapControlPerson(answers: any, keys: string[]) {
    if (answers && answers.control_persons) {
      const controlPerson = answers.control_persons[0]
      if (controlPerson) {
        const newAnswers = { ...answers }
        keys.forEach((key) => {
          const persons = answers[key]

          if (persons && persons.length > 0) {
            newAnswers[key] = persons.map((person: any) => {
              if (person.use_control_person) {
                return { ...person, ...controlPerson }
              }
              return person
            })
          }
        })
        return newAnswers
      }
    }
    return answers
  }

  async saveAnswers(newAnswers: Object) {
    this.setState(() => ({
      loading: true
    }))
    const currentAnswers = this.state.answers
    const answers = Object.assign({}, currentAnswers, newAnswers)
    await this.props.restService.route('in_progress_application').post({
      payload: this.makeLegacyPayload(answers)
    })
    this.setAnswersState(answers)
    return
  }

  setAnswersState(newAnswers: Object, loading = false) {
    const currentAnswers = this.state.answers
    const answers = Object.assign(
      {},
      currentAnswers,
      newAnswers
    ) as ApplicationPayload

    this.setState(() => ({
      answers,
      loading
    }))
  }
  async submitApplication() {
    if (!this.state.answers || payloadIsIncomplete(this.state.answers)) {
      return
    }
    this.setState(() => ({
      loading: true
    }))
    const payload = this.makeLegacyPayload(this.state.answers)
    try {
      await this.props.restService.route('participant_application').post({
        payload
      })

      // get the latest data from participant_users before loading the dashboard.
      await this.props.authUser.refreshUser()

      this.props.history.push(`/${this.props.participant.code}/dashboard`)
      return
    } catch (e) {
      notification.error({
        description:
          'Something went wrong. Please try again or contact support.',
        duration: 0,
        message: 'Failed to Submit.'
      })
      console.error('failed to submit application. error: ', e)
      throw e
    }
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <Spin />
        ) : (
          <Title
            level={2}
            style={{ marginLeft: 16, textTransform: 'capitalize' }}
          >
            {this.state.answers && this.state.answers.entity_type
              ? `${this.state.answers.entity_type} `
              : null}
            Participant Application
          </Title>
        )}
        <Switch>
          {this.steps.map((step, i) => (
            <Route
              key={`${this.props.match.url}/${step.route}`}
              exact={true}
              path={`${this.props.match.url}/${step.route}`}
              render={(routerProps: RouteComponentProps) => {
                let Step = ApplicationStep
                if (step.type === 'custom') {
                  Step = step.customForm as any
                }
                return (
                  <Step
                    authUser={this.props.authUser}
                    applicationSteps={this.steps}
                    participant={this.props.participant}
                    history={routerProps.history}
                    saveAnswers={this.saveAnswers}
                    setAnswersState={this.setAnswersState}
                    nextStep={this.steps[i + 1]}
                    currentStep={step}
                    previousStep={this.steps[i - 1]}
                    answers={this.state.answers}
                    restService={this.props.restService}
                    submitApplication={this.submitApplication}
                    loading={this.state.loading}
                    routerProps={routerProps}
                  />
                )
              }}
            />
          ))}
        </Switch>
      </>
    )
  }
}

export default OnboardingApplicationPage
