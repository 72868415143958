import { createSelector } from '@reduxjs/toolkit'
import { IApplicationState } from '../state'
import { IMovements } from './types'
import { AccountType } from '../../types'

export const getRoot = (state: IApplicationState) => state.accountMovements

export const getMovements = createSelector(
  getRoot,
  (accountMovements): IMovements => {
    return accountMovements.movements.value
  }
)

export const getMovementsLoading = createSelector(
  getRoot,
  (accountMovements): boolean => {
    return accountMovements.movements.loading
  }
)

export const getAccountType = createSelector(
  getRoot,
  (accountMovements): AccountType => {
    return accountMovements.accountType
  }
)
