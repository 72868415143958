import * as React from 'react'
import * as moment from 'moment'

import { Badge, Button, Table, Tooltip } from 'antd'
import { ReportTypeToTitle } from './helpers'
import { useConnect } from './hooks/useConnect'
import { DownloadOutlined } from '@ant-design/icons'

function ReportsTable() {
  const {
    participant,
    reports,
    fetchReports,
    generateReportSignedLink
  } = useConnect()
  const [downloadLoadingMap, setDownloadLoadingMap] = React.useState({})

  const handleDownloadClick = React.useCallback(
    async (report) => {
      setDownloadLoadingMap((downloadLoadingMap) => ({
        ...downloadLoadingMap,
        [report.id]: true
      }))
      const { signedLink } = await generateReportSignedLink(report)
      const link = document.createElement('a')
      link.setAttribute('href', signedLink)
      link.setAttribute('target', '_blank')
      link.click()
      setDownloadLoadingMap((downloadLoadingMap) => ({
        ...downloadLoadingMap,
        [report.id]: false
      }))
    },
    [generateReportSignedLink]
  )

  React.useEffect(() => {
    fetchReports()
  }, [participant])

  const isWithinOneDay = (date) => moment().diff(moment(date), 'days') < 1

  const TABLE_COLUMNS = React.useMemo(
    () => [
      {
        title: 'Request Time (UTC)',
        dataIndex: 'created_at',
        render: (createdAt) => {
          return moment(createdAt).toDate().toUTCString()
        }
      },
      {
        title: 'Report',
        dataIndex: 'type',
        render: (type) => ReportTypeToTitle[type]
      },
      {
        title: 'Request ID',
        dataIndex: 'id'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status) => {
          switch (status) {
            case 'completed':
              return <Badge status="success" text="Completed" />
            case 'pending':
              return <Badge status="processing" text="Pending" />
            case 'failed':
              return <Badge status="error" text="Failed" />
            default:
              return <Badge status="warning" text="Unknown" />
          }
        }
      },
      {
        title: 'Actions',
        width: '150px',
        render: (report) => {
          const { id, status } = report

          if (status !== 'completed') {
            return null
          }

          return isWithinOneDay(report.created_at) ? (
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              loading={downloadLoadingMap[id]}
              onClick={() => handleDownloadClick(report)}
            >
              Download
            </Button>
          ) : (
            <Tooltip placement="top" title="Report expired">
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                disabled={true}
              >
                Download
              </Button>
            </Tooltip>
          )
        }
      }
    ],
    [downloadLoadingMap]
  )

  return (
    <Table
      columns={TABLE_COLUMNS}
      dataSource={reports.value}
      loading={reports.loading}
      pagination={false}
    />
  )
}

export default ReportsTable
