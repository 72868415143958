import { IApplicationState } from './state'
import * as fundsSelectors from './funds/selectors'
import * as accountHistorySelectors from './accountHistory/selectors'
import * as signSelectors from './sign/selectors'
import * as participantSelectors from './participant/selectors'
import * as withdrawalSelectors from './withdrawal/selectors'
import * as netDeliveryObligations from './net-delivery-obligations/selectors'
import * as portfolio from './portfolio/selectors'
import * as userSettings from './user-settings/selectors'
import * as accountMovements from './account-movements/seletcors'

export const getUser = (state: IApplicationState) => state.user

export default {
  funds: fundsSelectors,
  accountHistory: accountHistorySelectors,
  sign: signSelectors,
  participant: participantSelectors,
  withdrawal: withdrawalSelectors,
  netDeliveryObligations,
  portfolio,
  userSettings,
  accountMovements
}
