let tagBasedAssets: { asset: string; tag: string }[] = []

try {
  // prettier-ignore
  tagBasedAssets = JSON.parse(window._env_.REACT_APP_TAG_BASED_ASSETS as string) || tagBasedAssets
} catch (error) {
  // it's ok, just warn about it in console
  console.warn(error)
}

interface IDestinationTag {
  label: string
  tag: string
}

interface IDestinationTags {
  [key: string]: IDestinationTag
}

const ASSET_ADDRESS_TAG_TO_LABEL = {
  memoId: 'Memo ID',
  dt: 'Destination Tag'
}

const TAG_BASED_ASSETS = tagBasedAssets.reduce<IDestinationTags>(
  (acc, item) => {
    acc[item.asset] = {
      label: ASSET_ADDRESS_TAG_TO_LABEL[item.tag],
      tag: item.tag
    }

    return acc
  },
  {}
)

export default TAG_BASED_ASSETS
