import { IUserState } from './state'
import { createReducer } from '@reduxjs/toolkit'
import { ISetUserAction } from './actions/types'
import * as UserActions from './actions'

const userInitialState: IUserState = {
  token: '',
  userIdentifier: ''
}

const history = createReducer(userInitialState, {
  [UserActions.setUser.type]: (state, action: ISetUserAction) => ({
    ...state,
    token: action.payload.token,
    userIdentifier: action.payload.userIdentifier
  })
})

export default history
