import {
  ApplicationPayload,
  orgType,
  incomeMap,
  netAssets,
  eligibilitiesPretty
} from '../../onboarding-application/participant'

export const propToEnumMap = (prop: keyof ApplicationPayload) => {
  switch (prop) {
    case 'org_type':
      return orgType
    case 'fin_income':
      return incomeMap
    case 'net_assets':
      return netAssets
    case 'eligibility':
      return eligibilitiesPretty
    default:
      return { prop }
  }
}
