import { IApplicationState } from '../state'

export const getParticipant = (state: IApplicationState) =>
  state.participant.participant
export const isLoading = (state: IApplicationState) => state.participant.loading
export const getDetails = (state: IApplicationState) =>
  state.participant.details
export const isApproved = (state: IApplicationState) => {
  const details = getDetails(state)
  return details ? details.approved : null
}
export const isPlatformOperator = (state: IApplicationState) => {
  return state.participant.isPlatformOperator
}
export const getState = (state: IApplicationState) => state.participant
