import { IParticipantPositionsResult } from '../types'
import { FSA, IFSAData } from '../../FSA'

const domain = `participant-positions`

export const FETCH_PARTICIPANT_POSITIONS = `${domain}/FETCH_PARTICIPANT_POSITIONS`
export const FETCH_PARTICIPANT_POSITIONS_FULFILL = `${domain}/FETCH_PARTICIPANT_POSITIONS_FULFILL`

export interface IFetchParticipantPositionsActionPayload {
  participantCode: string
  page: number
  pageSize?: number
}

export interface IFetchParticipantPositionsAction
  extends FSA<string, IFetchParticipantPositionsActionPayload> {
  type: typeof FETCH_PARTICIPANT_POSITIONS
}

export interface IFetchParticipantPositionsFulfillAction<Payload>
  extends IFSAData<Payload> {
  type: typeof FETCH_PARTICIPANT_POSITIONS_FULFILL
}

export interface IFetchParticipantPositionsFulfillActionSuccess
  extends IFetchParticipantPositionsFulfillAction<IParticipantPositionsResult> {
  error: false
}

export interface IFetchParticipantPositionsFulfillActionFail
  extends IFetchParticipantPositionsFulfillAction<Error> {
  error: true
}
