import { IPlatformPositionsResult } from '../types'
import { FSA, IFSAData } from '../../FSA'

const domain = `platform-positions`

export const FETCH_PLATFORM_POSITIONS = `${domain}/FETCH_PLATFORM_POSITIONS`
export const FETCH_PLATFORM_POSITIONS_FULFILL = `${domain}/FETCH_PLATFORM_POSITIONS_FULFILL`

export interface IFetchPlatformPositionsActionPayload {
  platformCode: string
  page: number
  pageSize?: number
}

export interface IFetchPlatformPositionsAction
  extends FSA<string, IFetchPlatformPositionsActionPayload> {
  type: typeof FETCH_PLATFORM_POSITIONS
}

export interface IFetchPlatformPositionsFulfillAction<Payload>
  extends IFSAData<Payload> {
  type: typeof FETCH_PLATFORM_POSITIONS_FULFILL
}

export interface IFetchPlatformPositionsFulfillActionSuccess
  extends IFetchPlatformPositionsFulfillAction<IPlatformPositionsResult> {
  error: false
}

export interface IFetchPlatformPositionsFulfillActionFail
  extends IFetchPlatformPositionsFulfillAction<Error> {
  error: true
}
