import * as React from 'react'
import OnboardingApplicationStepFooter from './OnboardingApplicationStepFooter'
import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/es/form'
import { WrappedFormUtils } from '@ant-design/compatible/es/form/Form'
import {
  AnyOnboardingApplicationItemConfig,
  OnboardingApplicationStepConfig
} from '../configuration'
import ParagraphFormItem from './FormItems/Paragraph'
import CheckboxFormItem from './FormItems/Checkbox'
import { OrderedListFormItem } from './FormItems/OrderedList'
import TextInputFormItem from './FormItems/TextInput'
import RadioGroupFormItem from './FormItems/RadioGroup'
import DropdownFormItem from './FormItems/Dropdown'
import DocumentFormItem from './FormItems/Document'
import { Layout, Menu, Spin } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import { getValueFromAnswers } from './FormItems'
import ListFormItem from './FormItems/List'
import PasswordInputFormItem from './FormItems/Password'
import CheckboxGroupFormItem from './FormItems/CheckboxGroup'
import RestService from '../../RestService'
import { IParticipant } from 'seed-shared-components/lib/types'
import { IAuthUser } from '../../auth/WithAuthentication'
import { Link, RouteComponentProps } from 'react-router-dom'
import Title from 'seed-shared-components/lib/components/Title'
import ButtonFormItem from './FormItems/Button'
import TitleFormItem from './FormItems/Title'
import CustomRequestDocumentFormItem from './FormItems/CustomRequestDocument'
import _ from 'lodash'
import SelectFormItem from './FormItems/Select'

const { Content } = Layout

export interface IOnboardingApplicationStepProps extends FormComponentProps {
  authUser: IAuthUser
  applicationSteps: OnboardingApplicationStepConfig[]
  history: any
  participant: IParticipant
  saveAnswers: (answers: Object) => Promise<any>
  setAnswersState: (answers: Object) => void
  submitApplication: () => Promise<void>
  currentStep: OnboardingApplicationStepConfig
  nextStep: OnboardingApplicationStepConfig
  previousStep: OnboardingApplicationStepConfig
  answers: any
  restService: RestService
  loading: boolean
  routerProps: RouteComponentProps
}

export type OnboardingApplicationFormItemSwitchProps = AnyOnboardingApplicationItemConfig & {
  form: WrappedFormUtils
  value: any
  formItemLayout: any
  answers: any
  participant: IParticipant
  restService: RestService
  authUser: IAuthUser
  parentKey?: string
  onAdd?: () => void
  onRemove?: (index: number) => void
}
export const OnboardingApplicationFormItemSwitch = (
  props: OnboardingApplicationFormItemSwitchProps
) => {
  switch (props.type) {
    case 'paragraph':
      return <ParagraphFormItem {...props} />
    case 'orderedList':
      return <OrderedListFormItem {...props} />
    case 'text':
      return <TextInputFormItem {...props} />
    case 'password':
      return <PasswordInputFormItem {...props} />
    case 'radio':
      return <RadioGroupFormItem {...props} />
    case 'checkbox':
      return <CheckboxFormItem {...props} />
    case 'dropdown':
      return <DropdownFormItem {...props} />
    case 'select':
      return <SelectFormItem {...props} />
    case 'document':
      return <DocumentFormItem application_document={true} {...props} />
    case 'listform':
      return <ListFormItem {...props} />
    case 'checkboxgroup':
      return <CheckboxGroupFormItem {...props} />
    case 'button':
      return <ButtonFormItem {...props} />
    case 'document_new':
      return (
        <CustomRequestDocumentFormItem application_document={true} {...props} />
      )
    case 'title':
      return <TitleFormItem {...props} />
  }
}

const maybeTransformValue = (
  item: AnyOnboardingApplicationItemConfig | undefined,
  values: any
) => {
  if (!item) {
    return null
  }
  if ((item as any).transformValue) {
    return (item as any).transformValue(values[item.itemKey])
  } else {
    return values[item.itemKey]
  }
}

const transformCheckboxGroup = (
  enteredValues: any,
  itemKey: string,
  answers: any
) => {
  if (
    enteredValues[itemKey] &&
    enteredValues[itemKey].length >= 1 &&
    typeof enteredValues[itemKey][0] === 'string'
  ) {
    // string array
    const values = enteredValues[itemKey] as string[]
    answers[itemKey] = values.reduce(
      (checkedAnswers, k) => ({ ...checkedAnswers, [k]: true }),
      {}
    )
    return
  }
  answers[itemKey] = Object.keys(enteredValues[itemKey]).reduce(
    (checkedAnswers, k) => ({ ...checkedAnswers, [k]: true }),
    {}
  )
}

const captureEnteredValueIntoAnswer = (
  answers: any,
  items: AnyOnboardingApplicationItemConfig[],
  enteredValues: any,
  itemKey: string
) => {
  const item = items.find((item) => item.itemKey === itemKey)
  if (!item) {
    throw new Error('Missing item for answered value')
  }
  if (enteredValues[itemKey] === null || enteredValues[itemKey] === undefined) {
    answers[itemKey] = null
    return
  }
  if (item.type === 'listform') {
    answers[itemKey] = (enteredValues[itemKey] as Object[]).map((listItem) => {
      const updated = Object.keys(listItem).reduce(
        (newListItem, propertyName) => {
          const listItemPropertyConfig = item.items.find(
            (i) => i.itemKey === propertyName
          )
          if (
            listItemPropertyConfig &&
            listItemPropertyConfig.type === 'checkboxgroup'
          ) {
            transformCheckboxGroup(
              listItem,
              listItemPropertyConfig.itemKey,
              newListItem
            )
          } else {
            newListItem[propertyName] = maybeTransformValue(
              listItemPropertyConfig,
              listItem
            )
          }

          return newListItem
        },
        {}
      )
      return updated
    })
  } else if (item.type === 'checkboxgroup') {
    transformCheckboxGroup(enteredValues, itemKey, answers)
  } else {
    answers[itemKey] = maybeTransformValue(item, enteredValues)
  }
}

export const captureAnswers = (
  form: WrappedFormUtils,
  currentStep: any
): any => {
  let values = form.getFieldsValue()
  values = _.pickBy(values, (value, key) => key !== 'undefined')
  const capturedAnswers = Object.keys(values).reduce((answers, itemKey) => {
    captureEnteredValueIntoAnswer(answers, currentStep.items, values, itemKey)
    return answers
  }, {})
  return capturedAnswers
}

export const navigateToNext = (
  nextStep: any,
  participantCode: string,
  history: any
) => {
  if (nextStep) {
    const nextRoute = `/${participantCode}/application/${nextStep.route}`
    history.push(nextRoute)
  }
}

class OnboardingApplicationStep extends React.Component<
  IOnboardingApplicationStepProps & FormComponentProps,
  { loading: boolean }
> {
  constructor(props: any) {
    super(props)
    this.state = {
      loading: false
    }
    this.saveInProgressApplication = this.saveInProgressApplication.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.saveAnswers = this.saveAnswers.bind(this)
    this.validateAndSubmitApplication = this.validateAndSubmitApplication.bind(
      this
    )
  }

  saveInProgressApplication(e?: any, callback?: () => Promise<any>) {
    if (e) {
      e.preventDefault()
    }
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      this.setState(() => ({
        loading: true
      }))
      await this.saveAnswers()

      navigateToNext(
        this.props.nextStep,
        this.props.participant.code,
        this.props.history
      )

      if (callback) {
        callback()
      }
      this.setState(() => ({
        loading: false
      }))
    })
  }

  handleChange = (e: any) => {
    e.preventDefault()
    this.setAnswersState()
  }

  saveAnswers() {
    const answers = captureAnswers(this.props.form, this.props.currentStep)
    return this.props.saveAnswers(answers)
  }

  setAnswersState() {
    const answers = captureAnswers(this.props.form, this.props.currentStep)
    this.props.setAnswersState(answers)
  }

  async validateAndSubmitApplication() {
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      this.setState(() => ({
        loading: true
      }))

      this.setAnswersState()

      await this.props.submitApplication()

      this.setState(() => ({
        loading: false
      }))
    })
  }

  render() {
    const formItemLayout = null
    const { currentStep, form } = this.props
    return (
      <div>
        <Layout hasSider={true}>
          <Sider>
            <Menu selectedKeys={[currentStep.route]}>
              {this.props.applicationSteps.map((step) => (
                <Menu.Item key={step.route}>
                  <Link
                    id={step.stepId}
                    to={`/${this.props.participant.code}/application/${step.route}`}
                  >
                    {step.label}
                  </Link>
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
          <Layout>
            <Content>
              <Spin spinning={this.props.loading || this.state.loading}>
                <Title level={3} style={{ padding: '12px 8px' }}>
                  {currentStep.label}
                </Title>
                <Form
                  onSubmit={this.saveInProgressApplication}
                  layout="vertical"
                  onChange={this.handleChange}
                >
                  {currentStep.items
                    .filter((item) =>
                      item.displayIf ? item.displayIf(this.props.answers) : true
                    )
                    .map((item) => (
                      <div style={{ margin: 8 }} key={item.itemKey}>
                        <OnboardingApplicationFormItemSwitch
                          value={getValueFromAnswers(
                            item.itemKey,
                            this.props.answers
                          )}
                          form={form}
                          formItemLayout={formItemLayout}
                          answers={this.props.answers}
                          restService={this.props.restService}
                          participant={this.props.participant}
                          authUser={this.props.authUser}
                          {...item}
                        />
                      </div>
                    ))}
                </Form>
                <OnboardingApplicationStepFooter
                  {...this.props}
                  nextStepTrigger={this.saveInProgressApplication}
                  submitApplication={this.validateAndSubmitApplication}
                />
              </Spin>
            </Content>
          </Layout>
        </Layout>
      </div>
    )
  }
}

const ApplicationStepForm = Form.create<IOnboardingApplicationStepProps>()(
  OnboardingApplicationStep
)
export default ApplicationStepForm
