import * as React from 'react'
import { FormInputItem } from '.'
import { IPasswordInputItemConfig } from '../../configuration'
import { Input, Tooltip } from 'antd'
import FormItem from '@ant-design/compatible/es/form/FormItem'
import Label from './Label'

export interface IPasswordInputFormProps
  extends FormInputItem<IPasswordInputItemConfig> {}

interface IPasswordInputFormState {
  initialized: boolean
}

class PasswordInputFormItem extends React.Component<
  IPasswordInputFormProps,
  IPasswordInputFormState
> {
  constructor(props: IPasswordInputFormProps) {
    super(props)

    this.state = {
      initialized: false
    }

    this.onBlur = this.onBlur.bind(this)
  }

  onBlur() {
    if (!this.state.initialized) {
      this.setState({ initialized: true })
    }
  }

  render() {
    const props = this.props

    return (
      <Tooltip placement="leftTop" title={props.tooltip}>
        <div>
          <FormItem
            {...props.formItemLayout}
            label={
              <Label
                itemKey={props.itemKey}
                label={props.label}
                tooltip={props.tooltip}
              />
            }
            extra={props.extraInstructions}
          >
            {props.form.getFieldDecorator(props.itemKey, {
              initialValue: props.value,
              rules: props.rules,
              validateTrigger: this.state.initialized ? 'onChange' : 'onBlur'
            })(<Input.Password onBlur={this.onBlur} />)}
          </FormItem>
        </div>
      </Tooltip>
    )
  }
}

export default PasswordInputFormItem
