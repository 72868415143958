import * as React from 'react'
import FormItem from '@ant-design/compatible/es/form/FormItem'
import { Input, Tooltip } from 'antd'
import ReactInputMask from 'react-input-mask'
import { FormInputItem } from '.'
import { ITextInputItemConfig } from '../../configuration'
import Label from './Label'

export interface ITextInputFormItemProps
  extends FormInputItem<ITextInputItemConfig> {}

interface ITextInputFormItemState {
  initialized: boolean
}

class TextInputFormItem extends React.Component<
  ITextInputFormItemProps,
  ITextInputFormItemState
> {
  constructor(props: ITextInputFormItemProps) {
    super(props)
    this.state = {
      initialized: false
    }

    this.onBlur = this.onBlur.bind(this)
  }

  onBlur() {
    if (!this.state.initialized) {
      this.setState({ initialized: true })
    }
  }

  render() {
    const props = this.props
    let value = props.value || '' // initialize value to avoid ReactInputMask Error

    // handle special transform for dates
    if (value && props.dateString && typeof value === 'object') {
      value = `${value.month}/${value.day}/${value.year}`
    }

    if (props.mask) {
      return (
        <Tooltip placement="leftTop" title={props.tooltip}>
          <div>
            <FormItem
              {...props.formItemLayout}
              label={
                <Label
                  itemKey={props.itemKey}
                  label={props.label}
                  tooltip={props.tooltip}
                />
              }
              extra={props.extraInstructions}
            >
              {props.form.getFieldDecorator(props.itemKey, {
                initialValue: value,
                rules: props.rules,
                validateTrigger: this.state.initialized ? 'onChange' : 'onBlur'
              })(
                <ReactInputMask
                  mask={props.mask}
                  disabled={false}
                  onBlur={this.onBlur}
                >
                  {(inputProps: any) => <Input {...inputProps} />}
                </ReactInputMask>
              )}
            </FormItem>
          </div>
        </Tooltip>
      )
    }
    const fieldDecoratorOptions = {
      initialValue: props.value,
      rules: props.rules,
      validateTrigger: this.state.initialized ? 'onChange' : 'onBlur'
    }
    if (props.conditionallyRequired) {
      const required = props.conditionallyRequired(props.answers)
      if (required) {
        fieldDecoratorOptions.rules = [required]
      }
    }
    return (
      <Tooltip placement="leftTop" title={props.tooltip}>
        <div>
          <FormItem
            {...props.formItemLayout}
            label={
              <Label
                itemKey={props.itemKey}
                label={props.label}
                tooltip={props.tooltip}
              />
            }
            extra={props.extraInstructions}
          >
            {props.form.getFieldDecorator(
              props.itemKey,
              fieldDecoratorOptions
            )(<Input onBlur={this.onBlur} />)}
          </FormItem>
        </div>
      </Tooltip>
    )
  }
}

export default TextInputFormItem
