import React from 'react'
import RightGroup from './RightGroup'
import { IAuthUser } from '../auth/WithAuthentication'
import NewBreadCrumbHeader from '../components/breadcrumb/NewBreadCrumbHeader'
import { IParticipant } from 'seed-shared-components/lib/types'
import { RouteComponentProps } from 'react-router-dom'
interface ITopNavProps extends RouteComponentProps {
  authUser: IAuthUser
  participant: IParticipant | undefined
}

const TopNav = (props: ITopNavProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <div className="logo-container">
          <img className="logo" src="/portal-logo.png" />
        </div>
        <NewBreadCrumbHeader participant={props.participant} {...props} />
      </div>

      <div style={{ display: 'flex' }}>
        <RightGroup authUser={props.authUser} />
      </div>
    </div>
  )
}
export default TopNav
