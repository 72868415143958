import React, { useState } from 'react'
import { Button, Input, notification, Tag } from 'antd'
import isIP from 'is-ip'
import { PlusOutlined } from '@ant-design/icons'

type AllowedIpsContainer = {
  key_id: string
  allowed_ips: string[]
  updateAllowedIPs: (id: string, allowedIps: string[]) => void
}

const AllowedIpsContainer = ({
  key_id,
  allowed_ips,
  updateAllowedIPs
}: AllowedIpsContainer) => {
  const [allowedIps, setAllowedIps] = useState(allowed_ips || [])
  const [inputValue, setInputValue] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const handleClose = (e: React.MouseEvent, ip: string) => {
    {
      /* This can be simplified and made without the use of "submitted"
        flag once we fix the this.refreshKeys() problem */
    }
    e.preventDefault()
    const filteredIps = allowedIps.filter((item) => item !== ip)
    if ((allowed_ips !== null || submitted) && allowedIps.length === 1) {
      notification.error({
        description: 'You must have at least one IP attached to an API Key',
        duration: 3,
        message: 'Unable to remove IP address'
      })
    } else if (!allowed_ips && !submitted) {
      setAllowedIps(filteredIps)
    } else if (allowedIps.length > 1) {
      setAllowedIps(filteredIps)
      updateAllowedIPs(key_id, filteredIps)
    } else {
      notification.error({
        description: 'You must have at least one IP attached to an API Key',
        duration: 3,
        message: 'Unable to remove IP address'
      })
    }
  }

  return (
    <>
      {/* If allowedIps is null, then we can restrict which IPs can use the ApiKey */}
      {!allowed_ips && !submitted && (
        <div>
          <p>This API Key is allowed for all IP addresses</p>
          <Input
            placeholder="192.168.0.1"
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
            addonAfter={
              <Button
                style={{
                  height: 'fit-content',
                  padding: '0 5px',
                  marginRight: '5px'
                }}
                disabled={!isIP(inputValue)}
                onClick={() => {
                  setAllowedIps([...new Set([...allowedIps, inputValue])])
                  setInputValue('')
                }}
              >
                <PlusOutlined />
              </Button>
            }
          />
          <div>
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                marginTop: '8px'
              }}
              disabled={!allowedIps.length}
              onClick={() => {
                updateAllowedIPs(key_id, allowedIps)
                setSubmitted(true)
              }}
              placeholder="Update IPs"
            >
              Update IPs
            </Button>
          </div>
        </div>
      )}
      {allowedIps?.map((ip: string) => (
        <Tag
          key={ip}
          style={{ cursor: 'inherit', margin: '4px' }}
          closable
          onClose={(e) => handleClose(e, ip)}
        >
          <span className="bold-text" style={{ fontSize: 12 }}>
            {ip}
          </span>
        </Tag>
      ))}
    </>
  )
}

export default AllowedIpsContainer
