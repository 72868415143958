import React from 'react'
import { Button } from 'antd'
import { IButtonItemConfig } from '../../new-world-configuration'

export interface IButtonFormItemProps extends Partial<IButtonItemConfig> {}

const ButtonFormItem = (props: IButtonFormItemProps) => (
  <Button onClick={props.onClick} style={props.style} type="primary">
    {props.label}
  </Button>
)

export default ButtonFormItem
