import { createAction } from '@reduxjs/toolkit'
import {
  FETCH_NET_DELIVERY_OBLIGATIONS,
  SET_NET_DELIVERY_OBLIGATIONS,
  IFetchNetDeliveryObligationsAction,
  ISetNetDeliveryObligationsAction
} from './types'

export const fetchNetDeliveryObligations = createAction<
  IFetchNetDeliveryObligationsAction['payload']
>(FETCH_NET_DELIVERY_OBLIGATIONS)

export const setNetDeliveryObligations = createAction<
  ISetNetDeliveryObligationsAction['payload']
>(SET_NET_DELIVERY_OBLIGATIONS)
