import * as React from 'react'
import { Dropdown, Menu, Avatar } from 'antd'
import { withRouter, Link, RouteComponentProps } from 'react-router-dom'

import { IAuthUser } from '../auth/WithAuthentication'
import { IParticipant } from 'seed-shared-components/lib/types'
import { UserOutlined } from '@ant-design/icons'

const { Component } = React

interface IRightGroupProps extends RouteComponentProps {
  authUser: IAuthUser
}
interface IRightGroupState {
  participantCode?: string
  participant?: IParticipant
}

class RightGroup extends Component<IRightGroupProps, IRightGroupState> {
  constructor(props: IRightGroupProps) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    this.setPartcipantCode()
  }
  componentDidUpdate(previousProps: IRightGroupProps) {
    if (
      previousProps.location.pathname !== this.props.location.pathname ||
      previousProps.authUser.participant !== this.props.authUser.participant
    ) {
      this.setPartcipantCode()
    }
  }
  setPartcipantCode() {
    const maybeParticipantCode = this.props.location.pathname.split('/')[1]
    const maybeParticipant = this.props.authUser.participants.find(
      (p) => p.code === maybeParticipantCode
    )
    this.setState(() => ({
      participantCode: maybeParticipantCode,
      participant: maybeParticipant
    }))
  }
  render() {
    const { authUser } = this.props
    const changePasswordLink = this.state.participant
      ? `/${this.state.participantCode}/change_password`
      : '/change_password'
    const menu = (
      <Menu selectedKeys={[]}>
        <Menu.Item key="logout">
          <Link to="/logout">Logout</Link>
        </Menu.Item>
        <Menu.Item key="changepassword">
          <Link to={changePasswordLink}>Change Password</Link>
        </Menu.Item>
        <Menu.Item key="privacy">
          <a
            target="_blank"
            href="https://zerohash.zendesk.com/hc/en-us/articles/360009900494-Privacy-Policy-"
          >
            Privacy Policy
          </a>
        </Menu.Item>
        <Menu.Item key="terms">
          <a
            target="_blank"
            href="https://zerohash.zendesk.com/hc/en-us/articles/360009900654-Terms-of-Service"
          >
            Terms of Service
          </a>
        </Menu.Item>
      </Menu>
    )

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Menu selectedKeys={[]} theme="light" mode="horizontal">
          <Menu.Item key="one">
            <Dropdown overlay={menu}>
              <div style={{ display: 'flex' }}>
                {authUser && (
                  <div style={{ marginRight: '10px' }}>{authUser.email}</div>
                )}
                <Avatar style={{ margin: 'auto' }} size={32} alt="avatar">
                  <UserOutlined style={{ margin: 0, fontSize: 19 }} />
                </Avatar>
              </div>
            </Dropdown>
          </Menu.Item>
        </Menu>
      </div>
    )
  }
}

export default withRouter<IRightGroupProps, any>(RightGroup)
