import React from 'react'

enum ASYNC_STATES {
  PENDING = 'pending',
  RESOLVED = 'resolved',
  REJECTED = 'rejected'
}

export type Monad<T, S> = { value: T | null; state: S }
export type PlainPromise<T> = Monad<
  T,
  ASYNC_STATES.PENDING | ASYNC_STATES.RESOLVED | ASYNC_STATES.REJECTED
>

export const useAsyncEffect = <T>(
  apiFunction: () => Promise<T>,
  effectFilter: any[],
  defaultValue: T = null
): PlainPromise<T> => {
  const [value, setValue] = React.useState<PlainPromise<T>>({
    value: defaultValue,
    state: defaultValue === null ? ASYNC_STATES.PENDING : ASYNC_STATES.RESOLVED
  })

  React.useEffect(() => {
    if (defaultValue === null) {
      setValue({ ...value, state: ASYNC_STATES.PENDING })
    }

    apiFunction()
      .then((value: T) => setValue({ value, state: ASYNC_STATES.RESOLVED }))
      .catch(() => setValue({ ...value, state: ASYNC_STATES.REJECTED }))
  }, effectFilter)

  return value
}

useAsyncEffect.STATES = ASYNC_STATES

export const fetchAllPages = async <T>(
  predicate: (page: number) => Promise<T[]>
) => {
  let result = []
  let pagesFetched = 0

  while (true) {
    const data = await predicate(pagesFetched++)

    if (data.length === 0) {
      break
    }

    result = result.concat(data)
  }

  return result
}

export const useUpdater = () => {
  const [__, set] = React.useState(0)

  return [__, () => set(__ + 1)] as any[]
}
