import { createReducer } from '@reduxjs/toolkit'
import * as FundsActions from './actions'
import { ILoadFundsSuccessAction } from './actions/types'
import * as ParticipantActions from '../participant/actions'
import { IFundsState } from './state'
import { computeAssetBalances } from './utils'

const fundsInitialState: IFundsState = {
  funds: null,
  assetBalances: {
    balances: null
  },
  loading: false,
  assets: []
}

const funds = createReducer(fundsInitialState, {
  [FundsActions.loadFundsSuccess.type]: (
    state,
    action: ILoadFundsSuccessAction
  ) => ({
    ...state,
    loading: false,
    funds: action.payload.funds,
    assetBalances: {
      ...state.assetBalances,
      balances: computeAssetBalances(
        action.payload.funds,
        action.payload.assets
      )
    },
    assets: action.payload.assets
  }),
  [ParticipantActions.selectParticipant.type]: (state) => ({
    ...state,
    funds: null,
    assetBalances: null
  }),
  [FundsActions.loadFunds.type]: (state) => ({
    ...state,
    loading: true
  }),
  [FundsActions.loadFundsFailure.type]: (state) => ({
    ...state,
    loading: false
  })
})

export default funds
