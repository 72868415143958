import { Table, Tag } from 'antd'
import * as React from 'react'
import Title from 'seed-shared-components/lib/components/Title'
import { IWithParticipantComponentProps } from '../components/participant/WithParticipant'
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from '../constants'
import { useAsyncEffect } from '../loan-portfolios/helpers/hooks'

interface IRelationship {
  id: string
  created_at: string
  updated_at: string
  type: string
  deleted: boolean
  primary_participant_code: string
  secondary_participant_code: string
  trading_disabled: boolean
  secondary_participant_name: string
}

export interface IFormattedRelatedParticipant {
  participant_code: string
  types: Set<string>
  name: string
}

interface IDetailsResponsePartial {
  relationships: IRelationship[]
}

const RelationshipsPage = (props: IWithParticipantComponentProps) => {
  const [isInitialDataLoaded, setIsInitialDataLoaded] = React.useState(false)
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0
  })
  const participantDetailsState = useAsyncEffect(async () => {
    return props.restService.route('details').get<IDetailsResponsePartial>()
  }, [])
  const relatedParticipantsState = useAsyncEffect<
    IFormattedRelatedParticipant[]
  >(async () => {
    // early return if details is not loaded yet
    if (participantDetailsState.state !== useAsyncEffect.STATES.RESOLVED) {
      return []
    }

    const participantDetails = participantDetailsState.value
    const platformParticipants = await props.getPaginatedPlatformParticipants({
      page: pagination.page,
      pageSize: pagination.pageSize,
      includePlatformParticipant: false,
      includeRelationships: true
    })

    if (pagination.total !== platformParticipants.count) {
      setPagination((p) => ({ ...p, total: platformParticipants.count }))
    }

    return platformParticipants.message.map<IFormattedRelatedParticipant>(
      (participant) => {
        const result: IFormattedRelatedParticipant = {
          participant_code: participant.registration_code,
          name: participant.name,
          types: new Set(participant.relationships.map(({ type }) => type))
        }

        return result
      }
    )
  }, [pagination, participantDetailsState])

  const handlePaginationChange = React.useCallback((page, pageSize) => {
    setPagination((p) => ({ ...p, page, pageSize }))
  }, [])

  React.useEffect(() => {
    if (isInitialDataLoaded) {
      return
    }

    if (
      participantDetailsState.state === useAsyncEffect.STATES.RESOLVED &&
      relatedParticipantsState.state === useAsyncEffect.STATES.RESOLVED
    ) {
      setIsInitialDataLoaded(true)
    }
  }, [
    isInitialDataLoaded,
    participantDetailsState.state,
    relatedParticipantsState.state
  ])

  return (
    <div>
      <Title level={2}>Relationships</Title>

      <div style={{ marginBottom: 10 }}>
        Find a list of all customers, counterparties, liquidity providers and
        platforms that you’re connected to within Zero Hash. Read{' '}
        <a
          href="https://zerohash.zendesk.com/hc/en-us/articles/360021160534"
          target="_blank"
        >
          here
        </a>{' '}
        for more information on the relationship types and what access rights
        they give.
      </div>
      <div style={{ marginBottom: 24 }}>
        Want to connect to someone new? Read about{' '}
        <a
          href="https://zerohash.zendesk.com/hc/en-us/articles/360046185094"
          target="_blank"
        >
          how to add new counterparties
        </a>{' '}
        and reach out to
        <a
          href="mailto:sales@zerohash.com?subject=Connect to other Zero Hash Participants"
          target="_blank"
        >
          {' '}
          sales@zerohash.com
        </a>{' '}
        to learn about other participants available to connect with.
      </div>

      <Table
        rowKey={(record) => record.participant_code}
        loading={
          !isInitialDataLoaded ||
          relatedParticipantsState.state !== useAsyncEffect.STATES.RESOLVED
        }
        columns={[
          {
            title: 'Participant',
            key: 'participant',
            dataIndex: 'participant_code',
            width: 200,
            render: (
              participantCode: string,
              record: IFormattedRelatedParticipant
            ) => (
              <span>
                {record.name} ({participantCode})
              </span>
            )
          },
          {
            title: 'Your Relationship(s) to Participant',
            key: 'relationships',
            dataIndex: 'types',
            width: 200,
            render: (types: Set<string>) =>
              Array.from(types).map((type) => (
                <Tag style={{ cursor: 'inherit' }}>
                  <span>{type}</span>
                </Tag>
              ))
          }
        ]}
        dataSource={relatedParticipantsState.value}
        pagination={{
          showSizeChanger: true,
          total: pagination.total,
          current: pagination.page,
          pageSize: pagination.pageSize,
          pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
          onChange: handlePaginationChange,
          onShowSizeChange: handlePaginationChange
        }}
      />
    </div>
  )
}

export default RelationshipsPage
