import * as React from 'react'
import {
  IListFormItemConfig,
  AnyOnboardingApplicationItemConfig
} from '../../configuration'
import { Button, Card } from 'antd'
import { OnboardingApplicationFormItemSwitch } from '../OnboardingApplicationStep'
import { FormInputItem, getValueFromAnswers } from '.'
import RestService from '../../../RestService'
import { IAuthUser } from '../../../auth/WithAuthentication'
import { IParticipant } from 'seed-shared-components/lib/types'

export interface IListFormItemProps extends FormInputItem<IListFormItemConfig> {
  restService: RestService
  authUser: IAuthUser
  participant: IParticipant
}

export interface IListFormItemState {
  listItems: any[]
}

export default class ListFormItem extends React.Component<
  IListFormItemProps,
  IListFormItemState
> {
  constructor(props: any) {
    super(props)
    const answererdItems = this.props.answers[this.props.itemKey]
    this.addItem = this.addItem.bind(this)
    this.state = {
      listItems:
        answererdItems && answererdItems.length > 0
          ? answererdItems.map((item: any) => ({
              ...item,
              parentKey: this.props.itemKey
            }))
          : Array(this.props.initialCount(this.props.answers)).fill({
              parentKey: this.props.itemKey
            })
    }
  }

  addItem() {
    this.setState(() => ({
      listItems: [
        ...this.state.listItems,
        { ...this.props, parentKey: this.props.itemKey }
      ]
    }))

    if (this.props.onAdd) {
      this.props.onAdd()
    }
  }

  removeItem(index: number) {
    const listItems = [...this.state.listItems]
    listItems.splice(index, 1)
    this.setState(() => ({
      listItems
    }))

    if (this.props.onRemove) {
      this.props.onRemove(index)
    }
  }

  renderRemove(i: number) {
    return (
      <>
        {this.state.listItems.length ===
        this.props.minimumCount(this.props.answers) ? null : (
          <a onClick={() => this.removeItem(i)}>Remove</a>
        )}
      </>
    )
  }

  render() {
    return (
      <div>
        {this.state.listItems.map((listItem: any, i: number) => (
          <Card
            key={`${this.props.label}-${i}`}
            title={`${this.props.label} ${i + 1}`}
            extra={this.renderRemove(i)}
            style={{ marginBottom: 12 }}
          >
            {this.props.items.map(
              (item: AnyOnboardingApplicationItemConfig) => {
                if (
                  item.displayIf
                    ? item.displayIf(this.props.answers, {
                        index: i,
                        key: this.props.itemKey
                      })
                    : true
                ) {
                  return (
                    <OnboardingApplicationFormItemSwitch
                      {...item}
                      itemKey={`${this.props.itemKey}[${i}][${item.itemKey}]`}
                      key={`${this.props.itemKey}[${i}][${item.itemKey}]`}
                      value={getValueFromAnswers(item.itemKey, listItem)}
                      form={this.props.form}
                      formItemLayout={this.props.formItemLayout}
                      answers={this.props.answers}
                      authUser={this.props.authUser}
                      participant={this.props.participant}
                      restService={this.props.restService}
                      parentKey={this.props.itemKey}
                      index={i}
                    />
                  )
                }
                return undefined
              }
            )}
          </Card>
        ))}
        {!this.props.fixed ? (
          <Button onClick={this.addItem}>
            Add&nbsp;another&nbsp;
            {this.props.label}
          </Button>
        ) : undefined}
      </div>
    )
  }
}
