import * as React from 'react'
import * as Redux from 'react-redux'

import * as positionsActions from '../../../../store/participant-positions/actions'

export function usePullParticipantPositions({
  participantCode,
  page,
  pageSize
}: {
  participantCode: string
  page: number
  pageSize?: number
}) {
  const dispatch = Redux.useDispatch()

  React.useEffect(() => {
    const pullInterval = setInterval(() => {
      dispatch(
        positionsActions.fetchParticipantPositions({
          participantCode,
          page,
          pageSize
        })
      )
    }, 10000)

    return () => {
      clearInterval(pullInterval)
    }
  }, [participantCode, page, pageSize])
}
