export type PortfolioItem = {
  asset: string
  amount: number
  value?: number
  part?: number
}

const domain = `portfolio`

export const FETCH_PORTFOLIO = `${domain}/FETCH_PORTFOLIO`
export const SET_PORTFOLIO = `${domain}/SET_PORTFOLIO`

export interface IFetchPortfolio {
  type: typeof FETCH_PORTFOLIO
  payload: {
    participantCode: string
  }
}

export interface ISetPortfolio {
  type: typeof SET_PORTFOLIO
  payload: {
    value?: PortfolioItem[]
    loading?: boolean
  }
}
