import { Col, Row } from 'antd'
import * as React from 'react'

import PageTitle from 'seed-shared-components/lib/components/Title'
import DataDownloadButton from 'seed-shared-components/lib/components/DownloadDataButton'
import PositionsTable from './PositionsTable'
import { useConnect } from './hooks/useConnect'

function ParticipantPositions() {
  const { positions, isSomeDataLoaded, isPositionsBeingLoaded } = useConnect()

  return (
    <div>
      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <Col style={{ padding: 0 }}>
          <PageTitle level={2}>Participant Positions</PageTitle>
        </Col>
        <Col style={{ padding: 0 }}>
          <DataDownloadButton
            data={positions}
            disabled={positions.length === 0}
            description="participant-positions"
          />
        </Col>
      </Row>
      <PositionsTable
        loading={!isSomeDataLoaded ? isPositionsBeingLoaded : false}
        dataSource={positions}
      />
    </div>
  )
}

export default ParticipantPositions
