import * as React from 'react'
import { Link } from 'react-router-dom'
import { Button, List, notification, Spin, Table, Typography } from 'antd'
import short from 'short-uuid'

import { IAuthUser } from '../../auth/WithAuthentication'
import RestService from '../../RestService'
import { IParticipant } from 'seed-shared-components/lib/types'
import { IWithParticipantComponentProps } from '../../components/participant/WithParticipant'
import { IParticipantApplicationStatus } from '../../onboarding-application/participant'
import { NetDeliveryObligation } from '../../store/net-delivery-obligations/actions/types'
import { PortfolioItem } from '../../store/portfolio/actions/types'
import DashboardStepsComponent from './DashboardSteps'
import {
  IPortalApiWithdrawalAccount,
  IPortalApiWithdrawalRequest
} from '../../withdrawal/withdrawalTypes'
import { ITransferRequest } from '../../transfers/components/TransfersPage'
import connector from './connector'
import { currencyFormatter } from 'seed-shared-components/lib/utils/CurrencyFormatter'

import DashboardPortfolioChart from './DashboardPortfolioChart'
import { Widget, WidgetsContainer, WidgetsStack } from './Widget'
import { getDisabledMessage, getSetupAccountNextStep } from '../utils'
import { DashboardSteps } from './constants'

import groupBy from 'lodash/groupBy'
import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons'
import BalancesWidget from '../../platform-balances/components/BalancesWidget'

const { Text } = Typography

const approveButtonShouldBeDisabled = (
  userRequest: IPortalApiWithdrawalRequest,
  userEmail: string
): boolean => {
  if (!userRequest.history) {
    return userRequest.userEmail === userEmail
  }

  if (userRequest.status === 'APPROVED') {
    return true
  }

  return !!userRequest.history.find((r: any) => {
    return (
      r.userEmail === userEmail &&
      (r.action === 'APPROVAL' ||
        r.action === 'REJECTION' ||
        r.action === 'CREATION_APPROVAL' ||
        r.action === 'CHANGE_APPROVAL')
    )
  })
}

const rejectButtonShouldBeDisabled = (
  userRequest: IPortalApiWithdrawalRequest,
  userEmail: string
): boolean => {
  if (!userRequest.history) {
    return userRequest.userEmail === userEmail
  }

  if (userRequest.status === 'REJECTED') {
    return true
  }

  return !!userRequest.history.find((r: any) => {
    return (
      r.userEmail === userEmail &&
      (r.action === 'REJECTION' || r.action === 'APPROVAL')
    )
  })
}

interface IDashboardPageState {
  loading: boolean
  hasError: boolean
  currentParticipant?: IParticipant
  application?: IParticipantApplicationStatus
  users: any[]
  participantDetails: any
  isAuthSigner?: boolean
  isAdmin?: boolean
  deposits: { loading: boolean; value: any[] }
  activeIndex: number
}

interface ICheckboxProps {
  currentUser: IAuthUser
  currentParticipant?: IParticipant
  users: any[]
  application?: IParticipantApplicationStatus
  participantDetails: any
  deposits: { loading: boolean; value: any[] }
}

const determineStep = (
  props: ICheckboxProps,
  hasError: boolean
): number | undefined => {
  const {
    application,
    currentParticipant,
    participantDetails,
    users,
    deposits
  } = props

  if (hasError) {
    return -1
  }

  if (!currentParticipant) {
    return DashboardSteps['createParticipant']
  }

  const traders = users.filter(
    (user) => user.roles.isTradeSubmitter || user.roles.isTradeEnabled
  )

  // walk down the list of the step, trying to find conditions to disqualify
  if (!application || !application.submittedAt) {
    return DashboardSteps['apply']
  }

  // it is possible that a participant could be approved without the "required" number
  // of authorized signer signatures.
  if (
    application &&
    application.status === 'waiting_for_signers' &&
    !participantDetails.approved
  ) {
    return DashboardSteps['signApplication']
  }

  if (!participantDetails.approved) {
    return DashboardSteps['seedApproval']
  }

  if (traders.length === 0) {
    return DashboardSteps['addUsers']
  }

  if (deposits.value.length === 0) {
    return DashboardSteps['fund']
  }

  if (deposits.value.length > 0) {
    return DashboardSteps['trade']
  }

  return 0
}

const userHasApproved = (
  record: IPortalApiWithdrawalAccount,
  userEmail: string
): boolean => {
  if (!record.history) {
    return false
  }
  let history = record.history
  if (record.status === 'PENDING_CHANGE') {
    // trim history down, keep since last change
    const historyCopy = [...history].reverse()
    const index = historyCopy.findIndex(
      (account: IPortalApiWithdrawalAccount) => account.status === 'APPROVED'
    )
    const count = historyCopy.length - 1
    const lastIndex = index >= 0 ? count - index : index
    const sinceLastApproval = history.slice(lastIndex + 1)
    history = sinceLastApproval
  }
  const userActions = history.filter((r: any) => {
    return (
      r.userEmail === userEmail &&
      (r.action === 'APPROVAL' ||
        r.action === 'CREATION_APPROVAL' ||
        r.action === 'CHANGE_APPROVAL')
    )
  })
  return userActions.length >= 1
}

const filterPendingAccounts = (
  accounts: IPortalApiWithdrawalAccount[],
  authUser: IAuthUser
): IPortalApiWithdrawalAccount[] => {
  const filteredAccounts = accounts.filter(
    (account) =>
      (account.status === 'PENDING_CREATION' ||
        account.status === 'PENDING_CHANGE') &&
      !userHasApproved(account, authUser.email)
  )

  return filteredAccounts
}

interface IDashboardPageProps extends IWithParticipantComponentProps {
  getAccounts: (participantCode: string) => void
  getWithdrawals: (participantCode: string) => void
  getplatformTransfers: (participantCode: string) => void
  fetchNetDeliveryObligations: (participantCode: string) => void
  fetchPortfolio: (participantCode: string) => void
  accounts: IPortalApiWithdrawalAccount[]
  withdrawals: IPortalApiWithdrawalRequest[]
  platformTransfers: ITransferRequest[]
  netDeliveryObligations: { value: NetDeliveryObligation[]; loading: boolean }
  portfolio: { value: PortfolioItem[]; loading: boolean }
}

export class DashboardPage extends React.Component<
  IDashboardPageProps,
  IDashboardPageState
> {
  state: IDashboardPageState = {
    hasError: false,
    loading: true,
    users: [],
    participantDetails: {},
    deposits: { loading: true, value: [] },
    activeIndex: 0
  }

  async componentWillMount() {
    await this.loadAndSetParticipant(this.props)
    await this.loadAndSetDeposits(this.props)
  }

  componentDidMount() {
    const { participant } = this.props

    if (participant && participant.code) {
      const { code } = participant
      this.props.getAccounts(code)
      this.props.getWithdrawals(code)
      this.props.getplatformTransfers(code)
      this.props.fetchNetDeliveryObligations(code)
      this.props.fetchPortfolio(code)
    }
  }

  handleError(description: string, message: string) {
    notification.error({
      description,
      duration: 0,
      message
    })
    console.error(message)
  }

  async getParticipantDetails(restService: RestService) {
    try {
      return await restService.route(`basic`).get()
    } catch (e) {
      throw e
    }
  }

  async getParticipantApplication(restService: RestService) {
    try {
      return await restService
        .route('participant_application_status')
        .get<IParticipantApplicationStatus>()
    } catch (e) {
      throw e
    }
  }

  async getUsers(restService: RestService) {
    try {
      const resp: any = await restService.route('users').get()
      return resp.users || []
    } catch (e) {
      return []
    }
  }

  async getDeposits(restService: RestService) {
    return restService
      .route('deposits?page=0')
      .get<any>()
      .then((res) => res.message)
      .catch(() => [])
  }

  async loadAndSetDeposits(nextProps: IWithParticipantComponentProps) {
    const { restService, participant } = nextProps

    if (!participant) {
      return
    }

    try {
      const value = await this.getDeposits(restService)
      this.setState({ deposits: { value, loading: false } })
    } catch (e) {
      console.log('failed fetching deposits. error: ', e)
    }
  }

  async loadAndSetParticipant(nextProps: IWithParticipantComponentProps) {
    const { restService, authUser, participant } = nextProps

    if (!participant) {
      // new user with no participants
      this.setState(() => ({
        loading: false
      }))
      return
    }

    let newState = Object.assign({}, this.state)

    try {
      const [
        isAuthSigner,
        isAdmin,
        participantDetails,
        users
      ] = await Promise.all([
        authUser.isAuthSigner(participant.code),
        authUser.isAdmin(participant.code),
        this.getParticipantDetails(restService),
        this.getUsers(restService)
      ])

      newState = {
        ...newState,
        isAuthSigner,
        isAdmin,
        participantDetails,
        users
      }

      this.setState(() => ({
        ...newState,
        loading: false,
        currentParticipant: participant
      }))
    } catch (errorReason) {
      this.handleError(errorReason, 'Unable to load participant data')
      this.setState(() => ({
        loading: false,
        hasError: true
      }))
      return
    }
  }

  render() {
    const { loading, currentParticipant, isAuthSigner, isAdmin } = this.state

    if (loading) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '100px 0'
          }}
        >
          <Spin />
        </div>
      )
    }
    let participantName
    let participantCode
    if (currentParticipant) {
      participantName = currentParticipant.name
      participantCode = currentParticipant.code
    }

    const checkboxProps: ICheckboxProps = {
      currentUser: this.props.authUser,
      currentParticipant: currentParticipant,
      users: this.state.users,
      application: this.state.application,
      participantDetails: this.state.participantDetails,
      deposits: this.state.deposits
    }
    const currentStep = determineStep(checkboxProps, this.state.hasError)

    let fundStepStatus:
      | 'process'
      | 'wait'
      | 'finish'
      | 'error'
      | undefined = undefined
    let fundStepIcon = undefined
    if (
      currentStep &&
      currentStep === DashboardSteps['fund'] &&
      this.state.deposits.loading
    ) {
      fundStepStatus = 'process'
      fundStepIcon = <LoadingOutlined />
    }

    const returnIconProp = (
      currentStep: DashboardSteps = 0,
      level: DashboardSteps
    ): { icon?: React.ReactNode } => {
      const completeIcon = <CheckCircleFilled style={{ fontSize: 32 }} />
      const tbd = currentStep <= level
      const complete = currentStep > level

      let icon: React.ReactNode | undefined = {}

      icon = tbd ? undefined : icon
      icon = complete ? completeIcon : icon

      return { icon }
    }

    const checkListCompleted = currentStep === DashboardSteps['trade']
    const fundStepIsLoading = fundStepStatus === 'process'

    const accounts = filterPendingAccounts(
      this.props.accounts,
      this.props.authUser
    )
    const withdrawals = this.props.withdrawals.filter(
      (request) =>
        request.status === 'PENDING' &&
        !approveButtonShouldBeDisabled(request, this.props.authUser.email) &&
        !rejectButtonShouldBeDisabled(request, this.props.authUser.email)
    )
    const platformTransfers = this.props.platformTransfers.filter(
      (transfer) => transfer.status === 'pending'
    )

    const hasAdminItems =
      accounts.length > 0 ||
      withdrawals.length > 0 ||
      platformTransfers.length > 0

    const withdrawalSections = [
      {
        id: 'account',
        title: 'Pending Accounts',
        linkTo: (currency: string) => `withdrawals/${currency}`,
        text: (currency: string) =>
          `Review pending ${currency} withdrawal accounts`,
        dataSource: accounts
      },
      {
        id: 'withdrawals',
        title: 'Pending Withdrawal Requests',
        linkTo: (currency: string) => `withdrawals/${currency}`,
        text: (currency: string) =>
          `Review pending ${currency} withdrawal requests`,
        dataSource: withdrawals
      },
      {
        id: 'platformTransfers',
        title: 'Pending Platform Requests',
        linkTo: () => `platform_transfers`,
        text: (currency: string) =>
          `Review pending ${currency} platform transfer requests`,
        dataSource: platformTransfers
      }
    ]

    let showSetupChecklist = !checkListCompleted && !fundStepIsLoading

    if (this.state.participantDetails) {
      showSetupChecklist = this.state.participantDetails.approved
        ? false
        : showSetupChecklist
    }

    const setupAccountNextStep = getSetupAccountNextStep({
      step: DashboardSteps[currentStep],
      isAuthSigner,
      application: this.state.application,
      code: participantCode,
      isAdmin
    })

    const showWidgets =
      currentStep === DashboardSteps['addUsers'] ||
      currentStep === DashboardSteps['fund']

    const portfolioData = this.props.portfolio.value.filter(
      ({ amount }) => amount > 0
    )

    const portfolioDataChart = portfolioData.map((data) => ({
      name: data.asset,
      ...data
    }))

    const setActiveIndex = (activeIndex: number) =>
      this.setState({ activeIndex })

    return (
      <WidgetsContainer>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 'fit-content'
            }}
          >
            {showSetupChecklist && (
              <Widget
                style={{ margin: 5 }}
                data-test-id="setup-checklist"
                title="Setup Checklist"
              >
                <DashboardStepsComponent
                  returnIconProp={returnIconProp}
                  steps={DashboardSteps}
                  isAuthSigner={isAuthSigner}
                  isAdmin={isAdmin}
                  application={this.state.application}
                  participantName={participantName}
                  fundStepStatus={fundStepStatus}
                  fundStepIcon={fundStepIcon}
                  currentStep={currentStep}
                  setupAccountNextStep={setupAccountNextStep}
                />

                <Link id="next_step_link" to={setupAccountNextStep || '#'}>
                  <Button
                    data-test-id="button-next-step"
                    type="primary"
                    disabled={!setupAccountNextStep}
                    style={{ marginTop: '20px' }}
                  >
                    {currentStep === 0 ? 'Start Setup' : 'Continue'}
                  </Button>
                </Link>
                {!setupAccountNextStep && (
                  <div style={{ marginTop: '10px' }}>
                    <Text type="warning">
                      {getDisabledMessage(DashboardSteps[currentStep])}
                    </Text>
                  </div>
                )}
              </Widget>
            )}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {(!showSetupChecklist || showWidgets) && (
                <WidgetsStack
                  stacked={showSetupChecklist}
                  style={{ width: '100%', marginRight: 12 }}
                >
                  {/* If we have something to show */}
                  {(isAdmin ||
                    (checkListCompleted && (!isAdmin || !hasAdminItems))) && (
                    <Widget
                      style={{ margin: 5 }}
                      data-test-id="admin-items"
                      title="Admin Items"
                    >
                      {isAdmin && (
                        <>
                          {withdrawalSections.map((section, idx) => {
                            return (
                              section.dataSource.length > 0 && (
                                <List
                                  key={idx}
                                  bordered
                                  dataSource={Object.keys(
                                    groupBy(section.dataSource, 'currency')
                                  )}
                                  renderItem={(currency) => (
                                    <List.Item>
                                      <Link to={section.linkTo(currency)}>
                                        {section.text(currency)}
                                      </Link>
                                    </List.Item>
                                  )}
                                />
                              )
                            )
                          })}
                        </>
                      )}

                      {checkListCompleted && (!isAdmin || !hasAdminItems) && (
                        <p>Everything done for now.</p>
                      )}
                    </Widget>
                  )}

                  {this.props.participant && (
                    <Widget
                      style={{ margin: 5 }}
                      data-test-id="net-delivery-obligations"
                      title="Net Delivery Obligations"
                    >
                      {this.props.netDeliveryObligations.value.length === 0 &&
                        !this.props.netDeliveryObligations.loading && (
                          <p>
                            You have no outstanding net delivery obligations.
                          </p>
                        )}
                      {(this.props.netDeliveryObligations.value.length > 0 ||
                        this.props.netDeliveryObligations.loading) && (
                        <Table
                          rowKey={() => short().generate()}
                          dataSource={this.props.netDeliveryObligations.value}
                          loading={this.props.netDeliveryObligations.loading}
                          columns={[
                            {
                              title: 'Platform',
                              dataIndex: 'accountGroup',
                              render: (_, v) =>
                                `${v.participantName || ''} (${v.accountGroup})`
                            },
                            { title: 'Asset', dataIndex: 'asset' },
                            {
                              title: 'NDO Amount',
                              dataIndex: 'amount',
                              render: (v, { asset }) =>
                                currencyFormatter(v, asset)
                            },
                            {
                              title: 'Value (USD)',
                              dataIndex: 'value',
                              render: (v) => currencyFormatter(v, 'USD')
                            }
                          ]}
                          pagination={false}
                        />
                      )}
                      <Link
                        style={{
                          display: 'block',
                          marginTop: 12,
                          width: 'fit-content'
                        }}
                        to="balances"
                      >
                        Explore my balances in detail
                      </Link>
                    </Widget>
                  )}

                  {this.props.participant && (
                    <BalancesWidget {...this.props}></BalancesWidget>
                  )}
                </WidgetsStack>
              )}
            </div>
          </div>

          {(!showSetupChecklist || showWidgets) && this.props.participant && (
            <Widget
              style={{ width: 'fit-content', margin: 5 }}
              data-test-id="portfolio"
              title="Portfolio"
            >
              {!this.props.portfolio.loading && (
                <p>
                  Total portfolio value:{' '}
                  <span style={{ fontWeight: 600 }}>
                    $
                    {currencyFormatter(
                      this.props.portfolio.value.reduce(
                        (acc, { value }) => acc + (value || 0),
                        0
                      ),
                      'USD'
                    )}
                  </span>
                </p>
              )}
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '100%',
                  margin: 'auto'
                }}
              >
                <Table
                  rowKey={() => short().generate()}
                  onRow={(record, rowIndex) => ({
                    onMouseEnter: () => {
                      setActiveIndex(rowIndex)
                    }
                  })}
                  style={{ marginTop: 20, width: '100%' }}
                  dataSource={portfolioData}
                  loading={this.props.portfolio.loading}
                  columns={[
                    { title: 'Asset', dataIndex: 'asset' },
                    {
                      title: 'Amount',
                      dataIndex: 'amount',
                      render: (v, { asset }) => currencyFormatter(v, asset)
                    },
                    {
                      title: 'Value (USD)',
                      dataIndex: 'value',
                      render: (v) => (!!v ? currencyFormatter(v, 'USD') : '')
                    },
                    {
                      title: 'Portfolio %',
                      dataIndex: 'part',
                      render: (v) => (!!v ? `${(100 * v).toFixed(2)}%` : '')
                    }
                  ]}
                  pagination={false}
                />
                <DashboardPortfolioChart
                  portfolioDataChart={portfolioDataChart}
                  activeIndex={this.state.activeIndex}
                  setActiveIndex={(activeIndex: number) =>
                    this.setState({ activeIndex })
                  }
                />
              </div>
              <Link to={`/${this.props.participant.code}/balances`}>
                Explore my portfolio
              </Link>
            </Widget>
          )}
        </div>
      </WidgetsContainer>
    )
  }
}

export default connector(DashboardPage)
