import React, { useState } from 'react'
import * as moment from 'moment'
import {
  Button,
  Modal,
  Input,
  DatePicker,
  Popconfirm,
  List,
  notification,
  Switch
} from 'antd'
import { isEmpty } from 'lodash'
import '@ant-design/compatible/assets/index.css'
import { Form } from '@ant-design/compatible'
import isIP from 'is-ip'

import RestService from '../../../RestService'
import { IParticipant } from 'seed-shared-components/lib/types'
import Title from 'seed-shared-components/lib/components/Title'
import validateWithdrawalsAccountLabel from '../../validateWithdrawalsAccountLabel'
import PermissionsGroupSelect from './PermissionsGroupSelect'
import ApiKeyDetails from './ApiKeyDetails'
import WithdrawalsLabelInfo from './WithdrawalsLabelInfo'
import { IApiKeyPermission } from '../../types'
import { IP_LIMIT } from '../../../constants'

import showErrorWithRequestId from '../../../api/showErrorWithRequestId'
import {
  DeleteOutlined,
  EnvironmentOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  WarningOutlined
} from '@ant-design/icons'
import { IAuthUser } from '../../../auth/WithAuthentication'

interface IAddApiKeyComponentProps {
  restService: RestService
  permissions: IApiKeyPermission[]
  participant: IParticipant
  loadApiKeys: () => Promise<void>
  authUser: IAuthUser
  marketDataEnabled: boolean
  participantSanctionScreeningInfoEnabled: boolean
  participantFullInfoEnabled: boolean
}

const partionList = (a: string[]) =>
  a.reduce(([p, f], e: string) => (isIP(e) ? [[...p, e], f] : [p, [...f, e]]), [
    [],
    []
  ])

const NewApiKeyModal = (props: IAddApiKeyComponentProps) => {
  const [showModal, setShowModal] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [name, setName] = useState<string>()
  const [passphrase, setPassphrase] = useState<string>()
  const [expire_at, setExpires_at] = useState()
  const [permissions, setPermissions] = useState<{
    [resource: string]: number[]
  }>({})
  const [transfersUsdOnly, setTransferUsdOnly] = useState(false)
  const [
    withdrawals_account_label,
    setWithdrawals_account_label
  ] = useState<string>()
  const [ipAddress, setIpAddress] = useState('')
  const [allowedIPs, setAllowedIPs] = useState([])
  const [allowedIPListEnabled, setAllowedIPListEnabled] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [apiKey, setApiKey] = useState()

  const handleSubmit = async () => {
    setSubmitting(true)
    const { participant } = props

    let resourcePermissions = Object.keys(permissions).map((resource) => {
      if (permissions[resource].length === 1) {
        return `${resource}_read`
      }
      if (permissions[resource].length === 2) {
        return `${resource}_write`
      }
      return ''
    })
    resourcePermissions = resourcePermissions.filter((e) => e)
    const payload = {
      name,
      passphrase,
      transfers_usd_only: transfersUsdOnly,
      participant_code: participant.code,
      permissions: Object.values(permissions).reduce((acc: any, perms) => {
        return acc.concat(perms)
      }),
      withdrawals_account_label,
      allowed_ips: allowedIPListEnabled ? allowedIPs : null,
      resource_permissions: resourcePermissions
    } as any

    if (!!expire_at) {
      payload.expire_at = +moment(expire_at).endOf('day')
    }

    try {
      const { data } = await props.restService
        .route(`api_keys`)
        .post(payload, { omitNotification: true })
      setTransferUsdOnly(false)
      setApiKey(data)
      setExpires_at(undefined)
      setIpAddress('')
      setAllowedIPs([])
      setAllowedIPListEnabled(false)
      setShowDetails(true)

      setTimeout(() => props.loadApiKeys(), 250)
    } catch (err) {
      showErrorWithRequestId({
        message:
          err.response?.data?.error ??
          'Something went wrong, please try again later.',
        error: err
      })
    }
    setSubmitting(false)
  }

  const getPermissions = () => {
    const localPermissions = {}
    props.permissions
      .filter((permission) => permission.type === 'API_KEY')
      .map((permission) => ({
        ...permission,
        definition: {
          ...permission.definition,
          resource: permission.definition.resource.replace('/', '')
        }
      }))
      .forEach((permission) => {
        const resource = permission.definition.resource
        if (!(resource in localPermissions)) {
          localPermissions[resource] = {
            resource
          }
        }

        let permissionSimple = { ...localPermissions[resource] }
        if (permission.definition.action === 'read') {
          permissionSimple = { ...permissionSimple, readId: permission.id }
        } else {
          permissionSimple = { ...permissionSimple, writeId: permission.id }
        }
        localPermissions[resource] = permissionSimple
      })

    return Object.values(localPermissions)
  }

  const onPermissionsChange = ({
    resource,
    values,
    withdrawalsAccountLabel
  }: {
    resource: string
    values: number[]
    withdrawalsAccountLabel?: string
  }) => {
    const localPermissions = { ...permissions }
    localPermissions[resource] = values

    if (typeof withdrawalsAccountLabel === 'string') {
      setWithdrawals_account_label(withdrawalsAccountLabel)
    }
    setPermissions(localPermissions)
  }

  const getPermissionsByCategory = () => {
    const zeroHashPermissions = getPermissions().filter(
      (permission: any) => !['orders', 'fills'].includes(permission.resource)
    )

    return {
      zeroHashPermissions
    }
  }

  const handleExpirationDateChange = (mDate: moment.Moment) =>
    setExpires_at(mDate as any)

  const handleClose = () => {
    props.loadApiKeys()
    setShowModal(false)
    setSubmitting(false)
    setTransferUsdOnly(false)
    setName(undefined)
    setPassphrase(undefined)
    setApiKey(undefined)
    setExpires_at(undefined)
    setPermissions({})
    setIpAddress('')
    setAllowedIPs([])
    setAllowedIPListEnabled(false)
  }

  const handleCancel = () => {
    setShowModal(false)
    setSubmitting(false)
    setTransferUsdOnly(false)
    setName(undefined)
    setPassphrase(undefined)
    setApiKey(undefined)
    setExpires_at(undefined)
    setPermissions({})
    setIpAddress('')
    setAllowedIPs([])
    setAllowedIPListEnabled(false)
  }

  const onTransfersUsdOnlyChange = (value) => setTransferUsdOnly(value)

  const addIPAddress = () => {
    const splittedList = ipAddress
      ?.split(/[ ,]+/)
      ?.filter((entry: string) => /\S/.test(entry))

    const [validList, failedList] = partionList(splittedList)

    if (!isEmpty(validList)) {
      const ipList = [...new Set([...allowedIPs, ...validList])]

      if (ipList.length > IP_LIMIT) {
        notification.error({
          message: 'Error',
          description: `The limit of allowed IPs is ${IP_LIMIT}, please contact support if you need to add more IPs.`
        })
      } else {
        setAllowedIPs(ipList)
        setIpAddress('')
      }
    }
    if (!isEmpty(failedList)) {
      notification.error({
        message: 'Error',
        description: `${[
          ...new Set(failedList)
        ].toString()} is not a valid IP address`
      })
      console.log('IP address is not valid')
    }
  }

  const removeIPAddress = (ip: string) => {
    const ipList = allowedIPs.filter((ipAddress) => ipAddress !== ip)
    setAllowedIPs(ipList)
  }

  const todayDate = moment().endOf('day').toDate()
  const isWithdrawalsAccountLabelValid = validateWithdrawalsAccountLabel(
    withdrawals_account_label
  )

  const isValidAllowedIps = allowedIPListEnabled ? !!allowedIPs?.length : true

  const isOkButtonDisabled =
    !isValidAllowedIps ||
    !(name && name.length) ||
    !(passphrase && passphrase.length) ||
    !expire_at ||
    !(
      permissions &&
      Object.values(permissions).reduce(
        (previous: any, current: any) => previous.concat(current),
        []
      ).length
    ) ||
    !isWithdrawalsAccountLabelValid
  const showPopconfirmWithdrawalsAccountLabel =
    !apiKey && !!withdrawals_account_label && isWithdrawalsAccountLabelValid

  return (
    <div>
      <Button
        data-test-id="api-key-add"
        onClick={() => setShowModal(true)}
        type="primary"
      >
        Add API Key
      </Button>

      {showModal && !apiKey && (
        <Modal
          data-test-id="api-key-new-modal"
          visible={true}
          width={600}
          closable={false}
          maskClosable={false}
          title={
            <Title level={3} style={{ marginTop: 16 }}>
              Add API Key
            </Title>
          }
          onCancel={!submitting ? handleCancel : undefined}
          footer={[
            <Button
              style={{ marginRight: 5 }}
              key="cancel"
              type="default"
              disabled={apiKey || submitting}
              onClick={handleCancel}
            >
              Cancel
            </Button>,
            showPopconfirmWithdrawalsAccountLabel ? (
              <Popconfirm
                title={<WithdrawalsLabelInfo />}
                onConfirm={handleSubmit}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  data-test-id="api-key-modal-ok"
                  key="ok"
                  danger={apiKey}
                  type="primary"
                  disabled={isOkButtonDisabled}
                  loading={submitting}
                >
                  Ok
                </Button>
              </Popconfirm>
            ) : (
              <Button
                data-test-id="api-key-modal-ok"
                key="ok"
                danger={apiKey}
                type="primary"
                disabled={isOkButtonDisabled}
                loading={submitting}
                onClick={apiKey ? handleClose : handleSubmit}
              >
                {apiKey ? 'Exit' : 'Ok'}
              </Button>
            )
          ]}
        >
          <Form layout="vertical">
            <Form.Item label="Nickname" required={true}>
              <Input
                data-test-id="api-key-input-nickname"
                placeholder="API Key Nickname"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Passphrase" required={true}>
              <Input
                data-test-id="api-key-input-passphrase"
                placeholder="Your Unique Passphrase"
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                value={passphrase}
                onChange={(e) => setPassphrase(e.target.value)}
              />
            </Form.Item>

            <Form.Item label="Expiration date" required={true}>
              <DatePicker
                data-test-id="api-key-date-expiration"
                defaultValue={expire_at as any}
                format={'MM/DD/YYYY'}
                disabledDate={(currentDate: moment.Moment) => {
                  return currentDate.toDate() <= todayDate
                }}
                onChange={handleExpirationDateChange}
              />
            </Form.Item>

            <Form.Item label="Allowed IPs">
              <div>
                <Switch
                  data-test-id="api-key-switch-ip"
                  onChange={() =>
                    setAllowedIPListEnabled(!allowedIPListEnabled)
                  }
                />
                <span style={{ marginLeft: '5px' }}>Enable IP allow list</span>
              </div>
              <Input
                data-test-id="api-key-input-ip"
                disabled={!allowedIPListEnabled}
                placeholder="IP address 192.168.0.1"
                prefix={
                  <EnvironmentOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                value={ipAddress}
                onChange={(e) => setIpAddress(e.target.value)}
                addonAfter={
                  <>
                    <Button
                      disabled={!allowedIPListEnabled}
                      style={{
                        height: 'fit-content',
                        padding: '0 5px',
                        marginRight: '5px'
                      }}
                      onClick={addIPAddress}
                    >
                      <PlusOutlined />
                    </Button>
                    <Popconfirm
                      title="Do you want to remove ALL allowed IPs?"
                      okText="Yes"
                      cancelText="No"
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      disabled={
                        !allowedIPListEnabled || allowedIPs.length === 0
                      }
                      onConfirm={() => setAllowedIPs([])}
                    >
                      <Button
                        disabled={
                          !allowedIPListEnabled || allowedIPs.length === 0
                        }
                        danger
                        style={{
                          height: 'fit-content',
                          padding: '0 5px'
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  </>
                }
              />

              {allowedIPListEnabled && (
                <>
                  {allowedIPs.length === 0 ? (
                    <div style={{ marginTop: '5px' }}>
                      <WarningOutlined />
                      Only calls made by the specified IP addresses will be
                      permitted. All others will be rejected.
                    </div>
                  ) : (
                    <List
                      style={{
                        overflow: 'auto',
                        marginTop: '20px',
                        maxHeight: '100px'
                      }}
                      size="small"
                      bordered
                      dataSource={allowedIPs}
                      renderItem={(item) => (
                        <List.Item
                          key={item}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                          }}
                        >
                          <div>{item}</div>
                          <div>
                            <DeleteOutlined
                              onClick={() => removeIPAddress(item)}
                            />
                          </div>
                        </List.Item>
                      )}
                    ></List>
                  )}
                </>
              )}
            </Form.Item>

            {getPermissionsByCategory().zeroHashPermissions.map(
              (permission: any, idx) => (
                <Form.Item
                  key={idx}
                  label={idx === 0 ? 'API Permissions' : undefined}
                  style={{ paddingBottom: 0, marginBottom: 0 }}
                >
                  <PermissionsGroupSelect
                    key={permission.resource}
                    resource={permission.resource}
                    readId={permission.readId}
                    writeId={permission.writeId}
                    onChange={onPermissionsChange}
                    transfersUsdOnly={transfersUsdOnly}
                    onTransfersUsdOnlyChange={onTransfersUsdOnlyChange}
                    authUser={props.authUser}
                    participant={props.participant}
                    marketDataEnabled={props.marketDataEnabled}
                    participantSanctionScreeningInfoEnabled={
                      props.participantSanctionScreeningInfoEnabled
                    }
                    participantFullInfoEnabled={
                      props.participantFullInfoEnabled
                    }
                  />
                </Form.Item>
              )
            )}
          </Form>
        </Modal>
      )}
      {showDetails && (
        <Modal
          title={
            <Title level={3} style={{ marginTop: 16 }}>
              API Key Details
            </Title>
          }
          visible={showDetails}
          closable={false}
          onOk={() => {
            setShowDetails(false)
            handleCancel()
          }}
          cancelButtonProps={{
            style: {
              display: 'none'
            }
          }}
          width={600}
        >
          <ApiKeyDetails apiKey={apiKey} />
        </Modal>
      )}
    </div>
  )
}
export default NewApiKeyModal
