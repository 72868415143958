import * as React from 'react'

import { Tooltip } from 'antd'
import { Link } from 'react-router-dom'

interface IMenuItemProps {
  disabled?: boolean
  title: string
  link: string
  tooltipText?: string | JSX.Element
}

export default function MenuItem(props: IMenuItemProps) {
  return props.disabled ? (
    <Tooltip
      placement="right"
      title={
        props.tooltipText ||
        'Access restricted due to insufficient user privileges.'
      }
    >
      {props.title}
    </Tooltip>
  ) : (
    <Link to={props.link}>{props.title}</Link>
  )
}
