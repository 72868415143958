export type NetDeliveryObligation = {
  asset: string
  amount: number
  accountGroup: string
  participantName?: string
}

const domain = `net-delivery-obligations`

export const FETCH_NET_DELIVERY_OBLIGATIONS = `${domain}/FETCH_NET_DELIVERY_OBLIGATIONS`
export const SET_NET_DELIVERY_OBLIGATIONS = `${domain}/SET_NET_DELIVERY_OBLIGATIONS`

export interface IFetchNetDeliveryObligationsAction {
  type: typeof FETCH_NET_DELIVERY_OBLIGATIONS
  payload: {
    participantCode: string
  }
}

export interface ISetNetDeliveryObligationsAction {
  type: typeof SET_NET_DELIVERY_OBLIGATIONS
  payload: {
    value?: NetDeliveryObligation[]
    loading?: boolean
  }
}
