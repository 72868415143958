export interface Signature {
  id: number
  participant_application_id?: number
  user_id?: number
  updated_at: string // "2018-09-26T19:57:06.455633"
  email?: string
}

// this type is created with javascript in participant-service
export type ApplicationStatus =
  | 'not_started'
  | 'in_progress'
  | 'waiting_for_signers'
  | 'waiting_for_seed'

export interface IParticipantApplicationStatus {
  submittedAt: null | string
  numberOfAuthSigners: number
  numberOfSignaturesCurrently: number
  numberOfSignaturesRequired: number
  currentUserHasSigned: boolean
  status: ApplicationStatus
}

export interface Application {
  id: string
  participant_id: string
  payload: ApplicationPayload
  created_at: string
  updated_at: string
  user_id: string
}

export interface Files {
  [filename: string]: UploadedFile
  // error: boolean;
}

export interface UploadedFile {
  progress: number
  state: string
  size: number
  type: string
  uploadedName: string // "/user_uploads/45293db9-541c-4ad7-a770-db68b6563c26",
  signedUrl: string
  bucket?: string // applicaitons from P2
  created_at?: number // applications from P2
}

interface DisclosureAffirmation {
  true?: true
  false?: true
}

export enum orgType {
  corp = 'Corporation',
  llc = 'Limited Liability Company',
  partnership = 'Partnership',
  other = 'Other'
}

export enum netAssets {
  '< $1,000,000' = 1,
  '$1,000,000 - $4,999,999',
  '$5,000,000 - $9,999,999',
  '$10,000,000+'
}

export enum incomeMap {
  '$0 - $100,000' = 1,
  '$100,000 - $249,999',
  '$250,000 - $499,999',
  '$500,000 - $999,999',
  '$1,000,000 - $4,999,999',
  '$5,000,000 - $9,999,999',
  '$10,000,000 - '
}

export enum eligibilitiesPretty {
  brokerdealer = 'A registered broker-dealer',
  brokerdealer_associate = 'An associated person of a registered broker or dealer engaged in the financial services activity of such broker or dealer',
  business_10m = 'A business with at least $10,000,000 in assets',
  business_ecp = 'A business guaranteed by an entity with at least $10,000,000 in assets',
  business_hedge = 'A business that is using Seed SEF to hedge risk and has a net worth of at least $1,000,000 (risks must arise in the ordinary course of commercial business, such as by acting as a market maker on Seed SEF or its affiliate. An entity entering hedging positions solely as part of an overall investment strategy would not qualify).',
  ebp = 'An employee benefit plan subject to the Employee Retirement Income Security Act (ERISA), a governmental employee benefit plan, or a foreign person performing a similar role or function',
  ebp_5m = 'An employee benefit plan with assets exceeding $5,000,000',
  ebp_advisor = 'An employee benefit plan with decisions made by an investment adviser, commodity trading advisor, financial institution, insurance company, or foreign entity performing a similar role',
  financial = 'A financial institution',
  financial_agreement = 'An “agreement corporation”',
  financial_edge = 'An “Edge Act corporation”',
  financial_farm_credit = 'An institution regulated by the Farm Credit Administration',
  financial_credit_union = 'A federal credit union or state credit union',
  financial_depository = 'A depository institution',
  financial_foreign = 'A foreign bank or a branch or agency of a foreign bank',
  financial_holding = 'Any financial holding company',
  financial_trust = 'A trust company',
  floor_broker = 'A floor broker or floor trader subject to regulation under the Commodity Exchange Act',
  fut_merchant = 'A futures commission merchant subject to regulation under the Commodity Exchange Act',
  govt = 'A governmental or multinational entity, or one of its agencies or departments',
  ibank = 'An investment bank holding company (as defined by the Securities Exchange Act of 1934)',
  ind_10m = 'An individual with assets exceeding $10,000,000',
  ind_hedge = 'An individual that is using Seed SEF to hedge risk and has $5,000,000 or more in discretionary investments',
  insurance = 'An insurance company regulated by a U.S. state or foreign government',
  investment = 'An investment company',
  investment_company = 'An investment company subject to regulation under the Investment Company Act of 1940',
  investment_foreign = 'A foreign person performing a similar role or function as an investment company and subject as such to foreign regulation',
  pool = 'A commodity pool with a registered operator and total assets exceeding $5,000,000 (all participants in the commodity pool must also be ECPs)',
  pool_10m = 'A commodity pool that (1) has total assets exceeding $10,000,000, (2) is not formed for the purpose of evading section 2(c)(2)(B) or section 2(c)(2)(C) of the Commodity Exchange Act or related rules, regulations or orders, and (3) is formed and operated by a registered or exempted commodity pool operator',
  major_swap_participant = 'A Major Swap Participant',
  swap_dealer = 'A Swap Dealer',
  major_security_based_swap_participant = 'A Major Security Based Swap Participant',
  security_based_swap_participant = 'A Security Based Swap Participant',
  does_not_qualify = 'Applicant does not meet any of the qualifications listed',
  scxm = 'An individual, business, corporation, partnership, proprietorship, or trust with assets exceeding $1,000,000',
  scxm_guaranteed = 'An individual, business, corporation, partnership, proprietorship, or trust, the obligations of which are guaranteed by an individual, business, corporation, partnership, proprietorship, or trust with assets exceeding $1,000,000',
  meets_ecp = 'Applicant meets one or more ECP qualification above'
}

export enum eligibilities {
  brokerdealer = 'brokerdealer',
  brokerdealer_associate = 'brokerdealer_associate',
  business_10m = 'business_10m',
  business_ecp = 'business_ecp',
  business_hedge = 'business_hedge',
  ebp = 'ebp',
  ebp_5m = 'ebp_5m',
  ebp_advisor = 'ebp_advisor',
  financial = 'financial',
  financial_agreement = 'financial_agreement',
  financial_edge = 'financial_edge',
  financial_farm_credit = 'financial_farm_credit',
  financial_credit_union = 'financial_credit_union',
  financial_depository = 'financial_depository',
  financial_foreign = 'financial_foreign',
  financial_holding = 'financial_holding',
  financial_trust = 'financial_trust',
  floor_broker = 'floor_broker',
  fut_merchant = 'fut_merchant',
  govt = 'govt',
  ibank = 'ibank',
  ind_10m = 'ind_10m',
  ind_hedge = 'ind_hedge',
  insurance = 'insurance',
  investment = 'investment',
  investment_company = 'investment_company',
  investment_foreign = 'investment_foreign',
  pool = 'pool',
  pool_10m = 'pool_10m',
  major_swap_participant = 'major_swap_participant',
  swap_dealer = 'swap_dealer',
  major_security_based_swap_participant = 'major_security_based_swap_participant',
  security_based_swap_participant = 'security_based_swap_participant',
  does_not_qualify = 'does_not_qualify',
  scxm = 'scxm',
  scxm_guaranteed = 'scxm_guaranteed',
  meets_ecp = 'meets_ecp'
}

export type Eligibility = { [eligibility in eligibilities]?: boolean }

export type mapOrEnumProps =
  | 'org_type'
  | 'fin_income'
  | 'net_assets'
  | 'eligibility'

export interface ApplicationPayload {
  eligibility: Eligibility
  disclosure_affirmation: DisclosureAffirmation
  review: DisclosureAffirmation
  number_of_beneficial_owners: number
  entity_type: EntityType
  number_of_authorized_signers: number
  legal_name: string
  address_line1: string
  address_city: string
  address_state: string
  address_zip: string
  address_country: string
  trading_experience: string
  bankruptcy: string
  bankruptcy_year: string
  fin_income: incomeMap
  net_assets: netAssets
  source_of_funds: string
  legal_officer: string
  us_person_dodd: string
  entity_dodd: string
  swap_dealer: string
  major_swap_participant: string
  nfa: string
  nfa_id: string
  finra: string
  CRD_id: string
  email: string
  address_line2: string
  contact_number: string
  ind_photo_id: string
  eligibility_proof: Files
  photo_id: Files
  proof_address: Files
  // not found in business payloads
  ind_occupation: string
  individual_lei?: string
  ind_dob: string
  confirm_not_acting_in_business_capacity?: string
  //  not found in individual payloads:
  legal_disqual?: string
  org_website?: string
  org_type?: orgType
  org_state?: string
  tax_id_i?: string
  tax_id_b?: string
  business_lei?: string
  articles_incorporation?: Files
  cert_good_standing?: Files
  bylaws?: Files
  org_established?: string
  authorized_signers?: AuthorizedSigner[]
  beneficial_owners?: BeneficialOwner[]
  control_persons?: ControlPerson[]
  passport_country?: string
  passport_document?: Files
}

export type ApplicationFileProp =
  | 'eligibility_proof'
  | 'photo_id'
  | 'proof_address'
  | 'articles_incorporation'
  | 'cert_good_standing'
  | 'bylaws'

export type ApplicationMapProperties =
  | 'eligibility'
  | 'disclosure_affirmation'
  | 'review'

export interface IDate {
  month: string // "08"
  day: string // "26"
  year: string // "1999"
}

export interface IBasePerson {
  id: string
  sort_timestamp: number
  name?: string
  dob?: IDate
  ssn?: string
  united_states_ssn?: boolean
  passport_country?: string
  passport_document?: Files
  type?: { [personType in personTypes]?: boolean }
}

export interface ControlPerson extends IBasePerson {
  title: string
  address?: string
  address_line1?: string
  address_line2?: string
  address_city?: string
  address_state?: string
  address_zip?: string
  address_country?: string
}

export interface AuthorizedSigner extends IBasePerson {
  email: string
  phone: string
  title: string
  contact_person?: boolean
}

export interface BeneficialOwner extends IBasePerson {
  address?: string
  address_line1?: string
  address_line2?: string
  address_city?: string
  address_state?: string
  address_zip?: string
  address_country?: string
}

export enum personTypes {
  control_person = 'Control Person',
  authorized_signer = 'Authorized Signer',
  beneficial_owner = 'Beneficial Owner'
}

export type Person = BeneficialOwner | ControlPerson | AuthorizedSigner

export type EntityType = 'individual' | 'business'

export interface Desk {
  id: string
  participant_id: string
  code: string
  nickname: string
  limit: number
  is_active: boolean
  created_at: string
  updated_at: string
  trading_accounts: TradingAccount[]
}

export interface TradingAccount {
  id: string
  trading_desk_id: string
  code: string
  cti: number
  is_active: boolean
  created_at: string
  updated_at: string
  user_id: string
  connection_id: string
  user: User
}

export interface User {
  id: string
  email: string
  name: string
  user_created_at: string
  participant_users_id: string
  participant_users_created_at: string
  participant_users_updated_at: string
  is_admin: boolean
  is_authorized_signer: boolean
}

export interface Connection {
  id: string
  participant_id: string
  port: number
  comp_id: string
  created_at: string
  updated_at: string
  connection_type: string
}

export interface Agreement {
  id: string
  agreement_id: number
  user_id: number
  email: string
  name: string
  participant_agreement_signatures_created_at: string
  agreement_name: string
  document_url: string
  agreement_active: boolean
  agreement_created_at: string
  agreement_updated_at: string
}
