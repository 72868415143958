import { IParticipantState } from './state'
import * as ParticipantActions from './actions'
import { createReducer } from '@reduxjs/toolkit'
import {
  ILoadParticipantDetailsAction,
  ILoadParticipantAction,
  ILoadPlatformOperatorsAction
} from './actions/types'

const participantInitialState: IParticipantState = {
  participant: null,
  loading: false,
  details: null,
  isPlatformOperator: null
}

const participant = createReducer(participantInitialState, {
  [ParticipantActions.loadParticipant.type]: (
    state,
    action: ILoadParticipantAction
  ) => {
    if (action.payload.participant) {
      return { ...state, participant: action.payload.participant }
    }

    return state
  },
  [ParticipantActions.loadIsPlatformOperator.type]: (
    state,
    action: ILoadPlatformOperatorsAction
  ) => {
    return {
      ...state,
      isPlatformOperator: action.payload.isPlatformOperator
    }
  },
  [ParticipantActions.loadParticipantDetails.type]: (
    state,
    action: ILoadParticipantDetailsAction
  ) => ({
    ...state,
    loading: false,
    details: action.payload.details
  }),
  [ParticipantActions.selectParticipant.type]: (state) => ({
    ...state,
    participant: null,
    details: null,
    hasAccess: null,
    loading: true
  })
})

export default participant
