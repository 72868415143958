import { IMovements } from './types'
import portalApi from '../../api/portalApi'

export const fetchMovements = async ({
  token = '',
  participantCode,
  accountId,
  version,
  page,
  pageSize
}: {
  token: string
  participantCode: string
  accountId: string
  version: string
  page?: number
  pageSize?: number
}): Promise<IMovements> => {
  const { data } = await portalApi.get(
    `${participantCode}/withdrawal_accounts/${accountId}/movements/${version}`,
    {
      params: {
        page,
        pageSize
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}
