import React from 'react'
import { Steps } from 'antd'
import { Link } from 'react-router-dom'

const Step = Steps.Step

// TODO: cleanup component props and related logic
const DashboardStepsComponent = ({
  currentStep,
  steps,
  returnIconProp,
  isAuthSigner,
  isAdmin,
  application,
  participantName,
  fundStepStatus,
  fundStepIcon,
  setupAccountNextStep
}) => (
  <Steps direction="vertical" current={currentStep}>
    <Step
      data-testid="step-create-participant"
      title={
        currentStep === steps['createParticipant'] ? (
          <Link
            data-testid="create-new-participant"
            id="create_new_participant"
            to={setupAccountNextStep}
          >
            Create Participant and Apply
          </Link>
        ) : (
          'Create Participant and Apply'
        )
      }
      {...returnIconProp(currentStep, steps['createParticipant'])}
      description="The first step toward trading"
    />
    <Step
      data-testid="step-seed_approval"
      title="Participant Approval"
      description="Approval by Zero Hash"
      {...returnIconProp(currentStep, steps['seedApproval'])}
    />
  </Steps>
)

export default DashboardStepsComponent
