import * as React from 'react'
import { Menu, Row, Col } from 'antd'
import { FiatAddress } from 'seed-ts/types/banks'

interface IBanksListAndDetailsProps {
  asset?: string
  addresses: FiatAddress[]
}

const pdfLinks = {
  JPY:
    'https://s3-us-west-2.amazonaws.com/seedcx.com/assets/BMOHB_MCA-JapaneseYen.pdf',
  EUR:
    'https://s3-us-west-2.amazonaws.com/seedcx.com/assets/BMOHB_MCA-Euro.pdf',
  CAD:
    'https://s3-us-west-2.amazonaws.com/seedcx.com/assets/BMOHB_MCA-CanadianDollar.pdf',
  GBP:
    'https://s3-us-west-2.amazonaws.com/seedcx.com/assets/BMOHB_MCA-BritishPound_0713_v2.pdf',
  ZAR:
    'https://s3-us-west-2.amazonaws.com/seedcx.com/assets/BMOHB_MCA-SouthAfricanRand.pdf'
}
interface IBanksListAndDetailsState {
  selectedBank?: FiatAddress
  selectedKey?: string
}
export default class BanksListAndDetails extends React.Component<
  IBanksListAndDetailsProps,
  IBanksListAndDetailsState
> {
  constructor(props: IBanksListAndDetailsProps) {
    super(props)
    this.state = {}
    if (props.addresses && props.addresses.length >= 1) {
      this.state = {
        selectedBank: props.addresses[0],
        selectedKey: props.addresses[0].shortName
      }
    }
    this.onSelect = this.onSelect.bind(this)
  }
  componentDidUpdate(previousProps: IBanksListAndDetailsProps) {
    if (
      this.props.addresses &&
      this.props.addresses.length >= 1 &&
      this.props.addresses !== previousProps.addresses
    ) {
      this.setState({
        selectedBank: this.props.addresses[0],
        selectedKey: this.props.addresses[0].shortName
      })
    }
  }
  onSelect(selectedParam: { key: any }) {
    this.setState(() => ({
      selectedKey: selectedParam.key,
      selectedBank: this.props.addresses.find(
        (address) => address.shortName === selectedParam.key
      )
    }))
  }
  render() {
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div>
            <Menu
              mode="inline"
              style={{ width: 160 }}
              onSelect={this.onSelect}
              selectedKeys={[this.state.selectedKey || '']}
            >
              {this.props.addresses.map((address, i) => (
                <Menu.Item key={address.shortName}>
                  {address.shortName}
                </Menu.Item>
              ))}
            </Menu>
          </div>
          {this.state && this.state.selectedBank
            ? tableContent(this.state.selectedBank, this.props.asset)
            : null}
        </div>
      </>
    )
  }
}

const tableContent = (bank: FiatAddress, asset?: string) => {
  const rowProps: any = {
    type: 'flex',
    justify: 'center',
    align: 'bottom',
    style: { marginBotton: 4 }
  }
  const row = (key: string, value?: string | JSX.Element) => (
    <Row gutter={16} {...rowProps}>
      <Col span={24}>
        <span className="bold-text">{key}</span>
        {`: `}
        {value}
      </Col>
    </Row>
  )
  const displayByType = {
    united_states_wire: (
      <>
        {row('Bank Name', bank.bankName)}
        {row('Bank Address', bank.bankPhysicalAddress)}
        <br />
        {row('Recipient Name', bank.recipientName)}
        {row('Recipient Address', bank.recipientPhysicalAddress)}
        <br />

        {row('Account Number', bank.accountNumber)}
        {row('Routing Number', bank.routingNumber)}
        <br />
        {row(
          'Recipient Instructions/Message',
          <span style={{ color: 'red', fontWeight: 700 }}>
            {bank.reference}
          </span>
        )}
      </>
    ),
    international_wire: (
      <>
        {row('Bank Name', bank.bankName)}
        {row('Bank Address', bank.bankPhysicalAddress)}
        <br />
        {row('Recipient Name', bank.recipientName)}
        {row('Recipient Address', bank.recipientPhysicalAddress)}
        <br />

        {row('Account Number', bank.accountNumber)}
        {row('Routing Number', bank.routingNumber)}
        {row('SWIFT Code', bank.swift)}
        <br />

        {bank.correspondentBankName
          ? row('Correspondent Bank Name', bank.correspondentBankName)
          : null}
        {bank.correspondentSwift
          ? row('Correspondent SWIFT code', bank.correspondentSwift)
          : null}
        {bank.correspondentBranchName
          ? row('Correspondent Branch Name', bank.correspondentBranchName)
          : null}
        {bank.correspondentBranchAddress
          ? row('Correspondent Branch Address', bank.correspondentBranchAddress)
          : null}
        {bank.correspondentAccountNumber
          ? row('Correspondent Account Number', bank.correspondentAccountNumber)
          : null}

        {bank.correspondentBankName ||
        bank.correspondentSwift ||
        bank.correspondentAccountNumber ? (
          <br />
        ) : null}

        {row(
          'Recipient Instructions/Message',
          <span style={{ color: 'red', fontWeight: 700 }}>
            {bank.reference}
          </span>
        )}

        {asset && pdfLinks[asset]
          ? row(
              'Bank Instructions',
              <a href={pdfLinks[asset]} target="_blank">
                Download PDF
              </a>
            )
          : null}
      </>
    ),
    internal_transfer: (
      <>
        {row('Bank Name', bank.bankName)}
        {row('Bank Address', bank.bankPhysicalAddress)}
        <br />
        {row('Recipient Name', bank.recipientName)}
        <br />
        {row(
          bank.bankName === 'CUBIX' ? 'Account Number' : 'Wallet Address',
          bank.accountNumber
        )}
        <br />
        {row(
          bank.bankName === 'CUBIX'
            ? 'Public description'
            : 'Recipient Instructions/Message',
          <span style={{ color: 'red', fontWeight: 700 }}>
            {bank.reference}
          </span>
        )}
      </>
    )
  }
  return <div style={{ marginLeft: 24 }}>{displayByType[bank.type]}</div>
}
