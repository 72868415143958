import { Button, ButtonProps } from 'antd'
import React from 'react'

interface KycButtonProps extends ButtonProps {
  primaryColor?: string
  textColor?: string
  buttonProps?: ButtonProps
  children?: React.ReactNode
}

const KycButton = ({
  primaryColor,
  textColor,
  children = 'Submit',
  ...buttonProps
}: KycButtonProps) => {
  return (
    <Button
      type="primary"
      {...buttonProps}
      style={{
        backgroundColor: primaryColor || '#2253FF',
        borderColor: primaryColor || '#2253FF',
        color: textColor,
        ...buttonProps.style
      }}
    >
      {children}
    </Button>
  )
}

export default KycButton
