import { connect } from 'react-redux'
import {
  IReviewOnboardingApplicationInputProps,
  IReviewOnboardingApplicationOutputProps,
  IReviewOnboardingApplicationExplicitProps
} from './props'
import { IApplicationState } from '../../../store/state'
import selectors from '../../../store/selectors'
import * as SignActions from '../../../store/sign/actions'

export default connect<
  IReviewOnboardingApplicationInputProps,
  IReviewOnboardingApplicationOutputProps,
  IReviewOnboardingApplicationExplicitProps,
  IApplicationState
>(
  (state) => {
    const application = selectors.sign.getApplication(state)
    return {
      loading: application.loading,
      status: application.status,
      submitted: application.submitted,
      error: application.error
    }
  },
  (dispatch, props) => ({
    onLoadApplication: () => dispatch(SignActions.loadApplication()),
    onLoadApplicationFailure: () =>
      dispatch(SignActions.loadApplicationFailure()),
    onLoadApplicationSuccess: (status: 'signed' | 'unsigned') =>
      dispatch(SignActions.loadApplicationSuccess({ status })),
    onSignFailure: () => dispatch(SignActions.signApplicationFailure()),
    onSignSuccess: () => dispatch(SignActions.signApplicationSuccess())
  })
)
