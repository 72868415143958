import { put, takeEvery, call, select } from 'typed-redux-saga'
import { notification } from 'antd'
import _ from 'lodash'
import { fetchMultipleRates } from '../../api/marketData'
import { IFetchPortfolio } from './actions/types'

import * as actions from './actions'
import * as appSelectors from '../selectors'

import { requestSaga } from '../platform-balances/helpers'

function* fetchPortfolioSaga(action: IFetchPortfolio) {
  yield put(actions.setPortfolio({ loading: true }))
  const { token } = yield select(appSelectors.getUser)

  try {
    const { participantCode } = action.payload

    const value: any[] = yield call(requestSaga, {
      method: 'GET',
      url: `/${participantCode}/funds`
    })

    const portfolio = _(value)
      .groupBy((value) => value.asset)
      .map((group) => ({
        asset: group[0].asset,
        amount: group.reduce(
          (acc, entry) => acc + entry.available + entry.collateral,
          0
        ),
        value: null,
        part: null
      }))
      .value()

    yield put(actions.setPortfolio({ value: portfolio }))

    /* Fetch USD adjusted values */

    const assets = _(portfolio)
      .filter(({ amount }) => amount > 0)
      .map(({ asset }) => asset)
      .uniq()
      .filter((asset) => asset !== 'USD')
      .value()

    const indexesHash = yield fetchMultipleRates({ assets, token })

    const portfolioWithValue = portfolio
      .map((item) => ({
        ...item,
        value: !!indexesHash[item.asset]?.value
          ? indexesHash[item.asset].value * Number(item.amount)
          : null
      }))
      .sort((a, b) => (a.value > b.value ? -1 : 1))

    const overallValue = portfolioWithValue.reduce(
      (acc, { value }) => acc + (value !== null ? value : 0),
      0
    )

    const portfolioWithPart = portfolioWithValue
      .map((item) => ({
        ...item,
        part: item.value !== null ? item.value / overallValue : null
      }))
      .sort((a, b) => (a.value > b.value ? -1 : 1))

    yield put(
      actions.setPortfolio({ value: portfolioWithPart, loading: false })
    )
  } catch (e) {
    notification.error({
      duration: 5,
      message: 'Failed fetching Portfolio',
      description: 'Please try again, contact support.'
    })

    yield put(actions.setPortfolio({ loading: false }))
  }
}

function* watchFetchPortfolio() {
  yield takeEvery(actions.fetchPortfolio.type, fetchPortfolioSaga)
}

export { watchFetchPortfolio }
