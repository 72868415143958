import * as React from 'react'
import { AssetBalancesDownloadProps } from './props'
import DataDownloadButton from 'seed-shared-components/lib/components/DownloadDataButton'
import connect from './connector'

class AssetBalancesDownload extends React.PureComponent<AssetBalancesDownloadProps> {
  render() {
    if (!this.props.assetBalances.length || !this.props.participantCode) {
      return null
    }

    return (
      <DataDownloadButton
        data={this.props.assetBalances}
        description={`${this.props.participantCode} Funding`}
      />
    )
  }
}

export default connect(AssetBalancesDownload)
