// log env variables on dev
if (window._env_.REACT_APP_SEED_ENV === 'dev') {
  console.log(window._env_)
}

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import App from './App'
import store from './store/store'
import { Provider } from 'react-redux'
import Decimal from 'decimal.js'

import './index.css'
// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// registerServiceWorker();

/* Literally turn of 1e8 like (scientific) notation */
Decimal.set({ toExpPos: 1000, toExpNeg: -1000 })
