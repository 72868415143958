import { WrappedFormUtils } from '@ant-design/compatible/es/form/Form'
import { AnyOnboardingApplicationItemConfig } from '../../configuration'

export type FormInputItem<T extends AnyOnboardingApplicationItemConfig> = T & {
  form: WrappedFormUtils
  value: any
  answers: any
  formItemLayout: any
}

export const getValueFromAnswers = (key: string, answers: any) => {
  return answers[key]
}
