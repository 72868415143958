import React, { useEffect, useState } from 'react'
import { message, notification, Popconfirm, Switch, Table, Tooltip } from 'antd'
import _ from 'lodash'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { IParticipantUser } from 'seed-shared-components/lib/components/ParticipantUsers/types'

import { PermissionsChangingConfirmation } from './PermissionsChangingConfirmation'

import participantUserRoleText from './ParticipantUserRoleText'
import { IAuthUser } from '../auth/WithAuthentication'

export interface ParticipantUsersTableProps {
  participantUsers: IParticipantUser[]
  adminMode: boolean
  participantCode: string
  authUser: IAuthUser
  updateUser: (
    roles: Partial<IParticipantUser>,
    userId: number
  ) => Promise<void>
  removeHandler: (user: IParticipantUser) => void
}

const tooltipTitle = (title: string, tooltipText: string): JSX.Element => {
  if (tooltipText) {
    return (
      <Tooltip placement="top" title={tooltipText}>
        {title}
        {` `}
        <QuestionCircleOutlined />
      </Tooltip>
    )
  }

  return <></>
}

const ParticipantUsersTable = ({
  participantUsers,
  adminMode,
  participantCode,
  authUser,
  updateUser,
  removeHandler
}: ParticipantUsersTableProps) => {
  const [recordsBeingEdited, setRecordsBeingEdited] = useState<
    IParticipantUser[]
  >([])
  const [isAdmin, setIsAdmin] = useState(false)
  const [confirmationChangedCaps, setConfirmationChangedCaps] = useState<{
    id: IParticipantUser['id']
    caps: string[]
  } | null>(null)

  useEffect(() => {
    authUser.isAdmin(participantCode).then((isAdmin) => {
      setIsAdmin(isAdmin)
    })
  }, [])

  /* Manage editing records stuff */

  const getRecordBeingEdited = (id: IParticipantUser['id']) =>
    _.find(recordsBeingEdited, (record) => record.id === id)

  const hasRecordBeingEdited = (id: IParticipantUser['id']) =>
    !!getRecordBeingEdited(id)

  const removeRecordBeingEdited = (id: IParticipantUser['id']) =>
    setRecordsBeingEdited((prev) => prev.filter((record) => record.id !== id))

  const updateRecordBeingEdited = (
    id: IParticipantUser['id'],
    updater: (value: IParticipantUser) => IParticipantUser
  ) =>
    setRecordsBeingEdited(
      _.map(recordsBeingEdited, (record) =>
        record.id === id ? updater(record) : record
      )
    )

  /* Manage cap toggles state and on change handlers */

  const getCapToggleOnChange = (id: IParticipantUser['id'], cap: string) => (
    newValue: boolean
  ) => {
    return updateRecordBeingEdited(id, (record) => ({
      ...record,
      [cap]: newValue
    }))
  }

  const getCapToggleDisabled = (id: IParticipantUser['id'], cap: string) => {
    const record = getRecordBeingEdited(id)

    if (record && cap === 'is_admin') {
      // quick hack here - if we are already in a wrong state we need to have a way out
      if (record.is_authorized_signer && !record.is_admin) {
        return false
      }

      return record.is_authorized_signer
    }

    if (record && cap === 'is_authorized_signer') {
      // user may not be able to edit this column
      return true
    }

    return false
  }

  /* Manage modal and it's content */

  const saveRecord = (id: IParticipantUser['id']) => {
    const record = getRecordBeingEdited(id)
    const user = _.find(participantUsers, (user) => user.id === id)
    const changedCaps = _.keys(record).filter(
      (key) => record[key] !== user[key]
    )

    if (changedCaps.length === 0) {
      message.success('No changes to save!', 2.5)
    } else {
      setConfirmationChangedCaps({ id, caps: changedCaps })
    }
  }

  const confirmSaving = async () => {
    if (!confirmationChangedCaps) {
      return
    }

    const { id, caps } = confirmationChangedCaps
    const record = getRecordBeingEdited(id)

    try {
      await updateUser(_.pick(record, caps), record.id)

      message.success('Save Complete!', 2.5)
      cancelSaving()
      removeRecordBeingEdited(id)
    } catch (err) {
      cancelSaving()
      notification.error({
        description:
          'Something went wrong. Please try again or contact support.',
        duration: 0,
        message: 'Save Failed'
      })
    }
  }

  const cancelSaving = () => setConfirmationChangedCaps(null)

  const roles = [
    ['is_admin', 'Admin'],
    ['is_trade_submitter', 'Trade Submitter']
  ]

  const roleToColumn = ([cap, label]: string[]) => ({
    title: tooltipTitle(label, (participantUserRoleText[cap] || {}).tooltip),
    width: 300,
    dataIndex: cap,
    key: cap,
    render: (capValue: boolean, record: IParticipantUser) =>
      hasRecordBeingEdited(record.id) ? (
        <Switch
          checked={getRecordBeingEdited(record.id)[cap]}
          onChange={getCapToggleOnChange(record.id, cap)}
          disabled={getCapToggleDisabled(record.id, cap)}
        />
      ) : (
        <Switch checked={capValue} disabled />
      )
  })

  const renderEdit = (_skip: any, record: IParticipantUser) =>
    hasRecordBeingEdited(record.id) ? (
      <>
        <a onClick={() => saveRecord(record.id)}>Save</a>{' '}
        <a onClick={() => removeRecordBeingEdited(record.id)}>Cancel</a>
      </>
    ) : (
      <a
        onClick={() => setRecordsBeingEdited((prev) => _.concat(prev, record))}
      >
        Edit
      </a>
    )

  const renderRemove = (_skip: any, record: IParticipantUser) => (
    <Popconfirm
      title={'Are you sure you want to remove this user?'}
      onConfirm={() => removeHandler(record)}
      disabled={!isAdmin}
      okText="Yes"
      cancelText="No"
    >
      <a>Remove</a>
    </Popconfirm>
  )

  const columns: {
    title: any
    dataIndex?: string
    key?: string
    render?: any
    width?: number
  }[] = [
    { title: 'Email', dataIndex: 'email', key: 'email' },
    /* Role toggles */
    ...roles.map(roleToColumn)
  ]

  /* Edit columns */
  if (adminMode) {
    columns.push({ title: 'Edit', render: renderEdit, width: 200 })
    columns.push({ title: 'Remove', render: renderRemove, width: 200 })
  }

  return (
    <div>
      {confirmationChangedCaps !== null && (
        <PermissionsChangingConfirmation
          caps={confirmationChangedCaps.caps}
          newRecord={getRecordBeingEdited(confirmationChangedCaps.id)}
          onOk={confirmSaving}
          onCancel={cancelSaving}
        />
      )}

      <Table
        rowKey="id"
        columns={columns}
        dataSource={participantUsers}
        pagination={false}
      />
    </div>
  )
}

export default ParticipantUsersTable
