import * as React from 'react'
import Decimal from 'decimal.js'
import { Table, Tooltip } from 'antd'

import { currencyFormatter } from 'seed-shared-components/lib/utils/CurrencyFormatter'
import { IPlatformPositionsEntity } from '../../../store/platform-positions/types'
import { QuestionCircleOutlined } from '@ant-design/icons'

type PositionsTableProps = {
  loading: boolean
  dataSource: IPlatformPositionsEntity[]
}

export const PositionsTable = (props: PositionsTableProps) => {
  const { loading, dataSource } = props
  const TABLE_COLUMNS = React.useMemo(
    () => [
      {
        title: 'Participant',
        render: ({ participant_name, participant_code }) => {
          return `${participant_name} (${participant_code})`
        },
        width: '20%'
      },
      {
        title: 'Asset',
        render: ({ asset }) => asset.toUpperCase(),
        width: '8%'
      },
      {
        title: () => (
          <>
            <span>Position (Accepted)</span>
            &nbsp;
            <Tooltip
              placement="top"
              title={`Position (Accepted) refers to a position that exists due to trades that have not yet been processed by the settlement system, i.e. due to trades with a trade state of 'accepted'.`}
            >
              <QuestionCircleOutlined style={{ cursor: 'help' }} />
            </Tooltip>
          </>
        ),
        // prettier-ignore
        render: ({ pos_accepted, asset }) => currencyFormatter(pos_accepted, asset)
      },
      {
        title: () => (
          <>
            <span>NAB</span>
            &nbsp;
            <Tooltip
              placement="top"
              title={`NAB is the Net Account Balance that is held in an asset on a particular platform. It is calculated as: Available + Receivable - Payable + Collateral - Collateral Payable.`}
            >
              <QuestionCircleOutlined style={{ cursor: 'help' }} />
            </Tooltip>
          </>
        ),
        // prettier-ignore
        render: ({ nab, asset }) => currencyFormatter(nab, asset)
      },
      {
        title: () => (
          <>
            <span>Expected NDO</span>
            &nbsp;
            <Tooltip
              placement="top"
              title={`Expected NDO refers to the Net Delivery Obligation that will become due in the future, calculated as the difference between the Position (Accepted) value and the current net available balance in this asset.`}
            >
              <QuestionCircleOutlined style={{ cursor: 'help' }} />
            </Tooltip>
          </>
        ),
        render: ({ endo, asset }) => currencyFormatter(endo, asset)
      },
      {
        title: () => (
          <>
            <span>Position (Active)</span>
            &nbsp;
            <Tooltip
              placement="top"
              title={`Position (Active) refers to a position that exists due to trades that have been processed by the settlement system, but are outstanding due to outstanding delivery obligations, i.e. due to trades with a trade state of 'active'.`}
            >
              <QuestionCircleOutlined style={{ cursor: 'help' }} />
            </Tooltip>
          </>
        ),
        render: ({ pos_active, asset }) => currencyFormatter(pos_active, asset)
      },
      {
        title: () => (
          <>
            <span>NDO</span>
            &nbsp;
            <Tooltip
              placement="top"
              title={`NDO refers to Net Delivery Obligations. This amount must be delivered before Position (Active) can settle.`}
            >
              <QuestionCircleOutlined style={{ cursor: 'help' }} />
            </Tooltip>
          </>
        ),
        render: ({ ndo, asset }) => {
          if (ndo === '') {
            return <span>0</span>
          }

          const ndoDecimal = new Decimal(ndo)
          return (
            <span className={ndoDecimal.greaterThan(0) ? 'ndo-exception' : ''}>
              {currencyFormatter(ndo, asset)}
            </span>
          )
        }
      },
      {
        title: () => (
          <>
            <span>Position (All)</span>
            &nbsp;
            <Tooltip
              placement="top"
              title={`Position (All) refers a position due to all open trades, i.e. due to trades with a trade state of 'accepted' or 'active'.`}
            >
              <QuestionCircleOutlined style={{ cursor: 'help' }} />
            </Tooltip>
          </>
        ),
        render: ({ pos_all, asset }) => currencyFormatter(pos_all, asset)
      }
    ],
    []
  )

  return (
    <Table
      columns={TABLE_COLUMNS}
      dataSource={dataSource}
      loading={loading}
      pagination={false}
    />
  )
}

export default PositionsTable
