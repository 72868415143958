import { createAction } from '@reduxjs/toolkit'
import {
  SIGNATURE_AUTH_USER_ROLE_GET,
  SIGNATURE_AGREEMENTS_LOAD,
  SIGNATURE_AGREEMENTS_LOAD_FAILURE,
  SIGNATURE_AGREEMENTS_LOAD_SUCCESS,
  SIGNATURE_APPLICATION_LOAD,
  SIGNATURE_APPLICATION_LOAD_SUCCESS,
  SIGNATURE_APPLICATION_LOAD_FAILURE,
  SIGNATURE_SIGN,
  SIGNATURE_AGREEMENTS_SIGN_FAILURE,
  SIGNATURE_AGREEMENTS_SIGN_SUCCESS,
  SIGNATURE_APPLICATION_SIGN_FAILURE,
  SIGNATURE_APPLICATION_SIGN_SUCCESS,
  SIGNATURE_AGREEMENTS_VALIDATE,
  IGetAuthUserRolePayload,
  ILoadAgreementsSuccessPayload,
  ILoadApplicationSuccessPayload,
  IValidateAgreementsFormPayload
} from './types'

export const getAuthUserRole = createAction<IGetAuthUserRolePayload>(
  SIGNATURE_AUTH_USER_ROLE_GET
)
export const loadAgreements = createAction(SIGNATURE_AGREEMENTS_LOAD)
export const loadAgreementsFailure = createAction(
  SIGNATURE_AGREEMENTS_LOAD_FAILURE
)
export const loadAgreementsSuccess = createAction<ILoadAgreementsSuccessPayload>(
  SIGNATURE_AGREEMENTS_LOAD_SUCCESS
)
export const loadApplication = createAction(SIGNATURE_APPLICATION_LOAD)
export const loadApplicationSuccess = createAction<ILoadApplicationSuccessPayload>(
  SIGNATURE_APPLICATION_LOAD_SUCCESS
)
export const loadApplicationFailure = createAction(
  SIGNATURE_APPLICATION_LOAD_FAILURE
)
export const signAgreementsAndApplication = createAction(SIGNATURE_SIGN)
export const signAgreementsFailure = createAction(
  SIGNATURE_AGREEMENTS_SIGN_FAILURE
)
export const signAgreementsSuccess = createAction(
  SIGNATURE_AGREEMENTS_SIGN_SUCCESS
)
export const signApplicationFailure = createAction(
  SIGNATURE_APPLICATION_SIGN_FAILURE
)
export const signApplicationSuccess = createAction(
  SIGNATURE_APPLICATION_SIGN_SUCCESS
)
export const validateAgreementsForm = createAction<IValidateAgreementsFormPayload>(
  SIGNATURE_AGREEMENTS_VALIDATE
)
