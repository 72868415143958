import React from 'react'
import { Button, ButtonProps, Row, Tooltip } from 'antd'
import _ from 'lodash'

interface IZHSubmitButtonProps {
  loading?: boolean
  disabled?: boolean
  tooltipTitle?: string
  handleSubmit?: () => void
}

const ZHSubmitButton = ({
  children = 'Submit',
  loading,
  disabled,
  tooltipTitle,
  handleSubmit,
  ...ButtonProps
}: IZHSubmitButtonProps & ButtonProps) => {
  return (
    <Row
      style={{
        marginTop: '16px',
        marginBottom: '16px',
        justifyContent: 'flex-end'
      }}
    >
      <Tooltip title={tooltipTitle}>
        <Button
          loading={loading}
          type="primary"
          disabled={disabled}
          onClick={handleSubmit}
          htmlType="submit"
          {...ButtonProps}
        >
          {children}
        </Button>
      </Tooltip>
    </Row>
  )
}

export default ZHSubmitButton
