import uuid from 'uuid'

export interface ISubdivision {
  countryCode: string
  subdivisionName: string
  subdivisionCode: string
}

export const fetchSubdivisions = async (restService, countryCode: string) => {
  try {
    const { subDivisionsList } = await restService
      .route(
        `subdivisions?requestId=${uuid()}&page_size=2000&eq_country_code=${countryCode}`
      )
      .get()

    return subDivisionsList.map((subdivision: { subdivisionCode: string }) => {
      // Trim the country code from subdivisionCode
      const trimmedCode = subdivision.subdivisionCode.replace(
        `${countryCode}-`,
        ''
      )
      return {
        ...subdivision,
        subdivisionCode: trimmedCode
      }
    })
  } catch (err) {
    console.error('Error fetching subdivisions list', err)
  }
}
