import { IUserSettingsState } from './state'
import { createReducer } from '@reduxjs/toolkit'
import { ISetUserSettingAction, ISetUserSettingsAction } from './actions/types'
import * as actions from './actions'

const userSettingsInitialState: IUserSettingsState = {
  settings: {}
}

export default createReducer(userSettingsInitialState, {
  [actions.setUserSetting.type]: (state, action: ISetUserSettingAction) => ({
    ...state,
    settings: {
      ...state.settings,
      ...action.payload
    }
  }),

  [actions.setUserSettings.type]: (state, action: ISetUserSettingsAction) => ({
    ...state,
    settings: action.payload.settings
  })
})
