import { connect } from 'react-redux'

import { IApplicationState } from '../../store/state'

import {
  IBreadcrumbHeaderInputProps,
  IBreadcrumbHeaderExplicitProps,
  IBreadcrumbHeaderOutputProps
} from './props'
import selectors from '../../store/selectors'
import * as ParticipantActions from '../../store/participant/actions'

export default connect<
  IBreadcrumbHeaderInputProps,
  IBreadcrumbHeaderOutputProps,
  IBreadcrumbHeaderExplicitProps,
  IApplicationState
>(
  (state) => ({
    account: selectors.accountHistory.getAccount(state)
  }),
  (dispatch) => ({
    selectParticipant: () => dispatch(ParticipantActions.selectParticipant())
  })
)
