import { Col, Form, Row } from 'antd'
import React from 'react'

import KycTypographyText from './kyc-customized-components/KycTypographyText'
import KycTypographyLink from './kyc-customized-components/KycTypographyLink'
import VerificationInput from 'react-verification-input'
import KycTypographyTitle from './kyc-customized-components/KycTypographyTitle'
import KycButton from './kyc-customized-components/KycButton'
import { ThemeColors } from './types'

type KycPhoneVerificationProps = {
  colors: ThemeColors
  fontSize?: number
  fontFamily: string
}

const KycPhoneVerification = ({
  colors,
  fontSize,
  fontFamily
}: KycPhoneVerificationProps) => {
  const style = {
    '--custom-primary-color': colors.colorPrimary || '#2253FF',
    '--custom-color-text': colors.colorText || '#303133'
  } as React.CSSProperties
  return (
    <div
      style={{
        maxWidth: '500px',
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        padding: '1rem',
        minHeight: '540px',
        fontFamily: fontFamily,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <KycTypographyTitle
        fontSize={fontSize}
        colorText={colors.colorText || '#303133'}
      >
        We sent you a code
      </KycTypographyTitle>
      {/* 24px based on 16px */}
      <Col style={{ marginBottom: '1.5rem' }}>
        <KycTypographyText colorText={colors.colorText} fontSize={fontSize}>
          Please enter the code we sent to +1 (205) 250-2502
        </KycTypographyText>
      </Col>
      <Form layout="vertical">
        <Col>
          <Form.Item
            name="verificationCode"
            rules={[
              { required: true, message: 'Please enter a valid code' },
              { min: 6, message: 'Please enter a valid code' },
              { max: 6, message: 'Please enter a valid code' }
            ]}
            style={style}
          >
            <VerificationInput
              inputProps={{ maxLength: 6 }}
              validChars="0-9"
              placeholder="-"
              autoFocus
              classNames={{
                container: 'container',
                character: 'character',
                characterInactive: 'character--inactive',
                characterSelected: 'character--selected'
              }}
            />
          </Form.Item>
        </Col>
        <Row style={{ marginTop: 0 }}>
          <Col xs={24}>
            <KycTypographyLink
              linkColor={colors.colorLink || '#3A15A0'}
              linkHoverColor={colors.colorLinkHover || '#3A15A0'}
              fontSize={fontSize}
            >
              Resend code
            </KycTypographyLink>
          </Col>
          <Col xs={24}>
            <KycTypographyLink
              linkColor={colors.colorLink || '#3A15A0'}
              linkHoverColor={colors.colorLinkHover || '#3A15A0'}
              fontSize={fontSize}
            >
              Edit your phone number
            </KycTypographyLink>
          </Col>
        </Row>
      </Form>
      <Row
        style={{
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'flex-end'
        }}
      >
        <KycButton
          primaryColor={colors.colorPrimary}
          textColor={colors.colorText}
          style={{
            width: '100%',
            marginTop: '3rem',
            fontSize: fontSize ? fontSize * 1.33 : '16px',
            height: '3rem', // 48px,
            fontWeight: 800
          }}
        >
          Submit
        </KycButton>
      </Row>
    </div>
  )
}

export default KycPhoneVerification
