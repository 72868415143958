import { IAccountHistoryState } from './state'
import { createReducer } from '@reduxjs/toolkit'
import * as HistoryActions from './actions'
import {
  ILoadHistorySuccessAction,
  ISelectHistoryAccountAction
} from './actions/types'
import * as ParticipantActions from '../participant/actions'

const historyInitialState: IAccountHistoryState = {
  account: null,
  history: null,
  loading: false
}

const history = createReducer(historyInitialState, {
  [HistoryActions.loadHistory.type]: (state) => ({
    ...state,
    loading: true
  }),
  [HistoryActions.selectAccount.type]: (
    state,
    action: ISelectHistoryAccountAction
  ) => ({
    ...state,
    loading: false,
    account: action.payload.account
  }),
  [ParticipantActions.selectParticipant.type]: (state) => ({
    ...state,
    account: null,
    history: null
  }),
  [HistoryActions.unmountHistory.type]: (state) => ({
    ...state,
    account: null,
    history: null
  }),
  [HistoryActions.loadHistorySuccess.type]: (
    state,
    action: ILoadHistorySuccessAction
  ) => ({
    ...state,
    loading: false,
    history: action.payload.history
  })
})

export default history
