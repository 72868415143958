export const getTransactionUrl = (asset?: string) => {
  switch (asset) {
    case 'BTC':
      return 'https://www.blockchain.com/btc/tx'

    case 'BCH':
      return 'https://www.blockchain.com/bch/tx'

    case 'LTC':
      return 'https://live.blockcypher.com/ltc/tx'

    case 'ETH':
    case 'BAT':
    case 'BUSD':
    case 'CEL':
    case 'CUSDC':
    case 'CVC':
    case 'CZRX':
    case 'DAI':
    case 'ENJ':
    case 'KIN':
    case 'KNC':
    case 'LINK':
    case 'OMG':
    case 'USDP':
    case 'REP':
    case 'STORJ':
    case 'TUSD':
    case 'USDC':
    case 'USDT':
    case 'ZRX':
      return 'https://etherscan.io/tx'

    case 'XLM':
      return 'https://stellarchain.io/tx'

    case 'EOS':
      return 'https://eosauthority.com/transaction'

    case 'XRP':
      return 'https://xrpcharts.ripple.com/#/transactions'

    default:
      return null
  }
}
