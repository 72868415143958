import React, { useCallback } from 'react'
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  Sector,
  ResponsiveContainer,
  SectorProps
} from 'recharts'
import { Empty } from 'antd'
import * as _ from 'lodash'

import { currencyFormat } from '../utils'
import { COLORS } from './constants'

type ActiveShapeProps = {
  percent?: number
  name?: string | number
  midAngle?: number
  middleRadius?: number
  value?: number
  paddingAngle?: number
  payload: { name: 'string'; payload: { [key: string]: string } }
}

type ChartProps = {
  name: string
  asset: string
}

const DashboardPortfolioChart = ({
  activeIndex,
  setActiveIndex,
  portfolioDataChart
}) => {
  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index)
  }

  const handleMouseEnter = useCallback(
    (e: { value: string; asset: string }) => {
      const { value } = e

      setActiveIndex(
        portfolioDataChart.findIndex(
          (element: ChartProps) => element.asset === value
        )
      )
    },
    [portfolioDataChart]
  )

  const renderActiveShape = (props: SectorProps & ActiveShapeProps) => {
    /*
      RADIAN: angle of the center of the circle
      midAngle: 180 if full circle
      angle: -RADIAN * midAngle
      sin: sin(angle) 0 if full circle
      cos: cost(angle) -1 if full circle

      sx: x position of the start of the line that leads to the dot (always from  internal to external)
      sy: y position of the start of the line that leads to the dot (always from  internal to external)
      mx: x position of the first half of the line that leads to the dot
      my: y position of the first half of the line that leads to the dot
      ex: x position of the second half of the line that leads to the dot
      ey: y position of the second half of the line that leads to the dot
    */
    const RADIAN = Math.PI / 180
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value
    } = props

    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const ey = my
    const textAnchor = cos >= 0 ? 'start' : 'end'
    const formattedValuePositionX = ex + (cos >= 0 ? 1 : -1) * 12
    const formattedValue = value ? currencyFormat(value) : ''
    const percentAmount = (percent * 100).toFixed(2)

    return (
      <g>
        <text x={cx} y={cy - 10} dy={8} textAnchor="middle" fill={fill}>
          {payload?.name}
        </text>
        <text x={cx} y={cy + 10} dy={8} textAnchor="middle" fill={fill}>
          {`(${percentAmount}%)`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={formattedValuePositionX}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >
          {formattedValue}
        </text>
      </g>
    )
  }

  return (
    <div style={{ width: '600px', height: 300 }}>
      {_.isEmpty(portfolioDataChart) ? (
        <Empty />
      ) : (
        <ResponsiveContainer>
          <PieChart>
            <Pie
              dataKey="value"
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              onMouseEnter={onPieEnter}
              isAnimationActive={true}
              data={portfolioDataChart}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={portfolioDataChart?.length > 1 ? 5 : 0}
            >
              {portfolioDataChart.map((entry: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend onMouseEnter={handleMouseEnter} />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}

export default DashboardPortfolioChart
