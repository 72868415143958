import * as React from 'react'
import { Table } from 'antd'

import { Link } from 'react-router-dom'
import Title from 'seed-shared-components/lib/components/Title'

import { numberFormatter } from 'seed-shared-components/lib/utils/CurrencyFormatter'
import { IAsset } from 'seed-shared-components/lib/static-data/assetConfig'
import { useMemo } from 'react'
import dateFormatter from '../../utils/dateFormatter'
import { IPagination } from './DepositPage'
import { IDeposit } from './DepositPage'

export interface IDepositHistoryProps {
  selectedAsset: IAsset
  detailsBaseUrl: string
  pagination: IPagination
  deposits: IDeposit[]
  onChangePage: (page: number) => void
}

const DepositHistory = (props: IDepositHistoryProps) => {
  const {
    selectedAsset,
    detailsBaseUrl,
    pagination,
    deposits,
    onChangePage
  } = props

  const tableColumns = useMemo(
    () => [
      {
        title: 'Asset',
        dataIndex: 'currency'
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        render: (item: number) => numberFormatter(item)
      },
      {
        title: 'Settled at',
        dataIndex: 'settle_timestamp',
        render: (item: number) => dateFormatter(item)
      },
      {
        title: 'External Reference ID',
        render: ({ reference_id: referenceId, currency }) => {
          if (!referenceId) {
            return undefined
          }

          const asset = selectedAsset?.asset || currency
          const detailsUrl = asset
            ? `${detailsBaseUrl}/${asset}/${referenceId}`
            : `${detailsBaseUrl}/${referenceId}`

          return (
            <Link to={detailsUrl}>
              {referenceId.length < 40
                ? referenceId
                : `${referenceId.substring(0, 40)}...`}
            </Link>
          )
        }
      }
    ],
    [selectedAsset]
  )
  return (
    <>
      <Title level={3}>
        {selectedAsset
          ? `${selectedAsset.name} Deposit History`
          : `Deposit History`}
      </Title>

      <Table
        columns={tableColumns}
        dataSource={deposits}
        rowKey="id"
        pagination={{
          defaultCurrent: 1,
          current: pagination.page,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: onChangePage
        }}
      />
    </>
  )
}

export default DepositHistory
