import { connect } from 'react-redux'
import {
  IMovementsPageStateProps,
  IMovementsPageDispatchProps,
  IMovementsPageExplicitProps
} from './props'
import { IApplicationState } from '../../../store/state'
import selectors from '../../../store/selectors'
import * as historyActions from '../../../store/accountHistory/actions'
import * as movementsActions from '../../../store/account-movements/actions'

export default connect<
  IMovementsPageStateProps,
  IMovementsPageDispatchProps,
  IMovementsPageExplicitProps,
  IApplicationState
>(
  (state) => ({
    funds: selectors.funds.getFunds(state) || [],
    accountHistory: selectors.accountHistory.getHistory(state),
    accountType: selectors.accountMovements.getAccountType(state),
    movements: {
      loading: selectors.accountMovements.getMovementsLoading(state),
      value: selectors.accountMovements.getMovements(state)
    }
  }),
  (dispatch) => ({
    onLoad: (account) => dispatch(historyActions.selectAccount({ account })),
    fetchMovements: (params) =>
      dispatch(movementsActions.fetchMovements(params))
  })
)
