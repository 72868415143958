import React, { useEffect, useState } from 'react'
import { Card, notification } from 'antd'

import { FiatAddress } from 'seed-ts/types/banks'
import RestService from '../../RestService'
import { IAsset } from 'seed-shared-components/lib/static-data/assetConfig'
import BanksListAndDetails from '../BankListAndDetail'
import { ExclamationCircleOutlined } from '@ant-design/icons'

type AddressType =
  | 'united_states_wire'
  | 'international_wire'
  | 'internal_transfer'

interface IFiatAddressInformationProps {
  restService: RestService
  selectedAsset: IAsset
}

interface ITab {
  key: string
  tab: string
}

const FiatAddressInformation = (props: IFiatAddressInformationProps) => {
  const { restService, selectedAsset } = props

  const [isLoading, setLoading] = useState<boolean>(true)
  const [getFiatAddresses, setFiatAddresses] = useState<FiatAddress[]>([])
  const [getTabKey, setTabKey] = useState<AddressType>(null)
  const [getTabList, setTabList] = useState<Array<ITab>>([])

  useEffect(() => {
    if (selectedAsset) {
      setLoading(true)
      ;(async function (selectedAsset) {
        try {
          const fiatAddresses = await restService
            .route(`fiat-deposit/${selectedAsset.asset}`)
            .get<FiatAddress[]>()

          setFiatAddresses(fiatAddresses)
        } catch (errorMessage) {
          notification.error({
            description: errorMessage,
            duration: 0,
            message: 'Failed to load deposit information.'
          })
        } finally {
          setLoading(false)
        }
      })(selectedAsset)
    }
  }, [selectedAsset])

  useEffect(() => {
    if (getFiatAddresses && getFiatAddresses.length > 0) {
      // create list of tabs
      const tabList = []

      if (
        getFiatAddresses.findIndex(
          (address) => address.type === 'united_states_wire'
        ) >= 0
      ) {
        tabList.push({
          key: 'united_states_wire',
          tab: 'Domestic U.S. Wire'
        })
      }
      if (
        getFiatAddresses.findIndex(
          (address) => address.type === 'international_wire'
        ) >= 0
      ) {
        tabList.push({
          key: 'international_wire',
          tab: 'International Wire'
        })
      }
      if (
        getFiatAddresses.findIndex(
          (address) => address.type === 'internal_transfer'
        ) >= 0
      ) {
        tabList.push({
          key: 'internal_transfer',
          tab: 'Real-Time Transfers'
        })
      }

      setTabList(tabList)
    }
  }, [getFiatAddresses])

  const maybeRenderIntermediaryBankWarning = () => {
    if (selectedAsset.asset.toLowerCase() !== 'usd') {
      return (
        <div style={{ textAlign: 'center', marginBottom: 10 }}>
          <div style={{ background: 'rgba(222, 193, 140, 1)' }}>
            <div style={{ padding: 4 }}>
              <div style={{ marginTop: 8 }}>
                <ExclamationCircleOutlined
                  style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.65)' }}
                />{' '}
                <span>Warning</span>
              </div>
              <p>
                All correspondent bank information must be included for non-USD
                fiat deposits. A correspondent bank is any bank authorized to
                provide services on behalf of another bank. If omitted, your
                deposits will not reach our direct banking partner, and so they
                will fail or may be significantly delayed.
              </p>
            </div>
          </div>
        </div>
      )
    }

    return null
  }

  const onTabChange = (tabKey: AddressType): void => {
    setTabKey(tabKey)
  }

  if (getTabList.length >= 1 && !getTabKey) {
    // set the first tab as selected by default
    onTabChange(getTabList[0].key as AddressType)
  }

  return (
    <>
      {maybeRenderIntermediaryBankWarning()}
      <Card
        activeTabKey={getTabKey}
        tabList={getTabList}
        onTabChange={onTabChange}
        loading={isLoading}
        style={{ marginBottom: 24 }}
        className="transparent"
      >
        {getFiatAddresses ? (
          <BanksListAndDetails
            asset={selectedAsset?.asset}
            addresses={getFiatAddresses.filter((i) => i.type === getTabKey)}
          />
        ) : null}
      </Card>
    </>
  )
}

export default FiatAddressInformation
