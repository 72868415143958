import * as React from 'react'

export default () => {
  return (
    <div style={{ width: 260 }}>
      <p>
        This account label field gives the user the option to specify that a key
        can only withdrawal funds out of accounts that have an account label
        equal to what you enter in the field.
      </p>
      <p>
        This key will only be able to request withdrawals from accounts where
        account label is equal to your entered value.
      </p>
      <p>
        If you leave this field empty (most common) the key will not have
        withdrawal restrictions.
      </p>
    </div>
  )
}
