import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Breadcrumb, Dropdown, Menu } from 'antd'
import * as uuid from 'uuid'
import { Link } from 'react-router-dom'
import { DownCircleOutlined } from '@ant-design/icons'

import { assets as assetConfigs } from 'seed-shared-components/lib/static-data/assetConfig'
import { IAsset } from 'seed-shared-components/lib/static-data/assetConfig'

import { BreadcrumbHeaderProps } from './props'
import applicationSteps from '../../onboarding-application/configuration'
import connect from './connector'
import AuthUserContext from '../../auth/AuthUserContext'
import RestService from '../../RestService'

const staticRouteNameMap: {
  [route: string]: { label: string; link: boolean }
} = {
  ['${asset}']: { label: '${name}', link: false },
  ['application']: { label: 'Onboarding Application', link: false },
  ['deposit']: { label: 'Deposit ${name}', link: true },
  ['withdraw']: { label: 'Withdraw ${name}', link: true },
  ['accounts']: { label: '${name} Accounts', link: true },
  ['history']: {
    label: '${account} ${name} ${accountType} Run History',
    link: true
  },
  ['movements']: { label: 'Movements', link: true },
  ['balances']: { label: 'Balances', link: true },
  ['deposits']: { label: 'Deposits', link: true },
  ['withdrawals']: { label: 'Withdrawals', link: true },
  ['users']: { label: 'Users', link: true },
  ['reports']: { label: 'Reports', link: true },
  ['dashboard']: { label: 'Dashboard', link: true },
  ['documents']: { label: 'Documents', link: true },
  ['api_keys']: { label: 'API Keys', link: true },
  ['sign']: { label: 'Authorized Signer Review', link: true },
  ['allocations']: { label: 'Allocations', link: true },
  ['trades']: { label: 'Trades', link: true },
  ['settings']: { label: 'Settings', link: true },
  ['trade_submission']: { label: 'Submission', link: true },
  ['loans']: { label: 'Loans', link: true },
  ['loan_submission']: { label: 'Submission', link: true },
  ['relationships']: { label: 'Relationships', link: true },
  ['platform_transfers']: { label: 'Platform Transfers', link: true },
  ['platform_balances']: { label: 'Platform Balances', link: true },
  ['platform_positions']: { label: 'Platform Positions', link: true },
  ['participant_positions']: { label: 'Participant Positions', link: true },
  ['loan-portfolios']: { label: 'Loan Portfolios', link: true },
  ['loan-exposure']: { label: 'Loan Exposure', link: true },
  ['loan-interest-accruals']: { label: 'Loan Interest Accruals', link: true },
  ['create-loan-portfolio']: { label: 'Create Loan Portfolio', link: true },
  ['loan-credit']: { label: 'Loan Credit', link: true },
  ['theme']: { label: 'Theme', link: true }
}

const NewBreadcrumbHeader = (props: BreadcrumbHeaderProps) => {
  const [assets, setAssets] = useState<IAsset[]>([])
  const { idToken } = useContext(AuthUserContext)
  const fetchAssets = useCallback(
    async (participantCode) => {
      const restService = new RestService(idToken, participantCode)
      try {
        const responseCryptoAssets: IAsset[] = await restService
          .route(`assets?requestId=${uuid.v4()}&asset_type=CRYPTO`)
          .get()
        const fiatCurrencies = assetConfigs.filter(
          (asset) => asset.type === 'Fiat'
        )
        setAssets([...responseCryptoAssets, ...fiatCurrencies])
      } catch (e) {
        console.error('Failed to fetch assets for TopNav', e)
      }
    },
    [idToken]
  )

  useEffect(() => {
    if (props.participant?.code) {
      fetchAssets(props.participant?.code)
    }
  }, [fetchAssets, props.participant?.code])

  const interpolate = (text: string, params: object) => {
    const names = Object.keys(params)
    const vals = Object.values(params)
    return new Function(...names, `return \`${text}\`;`)(...vals)
  }

  const generateBreadcrumb = (pathSnippets: string[]) => {
    const { participant } = props

    for (const step of applicationSteps) {
      staticRouteNameMap[step.route] = { label: step.label, link: true }
    }

    for (const asset of assets) {
      staticRouteNameMap[asset.asset] = { label: asset.name, link: true }
    }

    const breadcrumbItems = pathSnippets.map(
      (snippet: string, index: number) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
        // currency is always in the third position
        const currencyName = pathSnippets[2]
          ? pathSnippets[2].toUpperCase()
          : ''
        let name = ''

        /**
         * Handle Breadcrumbs for platform_balances page
         */
        if (pathSnippets[1] === 'platform_balances' && index > 1) {
          if (index === 2) {
            return (
              <Breadcrumb.Item key="participantCode">
                <Link to={'/' + pathSnippets.slice(0, index + 1).join('/')}>
                  {snippet}
                </Link>
              </Breadcrumb.Item>
            )
          }

          if (index === 5) {
            return (
              <Breadcrumb.Item key="accountId">
                <Link to={'/' + pathSnippets.slice(0, index + 1).join('/')}>
                  {pathSnippets[4]} Available Run History
                </Link>
              </Breadcrumb.Item>
            )
          }

          if (index === 7) {
            return (
              <Breadcrumb.Item key="movementId">
                <Link to={'/' + pathSnippets.slice(0, index + 1).join('/')}>
                  {snippet}
                </Link>
              </Breadcrumb.Item>
            )
          }

          return null
        }

        if (participant && participant.code === snippet) {
          // will handle the root breadcrumb with the participant dropdown
          return null
        }

        if (pathSnippets[index - 1] === 'withdrawals') {
          return (
            <Breadcrumb.Item key={pathSnippets[2]}>
              {pathSnippets[2]}
            </Breadcrumb.Item>
          )
        }

        // assets
        if (
          pathSnippets[index - 1] !== 'deposits' &&
          assets.some(
            (a) => a.asset.toLowerCase() === pathSnippets[index].toLowerCase()
          )
        ) {
          // This opts out of a breadcrumb for assets. eg domain.com/82IPLZ/ETH
          // We can remove this if we ever add an asset summary page. eg ^
          return null
        }
        const { account } = props

        const accountTitle =
          account && account.participantName
            ? `${account.participantName} (${account.account_group})`
            : account
            ? account.account_group
            : ''

        const accountType = account
          ? account.type.charAt(0).toUpperCase() + account.type.slice(1)
          : ''

        let routeName = staticRouteNameMap[snippet]
        if (!routeName) {
          routeName = {
            label: snippet,
            link: true
          }
        }
        // static routes
        if (routeName) {
          name = interpolate(routeName.label, {
            name: currencyName,
            account: accountTitle,
            accountType
          })
        }

        // special cases to handle route params
        const isGUID = snippet.length >= 36
        const isNumber = !isNaN(snippet as any) // typescript thinks isNaN() only takes numbers
        const isNull = snippet === 'null'
        const isUndefined = snippet === 'undefined'
        if (isGUID || isNumber || isNull || isUndefined) {
          // skip guids and numbers, like accountId and ledgerVersion.
          // 'null' is possible if a user clicks a fake link to a non-existant account
          const previousSnippet = pathSnippets[index - 1]
          const secondPriorSnippet = pathSnippets[index - 2]
          // display GUID for trades
          if (
            (previousSnippet === 'trades' ||
              previousSnippet === 'loans' ||
              previousSnippet === 'loan-portfolios' ||
              secondPriorSnippet === 'movements') &&
            isGUID
          ) {
            name = snippet
          } else {
            name = interpolate(staticRouteNameMap[previousSnippet]?.label, {
              name: currencyName,
              account: accountTitle,
              accountType
            })
          }
        }

        // these routes get handled by the route-param block above
        if (
          pathSnippets[index] === 'history' ||
          pathSnippets[index] === 'movements'
        ) {
          return null
        }

        if (
          pathSnippets[index - 1] === 'deposits' ||
          pathSnippets[index - 2] === 'deposits'
        ) {
          name = snippet
        }

        return (
          <Breadcrumb.Item key={url}>
            {routeName.link ? <Link to={url}>{name}</Link> : name}
          </Breadcrumb.Item>
        )
      }
    )

    return breadcrumbItems
  }

  const { history, participant } = props
  if (!participant) {
    return null
  }
  const pathSnippets = location.pathname.split('/').filter((i: string) => i)

  if (pathSnippets.length === 1 && participant) {
    history.push(`/${participant.code}/dashboard`)
    return null
  }
  const menu = (
    <Menu data-test-id="header-nav-menu" selectedKeys={[]}>
      {props.authUser.participants.map((participant) => (
        <Menu.Item key={`${participant.code}`}>
          <Link
            data-test-id={`header-nav-${participant.code}`}
            to={`/${participant.code}/dashboard`}
            onClick={() => props.selectParticipant()}
          >
            {`${participant.name} (${participant.code})`}
          </Link>
        </Menu.Item>
      ))}
      {
        <Menu.Item data-test-id="item-new-participant" key="___create">
          {<Link to="/create">New Participant</Link>}
        </Menu.Item>
      }
    </Menu>
  )
  const items = generateBreadcrumb(pathSnippets)
  const dropdown = (
    <Breadcrumb.Item key={participant.code}>
      <Dropdown overlay={menu}>
        <span>
          <span>
            <DownCircleOutlined style={{ fontSize: 16 }} />
          </span>
          {` `}
          <span>{`${participant.name} (${participant.code})`}</span>
        </span>
      </Dropdown>
    </Breadcrumb.Item>
  )

  const itemsWithDropdown = [dropdown, ...items]
  return (
    <div style={{ margin: 'auto' }}>
      <Breadcrumb data-test-id="breadcrumb">{itemsWithDropdown}</Breadcrumb>
    </div>
  )
}

export default connect(NewBreadcrumbHeader)
