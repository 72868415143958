import React, { useState } from 'react'
import { Table, message, Tooltip, Button } from 'antd'
import type { ColumnsType } from 'antd/es/table'

import { PendingUserReviewConfirmation } from './PendingUserReviewConfirmation'
import { IAuthUser } from '../auth/WithAuthentication'
import { PendingParticipantUser } from './types'
import RestService from '../RestService'

export interface PendingParticipantUsersTableProps {
  pendingUsers: PendingParticipantUser[]
  authUser: IAuthUser
  participantCode: string
  restService: RestService
  loadUsers: () => Promise<void>
  loadPendingUsers: () => Promise<void>
}

const PendingParticipantUsersTable = ({
  authUser: { email, participants },
  participantCode,
  pendingUsers,
  restService,
  loadUsers,
  loadPendingUsers
}: PendingParticipantUsersTableProps) => {
  const [recordBeingReviewed, setRecordBeingReviewed] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const reviewUrl = `${participantCode}/${recordBeingReviewed?.pendingParticipantUserId}/review`

  const confirmApproval = async () => {
    setIsSubmitting(true)
    try {
      await restService.route(reviewUrl, true).post(
        {
          action_user_email: email,
          action: 1,
          pending_participant_user_id:
            recordBeingReviewed.pendingParticipantUserId
        },
        {
          customErrorMessage: `Could not approve user "${recordBeingReviewed.pendingParticipantUserId}"`
        }
      )
      loadUsers()
      loadPendingUsers()
      message.success('Save Complete!', 2.5)
      setRecordBeingReviewed(null)
    } finally {
      setIsSubmitting(false)
    }
  }

  const confirmReject = async () => {
    setIsSubmitting(true)
    try {
      await restService.route(reviewUrl, true).post(
        {
          action_user_email: email,
          action: 2,
          pending_participant_user_id:
            recordBeingReviewed.pendingParticipantUserId
        },
        {
          customErrorMessage: `Could not reject user "${recordBeingReviewed.pendingParticipantUserId}"`
        }
      )
      loadPendingUsers()
      message.success('Save Complete!', 2.5)
      setRecordBeingReviewed(null)
    } finally {
      setIsSubmitting(false)
    }
  }

  /* Render stuff */
  const renderReview = (_skip: any, record: PendingParticipantUser) => {
    const isAdmin = participants.find(
      (p) => p.code === participantCode && p.isAdmin
    )
    const canReviewPendingUsers =
      record.request.participantuser.actionUserEmail !== email && isAdmin
    return (
      <Tooltip
        title={
          !canReviewPendingUsers
            ? 'You must be an Admin to review pending users and you cannot review your own submissions'
            : ''
        }
      >
        <Button
          style={{
            color: !canReviewPendingUsers ? 'light-gray' : '#00f197'
          }}
          type="text"
          disabled={!canReviewPendingUsers}
          onClick={() => setRecordBeingReviewed(record)}
        >
          Review
        </Button>
      </Tooltip>
    )
  }

  const columns: ColumnsType<PendingParticipantUser> = [
    { title: 'Pending User Email', dataIndex: 'userEmail', key: 'userEmail' },
    {
      title: 'Submitted By',
      dataIndex: ['request', 'participantuser', 'actionUserEmail'],
      key: 'submitted_by'
    },
    { title: 'Review', render: renderReview, width: 200 }
  ]

  return (
    <div>
      {recordBeingReviewed !== null && (
        <PendingUserReviewConfirmation
          newRecord={recordBeingReviewed}
          onApprove={confirmApproval}
          onReject={confirmReject}
          onCancel={() => setRecordBeingReviewed(null)}
          isSubmitting={isSubmitting}
        />
      )}

      <Table
        rowKey="id"
        columns={columns}
        dataSource={pendingUsers}
        pagination={false}
      />
    </div>
  )
}

export default PendingParticipantUsersTable
