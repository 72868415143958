import { IParticipantBasic } from 'seed-ts/types/participants'
import { IFund } from 'seed-shared-components/lib/static-data/assetConfig'
import { ISettlementRun, IMovements } from '../../accountHistory/state'

const domain = `platform-balances`

export const FETCH_PLATFORM_PARTICIPANTS = `${domain}/FETCH_RELATIONSHIPS`
export const SET_PLATFORM_PARTICIPANTS = `${domain}/SET_RELATIONSHIPS`

export const FETCH_FUNDS = `${domain}/FETCH_FUNDS`
export const SET_FUNDS = `${domain}/SET_FUNDS`

export const FETCH_RUNS = `${domain}/FETCH_RUNS`
export const SET_RUNS = `${domain}/SET_RUNS`

export const FETCH_MOVEMENTS = `${domain}/FETCH_MOVEMENTS`
export const SET_MOVEMENTS = `${domain}/SET_MOVEMENTS`

export interface IFetchPlatformParticipantsAction {
  type: typeof FETCH_PLATFORM_PARTICIPANTS
  payload: {
    participantCode: string
    searchString: string
    basic?: boolean
    relationshipsTypes?: string[]
  }
}

export interface ISetPlatformParticipants {
  type: typeof SET_PLATFORM_PARTICIPANTS
  payload: {
    participants: {
      value?: IParticipantBasic[]
      loading?: boolean
    }
  }
}

export interface IFetchFundsAction {
  type: typeof FETCH_FUNDS
  payload: {
    platformCode: string
  }
}

export interface ISetFundsAction {
  type: typeof SET_FUNDS
  payload: {
    funds: {
      value?: IFund[]
      loading?: boolean
    }
  }
}

export interface IFetchRunsAction {
  type: typeof FETCH_RUNS
  payload: {
    accountId: string
  }
}

export interface ISetRunsAction {
  type: typeof SET_RUNS
  payload: {
    runs: {
      value?: ISettlementRun[]
      loading?: boolean
      page?: number
      pageSize?: number
      count?: number
    }
  }
}

export interface IFetchMovementsAction {
  type: typeof FETCH_MOVEMENTS
  payload: {
    accountId: string
    version: string
    page: number
    pageSize: number
  }
}

export interface ISetMovementsAction {
  type: typeof SET_MOVEMENTS
  payload: {
    movements: {
      value?: IMovements
      loading?: boolean
    }
  }
}
