import React from 'react'
import { Tabs } from 'antd'

import { FiatTabType } from './types'
import useFlag from '../../../unleash/useFlag'
interface FiatTabsProps {
  tab: FiatTabType
  setTab: React.Dispatch<React.SetStateAction<FiatTabType>>
}

const FiatTabs = ({ tab, setTab }: FiatTabsProps) => {
  const isCBITEnabled = useFlag('portal-app.cbit-enabled.SET-3709')
  return (
    <>
      <p>Zero Hash supports multiple types of withdrawal accounts:</p>
      <p>
        <strong>Transfer Network</strong> accounts offer 24/7 payment
        capabilities via {isCBITEnabled ? 'CBIT or CUBIX' : 'CUBIX'}.
      </p>
      <p>
        <strong>Domestic Wires</strong> accounts are for US-based wires
        bank-to-bank using Fedwire. Withdrawals made to these accounts will be
        based upon the Fedwire’s processing schedule.
      </p>
      <p>
        <strong>International Wire</strong> accounts provide for withdrawals to
        any global destination. Withdrawals made to these accounts will be based
        upon domestic and international bank processing schedules.
      </p>
      <Tabs
        data-testid="tabs"
        activeKey={tab}
        onChange={(v: FiatTabType) => setTab(v)}
      >
        <Tabs.TabPane tab="Transfer Network" key="network" />
        <Tabs.TabPane tab="Domestic Wire" key="domestic" />
        <Tabs.TabPane tab="International Wire" key="international" />
      </Tabs>
    </>
  )
}

export default FiatTabs
