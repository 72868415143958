import * as React from 'react'
import Title from 'seed-shared-components/lib/components/Title'
import { ITitleItemConfig } from '../../new-world-configuration'

export interface ITitleItemProps extends ITitleItemConfig {}

const TitleFormItem = (props: ITitleItemProps) => (
  <Title level={props.level}>{props.label}</Title>
)

export default TitleFormItem
