import { IHistoryServiceProps } from './props'
import * as React from 'react'
import { notification } from 'antd'
import connect from './connector'
import { IAccountHistory } from '../../../store/accountHistory/state'

class HistoryService extends React.PureComponent<IHistoryServiceProps> {
  async componentDidMount() {
    try {
      const history = await this.loadHistory()
      if (history) {
        this.props.onLoadHistorySuccess(history)
      }
    } catch (errorMessage) {
      this.props.onHistoryLoadFailure()
      notification.error({
        description: errorMessage.message,
        duration: 0,
        message: 'Failed to fetch Account History'
      })
    }
  }

  async loadHistory() {
    const { accountId } = this.props.match.params
    if (accountId === undefined || accountId === null) {
      return null
    }

    return this.props.restService
      .route(`withdrawal_accounts/${accountId}/history?page=0`)
      .get<IAccountHistory>()
  }

  componentWillUnmount() {
    this.props.onUnmount()
  }

  render() {
    return null
  }
}

export default connect(HistoryService)
