import { connect } from 'react-redux'
import {
  ISignPageInputProps,
  ISignPageOutputProps,
  ISignPageExplicitProps
} from './props'
import { IApplicationState } from '../../../store/state'
import * as SignActions from '../../../store/sign/actions'
import selectors from '../../../store/selectors'

export default connect<
  ISignPageInputProps,
  ISignPageOutputProps,
  ISignPageExplicitProps,
  IApplicationState
>(
  (state) => {
    const agreement = selectors.sign.getAgreements(state)
    const application = selectors.sign.getApplication(state)
    return {
      agreement: {
        loading: agreement.loading,
        status: agreement.status
      },
      application: {
        loading: application.loading,
        status: application.status
      },
      valid: selectors.sign.isValid(state),
      authSigner: selectors.sign.isAuthSigner(state)
    }
  },
  (dispatch) => ({
    signAgreementsAndApplication: () =>
      dispatch(SignActions.signAgreementsAndApplication()),
    onChange: (isValid: boolean) =>
      dispatch(SignActions.validateAgreementsForm({ isValid })),
    onLoadAuthSigner: (isAuthSigner: boolean) =>
      dispatch(SignActions.getAuthUserRole({ isAuthSigner }))
  })
)
