import { put, takeEvery, call, select } from 'typed-redux-saga'
import { notification } from 'antd'
import * as appSelectors from '../selectors'
import { IFetchParticipantPositionsAction } from './actions/types'
import * as actions from './actions'
import * as api from './api'

export function* fetchParticipantPositionsSaga(
  action: IFetchParticipantPositionsAction
) {
  const {
    payload: { participantCode }
  } = action
  const { token } = yield select(appSelectors.getUser)

  try {
    const positions = yield call(api.fetchParticipantPositions, {
      token,
      participantCode
    })

    yield put(
      actions.fetchParticipantPositionsFulfill({
        error: false,
        payload: positions
      })
    )
  } catch (error) {
    notification.error({
      duration: 5,
      message: 'Failed fetching positions data',
      description: 'Please try again or contact support.'
    })

    yield put(
      actions.fetchParticipantPositionsFulfill({
        error: true,
        payload: error
      })
    )
  }
}

export function* watchParticipantPositionsSaga() {
  yield takeEvery(
    actions.fetchParticipantPositions.type,
    fetchParticipantPositionsSaga
  )
}
