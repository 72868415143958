const domain = 'user-settings'

export const SET_USER_SETTING = `${domain}/SET_USER_SETTING`
export const SET_USER_SETTINGS = `${domain}/SET_USER_SETTINGS`

export interface ISetUserSettingAction {
  type: typeof SET_USER_SETTING
  payload: ISetUserSettingActionPayload
}

export interface ISetUserSettingActionPayload {
  [k: string]: any
}

export interface ISetUserSettingsAction {
  type: typeof SET_USER_SETTINGS
  payload: ISetUserSettingsActionPayload
}

export interface ISetUserSettingsActionPayload {
  settings: { [k: string]: string }
}
