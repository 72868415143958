import * as React from 'react'
import { states } from 'seed-shared-components/lib/static-data/states'
import { countries } from 'seed-shared-components/lib/static-data/countries'
import { organizationTypes } from './organization-types'
import { incomeLevels } from './income-levels'
import { netAssetLevels } from './net-asset-levels'
import { eligibilities, eligibilitiesPretty, personTypes } from './participant'
import RestService from '../RestService'
import PersonsOnboardingApplicationStep from './components/CustomSteps/PersonsOnboardingApplicationStep'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { WrappedFormUtils } from '@ant-design/compatible/es/form/Form'
import { ValidationRule } from '@ant-design/compatible/es/form'
import {
  IButtonItemConfig,
  ICustomRequestDocumentItemConfig,
  ITitleItemConfig
} from './new-world-configuration'

export type CustomValidator = (rule: any, value: any, callback: Function) => any

export interface IOnboardingApplicationStepConfig {
  stepId: string
  label: string
  route: string
  items: AnyOnboardingApplicationItemConfig[]
  readonly?: boolean
  type: 'step'
}

export interface IOnboardingApplicationCustomStepConfig {
  stepId: string
  label: string
  route: string
  customForm: typeof React.Component
  items: AnyOnboardingApplicationItemConfig[]
  type: 'custom'
  readonly?: boolean // just to bail out of TS issue
}

export type OnboardingApplicationStepConfig =
  | IOnboardingApplicationStepConfig
  | IOnboardingApplicationCustomStepConfig

export type AnyOnboardingApplicationItemConfig =
  | IParagraphItemConfig
  | IOrderedListItemConfig
  | ICheckboxItemConfig
  | ITextInputItemConfig
  | IRadioGroupItemConfig
  | IDropdownItemConfig
  | ISelectItemConfig
  | IDocumentItemConfig
  | IListFormItemConfig
  | ICheckboxGroupItemConfig
  | IPasswordInputItemConfig
  | IButtonItemConfig
  | ICustomRequestDocumentItemConfig
  | ITitleItemConfig

export interface IOnboardingApplicationItemConfig {
  itemKey: string
  type:
    | 'paragraph'
    | 'orderedList'
    | 'text'
    | 'radio'
    | 'checkbox'
    | 'dropdown'
    | 'select'
    | 'document'
    | 'listform'
    | 'checkboxgroup'
    | 'password'

  displayIf?: (
    answers: Object,
    group?: { index: number; key?: string }
  ) => boolean
  extraInstructions?: string
  rules?: ValidationRule[]
  tooltip?: string | JSX.Element | undefined
  // use if the value coming from Ant needs to be
  transformValue?: (value: any) => any
  parentKey?: string
  index?: number
}

export interface IParagraphItemConfig extends IOnboardingApplicationItemConfig {
  type: 'paragraph'
  text: string
  label?: string | JSX.Element
}

export interface IOrderedListItemConfig
  extends IOnboardingApplicationItemConfig {
  type: 'orderedList'
  items: string[]
}

export interface ICheckboxItemConfig extends IOnboardingApplicationItemConfig {
  type: 'checkbox'
  label: string | JSX.Element | ((answers: any) => string)
}

export interface IRadioGroupItemConfig
  extends IOnboardingApplicationItemConfig {
  type: 'radio'
  orientation: 'vertical' | 'horizontal'
  label: string | JSX.Element
  rules?: ValidationRule[]
  options: { label: string; value: any }[]
}

export interface ICheckboxGroupItemConfig
  extends IOnboardingApplicationItemConfig {
  type: 'checkboxgroup'
  label: string | JSX.Element
  rules?: ValidationRule[]
  children: (
    | {
        label: string | JSX.Element
        value: any
        type: 'option'
        displayIf?: (
          answers: Object,
          group?: { index: number; key?: string }
        ) => boolean
        disabled?: boolean
        onChange?: (form: WrappedFormUtils) => (e: CheckboxChangeEvent) => void
      }
    | { label: string | JSX.Element; type: 'label' }
  )[]
}

export interface IDropdownItemConfig extends IOnboardingApplicationItemConfig {
  type: 'dropdown'
  label: string | JSX.Element
  rules?: ValidationRule[]
  isState?: boolean
  options?: { label: string; value: any; disabled?: boolean }[]
  normalize?: (value: any, prevValue: any) => any
  fetchOptions?: (
    restService: RestService,
    answers: any,
    itemKey: string,
    additional?: { [key: string]: any }
  ) => Promise<{ label: string; value: any; disabled?: boolean }[]>
  restService?: RestService
  additional?: { [key: string]: any }
}

export interface ISelectItemConfig extends IOnboardingApplicationItemConfig {
  type: 'select'
  label: string | JSX.Element
  rules?: ValidationRule[]
  isState?: boolean
  options?: { label: string; value: any; disabled?: boolean }[]
  normalize?: (value: any, prevValue: any) => any
  fetchOptions?: (
    restService: RestService,
    answers: any,
    itemKey: string,
    additional?: { [key: string]: any }
  ) => Promise<{ label: string; value: any; disabled?: boolean }[]>
  restService?: RestService
  additional?: { [key: string]: any }
}

export interface IDocumentItemConfig extends IOnboardingApplicationItemConfig {
  type: 'document'
  label: string | JSX.Element | undefined
  rules?: ValidationRule[]
  minimumCount: (answers: any) => number
  restService?: RestService
}

export interface ITextInputItemConfig extends IOnboardingApplicationItemConfig {
  type: 'text'
  label: string | JSX.Element
  dateString?: boolean
  displayIf?: (answers: Object) => boolean
  rules?: ValidationRule[]
  mask?: string
  conditionallyRequired?: (answers: Object) => false | ValidationRule
}

export interface IListFormItemConfig extends IOnboardingApplicationItemConfig {
  type: 'listform'
  label: string | JSX.Element
  items: AnyOnboardingApplicationItemConfig[]
  fixed?: boolean
  minimumCount: (answers: any) => number
  initialCount: (answers: any) => number
  onAdd?: () => void
  onRemove?: (index: number) => void
}

export interface IPasswordInputItemConfig
  extends IOnboardingApplicationItemConfig {
  type: 'password'
  label: string | JSX.Element
  displayIf?: (answers: Object) => boolean
  rules?: ValidationRule[]
}

const isValidURL = (s: string) => {
  if (!s) {
    return false
  }
  const res = s.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  )
  if (res == null) {
    return false
  } else {
    return true
  }
}
const validateDropdown = (
  rule: { field: string },
  value: string,
  callback: (message?: string) => void
) => {
  const message = 'Please select an option from the list'
  if (!value) {
    callback(message)
    return
  }
  if (rule.field === 'address_country' || rule.field === 'passport_country') {
    countries.includes(value) ? callback() : callback(message)
    return
  }
  if (rule.field === 'address_state' || rule.field === 'org_state') {
    states.includes(value) ? callback() : callback(message)
    return
  }
  callback()
}

const isValidDate = (s: string) => {
  if (!s) {
    return true
  }
  const splitDob = s.split('/')
  if (splitDob.length < 3) {
    return false
  }
  const m = splitDob[0]
  const d = splitDob[1]
  const y = splitDob[2]
  // validate each chunk's length
  if (m.length < 2 || d.length < 2 || y.length < 4) {
    return false
  }
  // validate each chunk is a number
  if (!Number(m) || !Number(d) || !Number(y)) {
    return false
  }
  // validate chunks are within proper bounds
  if (
    Number(m) > 12 ||
    Number(d) > 31 ||
    Number(y) > new Date().getFullYear()
  ) {
    return false
  }
  return true
}

const transformDateToParts = (dateString: string) => {
  if (!dateString) {
    return undefined
  }
  const dateParts = dateString.split('/')
  return {
    month: dateParts[0],
    day: dateParts[1],
    year: dateParts[2]
  }
}
const normalizeState = (value: string, prevValue: any) => {
  if (!value || 'Not Applicable'.includes(value)) {
    return value
  }
  if (
    (value.length === 1 &&
      states.map((s) => s.slice(0).includes(value.toUpperCase()))) ||
    states.includes(value.toUpperCase())
  ) {
    return value.toUpperCase()
  }
  return prevValue
}

export const isBusiness = (answers: any) => answers.entity_type === 'business'
export const isIndividual = (answers: any) =>
  answers.entity_type === 'individual'
export const anyChecked = (itemKey: string, answers: any, values: string[]) =>
  answers[itemKey]
    ? Object.keys(answers[itemKey])
        .filter((k) => values.includes(k))
        .some((k) => answers[itemKey][k] === true)
    : false

function fieldMatches<T>(itemKey: string, answers: any, value: T) {
  return answers[itemKey] && answers[itemKey] === value
}

const checkboxGroupContains = (
  itemKey: string,
  answers: any,
  checkboxGroupKey: string
) => {
  return (
    answers[checkboxGroupKey] && answers[checkboxGroupKey][itemKey] === true
  )
}

const listCheckboxGroupContains = (
  itemKey: string,
  answers: any,
  checkboxGroupKey: string,
  index: number,
  parentKey: string
) => {
  return (
    answers[parentKey] &&
    answers[parentKey][index] &&
    answers[parentKey][index][checkboxGroupKey] &&
    answers[parentKey][index][checkboxGroupKey][itemKey] === true
  )
}

function listFieldMatches<T>(
  itemKey: string,
  index: number,
  answers: any,
  value: T,
  parentKey: string
) {
  return (
    answers[parentKey] &&
    answers[parentKey][index] &&
    answers[parentKey][index][itemKey] === value
  )
}

// Match an itemKey within a checkboxGroup
function fieldContainsAndMatches<T>(
  groupKey: string,
  itemKeys: string[],
  index: number,
  answers: any,
  value: T,
  parentKey: string
) {
  return (
    answers[parentKey] &&
    answers[parentKey][index] &&
    answers[parentKey][index][groupKey] &&
    itemKeys.some(
      (itemKey) => answers[parentKey][index][groupKey][itemKey] === value
    )
  )
}

const eligibilitiesOnChange = (form: WrappedFormUtils) => (
  e: CheckboxChangeEvent
) => {
  if (e.target.checked) {
    const eligibilitySCXM: string[] = [
      ...form.getFieldValue('eligibility_scxm')
    ]
    if (eligibilitySCXM.includes(eligibilities.meets_ecp)) {
      return
    }
    eligibilitySCXM.push(eligibilities.meets_ecp)
    form.setFieldsValue({
      eligibility_scxm: eligibilitySCXM
    })
  } else {
    const eligibilitiesForm: string[] = form.getFieldValue('eligibility')
    if (eligibilitiesForm.length > 1) {
      return
    }

    const eligibilitySCXM: string[] = [
      ...form.getFieldValue('eligibility_scxm')
    ]

    const index = eligibilitySCXM.indexOf(eligibilities.meets_ecp)
    if (index > -1) {
      eligibilitySCXM.splice(index, 1)
      form.setFieldsValue({
        eligibility_scxm: eligibilitySCXM
      })
    }
  }
}

const applicationSteps: OnboardingApplicationStepConfig[] = [
  {
    stepId: 'directions',
    label: 'Directions',
    route: `directions`,
    readonly: true,
    type: 'step',
    items: [
      {
        itemKey: 'directions_1',
        text:
          'Thank you for your interest in becoming a Participant with Seed!',
        type: 'paragraph'
      },
      {
        itemKey: 'directions_2',
        text:
          'The process of trading begins with your completion of the following questionnaire. There are a number of sections that are required, which give us a better understanding of the individual or business applying (the “Applicant”), and the Applicant’s legal and financial background.',
        type: 'paragraph'
      },
      {
        itemKey: 'directions_3',
        text:
          'Please note that to trade derivatives on Seed SEF, you must be an  eligible contract participant (“ECP”), as that term is defined in Section 1a(18) of the CEA (7 U.S.C. 1a(18)).',
        type: 'paragraph'
      },
      {
        itemKey: 'directions_4',
        text:
          'Once the application is submitted, Seed will review the information provided, and may require additional information before approving or rejecting the application. Whilst you wait for full approval, you can get started by adding users and building your trading structure, but you will not be able to trade until the Applicant is approved as a Participant and you have funded your account.',
        type: 'paragraph'
      },
      {
        itemKey: 'directions_5',
        text:
          'We look forward to getting your application and having you become a Participant!',
        type: 'paragraph'
      },
      {
        itemKey: 'directions_6',
        text:
          'ATTENTION: Please make and retain a copy of this entire account application for your records.',
        type: 'paragraph'
      }
    ]
  },
  {
    stepId: 'disclosures',
    label: 'Disclosures',
    route: 'disclosures',
    type: 'step',
    items: [
      {
        itemKey: 'disclosures_head',
        type: 'paragraph',
        text:
          'The risk of loss in trading commodity derivative contracts can be substantial. You should, therefore, carefully consider whether such trading is suitable for you in light of your circumstances and financial resources. You should be aware of the following points:'
      },
      {
        itemKey: 'disclosures_list',
        type: 'orderedList',
        items: [
          'Seed is not liable for price fluctuations in any contract. Seed does not own or control the underlying software protocols which govern the operation of digital assets supported on the platform. In general, the underlying protocols are open source and anyone can use, copy, modify, and distribute them.',
          'Trading on margin carries a high level of risk and may not be suitable for all investors. Before deciding to trade products offered on by Seed, the Applicant should carefully consider its objectives, financial situation, needs and level of experience. Applicant should be aware of all the risks associated with trading on margin. The possibility exists that Applicant could sustain a loss of some or all of its deposited funds, and therefore Applicant should not speculate with capital that Applicant cannot afford to lose. Applicant should be aware of all the risks associated with trading on margin. Seed recommends Applicant seek advice from a separate financial advisor before executing any contracts on Seed.',
          'If the market moves against your position, you may be called upon to deposit a substantial amount of additional margin funds, on short notice, in order to maintain your position. If you do not provide the required funds within the time required, your position may be liquidated at a loss, and you will be liable for any resulting deficit in your account.'
        ]
      },
      {
        itemKey: 'disclosure_affirmation',
        label: 'Yes, I have read and understand the above disclosures.',
        type: 'checkbox',
        transformValue: (value: any) => ({ true: value }),
        rules: [
          {
            validator: (rule, value, callback) =>
              value ? callback() : callback('You must affirm to continue')
          }
        ]
      }
    ]
  },
  {
    stepId: 'application-type',
    label: 'Application Type',
    route: 'application_type',
    type: 'step',
    items: [
      {
        itemKey: 'entity_type',
        label: 'What type of entity are you?',
        type: 'radio',
        orientation: 'horizontal',
        options: [
          { label: 'Individual', value: 'individual' },
          { label: 'Business', value: 'business' }
        ],
        rules: [{ required: true, message: 'Please choose an entity type' }]
      }
    ]
  },
  {
    stepId: 'application-information',
    label: 'Application Information',
    route: 'application_information',
    type: 'step',
    items: [
      {
        itemKey: 'legal_name',
        label: 'Legal name of applicant',
        displayIf: isIndividual,
        type: 'text',
        rules: [
          {
            required: true,
            message: 'Please enter the Legal name',
            whitespace: true
          },
          { min: 5, message: 'Please enter at least 5 characters' }
        ]
      },
      {
        itemKey: 'legal_name',
        label: 'Legal Entity Name',
        displayIf: isBusiness,
        type: 'text',
        rules: [
          {
            required: true,
            message: 'Please enter the Legal name',
            whitespace: true
          },
          { min: 5, message: 'Please enter at least 5 characters' }
        ]
      },
      {
        itemKey: 'email',
        label: 'Email',
        type: 'text',
        rules: [
          { required: true, message: 'Please enter an email' },
          { type: 'email', message: 'Please enter a valid email address' }
        ],
        displayIf: isIndividual
      },
      {
        itemKey: 'address_line1',
        label: 'Address Line 1',
        extraInstructions: 'P.O. Boxes are not permitted',
        type: 'text',
        rules: [
          {
            required: true,
            message: 'Please enter an Address',
            whitespace: true
          },
          { min: 5, message: 'Please enter at least 5 characters' }
        ]
      },
      { itemKey: 'address_line2', label: 'Address Line 2', type: 'text' },
      {
        itemKey: 'address_city',
        label: 'City',
        type: 'text',
        rules: [
          { required: true, message: 'Please enter a city', whitespace: true }
        ]
      },
      {
        itemKey: 'address_state',
        label: 'State',
        type: 'dropdown',
        options: states.map((s) => ({ label: s, value: s })),
        rules: [
          { required: true, message: 'Please select a State' },
          { validator: validateDropdown }
        ],
        normalize: normalizeState
      },
      {
        itemKey: 'address_zip',
        label: 'Zip',
        type: 'text',
        rules: [
          {
            required: true,
            message: 'Please enter a zip code',
            whitespace: true
          }
        ]
      },
      {
        itemKey: 'address_country',
        label: 'Country',
        type: 'dropdown',
        options: countries.map((s) => ({ label: s, value: s })),
        rules: [
          { required: true, message: 'Please select a Country' },
          { validator: validateDropdown }
        ]
      },
      {
        itemKey: 'ind_occupation',
        label: 'Occupation',
        type: 'text',
        displayIf: isIndividual,
        rules: [
          {
            required: true,
            message: 'Please enter your occupation',
            whitespace: true
          }
        ]
      },
      {
        itemKey: 'org_website',
        label: 'Business Website',
        type: 'text',
        displayIf: isBusiness,
        rules: [
          { required: true, message: 'Please enter a website' },
          {
            validator: (rule, value, callback) =>
              isValidURL(value)
                ? callback()
                : callback('Please enter a valid URL')
          }
        ]
      },
      {
        itemKey: 'org_type',
        label: 'Organization Type',
        type: 'radio',
        orientation: 'horizontal',
        displayIf: isBusiness,
        options: [
          { label: organizationTypes.corp, value: 'corp' },
          { label: organizationTypes.llc, value: 'llc' },
          { label: organizationTypes.partnership, value: 'partnership' },
          { label: organizationTypes.other, value: 'other' }
        ],
        rules: [
          { required: true, message: 'Please select an Organization Type' }
        ]
      },
      {
        itemKey: 'org_state',
        label: 'State of Organization',
        type: 'dropdown',
        displayIf: isBusiness,
        options: states.map((s) => ({ label: s, value: s })),
        rules: [
          { required: true, message: 'Please select a State' },
          { validator: validateDropdown }
        ],
        normalize: normalizeState
      },
      {
        itemKey: 'org_established',
        label: 'Date Established',
        dateString: true,
        type: 'text',
        mask: '99/99/9999',
        displayIf: isBusiness,
        rules: [
          {
            required: true,
            message:
              'Please enter the date the organization was established (MM/DD/YYYY)'
          },
          {
            validator: (rule, value, callback) =>
              isValidDate(value)
                ? callback()
                : callback('Please enter a valid date')
          }
        ]
      },
      {
        itemKey: 'tax_id_b',
        label: 'EIN',
        type: 'text',
        rules: [
          { required: false, message: 'Please enter the EIN', whitespace: true }
        ],
        tooltip:
          'The Employer Identification Number (EIN) is a unique nine-digit number assigned by the Internal Revenue Service (IRS) to the Applicant.',
        displayIf: isBusiness,
        conditionallyRequired: (answers: any) => {
          if (answers.address_country === 'United States of America') {
            return {
              required: true,
              message: 'Please enter the EIN of the applicant',
              whitespace: true
            }
          }
          return false
        }
      },
      {
        itemKey: 'contact_number',
        label: 'Contact Number',
        type: 'text',
        rules: [
          {
            required: true,
            message: 'Please enter a Phone Number',
            whitespace: true
          }
        ],
        displayIf: isIndividual
      },
      {
        itemKey: 'ind_photo_id',
        label: 'Passport, State or National ID Number',
        type: 'text',
        rules: [
          {
            required: true,
            message: 'Please enter a Passport, State or National ID Number',
            whitespace: true
          }
        ],
        displayIf: isIndividual
      },
      {
        itemKey: 'ind_dob',
        dateString: true,
        label: 'Date of Birth (MM/DD/YYYY)',
        type: 'text',
        mask: '99/99/9999',
        displayIf: isIndividual,
        rules: [
          { required: true, message: 'Please enter a Date of Birth' },
          {
            validator: (rule, value, callback) =>
              isValidDate(value)
                ? callback()
                : callback('Please enter a valid date')
          }
        ]
      },
      {
        itemKey: 'business_lei',
        label: 'Legal Entity Identifier',
        extraInstructions: 'Required for derivatives trading on Seed SEF only',
        rules: [
          {
            message: 'Please enter a Legal Entity Identifier',
            whitespace: true
          },
          { min: 20, message: 'Please enter at least 20 characters' }
        ],
        type: 'text',
        tooltip: (
          <span>
            <span>
              Businesses intending to trade derivatives on Seed SEF must provide
              an LEI.
            </span>
            <br />
            <br />
            <span>
              Legal Entity Identifier (LEI) numbers are 20-digit, alphanumeric
              codes that uniquely identify financial market participants around
              the globe. LEIs can be obtained via{' '}
              <a href="https://www.gmeiutility.org/" target="_blank">
                GMEI Utility
              </a>
              .
            </span>
          </span>
        ),
        displayIf: isBusiness
      },
      {
        itemKey: 'individual_lei',
        label: 'Legal Entity Identifier',
        extraInstructions: 'Required for derivatives trading on Seed SEF only',
        rules: [
          {
            min: 20,
            message: 'Please enter at least 20 characters',
            whitespace: true
          }
        ],
        type: 'text',
        tooltip: (
          <span>
            <span>
              Individuals who intend to trade derivatives on Seed SEF and who
              act in a business capacity must provide an LEI. Please read the{' '}
              <a
                href="https://www.leiroc.org/publications/gls/lou_20150930-1.pdf"
                target="_blank"
              >
                LEI Regulatory Oversight Committee’s publication
              </a>{' '}
              to determine whether you, as an individual, act in a business
              capacity.
            </span>
            <br />
            <br />
            <span>
              LEIs can be obtained via the{' '}
              <a href="https://www.gmeiutility.org/" target="_blank">
                GMEI Utility
              </a>
              . Individual participants do not require an LEI to proceed. Some
              individuals may have an LEI which we request.
            </span>
          </span>
        ),
        displayIf: isIndividual
      },
      {
        itemKey: 'confirm_not_acting_in_business_capacity',
        label:
          'LEIs are required for all individuals acting in a business capacity. If you do not provide an LEI, please confirm you are not acting in a business capacity.',
        type: 'radio',
        orientation: 'horizontal',
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ],
        displayIf: (answers: any) =>
          answers.entity_type === 'individual' && !answers.individual_lei
      },
      {
        itemKey: 'trading_experience',
        label: "What is the Applicant's trading experience level?",
        type: 'radio',
        orientation: 'horizontal',
        options: [
          { label: 'Beginner', value: 'beginner' },
          { label: 'Intermediate', value: 'intermediate' },
          { label: 'Advanced', value: 'advanced' },
          { label: 'Expert', value: 'expert' }
        ],
        rules: [
          { required: true, message: 'Please select the trading experience' }
        ]
      },
      {
        itemKey: 'united_states_ssn',
        label: <span>Do you have a US SSN?</span>,
        type: 'radio',
        orientation: 'horizontal',
        rules: [{ required: true, message: 'Please select' }],
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ],
        displayIf: isIndividual
      },
      {
        itemKey: 'tax_id_i',
        label: 'Passport Number',
        type: 'password',
        rules: [
          {
            required: true,
            message: `Please enter the Applicant's Passport number`,
            whitespace: true
          },
          { min: 8, message: 'Please enter at least 8 characters' }
        ],
        displayIf: (answers: any) =>
          answers.entity_type === 'individual' &&
          answers.united_states_ssn === false
      },
      {
        itemKey: 'tax_id_i',
        label: 'United States SSN',
        type: 'password',
        rules: [
          {
            required: true,
            message: 'Please enter the Social Security Number of the Applicant',
            whitespace: true
          },
          { min: 8, message: 'Please enter at least 8 characters' }
        ],
        displayIf: (answers: any) =>
          answers.entity_type === 'individual' &&
          answers.united_states_ssn === true
      },
      {
        itemKey: 'passport_country',
        label: 'Passport Country',
        type: 'dropdown',
        options: countries.map((s) => ({ label: s, value: s })),
        displayIf: (answers: any) => {
          if (answers) {
            return (
              answers.entity_type === 'individual' &&
              answers.united_states_ssn === false
            )
          }
          return false
        },
        rules: [
          { required: true, message: 'Please select a Country' },
          { validator: validateDropdown }
        ]
      },
      {
        itemKey: 'passport_document',
        label: 'Passport Document',
        type: 'document',
        minimumCount: () => 1,
        displayIf: (answers: any) => {
          if (answers) {
            return (
              answers.entity_type === 'individual' &&
              answers.united_states_ssn === false
            )
          }
          return false
        },
        rules: [{ required: true, message: 'Please upload a document' }]
      },
      {
        itemKey: 'photo_id',
        label: 'Photo ID',
        displayIf: isIndividual,
        type: 'document',
        minimumCount: () => 1,
        rules: [{ required: true, message: 'Please upload a document' }]
      },
      {
        itemKey: 'proof_address',
        label: 'Proof of Address',
        type: 'document',
        minimumCount: () => 1,
        rules: [{ required: true, message: 'Please upload a document' }],
        tooltip:
          'Please provide proof of address (acceptable forms of proof may be a bank statement or utility bill in the name of the applicant entity).'
      },
      {
        itemKey: 'articles_incorporation',
        label: 'Articles of Incorporation',
        displayIf: isBusiness,
        type: 'document',
        minimumCount: () => 1,
        rules: [{ required: true, message: 'Please upload a document' }]
      },
      {
        itemKey: 'cert_good_standing',
        label: 'Certificate of Good Standing',
        displayIf: isBusiness,
        type: 'document',
        minimumCount: () => 1,
        rules: [{ required: true, message: 'Please upload a document' }]
      },
      {
        itemKey: 'bylaws',
        label: 'Corporate Bylaws',
        displayIf: isBusiness,
        type: 'document',
        minimumCount: () => 1,
        rules: [{ required: true, message: 'Please upload a document' }]
      }
    ]
  },
  {
    stepId: 'authorized-signers',
    label: 'Authorized Signers',
    route: 'authorized_signers',
    type: 'step',
    items: [
      {
        itemKey: 'directions_1',
        text:
          'We require at least one Authorized Signer who meet the definition of being an Authorized Signer as defined in the Electronic Signature Agreement, a signature on behalf of the Applicant, and to provide at least one Control Person of the business. Additionally, for the Control Person a scan of an identification document with a picture needs to be uploaded on the Documents page of this Application. If the Applicant only has on Authorized Signer then simply add one, however we will review and may require you to authorize as second user.',
        type: 'paragraph'
      },
      {
        itemKey: 'authorized_signers',
        label: 'Authorized Signer',
        type: 'listform',
        minimumCount: () => 1,
        initialCount: () => 1,
        items: [
          {
            itemKey: 'name',
            label: 'Name',
            type: 'text',
            rules: [
              {
                required: true,
                message: 'Please enter a name',
                whitespace: true
              }
            ]
          },
          {
            itemKey: 'title',
            label: 'Title',
            type: 'text',
            rules: [
              {
                required: true,
                message: 'Please enter a Title',
                whitespace: true
              }
            ]
          },
          {
            itemKey: 'email',
            label: 'Email',
            type: 'text',
            rules: [
              {
                required: true,
                message: 'Please enter a vaild email address',
                whitespace: true,
                type: 'email'
              }
            ]
          },
          {
            itemKey: 'phone',
            label: 'Phone',
            type: 'text',
            rules: [
              {
                required: true,
                message: 'Please enter a phone number',
                whitespace: true
              }
            ]
          },
          {
            itemKey: 'dob',
            dateString: true,
            label: 'Date of Birth (MM/DD/YYYY)',
            type: 'text',
            mask: '99/99/9999',
            transformValue: transformDateToParts,
            rules: [
              {
                required: true,
                message: 'Please enter the Date of Birth',
                whitespace: true
              },
              {
                validator: (rule, value, callback) =>
                  isValidDate(value)
                    ? callback()
                    : callback('Please enter a valid date')
              }
            ]
          },
          {
            itemKey: 'united_states_ssn',
            label: <span>Does this person have a United States SSN?</span>,
            type: 'radio',
            orientation: 'horizontal',
            rules: [{ required: true, message: 'Please select' }],
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]
          },
          {
            itemKey: 'ssn',
            label: 'Passport number',
            type: 'password',
            rules: [
              {
                required: true,
                message: 'Please enter the Passport number of the individual.',
                whitespace: true
              },
              { min: 8, message: 'Please enter at least 8 characters' }
            ],
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return listFieldMatches(
                  'united_states_ssn',
                  group.index,
                  answers,
                  false,
                  group.key
                )
              }
              return true
            }
          },
          {
            itemKey: 'ssn',
            label: 'United States SSN',
            type: 'password',
            rules: [
              {
                required: true,
                message:
                  'Please enter the US Social Security Number of the individual',
                whitespace: true
              },
              { min: 8, message: 'Please enter at least 8 characters' }
            ],
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return listFieldMatches(
                  'united_states_ssn',
                  group.index,
                  answers,
                  true,
                  group.key
                )
              }
              return true
            }
          },
          {
            itemKey: 'passport_country',
            label: 'Passport Country',
            type: 'dropdown',
            options: countries.map((s) => ({ label: s, value: s })),
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return listFieldMatches(
                  'united_states_ssn',
                  group.index,
                  answers,
                  false,
                  group.key
                )
              }
              return true
            },
            rules: [
              { required: true, message: 'Please select a Country' },
              { validator: validateDropdown }
            ]
          },
          {
            itemKey: 'passport_document',
            label: 'Passport Document',
            type: 'document',
            minimumCount: () => 1,
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return listFieldMatches(
                  'united_states_ssn',
                  group.index,
                  answers,
                  false,
                  group.key
                )
              }
              return true
            },
            rules: [{ required: true, message: 'Please upload a document' }]
          }
        ]
      }
    ]
  },
  {
    stepId: 'persons',
    label: 'Persons',
    route: 'persons',
    type: 'custom',
    customForm: PersonsOnboardingApplicationStep,
    items: [
      {
        itemKey: 'persons',
        type: 'listform',
        label: 'Person',
        minimumCount: () => 1,
        initialCount: () => 1,
        items: [
          {
            itemKey: 'name',
            label: 'Name',
            type: 'text',
            rules: [
              {
                required: true,
                message: 'Please enter a name',
                whitespace: true
              }
            ]
          },
          {
            itemKey: 'dob',
            dateString: true,
            label: 'Date of Birth (MM/DD/YYYY)',
            type: 'text',
            mask: '99/99/9999',
            transformValue: transformDateToParts,
            rules: [
              {
                required: true,
                message: 'Please enter the Date of Birth',
                whitespace: true
              },
              {
                validator: (rule, value, callback) =>
                  isValidDate(value)
                    ? callback()
                    : callback('Please enter a valid date')
              }
            ]
          },
          {
            itemKey: 'type',
            label: 'What roles will this person have, as defined above?',
            type: 'checkboxgroup',
            children: [
              {
                label: personTypes.authorized_signer,
                value: personTypes.authorized_signer,
                type: 'option'
              },
              {
                label: personTypes.beneficial_owner,
                value: personTypes.beneficial_owner,
                type: 'option'
              },
              {
                label: personTypes.control_person,
                value: personTypes.control_person,
                type: 'option',
                displayIf: (
                  answers: any,
                  group?: { index: number; key: string }
                ) => {
                  if (group) {
                    if (
                      listCheckboxGroupContains(
                        personTypes.control_person,
                        answers,
                        'type',
                        group.index,
                        group.key
                      )
                    ) {
                      return true
                    } else {
                      if (answers && answers.persons) {
                        const noControlPerson = !answers.persons.some(
                          (person: any) =>
                            person.type &&
                            person.type[personTypes.control_person] === true
                        )
                        return noControlPerson
                      }
                    }
                  }
                  return true
                }
              }
            ],
            rules: [{ required: true, message: 'Please select at least one' }]
          },
          {
            itemKey: 'united_states_ssn',
            label: <span>Does this person have a United States SSN?</span>,
            type: 'radio',
            orientation: 'horizontal',
            rules: [{ required: true, message: 'Please select' }],
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return fieldContainsAndMatches(
                  'type',
                  [
                    personTypes.authorized_signer,
                    personTypes.beneficial_owner,
                    personTypes.control_person
                  ],
                  group.index,
                  answers,
                  true,
                  group.key
                )
              }
              return true
            }
          },
          {
            itemKey: 'ssn',
            label: 'Passport number',
            type: 'password',
            rules: [
              {
                required: true,
                message: 'Please enter the Passport number of the individual.',
                whitespace: true
              },
              { min: 8, message: 'Please enter at least 8 characters' }
            ],
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return listFieldMatches(
                  'united_states_ssn',
                  group.index,
                  answers,
                  false,
                  group.key
                )
              }
              return true
            }
          },
          {
            itemKey: 'ssn',
            label: 'United States SSN',
            type: 'password',
            rules: [
              {
                required: true,
                message:
                  'Please enter the US Social Security Number of the individual',
                whitespace: true
              },
              { min: 8, message: 'Please enter at least 8 characters' }
            ],
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return listFieldMatches(
                  'united_states_ssn',
                  group.index,
                  answers,
                  true,
                  group.key
                )
              }
              return true
            }
          },
          {
            itemKey: 'passport_country',
            label: 'Passport Country',
            type: 'dropdown',
            options: countries.map((s) => ({ label: s, value: s })),
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return listFieldMatches(
                  'united_states_ssn',
                  group.index,
                  answers,
                  false,
                  group.key
                )
              }
              return true
            },
            rules: [
              { required: true, message: 'Please select a Country' },
              { validator: validateDropdown }
            ]
          },
          {
            itemKey: 'passport_document',
            label: 'Passport Document',
            type: 'document',
            minimumCount: () => 1,
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return listFieldMatches(
                  'united_states_ssn',
                  group.index,
                  answers,
                  false,
                  group.key
                )
              }
              return true
            },
            rules: [{ required: true, message: 'Please upload a document' }]
          },
          {
            itemKey: 'title',
            label: 'Title',
            type: 'text',
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return fieldContainsAndMatches(
                  'type',
                  [personTypes.authorized_signer],
                  group.index,
                  answers,
                  true,
                  group.key
                )
              }
              return true
            },
            rules: [
              {
                required: true,
                message: 'Please enter a Title',
                whitespace: true
              }
            ]
          },
          {
            itemKey: 'email',
            label: 'Email',
            type: 'text',
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return fieldContainsAndMatches(
                  'type',
                  [personTypes.authorized_signer],
                  group.index,
                  answers,
                  true,
                  group.key
                )
              }
              return true
            },
            rules: [
              {
                required: true,
                message: 'Please enter a vaild email address',
                whitespace: true,
                type: 'email'
              }
            ]
          },
          {
            itemKey: 'phone',
            label: 'Phone',
            type: 'text',
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return fieldContainsAndMatches(
                  'type',
                  [personTypes.authorized_signer],
                  group.index,
                  answers,
                  true,
                  group.key
                )
              }
              return true
            },
            rules: [
              {
                required: true,
                message: 'Please enter a phone number',
                whitespace: true
              }
            ]
          },

          {
            itemKey: 'address_line1',
            label: 'Address Line 1',
            extraInstructions: 'P.O. Boxes are not permitted',
            type: 'text',
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return fieldContainsAndMatches(
                  'type',
                  [personTypes.control_person, personTypes.beneficial_owner],
                  group.index,
                  answers,
                  true,
                  group.key
                )
              }
              return true
            },
            rules: [
              {
                required: true,
                message: 'Please enter an Address',
                whitespace: true
              },
              { min: 5, message: 'Please enter at least 5 characters' }
            ]
          },
          {
            itemKey: 'address_line2',
            label: 'Address Line 2',
            type: 'text',
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return fieldContainsAndMatches(
                  'type',
                  [personTypes.control_person, personTypes.beneficial_owner],
                  group.index,
                  answers,
                  true,
                  group.key
                )
              }
              return true
            }
          },
          {
            itemKey: 'address_city',
            label: 'City',
            type: 'text',
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return fieldContainsAndMatches(
                  'type',
                  [personTypes.control_person, personTypes.beneficial_owner],
                  group.index,
                  answers,
                  true,
                  group.key
                )
              }
              return true
            },
            rules: [
              {
                required: true,
                message: 'Please enter a city',
                whitespace: true
              }
            ]
          },
          {
            itemKey: 'address_state',
            label: 'State',
            type: 'dropdown',
            options: states.map((s) => ({ label: s, value: s })),
            rules: [
              { required: true, message: 'Please select a State' },
              { validator: validateDropdown }
            ],
            normalize: normalizeState,
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return fieldContainsAndMatches(
                  'type',
                  [personTypes.control_person, personTypes.beneficial_owner],
                  group.index,
                  answers,
                  true,
                  group.key
                )
              }
              return true
            }
          },
          {
            itemKey: 'address_zip',
            label: 'Zip',
            type: 'text',
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return fieldContainsAndMatches(
                  'type',
                  [personTypes.control_person, personTypes.beneficial_owner],
                  group.index,
                  answers,
                  true,
                  group.key
                )
              }
              return true
            },
            rules: [
              {
                required: true,
                message: 'Please enter a zip code',
                whitespace: true
              }
            ]
          },
          {
            itemKey: 'address_country',
            label: 'Country',
            type: 'dropdown',
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                return fieldContainsAndMatches(
                  'type',
                  [personTypes.control_person, personTypes.beneficial_owner],
                  group.index,
                  answers,
                  true,
                  group.key
                )
              }
              return true
            },
            options: countries.map((s) => ({ label: s, value: s })),
            rules: [
              { required: true, message: 'Please select a Country' },
              { validator: validateDropdown }
            ]
          },
          {
            itemKey: 'contact_person',
            label: 'Contact Person for communication purposes.',
            type: 'checkbox',
            displayIf: (
              answers: any,
              group?: { index: number; key: string }
            ) => {
              if (group) {
                const isAuthorizedSigner = fieldContainsAndMatches(
                  'type',
                  [personTypes.authorized_signer],
                  group.index,
                  answers,
                  true,
                  group.key
                )
                if (isAuthorizedSigner) {
                  if (
                    listFieldMatches(
                      'contact_person',
                      group.index,
                      answers,
                      true,
                      group.key
                    )
                  ) {
                    return true
                  } else {
                    if (answers && answers.persons) {
                      const ans = !answers.persons.some(
                        (person: any) => person.contact_person
                      )
                      return ans
                    }
                  }
                }
              }
              return false
            }
          }
        ]
      }
    ]
  },
  {
    stepId: 'financial-information',
    label: 'Financial Information',
    route: 'financial_information',
    type: 'step',
    items: [
      {
        itemKey: 'bankruptcy',
        label: (
          <span>
            Has the Applicant, by any name, filed for bankruptcy within the past
            three (3) years?
          </span>
        ),
        type: 'radio',
        orientation: 'horizontal',
        rules: [{ required: true, message: 'Please check' }],
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]
      },
      {
        itemKey: 'bankruptcy_year',
        label: 'Year(s) of bankruptcy',
        type: 'text',
        rules: [
          {
            required: true,
            message: 'Please enter the year(s) of bankruptcy',
            whitespace: true
          }
        ],
        displayIf: (answers: any) => answers.bankruptcy === true
      },
      {
        itemKey: 'fin_income',
        label: "Applicant's annual income",
        type: 'radio',
        orientation: 'vertical',
        options: [
          { label: incomeLevels['1'], value: 1 },
          { label: incomeLevels['2'], value: 2 },
          { label: incomeLevels['3'], value: 3 },
          { label: incomeLevels['4'], value: 4 },
          { label: incomeLevels['5'], value: 5 },
          { label: incomeLevels['6'], value: 6 },
          { label: incomeLevels['7'], value: 7 }
        ],
        rules: [{ required: true, message: 'Please select an annual income' }]
      },
      {
        itemKey: 'net_assets',
        label: "What are the Applicant's net assets?",
        type: 'radio',
        orientation: 'vertical',
        options: [
          { label: netAssetLevels['1'], value: 1 },
          { label: netAssetLevels['2'], value: 2 },
          { label: netAssetLevels['3'], value: 3 },
          { label: netAssetLevels['4'], value: 4 }
        ],
        rules: [
          {
            required: true,
            message: "Please select the Applicant's net assets"
          }
        ]
      },
      {
        itemKey: 'source_of_funds',
        label: 'What is the Applicant’s source of funds?',
        type: 'text',
        rules: [
          {
            required: true,
            message: "Please add the Applicant's source of funds.",
            whitespace: true
          }
        ]
      }
    ]
  },
  {
    stepId: 'legal',
    label: 'Legal',
    route: 'legal',
    type: 'step',
    items: [
      {
        itemKey: 'legal_disqual',
        label: (
          <span>
            Is the Applicant subject to any statutory disqualification under{' '}
            <a
              href="https://www.law.cornell.edu/uscode/text/7/12a"
              target="_blank"
            >
              CEA Section 8a(2) or 8a(3)
            </a>
            ?
          </span>
        ),
        tooltip:
          '“Statutory disqualification” occurs when  person is enjoined temporarily or permanently from violating the securities or commodities laws by a court of competent jurisdiction; is barred or suspended from association with a broker-dealer or futures commission merchant by the Securities and Exchange Commission, the Commodities Futures Trading Commission, a self-regulatory organization (SRO) or foreign equivalent; or has been convicted of any felony or certain misdemeanors within the last ten years.',
        type: 'radio',
        orientation: 'horizontal',
        rules: [{ required: true, message: 'Please select' }],
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]
      },
      {
        itemKey: 'legal_officer',
        label: (
          <span>
            Is the Applicant an Officer or employee of Seed SEF or one of its
            Affiliates?
          </span>
        ),
        type: 'radio',
        orientation: 'horizontal',
        rules: [{ required: true, message: 'Please select' }],
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]
      },
      {
        itemKey: 'us_person_dodd',
        label: (
          <span>
            Is the Applicant acting as a U.S. person, with “person” as defined
            in{' '}
            <a
              href="https://www.law.cornell.edu/cfr/text/17/1.3"
              target="_blank"
            >
              17 CFR § 1.3 to mean individual or business
            </a>
            ?
          </span>
        ),
        tooltip:
          'U.S. persons are individuals or entities with a principal place of residence or business in the United States, or entities that are directly or indirectly majority owned by U.S. persons.',
        type: 'radio',
        orientation: 'horizontal',
        rules: [{ required: true, message: 'Please select' }],
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]
      },
      {
        itemKey: 'swap_dealer',
        label: (
          <span>
            Is the Applicant a Swap Dealer, as defined in{' '}
            <a
              href="https://www.law.cornell.edu/cfr/text/17/1.3"
              target="_blank"
            >
              17 CFR § 1.3
            </a>
            ?
          </span>
        ),
        tooltip:
          'A Swap Dealer is any person that (1) holds itself out as a dealer in swaps; (2) makes a market in swaps; (3) regularly enters into swaps with counterparties as an ordinary course of business for its own account; or (4) Engages in any activity causing it to be commonly known in the trade as a dealer or market maker in swaps.  There are various exceptions to the Swap Dealer definition, which can be found in 17 CFR § 1.3.',
        type: 'radio',
        orientation: 'horizontal',
        rules: [{ required: true, message: 'Please select' }],
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]
      },
      {
        itemKey: 'major_swap_participant',
        label: (
          <span>
            Is the Applicant a Major Swap Participant, as defined in{' '}
            <a
              href="https://www.law.cornell.edu/cfr/text/17/1.3"
              target="_blank"
            >
              17 CFR § 1.3
            </a>
            ?
          </span>
        ),
        tooltip:
          'A Major Swap Participant is a large trader in the swaps markets that is not Swap Dealer.',
        type: 'radio',
        orientation: 'horizontal',
        rules: [{ required: true, message: 'Please select' }],
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]
      },
      {
        itemKey: 'entity_dodd',
        label: (
          <span>
            Is the Applicant a Financial Entity, as defined in{' '}
            <a
              href="https://www.law.cornell.edu/uscode/text/7/2"
              target="_blank"
            >
              CEA section 2(h)(7)(C)
            </a>
            ?
          </span>
        ),
        tooltip:
          'Examples include banks, swap dealers, major swap participants, commodity pools, or private funds.',
        type: 'radio',
        orientation: 'horizontal',
        rules: [{ required: true, message: 'Please select' }],
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]
      },
      {
        itemKey: 'nfa',
        label: <span>Is the Applicant registered with NFA?</span>,
        type: 'radio',
        orientation: 'horizontal',
        rules: [{ required: true, message: 'Please select' }],
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]
      },
      {
        itemKey: 'nfa_id',
        label: 'NFA ID',
        type: 'text',
        rules: [{ required: true, message: 'Please select', whitespace: true }],
        displayIf: (answers: any) => fieldMatches<boolean>('nfa', answers, true)
      },
      {
        itemKey: 'finra',
        label: <span>Is the Applicant registered with FINRA?</span>,
        type: 'radio',
        orientation: 'horizontal',
        rules: [{ required: true, message: 'Please select' }],
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]
      },
      {
        itemKey: 'CRD_id',
        label: 'CRD ID',
        type: 'text',
        rules: [{ required: true, message: 'Please select', whitespace: true }],
        displayIf: (answers: any) =>
          fieldMatches<boolean>('finra', answers, true)
      }
    ]
  },
  {
    stepId: 'eligibility-step',
    label: 'Eligibility',
    route: 'eligibility',
    type: 'step',
    items: [
      {
        itemKey: 'eligibility',
        label: (
          <>
            In order to gain access to Seed SEF to trade derivatives, Applicant
            must qualify as an Eligible Contract Participant (ECP). For the
            legal definition of an ECP, please see this{' '}
            <a
              href="https://www.law.cornell.edu/uscode/text/7/1a"
              target="_blank"
            >
              link
            </a>
            . Please select the ECP classification(s) that apply to the
            Applicant:
          </>
        ),
        type: 'checkboxgroup',
        children: [
          { label: 'Qualified Business Entity', type: 'label' },
          {
            label: eligibilitiesPretty[eligibilities.business_10m],
            value: eligibilities.business_10m,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: eligibilitiesPretty[eligibilities.business_ecp],
            value: eligibilities.business_ecp,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: eligibilitiesPretty[eligibilities.business_hedge],
            value: eligibilities.business_hedge,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          { label: 'Qualified Individual', type: 'label' },
          {
            label: eligibilitiesPretty[eligibilities.ind_10m],
            value: eligibilities.ind_10m,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: eligibilitiesPretty[eligibilities.ind_hedge],
            value: eligibilities.ind_hedge,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          { label: 'Financial Institution', type: 'label' },
          {
            label: eligibilitiesPretty[eligibilities.financial_agreement],
            value: eligibilities.financial_agreement,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: eligibilitiesPretty[eligibilities.financial_edge],
            value: eligibilities.financial_edge,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: eligibilitiesPretty[eligibilities.financial_farm_credit],
            value: eligibilities.financial_farm_credit,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: eligibilitiesPretty[eligibilities.financial_credit_union],
            value: eligibilities.financial_credit_union,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: eligibilitiesPretty[eligibilities.financial_depository],
            value: eligibilities.financial_depository,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: eligibilitiesPretty[eligibilities.financial_foreign],
            value: eligibilities.financial_foreign,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: eligibilitiesPretty[eligibilities.financial_holding],
            value: eligibilities.financial_holding,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: eligibilitiesPretty[eligibilities.financial_trust],
            value: eligibilities.financial_trust,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          { label: 'Investment Company', type: 'label' },
          {
            label: eligibilitiesPretty[eligibilities.investment_company],
            value: eligibilities.investment_company,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: eligibilitiesPretty[eligibilities.investment_foreign],
            value: eligibilities.investment_foreign,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          { label: 'Commodity Pool', type: 'label' },
          {
            label: eligibilitiesPretty[eligibilities.pool],
            value: eligibilities.pool,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: eligibilitiesPretty[eligibilities.pool_10m],
            value: eligibilities.pool_10m,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          { label: 'Registered Broker-Dealer', type: 'label' },
          {
            label: eligibilitiesPretty[eligibilities.brokerdealer],
            value: eligibilities.brokerdealer,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: eligibilitiesPretty[eligibilities.brokerdealer_associate],
            value: eligibilities.brokerdealer_associate,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: eligibilitiesPretty[eligibilities.ibank],
            value: eligibilities.ibank,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: 'Registered Futures Commission Merchant',
            type: 'label'
          },
          {
            label: eligibilitiesPretty[eligibilities.fut_merchant],
            value: eligibilities.fut_merchant,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          { label: 'Employee Benefit Plan', type: 'label' },
          {
            label: (
              <>
                An employee benefit plan subject to the Employee Retirement
                Income Security Act (ERISA), a governmental employee benefit
                plan, or a foreign person performing a similar role or function
                subject as such to foreign regulation that meets the
                requirements described{' '}
                <a
                  href="https://www.law.cornell.edu/uscode/text/7/1a"
                  target="_blank"
                >
                  here
                </a>
              </>
            ),
            value: eligibilities.ebp,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          { label: 'Floor Broker or Trader', type: 'label' },
          {
            label: eligibilitiesPretty[eligibilities.floor_broker],
            value: eligibilities.floor_broker,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          { label: 'Insurance Company', type: 'label' },
          {
            label: eligibilitiesPretty[eligibilities.insurance],
            value: eligibilities.insurance,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          { label: 'Governmental Entity', type: 'label' },
          {
            label: eligibilitiesPretty[eligibilities.govt],
            value: eligibilities.govt,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          { label: 'Swap Participant', type: 'label' },
          {
            label: (
              <>
                A Major Swap Participant, as defined in{' '}
                <a
                  href="https://www.law.cornell.edu/cfr/text/17/1.3"
                  target="_blank"
                >
                  17 CFR § 1.3
                </a>
              </>
            ),
            value: eligibilities.major_swap_participant,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: (
              <>
                A Swap Dealer, as defined in{' '}
                <a
                  href="https://www.law.cornell.edu/cfr/text/17/1.3"
                  target="_blank"
                >
                  17 CFR § 1.3
                </a>
              </>
            ),
            value: eligibilities.swap_dealer,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: (
              <>
                A major security-based swap participant, as defined in{' '}
                <a
                  href="https://www.law.cornell.edu/uscode/text/15/78c#a_67_A"
                  target="_blank"
                >
                  15 USC § 78c(a)(67)(A)
                </a>
              </>
            ),
            value: eligibilities.major_security_based_swap_participant,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label: (
              <>
                A security-based swap dealer, as defined in{' '}
                <a
                  href="https://www.law.cornell.edu/uscode/text/15/78c#a_71_A"
                  target="_blank"
                >
                  15 USC § 78c(a)(71)(A)
                </a>
              </>
            ),
            value: eligibilities.security_based_swap_participant,
            type: 'option',
            onChange: eligibilitiesOnChange
          },
          {
            label:
              'If Applicant does not meet any of the qualifications listed above',
            type: 'label'
          },
          {
            label: eligibilitiesPretty[eligibilities.does_not_qualify],
            value: eligibilities.does_not_qualify,
            type: 'option'
          }
        ],
        rules: [{ required: true, message: 'Please select at least one' }]
      },
      {
        itemKey: 'eligibility_proof',
        label: 'Proof of ECP Financial Qualification',
        type: 'document',
        displayIf: (answers: any) =>
          !checkboxGroupContains(
            eligibilities.does_not_qualify,
            answers,
            'eligibility'
          ),
        minimumCount: () => 1,
        rules: [{ required: true, message: 'Please upload a document' }],
        tooltip:
          'You have selected an ECP qualification that involves surpassing a financial threshold. Please kindly provide proof of meeting such qualification. This can be a bank statement, company balance sheet (unaudited is sufficient), letter of credit or some other similar document.'
      },
      {
        itemKey: 'eligibility_scxm',
        label:
          'Applicant may gain access to Seed Digital Commodities Market to trade spot commodities through any of the ECP qualifications listed above or may also qualify through one of the following additional conditions. Please select the additional classification(s) that apply to the Applicant.',
        type: 'checkboxgroup',
        children: [
          {
            label: eligibilitiesPretty[eligibilities.scxm],
            value: eligibilities.scxm,
            type: 'option'
          },
          {
            label: eligibilitiesPretty[eligibilities.scxm_guaranteed],
            value: eligibilities.scxm_guaranteed,
            type: 'option'
          },
          {
            label: eligibilitiesPretty[eligibilities.does_not_qualify],
            value: eligibilities.does_not_qualify,
            type: 'option'
          },
          {
            label: eligibilitiesPretty[eligibilities.meets_ecp],
            value: eligibilities.meets_ecp,
            type: 'option',
            disabled: true,
            displayIf: (answers: any) => {
              const eligibilitiesAnswers = answers['eligibility']

              if (!eligibilitiesAnswers) {
                return false
              }

              if (
                eligibilitiesAnswers[eligibilities.does_not_qualify] === true
              ) {
                return false
              }

              return Object.keys(answers['eligibility']).some(
                (key) => eligibilitiesAnswers[key] === true
              )
            }
          }
        ],
        rules: [{ required: true, message: 'Please select at least one' }]
      }
    ]
  },
  {
    stepId: 'submit',
    label: 'Submit',
    route: 'submit',
    type: 'step',
    items: [
      {
        itemKey: 'review_head',
        type: 'paragraph',
        text:
          'Please review and confirm all information provided in the application before submitting. By checking this box, you agree that everything filled out is true and correct. You further confirm that you have read and agree to all disclosures and documents presented in this application, including all Seed service agreements.'
      },
      {
        itemKey: 'review',
        label: 'I agree',
        type: 'checkbox',
        transformValue: (value: any) => ({ true: value }),
        rules: [
          {
            required: true,
            validator: (rule, value, callback) =>
              value ? callback() : callback('You must affirm to continue')
          }
        ]
      }
    ]
  }
]

export default applicationSteps
