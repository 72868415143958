import * as React from 'react'
import { AxiosError } from 'axios'
import Logger from '../utils/logger'

import { notification } from 'antd'
import showErrorWithRequestId from './showErrorWithRequestId'
import { Button } from 'antd'

const clearAuthAndReload = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('id_token')
  localStorage.removeItem('expires_at')
  window.location.replace(window.location.pathname)
}

const showError = ({
  status,
  message,
  logger,
  error
}: {
  status: number
  message: string
  logger: Logger
  error: AxiosError
}) => {
  if (status === 401) {
    // tell the user, then get them to re-auth
    const key = `open_${Date.now()}`
    notification.error({
      btn: (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            notification.close(key)
            clearAuthAndReload()
          }}
        >
          Reload
        </Button>
      ),
      description: message,
      duration: 0,
      key,
      message: 'Session Expired',
      onClose: () => {
        logger.warn({ message: 'Dismissed re-auth notification' })
      }
    })
  } else {
    showErrorWithRequestId({
      message,
      error,
      notificationParams: {
        key: `open_${Date.now()}`
      }
    })
  }
}

const getErrorMessageForCode = (
  custom: string,
  code: number,
  defaultMessage?: string
) => {
  return typeof custom === 'string' ? custom : custom?.[code] || defaultMessage
}

const MESSAGE_BY_CODE = {
  401: 'Your session has expired. Please refresh to re-authenticate.',
  404: 'Not Found.',
  403: 'Permission Denied. You do not have the user permissions required.',
  503: 'Network Error. Please refresh and try again.',
  500: 'Internal Server Error. Please try again or contact support.'
}

const handleError = (
  logger: Logger,
  error: AxiosError,
  omitNotification = false,
  customErrorMessage = undefined
): string => {
  let resultErrorMessage =
    'We encountered a problem with your request. Please try again.'
  const statusCode = (error as any).statusCode
  const status = error?.response?.status

  if (statusCode === 404 || status === 404) {
    resultErrorMessage = getErrorMessageForCode(
      customErrorMessage,
      404,
      MESSAGE_BY_CODE[404]
    )
  } else {
    // if given custom message, then use this message and early return
    const errorMessageForCode = getErrorMessageForCode(
      customErrorMessage,
      status
    )
    if (status !== 401 && !omitNotification && errorMessageForCode) {
      showError({
        status,
        message: errorMessageForCode,
        logger,
        error
      })

      return errorMessageForCode
    }

    // otherwise do default logic and get message by code
    if (status === 400) {
      const responseMessage =
        error?.response?.data?.message || error?.response?.data
      if (typeof responseMessage === 'string') {
        resultErrorMessage = responseMessage
      } else if (Array.isArray(responseMessage?.errors)) {
        resultErrorMessage = (responseMessage.errors as Array<string>).join(
          ', '
        )
      } else {
        resultErrorMessage = 'Bad Request.'
      }
    } else {
      resultErrorMessage =
        MESSAGE_BY_CODE[status] || error.message || resultErrorMessage
    }

    if (!omitNotification) {
      showError({
        status,
        message: customErrorMessage || resultErrorMessage,
        logger,
        error
      })
    }
  }

  return resultErrorMessage
}

export default handleError
