import * as React from 'react'
import * as _ from 'lodash'
import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/es/form'
import * as uuid from 'uuid'
import {
  Spin,
  Select,
  Col,
  Row,
  Switch,
  Input,
  Tooltip,
  Button,
  DatePicker,
  Alert,
  Checkbox
} from 'antd'
import Title from 'seed-shared-components/lib/components/Title'
import { IParticipantBasic } from 'seed-ts/types/participants'
import { IWithParticipantComponentProps } from '../components/participant/WithParticipant'
import NumberInput from 'seed-shared-components/lib/components/NumberInput'
import { Decimal } from 'decimal.js'
import TradeConfirmation from './TradeConfirmation'
import * as moment from 'moment'
import NumberFormat from 'react-number-format'
import { currencyPrecision } from 'seed-shared-components/lib/utils/CurrencyPrecision'
import { IAuthUser } from '../auth/WithAuthentication'
import * as qs from 'qs'
import { IAssetWithNewProps } from '../withdrawal/v2/types'
import { assets as assetConfigs } from 'seed-shared-components/lib/static-data/assetConfig'
import { QuestionCircleOutlined } from '@ant-design/icons'

const Option = Select.Option
interface ITradeSubmissionProps
  extends FormComponentProps,
    IWithParticipantComponentProps {}

enum ProductTypeEnum {
  Spot = 'spot',
  Forward = 'forward'
}

enum TradeSubmissionParty {
  Buy = 'buy',
  Sell = 'sell'
}

interface ITradeSubmissionState {
  isInitialDataLoaded: boolean
  loading: boolean
  submitting: boolean
  disabled: boolean
  tradeSubmissionDetails: ITradeSubmissionDetails | null
  buyerParticipants: { value: IParticipantBasic[]; loading: boolean }
  sellerParticipants: { value: IParticipantBasic[]; loading: boolean }
  buyerParticipantCode?: string
  sellerParticipantCode?: string
  baseCurrency?: string
  quotedCurrency?: string
  validationErrors: { [field: string]: string | undefined }
  productType: ProductTypeEnum
  buyerSettling: boolean
  sellerSettling: boolean
  assets: string[]
}

export interface ITradeSubmissionDetails {
  baseCurrency: string
  quotedCurrency: string
  price: number
  quantity: number
  transactionTime: moment.Moment
  anonymous: boolean
  clientTradeId?: string
  buyer: ITradeSubmissionParty
  seller: ITradeSubmissionParty
  productType: 'spot' | 'forward'
  settlementPriceIndexId?: string
  settlementTimestamp?: moment.Moment
  expiryTimestamp?: moment.Moment
}

export interface ITradeSubmissionParty {
  name: string
  amount: number
  asset: string
  code: string
  side: TradeSubmissionParty
  settling: boolean
}

class TradeSubmission extends React.Component<
  ITradeSubmissionProps,
  ITradeSubmissionState
> {
  state = {
    isInitialDataLoaded: false,
    loading: true,
    submitting: false,
    disabled: true,
    tradeSubmissionDetails: null,
    buyerParticipants: { value: [], loading: false },
    sellerParticipants: { value: [], loading: false },
    baseCurrency: undefined,
    quotedCurrency: undefined,
    validationErrors: {},
    productType: ProductTypeEnum.Spot,
    buyerParticipantCode: undefined,
    sellerParticipantCode: undefined,
    buyerSettling: true,
    sellerSettling: true,
    assets: []
  }

  async fetchPlatformParticipants(searchString = '') {
    const queryParams: {
      page: number
      pageSize: number
      searchString: string
      relationshipType: string
    } = {
      page: 1,
      pageSize: 50,
      searchString,
      relationshipType: 'operates_platform_for'
    }

    const { message } = await this.props.restService
      .route(`grpc-related-participants?${qs.stringify(queryParams)}`)
      .get()

    return message
  }

  async fetchBuyerPlatformParticipants(searchString = '') {
    this.setState({
      buyerParticipants: {
        value: this.state.buyerParticipants.value,
        loading: true
      }
    })

    const platformParticipantsResult = await this.fetchPlatformParticipants(
      searchString
    )

    this.setState({
      buyerParticipants: {
        value: platformParticipantsResult,
        loading: false
      }
    })
  }

  async fetchSellerPlatformParticipants(searchString = '') {
    this.setState({
      sellerParticipants: {
        value: this.state.sellerParticipants.value,
        loading: true
      }
    })

    const platformParticipantsResult = await this.fetchPlatformParticipants(
      searchString
    )

    this.setState({
      sellerParticipants: {
        value: platformParticipantsResult,
        loading: false
      }
    })
  }

  async componentDidMount() {
    this.setState({
      isInitialDataLoaded: false,
      sellerParticipants: {
        value: this.state.sellerParticipants.value,
        loading: true
      },
      buyerParticipants: {
        value: this.state.buyerParticipants.value,
        loading: true
      }
    })

    const { authUser, participant } = this.props
    const [isTradeSubmitter, platformParticipants, assets] = await Promise.all([
      this.checkIfUserIsTraderSubmitter(authUser, participant.code),
      this.fetchPlatformParticipants(),
      this.getAssets()
    ])

    this.setState({
      isInitialDataLoaded: true,
      loading: false,
      disabled: !isTradeSubmitter,
      sellerParticipants: {
        value: platformParticipants,
        loading: false
      },
      buyerParticipants: {
        value: platformParticipants,
        loading: false
      },
      assets
    })
  }

  getAssets = async () => {
    if (!this.props.participant) {
      return []
    }
    const cryptoAssets: IAssetWithNewProps[] = await this.props.restService
      .route(`assets?requestId=${uuid.v4()}&asset_type=CRYPTO`)
      .get()
    const fiatCurrencies = assetConfigs
      .filter((asset) => asset.type === 'Fiat')
      .map((a) => a.asset)

    return [...fiatCurrencies, ...cryptoAssets.map((a) => a.asset)]
  }

  checkIfUserIsTraderSubmitter(
    authUser: IAuthUser,
    participantCode: string
  ): Promise<boolean> {
    return authUser.isTradeSubmitter(participantCode)
  }

  closeTradeConfirmation = (clearForm?: boolean) => {
    if (clearForm) {
      this.props.form.resetFields()
      this.setState({
        buyerParticipantCode: undefined,
        sellerParticipantCode: undefined
      })
    }
    this.setState({ tradeSubmissionDetails: null })
  }

  /**
   * Confirm trade only validates form and opens trade confirmation modal
   * Trade submission happens in TradeConfirmation.tsx
   */
  confirmTrade = (e: React.FormEvent) => {
    e.stopPropagation()
    this.props.form.validateFields((err, values) => {
      const validationErrors: { [field: string]: string | undefined } = {}
      validationErrors['buyer'] = this.validateParty('buyer')
      validationErrors['seller'] = this.validateParty('seller')

      this.setState({
        validationErrors
      })

      if (
        Object.values(validationErrors).filter((error) => !!error).length ||
        err
      ) {
        return
      }

      const buyerParticipant = this.state.buyerParticipants.value.find(
        (participant) => {
          return participant.registration_code === values.buyer
        }
      )
      const sellerParticipant = this.state.sellerParticipants.value.find(
        (participant) => {
          return participant.registration_code === values.buyer
        }
      )

      this.setState({
        tradeSubmissionDetails: {
          ...values,
          buyer: {
            name: buyerParticipant
              ? `${buyerParticipant.name} (${values.buyer})`
              : values.buyer,
            asset: values.baseCurrency,
            amount: values.quantity,
            code: values.buyer,
            side: TradeSubmissionParty.Buy,
            settling: this.state.buyerSettling
          },
          seller: {
            name: sellerParticipant
              ? `${sellerParticipant.name} (${values.seller})`
              : values.seller,
            asset: values.quotedCurrency,
            amount: new Decimal(values.quantity || 0)
              .times(values.price || 0)
              .toFixed(5),
            code: values.seller,
            side: TradeSubmissionParty.Sell,
            settling: this.state.sellerSettling
          }
        }
      })
    })
  }

  onProductTypeChange = (productType: ProductTypeEnum) => {
    this.props.form.resetFields([
      'settlement_price_index_id',
      'fixing_date',
      'settlement_date'
    ])

    if (productType === ProductTypeEnum.Forward) {
      // we only support BTC/USD for forwards currently
      this.setState({ productType })
      this.props.form.setFieldsValue({
        baseCurrency: 'BTC',
        quotedCurrency: 'USD'
      })
    } else {
      this.setState({ productType })
    }
  }

  handleBuyerParticipantSearch = _.debounce((search: string) => {
    if (search && search.length < 3) {
      return
    }

    this.fetchBuyerPlatformParticipants(search)
  }, 250)

  handleBuyerParticipantChange = (value) => {
    this.setState({
      buyerParticipantCode: value as string
    })
    // revert back to initial list of participants
    this.fetchBuyerPlatformParticipants()
  }

  handleSellerParticipantSearch = _.debounce((search: string) => {
    if (search && search.length < 3) {
      return
    }

    this.fetchSellerPlatformParticipants(search)
  }, 250)

  handleSellerParticipantChange = (value) => {
    this.setState({
      sellerParticipantCode: value as string
    })
    // revert back to initial list of participants
    this.fetchSellerPlatformParticipants()
  }

  validateParty(party: string) {
    const partyValue = this.props.form.getFieldValue(party)
    if (!partyValue) {
      return `${party} is required`
    }

    if (partyValue === this.props.participant.code) {
      const anonymous = this.props.form.getFieldValue('anonymous')
      if (anonymous) {
        return `trade cannot be submitted as anonymous if the trade reporter is also a counterparty in the trade`
      }
    }
    return undefined
  }

  validateSettlementTimestamp = (
    rule: any,
    value: moment.Moment,
    callback: (error?: string) => void
  ) => {
    const productType = this.props.form.getFieldValue('productType')

    if (productType === ProductTypeEnum.Spot) {
      callback()
    }

    const settlementTimestamp = this.props.form.getFieldValue(
      'settlementTimestamp'
    )
    const expiryTimestamp = this.props.form.getFieldValue('expiryTimestamp')

    if (settlementTimestamp < expiryTimestamp) {
      callback(
        'Settlement Timestamp cannot be before the Expiration / Fixing Date'
      )
    }

    callback()
  }

  renderForwardsSpecificFields() {
    const { getFieldDecorator } = this.props.form
    const settlementTimestamp = this.props.form.getFieldValue(
      'settlementTimestamp'
    )
    const expiryTimestamp = this.props.form.getFieldValue('expiryTimestamp')

    return (
      <>
        <Row gutter={62} align="middle">
          <Col xs={12}>
            <Form.Item
              label={
                <LabelWithTooltip
                  tooltip="The last datetime that the product can be traded, and the datetime that all final prices will be set, i.e. the fixing date Note: after this point, there is no more ability to exit the trade or change its economics"
                  label="Expiration / Fixing Date"
                />
              }
            >
              {getFieldDecorator('expiryTimestamp', {
                rules: [
                  {
                    required: true,
                    message: 'expiration / fixing date is required'
                  }
                ]
              })(
                <DatePicker
                  style={{ width: '100%' }}
                  disabled={this.state.disabled}
                  showTime
                  placeholder="Select Time"
                />
              )}
            </Form.Item>
          </Col>
          {expiryTimestamp && (
            <Col xs={12}>
              <Row>
                <Col xs={6} style={{ fontWeight: 800 }}>
                  UTC Time:
                </Col>
                <Col xs={18}>
                  {moment(expiryTimestamp).toDate().toUTCString()}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row gutter={62} align="middle">
          <Col xs={12}>
            <Form.Item
              label={
                <LabelWithTooltip
                  tooltip="The datetime when final settlement will first be attempted"
                  label="Settlement Date"
                />
              }
            >
              {getFieldDecorator('settlementTimestamp', {
                rules: [
                  {
                    required: true,
                    message: 'settlement date is required'
                  },
                  {
                    validator: this.validateSettlementTimestamp
                  }
                ]
              })(
                <DatePicker
                  style={{ width: '100%' }}
                  disabled={this.state.disabled}
                  showTime
                  placeholder="Select Time"
                />
              )}
            </Form.Item>
          </Col>
          {settlementTimestamp && (
            <Col xs={12}>
              <Row>
                <Col xs={6} style={{ fontWeight: 800 }}>
                  UTC Time:
                </Col>
                <Col xs={18}>
                  {moment(settlementTimestamp).toDate().toUTCString()}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row align="middle">
          <Col lg={11} xs={24}>
            <Form.Item
              label={
                <LabelWithTooltip
                  tooltip="The unique identifier of the benchmark settlement price to be used when calculating settlement obligations on trades that are settled at Zero Hash"
                  label="Mark to Mark Index"
                />
              }
            >
              {getFieldDecorator('settlementPriceIndexId', {
                rules: [
                  { required: true, message: 'Mark to Mark Index is required' }
                ]
              })(
                <Select disabled={this.state.disabled}>
                  {[
                    { display: 'Zero Hash Forward Index: Chicago', value: '3' },
                    { display: 'Zero Hash Forward Index: London', value: '4' },
                    {
                      display: 'Zero Hash Forward Index: Hong Kong, Singapore',
                      value: '5'
                    }
                  ].map((index) => (
                    <Option key={index.value} value={index.value}>
                      {index.display}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form

    let sellerAmount = undefined
    const quantity = parseFloat(this.props.form.getFieldValue('quantity'))
    const price = parseFloat(this.props.form.getFieldValue('price'))
    const transactionTime = this.props.form.getFieldValue('transactionTime')
    const productType = this.props.form.getFieldValue('productType')

    if (!isNaN(quantity) && !isNaN(price)) {
      sellerAmount = new Decimal(price).times(quantity).toFixed(5)
    }

    const underlyingCurrencies = this.state.assets
      .filter((a) => a !== this.state.quotedCurrency)
      // we only support BTC/USD for forwards currently
      .filter((a) =>
        productType === ProductTypeEnum.Spot ? true : a === 'BTC'
      )

    const quotedCurrencies = this.state.assets
      .filter((a) => a !== this.state.baseCurrency)
      // we only support BTC/USD for forwards currently
      .filter((a) =>
        productType === ProductTypeEnum.Spot ? true : a === 'USD'
      )

    return (
      <Spin spinning={this.state.loading || !this.state.isInitialDataLoaded}>
        <Title level={2}>Trade Submission</Title>

        <Col md={14} sm={24}>
          {this.state.disabled && (
            <>
              <Alert
                message="Only Trade Submitters have access to submit trades. Admins can set trade submitters from the Users page."
                type="warning"
              />
              <br />
            </>
          )}

          <Form
            layout="vertical"
            onSubmit={this.confirmTrade}
            hideRequiredMark={true}
          >
            <Form.Item label="Product Type">
              <Row>
                <Col lg={11} xs={24}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator('productType', {
                      rules: [
                        { required: true, message: 'product type is required' }
                      ],
                      initialValue: this.state.productType
                    })(
                      <Select
                        disabled={this.state.disabled}
                        onChange={this.onProductTypeChange}
                      >
                        {(window._env_.REACT_APP_SEED_ENV === 'prod'
                          ? [ProductTypeEnum.Spot]
                          : [ProductTypeEnum.Spot, ProductTypeEnum.Forward]
                        ).map((productType) => (
                          <Option key={productType} value={productType}>
                            {`${productType[0].toUpperCase()}${productType.slice(
                              1
                            )}`}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="Product Pair">
              <Row>
                <Col lg={11} xs={24}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator('baseCurrency', {
                      rules: [
                        { required: true, message: 'base currency is required' }
                      ]
                    })(
                      <Select
                        disabled={this.state.disabled}
                        onChange={(value) =>
                          this.setState({ baseCurrency: value as string })
                        }
                      >
                        {underlyingCurrencies.map((asset) => (
                          <Option key={asset} value={asset}>
                            {asset}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  <div style={{ fontStyle: 'italic', fontSize: 12 }}>
                    Underlying Asset or Base Currency
                  </div>
                </Col>
                <Col
                  lg={2}
                  md={0}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div
                    style={{
                      fontSize: '24px'
                    }}
                  >
                    /
                  </div>
                </Col>
                <Col lg={11} xs={24}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator('quotedCurrency', {
                      rules: [
                        {
                          required: true,
                          message: 'quoted currency is required'
                        }
                      ]
                    })(
                      <Select
                        disabled={this.state.disabled}
                        onChange={(value) =>
                          this.setState({ quotedCurrency: value as string })
                        }
                      >
                        {quotedCurrencies.map((asset) => (
                          <Option key={asset} value={asset}>
                            {asset}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  <div style={{ fontStyle: 'italic', fontSize: 12 }}>
                    Quoted Asset or Currency
                  </div>
                </Col>
              </Row>
              <Row>
                {productType === ProductTypeEnum.Forward && (
                  <p style={{ marginTop: '12px' }}>
                    Forwards are currently restricted to BTC/USD.{' '}
                    <a
                      href="mailto:support@zerohash.com?subject=New Forward Product Pair Request"
                      target="_blank"
                    >
                      Reach out
                    </a>{' '}
                    if you’d like us to enable more forward pairs.{' '}
                  </p>
                )}
              </Row>
            </Form.Item>
            <Row gutter={62} align="top">
              <Col lg={12} xs={24}>
                <Form.Item label="Quantity of Underlying Asset purchased">
                  {getFieldDecorator('quantity', {
                    rules: [
                      {
                        validator: numberValidator(15),
                        required: true,
                        message:
                          'quantity is required and must be greater than zero'
                      }
                    ]
                  })(
                    <NumberInput
                      acceptDecimal={true}
                      maxLength={15}
                      disabled={this.state.disabled}
                      suffix={this.state.baseCurrency}
                      allowLeadingZero={true}
                      decimalScale={
                        this.state.baseCurrency
                          ? currencyPrecision[this.state.baseCurrency as any]
                          : 2
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item
                  label="Price per unit of Underlying Asset, in the Quoted Currency"
                  style={{ marginBottom: 0 }}
                >
                  {getFieldDecorator('price', {
                    rules: [
                      {
                        required: true,
                        message:
                          'price is required and must be greater than zero'
                      }
                    ]
                  })(
                    <NumberInput
                      acceptDecimal={true}
                      disabled={this.state.disabled}
                      suffix={this.state.quotedCurrency}
                    />
                  )}
                </Form.Item>
                <div style={{ fontStyle: 'italic', fontSize: 12 }}>
                  Prices with any precision are accepted, however note that
                  final settlement amounts are limited to the asset’s currency
                  precision limit.{' '}
                  <a
                    href="https://zerohash.zendesk.com/hc/en-us/articles/360043014194"
                    target="_blank"
                  >
                    See here for more details.
                  </a>
                </div>
              </Col>
            </Row>

            <Row gutter={62} align="middle">
              <Col xs={12}>
                <Form.Item
                  label={
                    <LabelWithTooltip
                      tooltip="The time the trade was executed on the Platform in local time."
                      label="Transaction Time"
                    />
                  }
                >
                  {getFieldDecorator('transactionTime', {
                    rules: [
                      {
                        required: true,
                        message: 'transaction time is required'
                      }
                    ]
                  })(
                    <DatePicker
                      style={{ width: '100%' }}
                      disabled={this.state.disabled}
                      showTime
                      placeholder="Select Time"
                      disabledDate={(current) => {
                        if (!current) {
                          return false
                        }
                        return current > moment()
                      }}
                      disabledTime={(current) => {
                        let disabledHours: number[] = []
                        let disabledMinutes: number[] = []
                        const now = moment()
                        if (
                          moment((current || undefined) as any).isSame(
                            now,
                            'day'
                          )
                        ) {
                          disabledHours = range(now.hour() + 1, 24)
                          if (
                            moment((current || undefined) as any).isSame(
                              now,
                              'minute'
                            )
                          ) {
                            disabledMinutes = range(now.minute() + 1, 60)
                          }
                        }
                        return {
                          disabledHours: () => disabledHours,
                          disabledMinutes: () => disabledMinutes,
                          disabledSeconds: () => []
                        }
                      }}
                    />
                  )}
                </Form.Item>
              </Col>

              {transactionTime && (
                <Col xs={12}>
                  <Row>
                    <Col xs={6} style={{ fontWeight: 800 }}>
                      UTC Time:
                    </Col>
                    <Col xs={18}>
                      {moment(transactionTime).toDate().toUTCString()}
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>

            {this.state.productType === ProductTypeEnum.Forward && (
              <>{this.renderForwardsSpecificFields()}</>
            )}

            <Form.Item
              label={
                <LabelWithTooltip
                  tooltip={
                    <>
                      <Row style={{ marginBottom: 32 }}>
                        A flag to determine if the counterparties should be
                        anonymous to one another.
                      </Row>
                      <Row>
                        <em>
                          Note: this must be set to <b>No</b> if the submitter
                          is also a counterparty to the trade
                        </em>
                      </Row>
                    </>
                  }
                  label="Anonymous"
                />
              }
            >
              {getFieldDecorator('anonymous', {
                initialValue: true,
                valuePropName: 'checked',
                rules: [{ required: true }]
              })(
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  disabled={this.state.disabled}
                />
              )}
            </Form.Item>

            <Form.Item
              label={
                <LabelWithTooltip
                  tooltip="A unique identifier for the trade, generally produced by the Platform on which the trade was executed"
                  label="Client Trade ID"
                />
              }
            >
              {getFieldDecorator('clientTradeId', { rules: [{ max: 50 }] })(
                <Input disabled={this.state.disabled} maxLength={50} />
              )}
              <div
                style={{
                  textAlign: 'right',
                  fontSize: 12,
                  fontStyle: 'italic',
                  paddingTop: '10px'
                }}
              >
                Optional. A client trade ID will be assigned if left blank
              </div>
            </Form.Item>

            <Row gutter={12} align="middle">
              <Col xl={11} xs={24}>
                <Form.Item
                  label="Buyer"
                  validateStatus={
                    this.state.validationErrors['buyer'] ? 'error' : ''
                  }
                  help={this.state.validationErrors['buyer']}
                >
                  {getFieldDecorator('buyer', {
                    rules: [{ required: true }]
                  })(
                    <Select
                      showSearch
                      loading={this.state.buyerParticipants.loading}
                      disabled={this.state.disabled}
                      onSearch={this.handleBuyerParticipantSearch}
                      onChange={this.handleBuyerParticipantChange}
                      filterOption={() => true}
                    >
                      {this.state.buyerParticipants.value
                        .filter((participant) => {
                          return (
                            participant.registration_code !==
                            this.state.sellerParticipantCode
                          )
                        })
                        .map((participant) => {
                          const participantCode = participant.registration_code
                          const participantName = participant.name

                          return (
                            <Select.Option
                              key={participant.id}
                              value={participantCode}
                            >
                              {participantName} ({participantCode})
                            </Select.Option>
                          )
                        })}
                    </Select>
                  )}
                  <div style={{ marginTop: 10 }}>
                    <Checkbox
                      disabled={this.state.sellerSettling === false}
                      style={{ marginRight: 10 }}
                      defaultChecked
                      value={this.state.buyerSettling}
                      onChange={(e) =>
                        this.setState({ buyerSettling: e.target.checked })
                      }
                    />
                    Buyer Settling
                  </div>
                </Form.Item>
              </Col>
              <Col xl={6} xs={12}>
                <DisplayField
                  label="Asset Receiving"
                  value={this.props.form.getFieldValue('baseCurrency')}
                />
              </Col>
              <Col xl={6} xs={12}>
                <DisplayField
                  label="Amount Receiving"
                  value={this.props.form.getFieldValue('quantity')}
                  evaluateNumber
                />
              </Col>
            </Row>
            <Row gutter={12} align="middle">
              <Col xl={11} xs={24}>
                <Form.Item
                  label="Seller"
                  validateStatus={
                    this.state.validationErrors['seller'] ? 'error' : ''
                  }
                  help={this.state.validationErrors['seller']}
                >
                  {getFieldDecorator('seller', {
                    rules: [{ required: true }]
                  })(
                    <Select
                      showSearch
                      loading={this.state.sellerParticipants.loading}
                      disabled={this.state.disabled}
                      onSearch={this.handleSellerParticipantSearch}
                      onChange={this.handleSellerParticipantChange}
                      filterOption={() => true}
                    >
                      {this.state.sellerParticipants.value
                        .filter((participant) => {
                          return (
                            participant.registration_code !==
                            this.state.buyerParticipantCode
                          )
                        })
                        .map((participant) => {
                          const participantCode = participant.registration_code
                          const participantName = participant.name

                          return (
                            <Select.Option
                              key={participant.id}
                              value={participantCode}
                            >
                              {participantName} ({participantCode})
                            </Select.Option>
                          )
                        })}
                    </Select>
                  )}
                  <div style={{ marginTop: 10 }}>
                    <Checkbox
                      disabled={this.state.buyerSettling === false}
                      style={{ marginRight: 10 }}
                      defaultChecked
                      value={this.state.sellerSettling}
                      onChange={(e) =>
                        this.setState({ sellerSettling: e.target.checked })
                      }
                    />
                    Seller Settling
                  </div>
                </Form.Item>
              </Col>
              <Col xl={6} xs={12}>
                <DisplayField
                  label="Asset Receiving"
                  value={this.props.form.getFieldValue('quotedCurrency')}
                />
              </Col>
              <Col xl={6} xs={12}>
                <DisplayField
                  label="Amount Receiving"
                  value={sellerAmount}
                  evaluateNumber
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 12 }}>
              <Form.Item>
                <Button
                  disabled={this.state.disabled || this.state.submitting}
                  onClick={this.confirmTrade}
                >
                  Submit Trade
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Col>

        {this.state.tradeSubmissionDetails !== null && (
          <TradeConfirmation
            trade={this.state.tradeSubmissionDetails}
            close={this.closeTradeConfirmation}
            {...this.props}
          />
        )}
      </Spin>
    )
  }
}

const DisplayField = ({
  label,
  value,
  evaluateNumber
}: {
  label: string
  value: any
  evaluateNumber?: boolean
}) => {
  const displayValue = evaluateNumber ? (
    !isNaN(value) && value !== '' && value !== undefined && value !== null ? (
      <NumberFormat
        value={value}
        thousandSeparator={true}
        displayType={'text'}
      />
    ) : (
      'N/A'
    )
  ) : (
    value || 'N/A'
  )
  return (
    <Row align="middle">
      <Col lg={18} xs={12} style={{ fontWeight: 700 }}>
        {label}:
      </Col>
      <Col lg={6} xs={12}>
        {displayValue}
      </Col>
    </Row>
  )
}

const LabelWithTooltip = ({
  label,
  tooltip
}: {
  label: string
  tooltip: string | JSX.Element
}) => (
  <Tooltip title={tooltip} placement="right">
    {label}
    &nbsp;
    <QuestionCircleOutlined />
  </Tooltip>
)

const numberValidator = (maxLength?: number) => (
  rule: any,
  value: number,
  callback: (error?: string) => void
) => {
  if (value > 0) {
    if (maxLength !== undefined && String(value).length > 15) {
      callback(`Value must be less than ${maxLength} characters`)
    }
    callback()
    return
  }
  callback('value must be greater than zero')
}

function range(start: number, end: number) {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}

export default Form.create<ITradeSubmissionProps>()(TradeSubmission)
