import moment from 'moment'
import * as React from 'react'
import { IWithParticipantComponentProps } from '../components/participant/WithParticipant'
import { ITrade } from 'seed-shared-components/lib/components/TradeBlotter/types'
import TradeBlotter from './TradeBlotter'
import { Button, Tooltip, Switch } from 'antd'
import { IAuthUser } from '../auth/WithAuthentication'
import qs from 'qs'
import store from '../store/store'
import * as settingsActions from '../store/user-settings/actions'
import * as settingsSelectors from '../store/user-settings/selectors'
import { FileAddOutlined } from '@ant-design/icons'

interface ITradeBlotterContainerProps extends IWithParticipantComponentProps {}

const addTradeToMap = (tradeMap: { [id: string]: ITrade }) => (
  trade: ITrade
) => {
  if (trade.trade_id in tradeMap) {
    const tradeEntry = tradeMap[trade.trade_id]
    if (
      trade.created_at &&
      tradeEntry.created_at &&
      trade.created_at > tradeEntry.created_at
    ) {
      tradeMap[trade.trade_id] = trade
    }
  } else {
    tradeMap[trade.trade_id] = trade
  }
}

interface ITradeBlotterContainerState {
  loading: boolean
  trades: { [id: string]: ITrade }
  page?: number
  totalTrades?: number
  pageSize?: number
  isTradeSubmitter: boolean
  params: any
  timestamps: 'local' | 'utc'
  relationships: any[]
}

interface ITradeResponse {
  message: ITrade[]
  page: number
  total_pages: number
  page_size: number
}

export default class TradeBlotterContainer extends React.Component<
  ITradeBlotterContainerProps,
  ITradeBlotterContainerState
> {
  constructor(props: ITradeBlotterContainerProps) {
    super(props)
    this.state = {
      trades: {},
      loading: true,
      isTradeSubmitter: false,
      params: {},
      timestamps: 'utc',
      relationships: []
    }
    this.onRowClick = this.onRowClick.bind(this)
    this.loadTrades = this.loadTrades.bind(this)
  }

  componentDidMount() {
    const { authUser, participant } = this.props

    this.checkIfUserIsTraderSubmitter(authUser, participant.code).then(
      (isTradeSubmitter) => {
        this.setState({ isTradeSubmitter })
      }
    )

    this.loadTrades()

    const settings = settingsSelectors.getSettings(store.getState())

    this.setState({
      timestamps:
        settings['trade-blotter-timestamps'] === 'local' ? 'local' : 'utc'
    })
  }

  checkIfUserIsTraderSubmitter(
    authUser: IAuthUser,
    participantCode: string
  ): Promise<boolean> {
    return authUser.isTradeSubmitter(participantCode)
  }

  setPage = async (page: number) => {
    this.setState({ page }, () => {
      this.loadTrades()
    })
  }

  async loadTrades(): Promise<void> {
    this.setState({ loading: true })

    const res = await this.props.restService
      .route(
        `trades?${qs.stringify({
          page: this.state.page || 1,
          ...this.state.params
        })}`
      )
      .get<ITradeResponse>()
    const trades = this.buildTradesMap(res.message)

    this.setState({
      trades,
      loading: false,
      page: res.page,
      totalTrades: res.total_pages * res.page_size,
      pageSize: res.page_size
    })
  }

  SORT_FIELDS_MAP = {
    platform_name: 'platform_code',
    buyer_name: 'buyer',
    seller_name: 'seller'
  }

  handleTableChange = (skip, filter, sorting) => {
    const formatDate =
      this.state.timestamps === 'local'
        ? (v) => moment(v).unix() * 1000
        : (v) => moment(v).add(moment(v).utcOffset(), 'minutes').unix() * 1000
    const sort_by = !!sorting.column
      ? this.SORT_FIELDS_MAP[sorting.field] || sorting.field
      : undefined
    const sort_direction = !!sorting.column
      ? sorting.order === 'ascend'
        ? 'ASC'
        : 'DESC'
      : undefined
    const platform_code = filter.platform_name
      ? filter.platform_name[0]
      : undefined
    const buyer = filter.buyer_name ? filter.buyer_name[0] : undefined
    const seller = filter.seller_name ? filter.seller_name[0] : undefined
    const transaction_timestamp =
      filter.transaction_timestamp && filter.transaction_timestamp.length > 0
        ? {
            gte: formatDate(filter.transaction_timestamp[0][0]),
            lte: formatDate(filter.transaction_timestamp[0][1])
          }
        : undefined
    const trade_state = filter.trade_state ? filter.trade_state : undefined

    this.setState(
      {
        params: {
          sort_by,
          sort_direction,
          platform_code,
          buyer,
          seller,
          transaction_timestamp,
          trade_state
        }
      },
      () => {
        this.loadTrades()
      }
    )
  }

  buildTradesMap(trades?: ITrade[]) {
    const tradeMap: { [id: string]: ITrade } = {}
    if (trades && trades.length >= 1) {
      trades.forEach(addTradeToMap(tradeMap))
    }
    return tradeMap
  }

  onRowClick(trade: ITrade) {
    this.props.history.push(
      `/${this.props.participant.code}/trades/${trade.trade_id}`
    )
  }

  setTimetamps = (bool) => {
    const timestamps = bool ? 'local' : 'utc'

    store.dispatch(
      settingsActions.setUserSetting({ 'trade-blotter-timestamps': timestamps })
    )

    this.setState({ timestamps })
  }

  render() {
    return (
      <TradeBlotter
        viewerParticipantCode={this.props.participant.code}
        onRowClick={this.onRowClick}
        trades={this.state.trades}
        timestamps={this.state.timestamps}
        loading={this.state.loading}
        pagination={{
          page: this.state.page || 1,
          total: this.state.totalTrades || 0,
          pageSize: this.state.pageSize || 0,
          onPageSelect: this.setPage
        }}
        onChange={this.handleTableChange}
        fetchParticipantsForFilter={async (filter, count = 10) => {
          const {
            message: participants
          } = await this.props.getPaginatedPlatformParticipants({
            page: 1,
            pageSize: count,
            searchString: filter,
            includePlatformParticipant: true
          })

          // api return also platform, so we need to filter out participants that doesn't match filter string
          return participants
            .filter((participant) => {
              return `${participant.registration_code}${participant.name}`
                .toLowerCase()
                .includes(filter.toLowerCase())
            })
            .map((participant) => {
              return {
                participant_code: participant.registration_code,
                name: participant.name
              }
            })
        }}
        additionalButton={
          <div style={{ display: 'flex', alignItems: 'center', float: 'left' }}>
            <span style={{ marginRight: 12 }}>Timestamps: UTC</span>
            <Switch
              style={{ marginRight: 12 }}
              checked={this.state.timestamps === 'local'}
              onChange={this.setTimetamps}
            />
            <span style={{ marginRight: 24 }}>Local</span>
            <Tooltip title="You must have the Trade Submitter role to submit trades.">
              <Button
                type="primary"
                icon={<FileAddOutlined />}
                onClick={() =>
                  this.props.history.push('trades/trade_submission')
                }
                disabled={!this.state.isTradeSubmitter}
              >
                Submit Trade
              </Button>
            </Tooltip>
          </div>
        }
      />
    )
  }
}
