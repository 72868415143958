import React from 'react'
import { Typography, TypographyProps } from 'antd'
import { TitleProps } from 'antd/lib/typography/Title'

interface KycTypographyProps extends TitleProps {
  colorText?: string
  typographyProps?: TypographyProps
  children?: React.ReactNode
  fontSize: number
}

const KycTypographyTitle = ({
  children = 'Title',
  colorText,
  fontSize,
  style,
  ...typographyProps
}: KycTypographyProps) => {
  return (
    <Typography.Title
      level={3}
      style={{
        marginTop: 0,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: fontSize ? fontSize * 1.5 : 18, // 18px relative to a 12px fontSize
        lineHeight: '125%',
        marginBottom: '1rem',
        color: colorText,
        ...style
      }}
      {...typographyProps}
    >
      {children}
    </Typography.Title>
  )
}

export default KycTypographyTitle
