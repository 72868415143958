import { Decimal } from 'decimal.js'

// TODO: cleanup params and related logic
export const getSetupAccountNextStep = ({
  step,
  isAuthSigner,
  application,
  code,
  isAdmin
}: {
  step: string
  isAuthSigner: boolean
  application?: { currentUserHasSigned: boolean }
  code?: string | undefined
  isAdmin: boolean
}): string | null => {
  switch (step) {
    case 'apply':
      return null
    case 'createParticipant':
    default:
      return '/create'
  }
}

export const getDisabledMessage = (step: string) => {
  switch (step) {
    case 'signApplication':
      return 'Participant must be approved by an Authorized Signer to continue setup'
    case 'seedApproval':
      return 'Participant must be approved by Zero Hash to continue setup'
    case 'addUsers':
      return 'Only admin users can Add Users'
    case 'fund':
      return 'Only admin users can Send Funds'
    default:
      return ''
  }
}

export const currencyFormat = (value: string | number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(Number(new Decimal(value).toDecimalPlaces(2).toString()))
