import { createReducer } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import * as SignActions from './actions'
import {
  IGetAuthUserRoleAction,
  ILoadAgreementsSuccessAction,
  ILoadApplicationSuccessAction,
  IValidateAgreementsFormAction
} from './actions/types'
import { ISignState } from './state'

const signInitialState: ISignState = {
  agreements: {
    loading: false,
    submitted: false,
    status: null
  },
  application: {
    loading: false,
    submitted: false,
    status: null,
    error: false
  },
  valid: false,
  authSigner: false
}

const agreements = createReducer(signInitialState.agreements, {
  [SignActions.signAgreementsAndApplication.type]: (state) => ({
    ...state,
    loading: true,
    submitted: true
  }),
  [SignActions.loadAgreements.type]: (state) => ({
    ...state,
    loading: true
  }),
  [SignActions.loadAgreementsSuccess.type]: (
    state,
    action: ILoadAgreementsSuccessAction
  ) => ({
    ...state,
    loading: false,
    state: action.payload.status
  }),
  [SignActions.loadAgreementsFailure.type]: (state) => ({
    ...state,
    loading: false
  }),
  [SignActions.signAgreementsSuccess.type]: (state) => ({
    ...state,
    loading: false,
    status: 'signed',
    submitted: true
  }),
  [SignActions.signAgreementsFailure.type]: (state) => ({
    ...state,
    loading: false,
    submitted: false
  })
})

const application = createReducer(signInitialState.application, {
  [SignActions.signAgreementsAndApplication.type]: (state) => ({
    ...state,
    loading: true,
    submitted: true
  }),
  [SignActions.loadApplication.type]: (state) => ({
    ...state,
    loading: true,
    error: false
  }),
  [SignActions.loadApplicationFailure.type]: (state) => ({
    ...state,
    loading: false,
    error: true
  }),
  [SignActions.loadApplicationSuccess.type]: (
    state,
    action: ILoadApplicationSuccessAction
  ) => ({
    ...state,
    loading: false,
    error: false,
    status: action.payload.status
  }),
  [SignActions.signApplicationFailure.type]: (state) => ({
    ...state,
    loading: false,
    submitted: false
  }),
  [SignActions.signApplicationSuccess.type]: (state) => ({
    ...state,
    loading: false,
    status: 'signed',
    submitted: false
  })
})

const valid = createReducer(signInitialState.valid, {
  [SignActions.validateAgreementsForm.type]: (
    state,
    action: IValidateAgreementsFormAction
  ) => action.payload.isValid
})

const authSigner = createReducer(signInitialState.authSigner, {
  [SignActions.getAuthUserRole.type]: (state, action: IGetAuthUserRoleAction) =>
    action.payload.isAuthSigner
})

export default combineReducers<ISignState>({
  agreements,
  application,
  valid,
  authSigner
})
