import { connect } from 'react-redux'
import { IApplicationState } from '../../store/state'

import selectors from '../../store/selectors'
import * as WithdrawalActions from '../../store/withdrawal/actions'
import * as netDeliveryObligationsActions from '../../store/net-delivery-obligations/actions'
import * as portfolioActions from '../../store/portfolio/actions'

export default connect(
  (state: IApplicationState) => ({
    accounts: selectors.withdrawal.getAccounts(state),
    withdrawals: selectors.withdrawal.getWithdrawals(state),
    platformTransfers: selectors.withdrawal.getplatformTransfers(state),
    netDeliveryObligations: selectors.netDeliveryObligations.getNetDeliveryObligations(
      state
    ),
    portfolio: selectors.portfolio.getPortfolio(state)
  }),
  (dispatch) => ({
    getAccounts: (participantCode: string) =>
      dispatch(WithdrawalActions.getAccounts({ participantCode })),
    getWithdrawals: (participantCode: string) =>
      dispatch(WithdrawalActions.getWithdrawals({ participantCode })),
    getplatformTransfers: (participantCode: string) =>
      dispatch(WithdrawalActions.getplatformTransfers({ participantCode })),
    fetchNetDeliveryObligations: (participantCode: string) =>
      dispatch(
        netDeliveryObligationsActions.fetchNetDeliveryObligations({
          participantCode
        })
      ),
    fetchPortfolio: (participantCode: string) =>
      dispatch(portfolioActions.fetchPortfolio({ participantCode }))
  })
)
