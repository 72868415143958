import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
// import logger from 'redux-logger'
import rootSaga from './rootSaga'
import rootReducer from './rootReducer'

const sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware, ...getDefaultMiddleware()]

// if (window._env_.NODE_ENV === 'development') {
//   middleware.push(logger)
// }

export default configureStore({
  reducer: rootReducer,
  middleware
})

sagaMiddleware.run(rootSaga)
