import * as React from 'react'

import { IAuthUser } from '../../auth/WithAuthentication'
import RestService from '../../RestService'
import * as participantsApi from '../../api/participantsApi'
import { IParticipant } from 'seed-shared-components/lib/types'
import { Spin } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { IWIthParticipantProps } from './props'
import connect from './connector'
import { IParticipantBasic } from 'seed-ts/types/participants'

export interface IWithParticipantComponentProps<
  Params extends { [K in keyof Params]?: string } = {}
> extends RouteComponentProps<Params> {
  authUser: IAuthUser
  participant: IParticipant
  restService: RestService
  participantDetails: IParticipantBasic & {
    withdrawal_address_approvers_threshold?: number
  }
  getPaginatedPlatformParticipants: (
    params: participantsApi.IPaginatedPlatformParticipantsParams
  ) => Promise<participantsApi.IPaginatedPlatformParticipantsServiceResult>
  getParticipantsNames: (
    participantsCodes: string[]
  ) => Promise<participantsApi.IParticipantsNamesResult>
  hasRelationship: (
    params: participantsApi.IHasRelationshipParams
  ) => Promise<participantsApi.IHasRelationshipResult>
}

interface IWithParticipantProps {
  authUser: IAuthUser
  restService: RestService
  skipApproval?: boolean
}

const getParticipantsNames = (authToken: string, participantCode: string) => {
  return (participantsCodes: string[]) =>
    participantsApi.getParticipantsNames(
      authToken,
      participantCode,
      participantsCodes
    )
}

const getPaginatedPlatformParticipants = (
  authToken: string,
  participantCode: string
) => {
  return (params: participantsApi.IPaginatedPlatformParticipantsParams) =>
    participantsApi.getPaginatedPlatformParticipants(
      authToken,
      participantCode,
      params
    )
}

const hasRelationship = (authToken: string, participantCode: string) => {
  return (
    params: participantsApi.IHasRelationshipParams
  ): Promise<participantsApi.IHasRelationshipResult> => {
    return participantsApi.hasRelationship(authToken, participantCode, params)
  }
}

const withParticipant = (injectedProps: IWithParticipantProps) => (
  WrappedComponent: React.ComponentType<IWithParticipantComponentProps>
) => {
  class WithParticipant extends React.Component<IWIthParticipantProps> {
    render() {
      const { authUser, restService, skipApproval } = injectedProps
      const { participantCode } = this.props.match.params
      const { loading, participant, approved, participantDetails } = this.props

      if (!authUser.idToken || !participantCode) {
        return null
      }

      if (loading) {
        return <Spin />
      }

      if (!participant || !participantDetails) {
        return <span>No participant selected</span>
      }

      if (!skipApproval && !approved) {
        return <span>You do not have access at this time.</span>
      }

      return (
        <div>
          <WrappedComponent
            match={this.props.match}
            location={this.props.location}
            history={this.props.history}
            staticContext={this.props.staticContext}
            participant={participant}
            participantDetails={participantDetails}
            restService={restService}
            hasRelationship={hasRelationship(authUser.idToken, participantCode)}
            getParticipantsNames={getParticipantsNames(
              authUser.idToken,
              participantCode
            )}
            getPaginatedPlatformParticipants={getPaginatedPlatformParticipants(
              authUser.idToken,
              participantCode
            )}
            authUser={authUser}
            {...injectedProps}
          />
        </div>
      )
    }
  }

  return connect(WithParticipant)
}

export default withParticipant
