import React from 'react'
import moment from 'moment'
import * as _ from 'lodash'
import { Button, Col, Modal, Row, Table, Typography as T } from 'antd'

import { IPortalApiWithdrawalAccount } from '../withdrawalTypes'
import TAG_BASED_ASSETS from '../../utils/tagBasedAssets'

interface Props {
  visible: boolean
  id: number | null
  accounts: IPortalApiWithdrawalAccount[]
  onClose: () => void
}

/**
 * Audit trail table render helpers
 */

const mapAction = (status: string) =>
  status.split(/CHANGE\_|CREATION\_/).join('')

const renderTimestamp = (v: string) => (
  <T.Text style={{ fontSize: 12 }}>{moment(v).format('YYYY-DD-MM')}</T.Text>
)

const renderUser = (v: string) => <T.Text style={{ fontSize: 12 }}>{v}</T.Text>

const renderAction = (v: string) => (
  <T.Text strong style={{ fontSize: 12 }}>
    {mapAction(v)}
  </T.Text>
)

const renderStatus = (v: string) => (
  <T.Text style={{ fontSize: 12 }}>{v}</T.Text>
)

const AUDIT_TRALE_COLUMNS = [
  {
    key: 'timestamp',
    title: 'Timestamp',
    dataIndex: 'createdAt',
    render: renderTimestamp
  },
  { key: 'user', title: 'User', dataIndex: 'userEmail', render: renderUser },
  { key: 'action', title: 'Action', dataIndex: 'action', render: renderAction },
  { key: 'status', title: 'Status', dataIndex: 'status', render: renderStatus }
]

export default (props: Props) => {
  const { visible, accounts, id } = props
  const account = _.find(accounts, (a) => a.id === id)

  if (!account || id === null) {
    // id === null is used only for typing though
    return null
  }

  const sortedHistory = account.history
    .slice()
    .sort((a, b) =>
      moment(a.createdAt).unix() > moment(b.createdAt).unix() ? -1 : 1
    )

  const creationHistoryEntry = _.last(sortedHistory)

  const asset = creationHistoryEntry.currency
  const [address, rawTag] = (account.address || '').split('?')
  const params = new URLSearchParams(rawTag)

  const labelledValues = [
    { label: 'Withdrawal Account ID', value: account.withdrawalAccountId },
    { label: 'Nickname', value: account.name },
    { label: 'Status', value: account.status },
    {
      label: 'Scheduled Whitelist Threshold',
      value:
        account.status === 'PENDING_WHITELIST' && account.whitelistThreshold
          ? new Date(Number(account.whitelistThreshold)).toString()
          : ''
    },

    ...(account.accountType !== 'ADDRESS'
      ? [
          { label: 'Bank Name', value: account.bankName },
          { label: 'Beneficiary Name', value: account.beneficiaryName },
          { label: 'Account Number', value: account.accountNumber },
          {
            label: 'Recipient Instructions',
            value: account.recipientInstructions
          },
          ...(account.accountType === 'INTERNATIONAL_FIAT'
            ? [{ label: 'SWIFT', value: account.swiftCode }]
            : [{ label: 'Routing Number', value: account.routingNumber }])
        ]
      : []),

    ...(account.accountType === 'ADDRESS'
      ? [
          { label: 'Address', value: address },
          ...(TAG_BASED_ASSETS[asset]
            ? [
                {
                  label: TAG_BASED_ASSETS[asset].label,
                  value: params.get(TAG_BASED_ASSETS[asset].tag) || 'null'
                }
              ]
            : []),
          { label: 'Submitted Address', value: account.submitted_address }
        ]
      : []),

    {
      label: 'Withdrawal Limit',
      value: _.isFinite(account.withdrawalLimit)
        ? account.withdrawalLimit
        : 'no limit'
    }
  ]

  return (
    <Modal
      width={700}
      visible={visible}
      closable={false}
      onCancel={props.onClose}
      footer={
        <Button key="back" onClick={props.onClose}>
          Close
        </Button>
      }
    >
      <T.Title level={4}>Details</T.Title>
      {labelledValues.map(({ label, value }) => (
        <Row key={label} style={{ marginBottom: 12 }}>
          <Col span={10}>
            <T.Text>{label}</T.Text>
          </Col>
          {Array.isArray(value) ? (
            <Col span={14}>
              {value.map((t, i) => (
                <T.Text copyable style={{ whiteSpace: 'nowrap' }} key={i} code>
                  {t}
                </T.Text>
              ))}
            </Col>
          ) : (
            <Col span={14}>
              <T.Text copyable={value !== 'no limit' && !!value} code>
                {value}
              </T.Text>
            </Col>
          )}
        </Row>
      ))}

      <T.Title style={{ marginTop: 32 }} level={4}>
        Audit Trail
      </T.Title>
      <div style={{ margin: '0 -8px', maxHeight: 300, overflowY: 'auto' }}>
        <Table
          size="small"
          dataSource={sortedHistory}
          columns={AUDIT_TRALE_COLUMNS}
          pagination={false}
        />
      </div>
    </Modal>
  )
}
