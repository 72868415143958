import portalApi from './portalApi'

export const deleteParticipantUser = async ({
  token = '',
  participantCode,
  userCode
}: {
  token: string
  participantCode: string
  userCode: string
}): Promise<void> => {
  await portalApi.delete(`/${participantCode}/users/${userCode}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
