import * as React from 'react'
import moment from 'moment'
import * as _ from 'lodash'
import { Spin, Table, Button, Pagination, DatePicker, Select } from 'antd'
import short from 'short-uuid'

import {
  IParty,
  ITrade
} from 'seed-shared-components/lib/components/TradeBlotter/types'
import {
  dateFormatterStringPrecise,
  dateFormatterStringPreciseUTC
} from 'seed-shared-components/lib/formatters/date'
import Decimal from 'decimal.js'

import { numberFormatter } from 'seed-shared-components/lib/utils/CurrencyFormatter'
import { SearchOutlined } from '@ant-design/icons'
import Title from 'seed-shared-components/lib/components/Title'

const filterLimit = <T,>(
  collection: T[],
  predicate: (v: T) => boolean,
  limit: number
) => {
  const result = [] as T[]

  for (let i = 0; i < collection.length && result.length < limit; ++i) {
    if (predicate(collection[i])) {
      result.push(collection[i])
    }
  }

  return result
}

_.mixin({ filterLimit })

export interface ITradeBlotterPaginationConfig {
  page: number
  total: number
  pageSize: number
  onPageSelect: (page: number) => Promise<void>
}

export interface IParticipantForFilter {
  participant_code: string
  name: string
}

export interface ITradeBlotterProps {
  viewerParticipantCode?: string
  isAdmin?: boolean
  loading: boolean
  onRowClick: (record: any) => void
  trades: { [id: string]: ITrade }
  pagination: ITradeBlotterPaginationConfig
  additionalButton?: JSX.Element
  onChange?: any
  timestamps: 'utc' | 'local'
  hideFilters?: boolean
  fetchParticipantsForFilter: (
    filter: string,
    count: number
  ) => Promise<IParticipantForFilter[]>
}

export interface ITradeBlotterState {
  searchText: string
  platformSearch: string
  participantsForFilter: IParticipantForFilter[]
  participantsForFilterLoading: boolean
}

export default class TradeBlotter extends React.Component<
  ITradeBlotterProps,
  ITradeBlotterState
> {
  state = {
    searchText: '',
    platformSearch: '',
    participantsForFilter: [],
    participantsForFilterLoading: false
  }

  constructor(props: ITradeBlotterProps) {
    super(props)
  }

  handleReset = (clearFilters: any) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  filterDropdownForParticipantSearch = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }: any) => {
    return (
      <div style={{ padding: 8 }}>
        <div style={{ marginBottom: 12 }}>
          <Select
            showSearch
            loading={this.state.participantsForFilterLoading}
            placeholder="Search for participant"
            defaultActiveFirstOption={false}
            onSearch={_.debounce(async (query: string) => {
              this.setState({
                platformSearch: query,
                participantsForFilterLoading: true
              })

              const participantsForFilter = await this.props.fetchParticipantsForFilter(
                query,
                10
              )

              this.setState({
                participantsForFilter,
                participantsForFilterLoading: false
              })
            }, 400)}
            showArrow={false}
            value={selectedKeys[0]}
            style={{ width: 250 }}
            onChange={(value) => setSelectedKeys([value])}
            filterOption={false}
          >
            {this.state.participantsForFilter.map(
              (r: IParticipantForFilter) => {
                return (
                  <Select.Option
                    value={r.participant_code}
                    key={r.participant_code}
                  >
                    {r.participant_code} - {r.name}
                  </Select.Option>
                )
              }
            )}
          </Select>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      </div>
    )
  }

  get columns() {
    const { hideFilters = false } = this.props
    return [
      {
        title: 'Updated At',
        dataIndex: 'last_update',
        key: 'last_update'
      },
      {
        title: 'Transaction Timestamp',
        dataIndex: 'transaction_timestamp',
        key: 'transaction_timestamp',
        minWidth: 220,
        /**
         * Transaction timetamp is a bit more complex to filter than other fields.
         * This filtering should support from-to ranges with possible one value missing
         */
        filterDropdown: hideFilters
          ? undefined
          : ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
              <div style={{ padding: 8 }}>
                <DatePicker.RangePicker
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment('00:00:00', 'HH:mm:ss') as any,
                      moment('11:59:59', 'HH:mm:ss') as any
                    ]
                  }}
                  format="YYYY-MM-DD HH:mm:ss"
                  value={selectedKeys[0]}
                  onChange={(value) => setSelectedKeys([value])}
                  style={{ marginRight: 8 }}
                />
                <Button
                  type="primary"
                  onClick={() => confirm()}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90, marginRight: 8 }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => this.handleReset(clearFilters)}
                  size="small"
                  style={{ width: 90 }}
                >
                  Reset
                </Button>
              </div>
            ),
        filterIcon: hideFilters
          ? undefined
          : (filtered: boolean) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            ),
        sorter: (a: any, b: any) =>
          hideFilters
            ? undefined
            : moment(a.transaction_timestamp).unix() -
              moment(b.transaction_timestamp).unix()
      },
      {
        title: 'Trade ID',
        dataIndex: 'trade_id',
        key: 'trade_id',
        render: (text: string, record: ITrade) => (
          <a onClick={() => this.props.onRowClick(record)}>{text}</a>
        )
      },
      {
        title: 'Client Trade ID',
        dataIndex: 'client_trade_id',
        key: 'client_trade_id'
      },
      {
        title: 'Platform',
        dataIndex: 'platform_name',
        key: 'platform_name',
        filterDropdown: hideFilters
          ? undefined
          : this.filterDropdownForParticipantSearch,
        filterIcon: hideFilters
          ? undefined
          : (filtered: boolean) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            )
      },
      {
        title: 'Trade State',
        dataIndex: 'trade_state',
        key: 'trade_state',
        filters: hideFilters
          ? undefined
          : [
              {
                text: 'Accepted',
                value: 'accepted'
              },
              {
                text: 'Active',
                value: 'active'
              },
              {
                text: 'Terminated',
                value: 'terminated'
              }
            ]
      },
      {
        title: 'Instrument',
        dataIndex: 'symbol',
        key: 'symbol'
      },
      {
        title: 'Buyer',
        key: 'buyer_name',
        filterDropdown: hideFilters
          ? undefined
          : this.filterDropdownForParticipantSearch,
        render: (record: any) => {
          return (
            <div>
              <div>{record.buyer_name}</div>
              <small style={{ opacity: 0.5 }}>
                {record.settlement_states.buyer}
              </small>
            </div>
          )
        },
        filterIcon: hideFilters
          ? undefined
          : (filtered: boolean) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            )
      },
      {
        title: 'Seller',
        key: 'seller_name',
        filterDropdown: hideFilters
          ? undefined
          : this.filterDropdownForParticipantSearch,
        render: (record: any) => {
          return (
            <div>
              <div>{record.seller_name}</div>
              <small style={{ opacity: 0.5 }}>
                {record.settlement_states.seller}
              </small>
            </div>
          )
        },
        filterIcon: hideFilters
          ? undefined
          : (filtered: boolean) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            )
      },
      {
        title: 'Trade Price',
        key: 'trade_price',
        align: 'right' as const,
        render: (record: ITrade) => numberFormatter(record.trade_price)
      },
      {
        title: 'Trade Quantity',
        key: 'quantity',
        align: 'right' as const,
        render: (record: ITrade) =>
          numberFormatter(Number(record.trade_quantity))
      },
      {
        title: 'Trade Notional',
        key: 'notional',
        align: 'right' as const,
        render: (record: ITrade) =>
          record.trade_quantity && record.quoted_currency ? (
            numberFormatter(
              new Decimal(record.trade_price)
                .times(new Decimal(record.trade_quantity))
                .toNumber()
            )
          ) : (
            <>'N/A'</>
          )
      },
      {
        title: 'Total Notional',
        key: 'total_notional',
        align: 'right' as const,
        render: (record: ITrade) =>
          record.total_notional ? (
            numberFormatter(new Decimal(record.total_notional).toNumber())
          ) : (
            <>'N/A'</>
          )
      },
      {
        title: 'Network fee notional',
        key: 'network_fee_notional',
        align: 'right' as const,
        render: (record: ITrade) =>
          record.network_fee_notional ? (
            numberFormatter(new Decimal(record.network_fee_notional).toNumber())
          ) : (
            <>'N/A'</>
          )
      },
      {
        title: 'Network fee quantity',
        key: 'network_fee_quantity',
        align: 'right' as const,
        render: (record: ITrade) =>
          record.network_fee_quantity ? (
            numberFormatter(new Decimal(record.network_fee_quantity).toNumber())
          ) : (
            <>'N/A'</>
          )
      },
      {
        title: 'Asset cost notional',
        key: 'asset_cost_notional',
        align: 'right' as const,
        render: (record: ITrade) =>
          record.asset_cost_notional ? (
            numberFormatter(new Decimal(record.asset_cost_notional).toNumber())
          ) : (
            <>'N/A'</>
          )
      }
    ]
  }

  getPartyText(party: IParty | any | undefined) {
    if (party) {
      const participantCode = party.participant_code
        ? party.participant_code
        : party.participant.participant_code
      if (participantCode === 'Anonymous') {
        return participantCode
      }
      return `${party.name} (${participantCode})`
    } else {
      return 'N/A'
    }
  }

  getSettlementStates(trade: ITrade) {
    const buyer = trade.parties.filter(({ side }) => side === 'buy').pop()
    const seller = trade.parties.filter(({ side }) => side === 'sell').pop()

    return {
      buyer: buyer && buyer.settlement_state,
      seller: seller && seller.settlement_state
    }
  }

  get formattedTrades() {
    const dateFormatter =
      this.props.timestamps === 'utc'
        ? dateFormatterStringPreciseUTC
        : dateFormatterStringPrecise

    const formattedTrades = Object.keys(this.props.trades)
      .map((id, i) => {
        const trade = this.props.trades[id]
        const buyer = trade.parties.find((party) => party.side === 'buy')
        const seller = trade.parties.find((party) => party.side === 'sell')
        return {
          ...trade,
          quantity: trade.trade_quantity
            ? trade.trade_quantity
            : buyer
            ? buyer.amount
            : 'N/A',
          buyer_name: this.getPartyText(buyer),
          settlement_states: this.getSettlementStates(trade),
          seller_name: this.getPartyText(seller),
          platform_name: `${trade.platform_name} (${trade.platform_code})`,
          last_update: dateFormatter(trade.last_update),
          transaction_timestamp: dateFormatter(trade.transaction_timestamp),
          key: i
        }
      })
      .sort((a, b) => Date.parse(b.last_update) - Date.parse(a.last_update))
    return formattedTrades
  }

  handleTableChange = (...args) => {
    this.props.onChange?.(...args)
  }

  render() {
    return (
      <Spin spinning={this.props.loading}>
        <div className="trade-blotter">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 16
            }}
          >
            <div>
              <Title level={2} style={{ margin: 0 }}>
                Trade Blotter
              </Title>
            </div>
            <div>{this.props.additionalButton}</div>
          </div>
          <Table
            rowKey={() => short().generate()}
            style={{
              borderColor: 'green'
            }}
            dataSource={this.formattedTrades}
            columns={this.columns}
            pagination={false}
            size={'small'}
            onChange={this.handleTableChange}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              marginTop: 24,
              marginBottom: 24
            }}
          >
            <Pagination
              defaultCurrent={this.props.pagination.page}
              total={this.props.pagination.total}
              pageSize={this.props.pagination.pageSize}
              onChange={this.props.pagination.onPageSelect}
            />
          </div>
        </div>
      </Spin>
    )
  }
}
