import { createAction } from '@reduxjs/toolkit'
import {
  FUNDS_ASSET_BALANCES_LEAVE,
  FUNDS_ASSET_BALANCES_LOAD,
  FUNDS_LOAD_SUCCESS,
  FUNDS_LOAD_FAILURE,
  ILoadFundsSuccessPayload,
  FUNDS_LOAD
} from './types'

export const leaveAssetBalances = createAction(FUNDS_ASSET_BALANCES_LEAVE)
export const viewAssetBalances = createAction(FUNDS_ASSET_BALANCES_LOAD)
export const loadFunds = createAction(FUNDS_LOAD)
export const loadFundsSuccess = createAction<ILoadFundsSuccessPayload>(
  FUNDS_LOAD_SUCCESS
)
export const loadFundsFailure = createAction(FUNDS_LOAD_FAILURE)
