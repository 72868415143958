import { createAction } from '@reduxjs/toolkit'
import {
  SET_USER_SETTING,
  SET_USER_SETTINGS,
  ISetUserSettingActionPayload,
  ISetUserSettingsActionPayload
} from './types'

export const setUserSetting = createAction<ISetUserSettingActionPayload>(
  SET_USER_SETTING
)

export const setUserSettings = createAction<ISetUserSettingsActionPayload>(
  SET_USER_SETTINGS
)
