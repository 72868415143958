import { IParticipant } from 'seed-shared-components/lib/types'
import { IParticipantBasic } from 'seed-ts/types/participants'

export const PARTICIPANT_LOAD = 'PARTICIPANT/LOAD'
export const PARTICIPANT_LOAD_IS_PLATFORM_OPERATOR =
  'PARTICIPANT/LOAD_PLATFORM_OPERATORS'
export const PARTICIPANT_DETAILS_LOAD = 'PARTICIPANT/DETAILS_LOAD'
export const PARTICIPANT_SELECT = 'PARTICIPANT/SELECT'

export interface ILoadParticipantPayload {
  participant?: IParticipant
}

export interface ILoadPlatformOperatorsPayload {
  isPlatformOperator: boolean
}

export interface ILoadParticipantDetailsPayload {
  details: IParticipantBasic
}

export interface ILoadParticipantDetailsAction {
  type: typeof PARTICIPANT_DETAILS_LOAD
  payload: ILoadParticipantDetailsPayload
}

export interface ILoadParticipantAction {
  type: typeof PARTICIPANT_LOAD
  payload: ILoadParticipantPayload
}

export interface ILoadPlatformOperatorsAction {
  type: typeof PARTICIPANT_LOAD_IS_PLATFORM_OPERATOR
  payload: ILoadPlatformOperatorsPayload
}
