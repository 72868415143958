import React from 'react'
import { Tabs } from 'antd'

import { IAsset } from 'seed-shared-components/lib/static-data/assetConfig'
import { PlusOutlined } from '@ant-design/icons'

type IDepositAssetsAndCurrenciesProps = {
  assetsAndCurrenciesList: IAsset[]
  onChange: (key: string) => void
  selectedAsset?: IAsset
  includeAddCurrency?: boolean
}

const { TabPane } = Tabs

const AssetsAndCurrenciesTab = ({
  assetsAndCurrenciesList,
  onChange,
  selectedAsset,
  includeAddCurrency = true
}: IDepositAssetsAndCurrenciesProps) => {
  const renderTabs = () => {
    return (
      <Tabs
        activeKey={selectedAsset?.asset}
        onTabClick={onChange}
        tabBarStyle={{
          backgroundColor: '#ebebeb'
        }}
      >
        {assetsAndCurrenciesList.map((elem) => {
          return (
            <TabPane
              key={elem.asset}
              tab={
                <div
                  data-testid={`${elem.asset}-tab`}
                  style={{ paddingLeft: '12px', paddingRight: '12px' }}
                >
                  {elem.asset}
                </div>
              }
            />
          )
        })}
        {includeAddCurrency && <TabPane key="add" tab={<PlusOutlined />} />}
      </Tabs>
    )
  }

  return <>{renderTabs()}</>
}

export default AssetsAndCurrenciesTab
