import { IPlatformBalancesState } from './state'
import { createReducer } from '@reduxjs/toolkit'
import {
  ISetPlatformParticipants,
  ISetFundsAction,
  ISetRunsAction,
  ISetMovementsAction
} from './actions/types'
import * as PlatformBalancesActions from './actions'

const initialState: IPlatformBalancesState = {
  participants: { value: [], loading: true },
  funds: { value: [], loading: false },
  runs: { value: [], loading: true, page: 0, pageSize: 50, count: 0 },
  movements: {
    value: {
      page: 0,
      pageSize: 50,
      total: 0,
      result: []
    },
    loading: true
  }
}

export default createReducer(initialState, {
  [PlatformBalancesActions.setPlatformParticipants.type]: (
    state,
    action: ISetPlatformParticipants
  ) => ({
    ...state,
    participants: {
      ...state.participants,
      ...action.payload.participants
    }
  }),
  [PlatformBalancesActions.setFunds.type]: (
    state,
    action: ISetFundsAction
  ) => ({
    ...state,
    funds: {
      ...state.funds,
      ...action.payload.funds
    }
  }),
  [PlatformBalancesActions.setRuns.type]: (state, action: ISetRunsAction) => ({
    ...state,
    runs: {
      ...state.runs,
      ...action.payload.runs
    }
  }),
  [PlatformBalancesActions.setMovements.type]: (
    state,
    action: ISetMovementsAction
  ) => ({
    ...state,
    movements: {
      ...state.movements,
      ...action.payload.movements
    }
  })
})
