import { createAction, PrepareAction } from '@reduxjs/toolkit'
import fsaPrepareActionHandler from '../../../store/fsaPrepareActionHandler'
import {
  WITHDRAWAL_GET_ACCOUNTS,
  WITHDRAWAL_GET_ACCOUNTS_SUCCESS,
  WITHDRAWAL_GET_ACCOUNTS_FAILURE,
  WITHDRAWAL_GET_WITHDRAWAL,
  WITHDRAWAL_GET_WITHDRAWAL_SUCCESS,
  WITHDRAWAL_GET_WITHDRAWAL_FAILURE,
  GET_PLATFORM_TRANSFERS,
  GET_PLATFORM_TRANSFERS_SUCCESS,
  GET_PLATFORM_TRANSFERS_FAILURE,
  FETCH_WITHDRAWAL_REQUEST_HISTORY,
  FETCH_WITHDRAWAL_REQUEST_HISTORY_FULFILL,
  IGetWithdrawalAccountsPayload,
  IGetWithdrawalAccountsSuccessPayload,
  IGetWithdrawalsPayload,
  IGetWithdrawalsSuccessPayload,
  IGetPlatformTransfersPayload,
  IGetPlatformTransfersSuccessPayload,
  IFetchWithdrawalRequestHistoryAction,
  IFetchWithdrawalRequestHistoryFulfillActionSuccess,
  IFetchWithdrawalRequestHistoryFulfillActionFail
} from './types'

interface IFailure {
  error: Error
}

export const getAccounts = createAction<IGetWithdrawalAccountsPayload>(
  WITHDRAWAL_GET_ACCOUNTS
)
export const getAccountsSuccess = createAction<IGetWithdrawalAccountsSuccessPayload>(
  WITHDRAWAL_GET_ACCOUNTS_SUCCESS
)
export const getAccountsFailure = createAction<IFailure>(
  WITHDRAWAL_GET_ACCOUNTS_FAILURE
)
export const getWithdrawals = createAction<IGetWithdrawalsPayload>(
  WITHDRAWAL_GET_WITHDRAWAL
)
export const getWithdrawalsSuccess = createAction<IGetWithdrawalsSuccessPayload>(
  WITHDRAWAL_GET_WITHDRAWAL_SUCCESS
)
export const getWithdrawalsFailure = createAction<IFailure>(
  WITHDRAWAL_GET_WITHDRAWAL_FAILURE
)
export const getplatformTransfers = createAction<IGetPlatformTransfersPayload>(
  GET_PLATFORM_TRANSFERS
)
export const getplatformTransfersSuccess = createAction<IGetPlatformTransfersSuccessPayload>(
  GET_PLATFORM_TRANSFERS_SUCCESS
)
export const getplatformTransfersFailure = createAction<IFailure>(
  GET_PLATFORM_TRANSFERS_FAILURE
)

export const fetchWithdrawalRequestHistory = createAction<
  IFetchWithdrawalRequestHistoryAction['payload']
>(FETCH_WITHDRAWAL_REQUEST_HISTORY)
export const fetchWithdrawalRequestHistoryFulfill = createAction<
  PrepareAction<
    | IFetchWithdrawalRequestHistoryFulfillActionSuccess
    | IFetchWithdrawalRequestHistoryFulfillActionFail
  >
>(FETCH_WITHDRAWAL_REQUEST_HISTORY_FULFILL, fsaPrepareActionHandler)
