import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import Title from 'seed-shared-components/lib/components/Title'
import { Button, Col, List, Modal, Row, Tag, Typography } from 'antd'
import {
  API_KEY_STATE_STATUS,
  IApiKeyHistoryRecord,
  IApiKeyLedgerTableItem,
  IApiKeyLedgerTableItemPermission
} from '../types'

export interface IApiKeyDetailsModalProps {
  apiKey: IApiKeyLedgerTableItem
  apiKeyApprovalsRequiredCount: number
  onOk: () => void
}

const ApiKeyDetailsModal = ({
  onOk,
  apiKey,
  apiKeyApprovalsRequiredCount
}: IApiKeyDetailsModalProps) => {
  const apiKeyApprovalsCurrentCount = apiKey.history.filter((historyRecord) => {
    return historyRecord.status === API_KEY_STATE_STATUS.PENDING_APPROVAL
  }).length

  return (
    <Modal
      visible
      width={800}
      title={
        <Title level={3} style={{ marginTop: 16 }}>
          API Key Details
        </Title>
      }
      footer={[
        <Button key="ok" type="primary" onClick={onOk}>
          Ok
        </Button>
      ]}
      onCancel={onOk}
    >
      <Title level={4}>Information</Title>

      <List
        bordered
        dataSource={[apiKey]}
        renderItem={(apiKey: IApiKeyLedgerTableItem) => (
          <>
            <List.Item>
              <Row style={{ width: '100%' }}>
                <Col span={8}>Name:</Col>
                <Col span={16}>
                  <Typography.Text code copyable>
                    {apiKey.name}
                  </Typography.Text>{' '}
                </Col>
              </Row>
            </List.Item>
            <List.Item>
              <Row style={{ width: '100%' }}>
                <Col span={8}>Status:</Col>
                <Col span={16}>
                  <Typography.Text code>{apiKey.status}</Typography.Text>

                  {apiKey.status === API_KEY_STATE_STATUS.CREATED &&
                    _.isNumber(apiKeyApprovalsRequiredCount) && (
                      <>
                        <Typography.Text type="secondary">
                          {' '}
                          - Approvals required{' '}
                          <strong>{apiKeyApprovalsRequiredCount}</strong> |
                          Already received{' '}
                          <strong>
                            {/*// in case an API key has an approval count of 0, check if history of changes for key says otherwise*/}
                            {apiKey.approvals_count ||
                              apiKeyApprovalsCurrentCount}
                          </strong>
                        </Typography.Text>
                      </>
                    )}
                </Col>
              </Row>
            </List.Item>
            <List.Item>
              <Row style={{ width: '100%' }}>
                <Col span={8}>Public Key:</Col>
                <Col span={16}>
                  <Typography.Text code copyable>
                    {apiKey.public_key}
                  </Typography.Text>
                </Col>
              </Row>
            </List.Item>
            <List.Item>
              <Row style={{ width: '100%' }}>
                <Col span={8}>Created at:</Col>
                <Col span={16}>
                  <Typography.Text code>
                    {moment(apiKey.created_at).format('YYYY-MM-DD h:mm:ss')}
                  </Typography.Text>
                </Col>
              </Row>
            </List.Item>
            {!!apiKey.expire_at && (
              <List.Item>
                <Row style={{ width: '100%' }}>
                  <Col span={8}>Expire at:</Col>
                  <Col span={16}>
                    <Typography.Text code>
                      {moment(apiKey.expire_at).format('YYYY-MM-DD h:mm:ss')}
                    </Typography.Text>
                  </Col>
                </Row>
              </List.Item>
            )}
            <List.Item>
              <Row style={{ width: '100%' }}>
                <Col span={8}>Permissions:</Col>
                <Col
                  span={16}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap'
                  }}
                >
                  {apiKey.permissions.map(
                    (permission: IApiKeyLedgerTableItemPermission) => (
                      <div
                        key={permission.resource}
                        style={{ marginTop: 10, width: '50%' }}
                      >
                        <Tag style={{ cursor: 'inherit' }}>
                          <span className="bold-text" style={{ fontSize: 12 }}>
                            {`${permission.resource}:`}
                          </span>
                          <span>
                            {` ${permission.actions.map((action, i) =>
                              i === 0 ? action : ` ${action}`
                            )}`}
                          </span>
                        </Tag>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </List.Item>
            <List.Item>
              <Row style={{ width: '100%' }}>
                <Col span={8}>Withdrawals account label:</Col>
                <Col span={16}>
                  <Typography.Text code>
                    {apiKey?.withdrawals_account_label || 'null'}
                  </Typography.Text>
                </Col>
              </Row>
            </List.Item>
          </>
        )}
      />

      {apiKey.history.length && (
        <>
          <Title level={4} style={{ marginTop: 24 }}>
            Audit trail
          </Title>

          <List
            bordered
            rowKey={'timestamp'}
            dataSource={[{ timestamp: 0 }, ...apiKey.history]}
            renderItem={(historyRecord: any | IApiKeyHistoryRecord) => {
              if (historyRecord.timestamp === 0) {
                return (
                  <List.Item>
                    <Row style={{ width: '100%' }}>
                      <Col span={8}>Date</Col>
                      <Col span={8}>User</Col>
                      <Col span={4}>Action</Col>
                      <Col span={4}>Status</Col>
                    </Row>
                  </List.Item>
                )
              }

              return (
                <List.Item>
                  <Row style={{ width: '100%' }}>
                    <Col span={8}>
                      {moment(historyRecord.timestamp).format(
                        'YYYY-MM-DD h:mm:ss'
                      )}
                    </Col>
                    <Col span={8}>
                      {!historyRecord.executed_by_user_email && (
                        <Typography.Text code>unknown</Typography.Text>
                      )}

                      {historyRecord.executed_by_user_email && (
                        <Typography.Text code copyable>
                          {historyRecord.executed_by_user_email}
                        </Typography.Text>
                      )}
                    </Col>
                    <Col span={4}>
                      <Typography.Text code>
                        {historyRecord.action}
                      </Typography.Text>
                    </Col>
                    <Col span={4}>
                      <Typography.Text code>
                        {historyRecord.status}
                      </Typography.Text>
                    </Col>
                  </Row>
                </List.Item>
              )
            }}
          />
        </>
      )}
    </Modal>
  )
}

export default ApiKeyDetailsModal
