import * as React from 'react'
import { IAuthUser } from '../auth/WithAuthentication'
import Title from 'seed-shared-components/lib/components/Title'

interface IChangePasswordProps {
  authUser: IAuthUser
}
class ChangePassword extends React.Component<IChangePasswordProps> {
  render() {
    this.props.authUser.changePassword()
    return (
      <>
        <div>
          <Title level={2}>Password Reset</Title>
        </div>

        <div style={{ marginBottom: 10 }}>
          {`An email has just been sent to ${this.props.authUser.email} with details on the reset process.`}
        </div>

        <div style={{ marginBottom: 24 }}>
          Having trouble finding the email? Check your spam folder, search your
          email for "Password Change Request" or{' '}
          <a
            href="mailto:support@zerohash.com?subject=Assistance resetting password"
            target="_blank"
          >
            contact support
          </a>{' '}
          for assistance. You can also review our{' '}
          <a
            href="https://zerohash.zendesk.com/hc/en-us/sections/360003587273"
            target="_blank"
          >
            FAQ
          </a>{' '}
          for more information.
        </div>
      </>
    )
  }
}

export default ChangePassword
