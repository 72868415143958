export enum DashboardSteps {
  'createParticipant',
  'apply',
  'signApplication',
  'seedApproval',
  'addUsers',
  'fund',
  'trade'
}

export const COLORS = [
  '#483D8B',
  '#808080',
  '#FFD700',
  '#6600FF',
  '#800000',
  '#556B2F',
  '#FF00FF',
  '#191970',
  '#D8BFD8',
  '#0010FF',
  '#FF0000',
  '#88FF88',
  '#DEB887',
  '#FF6600',
  '#00FF00',
  '#C0C0C0'
]
