import portalApi from '../api/portalApi'

const isValidDigitalAssetAddress = async (
  asset: string,
  address: string,
  destinationTag: string
) => {
  try {
    await portalApi.request({
      method: 'POST',
      url: 'validate-digital-address',
      data: { asset, address, destinationTag },
      omitNotification: true
    })

    return true
  } catch (error) {
    return false
  }
}

export default isValidDigitalAssetAddress
