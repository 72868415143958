import * as React from 'react'
import { AxiosError } from 'axios'
import { notification } from 'antd'
import { ArgsProps } from 'antd/lib/notification'

export default ({
  error,
  title = 'An error occurred',
  message,
  notificationParams = {}
}: {
  error: AxiosError
  title?: string
  message?: string
  notificationParams?: Omit<ArgsProps, 'message'>
}) => {
  const requestId = error?.config?.headers?.['X-Request-Id']

  return notification.error({
    duration: 0,
    ...notificationParams,
    description: (
      <div>
        <p>{message}</p>

        {requestId && (
          <p>
            <strong>Request ID:</strong>
            <br />
            {requestId}
          </p>
        )}
      </div>
    ),
    message: title
  })
}
