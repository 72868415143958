import { IReport } from './types'
import portalApi from '../../api/portalApi'

export const fetchReports = async ({
  token,
  participantCode
}: {
  token: string
  participantCode: string
}): Promise<IReport[]> => {
  const { data } = await portalApi.get(`${participantCode}/reports`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    customErrorMessage: {
      500: 'Fetch reports failed, please contact support.'
    }
  })

  return data
}

export const fetchAccountLabels = async ({
  token,
  participantCode
}: {
  token: string
  participantCode: string
}): Promise<IReport[]> => {
  const { data } = await portalApi.get(`${participantCode}/account_labels`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    customErrorMessage: {
      500: 'Fetch account labels failed, please contact support.'
    }
  })

  return data
}

export const fetchReport = async ({
  token,
  participantCode,
  type,
  id
}: {
  token: string
  participantCode: string
  type: string
  id: string
}): Promise<IReport[]> => {
  const { data } = await portalApi.get(
    `${participantCode}/reports/${type}/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      customErrorMessage: {
        500: `Fetch report by ID ${id} failed, please contact support.`
      }
    }
  )

  return data
}

export const generateReport = async ({
  token,
  participantCode,
  reportParams
}: {
  token: string
  participantCode: string
  reportParams: {
    type: string
    participant?: string
    platform?: string
    from?: string
    to?: string
    timestamp?: string
    account_label?: string
  }
}): Promise<IReport[]> => {
  const { data } = await portalApi.post(
    `${participantCode}/reports/generate`,
    reportParams,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      customErrorMessage: {
        500: 'Generate report failed, please contact support.'
      }
    }
  )

  return data
}

export const generateReportSignedLink = async ({
  token,
  participantCode,
  key
}: {
  token: string
  participantCode: string
  key: string
}): Promise<{ signedLink: string }> => {
  const { data } = await portalApi.post(
    `${participantCode}/reports/signed-link`,
    { key },
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      customErrorMessage: {
        500: 'Generate signed link failed, please contact support.'
      }
    }
  )

  return data
}
