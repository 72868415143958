import { IPortfolioState } from './state'
import { createReducer } from '@reduxjs/toolkit'
import { ISetPortfolio } from './actions/types'
import * as actions from './actions'

const initialState: IPortfolioState = {
  portfolio: { value: [], loading: true }
}

export default createReducer(initialState, {
  [actions.setPortfolio.type]: (state, action: ISetPortfolio) => ({
    ...state,
    portfolio: {
      ...state.portfolio,
      ...action.payload
    }
  })
})
