import { createAction } from '@reduxjs/toolkit'
import {
  FETCH_PLATFORM_PARTICIPANTS,
  SET_PLATFORM_PARTICIPANTS,
  IFetchPlatformParticipantsAction,
  ISetPlatformParticipants,
  FETCH_FUNDS,
  SET_FUNDS,
  IFetchFundsAction,
  ISetFundsAction,
  FETCH_RUNS,
  SET_RUNS,
  IFetchRunsAction,
  ISetRunsAction,
  FETCH_MOVEMENTS,
  SET_MOVEMENTS,
  IFetchMovementsAction,
  ISetMovementsAction
} from './types'

export const fetchPlatformParticipants = createAction<
  IFetchPlatformParticipantsAction['payload']
>(FETCH_PLATFORM_PARTICIPANTS)
export const setPlatformParticipants = createAction<
  ISetPlatformParticipants['payload']
>(SET_PLATFORM_PARTICIPANTS)

export const fetchFunds = createAction<IFetchFundsAction['payload']>(
  FETCH_FUNDS
)
export const setFunds = createAction<ISetFundsAction['payload']>(SET_FUNDS)

export const fetchRuns = createAction<IFetchRunsAction['payload']>(FETCH_RUNS)
export const setRuns = createAction<ISetRunsAction['payload']>(SET_RUNS)

export const fetchMovements = createAction<IFetchMovementsAction['payload']>(
  FETCH_MOVEMENTS
)
export const setMovements = createAction<ISetMovementsAction['payload']>(
  SET_MOVEMENTS
)
