import * as React from 'react'

import { FormInputItem } from '.'
import { IRadioGroupItemConfig } from '../../configuration'
import { Radio, Tooltip } from 'antd'
import FormItem from '@ant-design/compatible/es/form/FormItem'
import { QuestionCircleOutlined } from '@ant-design/icons'

export interface IRadioGroupItemProps
  extends FormInputItem<IRadioGroupItemConfig> {}

export const RadioGroupFormItem = (props: IRadioGroupItemProps) => {
  const style =
    props.orientation === 'vertical'
      ? {
          display: 'block',
          height: '30px',
          lineHeight: '30px'
        }
      : undefined

  return (
    <Tooltip placement="leftTop" title={props.tooltip}>
      <div>
        <FormItem
          extra={props.extraInstructions}
          {...props.formItemLayout}
          label={
            <span data-testid={props.itemKey} style={{ whiteSpace: 'normal' }}>
              {props.label}
            </span>
          }
        >
          {props.form.getFieldDecorator(props.itemKey, {
            initialValue: props.value,
            rules: props.rules
          })(
            <Radio.Group>
              {props.options.map((o, i) => (
                <Radio.Button
                  key={`${props.itemKey}-${i}`}
                  value={o.value}
                  style={style}
                >
                  {o.label}
                </Radio.Button>
              ))}
              {props.tooltip ? <QuestionCircleOutlined /> : null}
            </Radio.Group>
          )}
        </FormItem>
      </div>
    </Tooltip>
  )
}

export default RadioGroupFormItem
