import * as React from 'react'
import * as auth from '../auth/auth'

export default () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      padding: '20px',
      textAlign: 'left'
    }}
  >
    <a href="/">
      <img style={{ width: '360px' }} src="/portal-logo.png" />
    </a>

    <br />
    <br />

    <h2 style={{ maxWidth: '1000px' }}>
      <b>We have just sent you a verification email.</b>
    </h2>

    <h2 style={{ maxWidth: '1000px' }}>
      <b>Please click the link in the email to continue the signup process.</b>
    </h2>

    <br />

    <p style={{ maxWidth: '1000px' }}>
      Having trouble? Check your spam folder, search your email for "Welcome to
      Zero Hash" or{' '}
      <a
        href={`mailto:support@zerohash.com?subject=Assistance required verifying email address`}
        target="_blank"
      >
        {` `}
        contact support
      </a>{' '}
      for assistance. You can also review our{' '}
      <a
        href={`https://zerohash.zendesk.com/hc/en-us/sections/360003587273`}
        target="_blank"
      >
        {` `}
        FAQ
      </a>{' '}
      for more information.
    </p>

    <br />

    <a
      onClick={auth.logout}
      style={{
        backgroundColor: '#00f197',
        border: '1px solid #00f197',
        color: '#FFFFFF',
        padding: '10px 40px',
        textAlign: 'center',
        fontSize: '18 px',
        transition: 'all 0.3s ease-in-out',
        width: 200
      }}
    >
      Return to Login
    </a>
  </div>
)
