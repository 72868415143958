import React, { useState } from 'react'
import { Modal, Form, Input } from 'antd'
import { Rule } from 'antd/lib/form'

import NumberInput from 'seed-shared-components/lib/components/NumberInput'
import { IAsset } from 'seed-shared-components/lib/static-data/assetConfig'
import isValidAddress from '../../../utils/digitalAssetAddressVerifier'
import TAG_BASED_ASSETS from '../../../utils/tagBasedAssets'
import { IAssetWithNewProps } from '../types'
import FiatFormItems from './FiatFormItems'
import FiatTabs from './FiatTabs'
import CryptoFormItems from './CryptoFormItems'
import { requiredRule } from './utils'
import { CryptoFormData, FiatFormData, FiatTabType } from './types'

type AccountModalProps = {
  visible: boolean
  loading: boolean
  asset: IAsset
  onClose: () => void
  onCreate: (data: any) => void
}

const getFiatAccountTypeByTab = (tab: string) => {
  switch (tab) {
    case 'domestic':
      return 'DOMESTIC_FIAT'
    case 'international':
      return 'INTERNATIONAL_FIAT'
    case 'network':
      return 'REAL_TIME_FIAT'
    default:
      return ''
  }
}

const validateAddressRule = async (
  _: Rule,
  address: string,
  asset: string,
  destinationTag: string
) => {
  if (!address) return Promise.reject('Address is required')
  try {
    const isAddressValid = await isValidAddress(asset, address, destinationTag)
    if (isAddressValid) return Promise.resolve()
  } catch (e) {}
  return Promise.reject(`Invalid ${asset} address`)
}

const NewAccountModal = (props: AccountModalProps) => {
  const [form] = Form.useForm()
  const [tab, setTab] = useState<FiatTabType>(
    props.asset.type === 'Fiat' ? 'network' : ''
  )
  const submitFiat = async (values: FiatFormData) => {
    const accountType = getFiatAccountTypeByTab(tab)
    const addressHint = `${values.number.slice(-4)}`
    props.onCreate({
      ...values,
      bankName:
        'networkBankName' in values ? values.networkBankName : values.bankName,
      accountType,
      currency: props.asset.asset,
      name: `${values.name} (${addressHint})`
    })
  }
  const submitCrypto = async (values: CryptoFormData) => {
    const accountType = 'ADDRESS'
    const address =
      shouldShowTag() && tagValue
        ? values.address + `?${getTagName()}=${tagValue}`
        : values.address
    const addressHint = `${address.slice(0, 3)}...${address.slice(-3)}`
    props.onCreate({
      ...values,
      accountType,
      address: address,
      currency: props.asset.asset,
      name: `${values.name} (${addressHint})`
    })
  }
  const shouldShowTag = () => !!TAG_BASED_ASSETS[props.asset.asset]
  const tagValue = Form.useWatch('tag', form)
  const getTagName = () =>
    TAG_BASED_ASSETS[props.asset.asset] &&
    TAG_BASED_ASSETS[props.asset.asset].tag
  const asset = props.asset as IAssetWithNewProps
  const { visible, loading } = props
  const accountTypeName = asset.type === 'Crypto' ? 'Address' : 'Account'
  const correspondentBankValue = Form.useWatch('correspondentBank', form)
  const intermediaryBankValue = Form.useWatch('intermediaryBank', form)
  return (
    <Modal
      title={`New ${asset.asset} Withdrawal ${accountTypeName}`}
      open={visible}
      confirmLoading={loading}
      onCancel={props.onClose}
      onOk={form.submit}
      width={700}
    >
      {asset.type === 'Fiat' && <FiatTabs setTab={setTab} tab={tab} />}
      <Form
        onFinish={asset.type === 'Crypto' ? submitCrypto : submitFiat}
        layout="horizontal"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 14 }}
        form={form}
      >
        <Form.Item name="name" label="Account Nickname" rules={[requiredRule]}>
          <Input />
        </Form.Item>
        {asset.type === 'Crypto' && (
          <CryptoFormItems
            asset={props.asset}
            validateAddressRule={validateAddressRule}
            destinationTagValue={tagValue}
          />
        )}
        {asset.type === 'Fiat' && (
          <FiatFormItems
            correspondentBankValue={correspondentBankValue}
            intermediaryBankValue={intermediaryBankValue}
            tab={tab}
          />
        )}
        <Form.Item
          name="withdrawalLimit"
          label="Withdrawal Limit"
          extra="Withdrawal limits are applied per withdrawal. Entering no value will allow users to submit withdrawals of any amount."
        >
          <NumberInput
            acceptDecimal={true}
            allowLeadingZero={true}
            decimalScale={asset.asset ? asset.precision : 2}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default NewAccountModal
