import React from 'react'
import { Spin } from 'antd'

type ZHSpinProps = {
  style?: React.CSSProperties
}

const ZHSpin = ({ style }: ZHSpinProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '50vh',
        ...style
      }}
    >
      <Spin />
    </div>
  )
}

export default ZHSpin
