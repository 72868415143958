import * as React from 'react'
import * as moment from 'moment'
import * as Redux from 'react-redux'

import * as reportsSelectors from '../store/reports/selectors'
import * as participantSelectors from '../store/participant/selectors'
import * as reportsActions from '../store/reports/actions'

import {
  Button,
  Card,
  Col,
  DatePicker,
  Radio,
  Row,
  Select,
  Tooltip,
  Typography
} from 'antd'
import * as antdBase from 'antd/lib/typography/Base'

import { ReportTypeToTitle } from './helpers'
import { ReportType } from '../store/reports/types'
import { CloudUploadOutlined } from '@ant-design/icons'
import { useState } from 'react'

function useConnect() {
  const dispatch = Redux.useDispatch()
  const { loading } = Redux.useSelector(reportsSelectors.generateReport)
  const { registration_code: participant } = Redux.useSelector(
    participantSelectors.getDetails
  )
  const accountLabels = Redux.useSelector(reportsSelectors.getAccountLabels)
  const fetchAccountLabels = React.useCallback(() => {
    return dispatch(
      reportsActions.fetchAccountLabels({ participantCode: participant })
    )
  }, [participant])

  const generateReport = React.useCallback(
    ({ type, target, from, to, timestamp, accountLabel }) => {
      return dispatch(
        reportsActions.generateReport({
          type,
          target,
          participant,
          from,
          to,
          timestamp,
          accountLabel
        })
      )
    },
    [participant]
  )

  return {
    loading,
    accountLabels,
    fetchAccountLabels,
    generateReport
  }
}

export enum ReportTarget {
  Participant = 'participant',
  Platform = 'platform'
}

function ReportsLimitText({ type = 'secondary' }: { type: antdBase.BaseType }) {
  return (
    <div style={{ textAlign: 'center' }}>
      <Typography.Text type={type}>
        You can view 2 months of data at a time
      </Typography.Text>
    </div>
  )
}

function GenerateReportForm() {
  const {
    loading,
    accountLabels,
    fetchAccountLabels,
    generateReport
  } = useConnect()

  React.useEffect(() => {
    fetchAccountLabels()
  }, [])

  const [isFromToDatesValid, setIsFromToDatesValid] = React.useState(true)
  const [getSelectedAccountLabel, setSelectedAccountLabel] = useState<string>(
    null
  )

  const [target, setTarget] = React.useState(ReportTarget.Participant)
  const handleTargetChange = React.useCallback((e) => {
    setTarget(e.target.value)
  }, [])

  const [timestamp, setTimestamp] = React.useState(moment())
  const handleTimestampChange = React.useCallback((mDate) => {
    setTimestamp(mDate)
  }, [])

  const [[from, to], setFromTo] = React.useState([
    moment().subtract(2, 'month'),
    moment()
  ])
  const handleFromToChange = React.useCallback(([mFromDate, mToDate]) => {
    setFromTo([mFromDate, mToDate])
  }, [])

  const [type, setType] = React.useState(null)
  const handleReportTypeChange = React.useCallback((type) => {
    setType(type)
    setTarget(type ? ReportTarget.Participant : null)
  }, [])

  const handleGenerate = React.useCallback(() => {
    generateReport({
      type: type,
      target,
      from: from.toISOString(),
      to: to.toISOString(),
      timestamp: timestamp.toISOString(),
      accountLabel: getSelectedAccountLabel
    })
  }, [type, target, from, to, timestamp, getSelectedAccountLabel])

  React.useEffect(() => {
    if (![ReportType.LoanInterest, ReportType.Loans].includes(type)) {
      if (from && to) {
        setIsFromToDatesValid(
          from.isAfter(to.clone().subtract(2, 'month').subtract(1, 'second'))
        )
      } else {
        setIsFromToDatesValid(false)
      }
    } else {
      setIsFromToDatesValid(true)
    }
  }, [from, to, type])

  return (
    <Card style={{ width: '100%' }}>
      <Row justify="space-between">
        <Col>
          <Select
            style={{ width: 300 }}
            placeholder="Select report type"
            optionFilterProp="children"
            onChange={handleReportTypeChange}
            disabled={loading}
          >
            {Object.values(ReportType).map((type) =>
              // Workaround to disable Loan reports
              ![ReportType.LoanInterest, ReportType.Loans].includes(type) ? (
                <Select.Option value={type} key={type}>
                  {ReportTypeToTitle[type]}
                </Select.Option>
              ) : null
            )}
          </Select>
        </Col>

        {[ReportType.LoanInterest, ReportType.Loans].includes(type) && (
          <React.Fragment key={type}>
            <Col>
              <Radio.Group
                defaultValue={target}
                buttonStyle="solid"
                disabled={loading}
              >
                <Radio.Button value={ReportTarget.Participant}>
                  Participant
                </Radio.Button>
                <Radio.Button value={ReportTarget.Platform} disabled>
                  Platform
                </Radio.Button>
              </Radio.Group>
            </Col>
            <Col>
              <DatePicker
                defaultValue={timestamp as any}
                format={'MM/DD/YYYY'}
                disabled={loading}
                onChange={handleTimestampChange}
              />
            </Col>
          </React.Fragment>
        )}

        {[ReportType.NetworkFees].includes(type) && (
          <React.Fragment key={type}>
            <Col>
              <Radio.Group
                defaultValue={target}
                buttonStyle="solid"
                disabled={loading}
              >
                <Radio.Button value={ReportTarget.Participant}>
                  Participant
                </Radio.Button>
                <Radio.Button value={ReportTarget.Platform} disabled>
                  Platform
                </Radio.Button>
              </Radio.Group>
            </Col>
            <Col>
              <DatePicker.RangePicker
                defaultValue={[from as any, to as any]}
                format={'MM/DD/YYYY'}
                disabled={loading}
                onChange={handleFromToChange}
              />
              <ReportsLimitText
                type={isFromToDatesValid ? 'secondary' : 'danger'}
              />
            </Col>
            <Col>
              <Select
                style={{ width: 250 }}
                onChange={setSelectedAccountLabel}
                showSearch
                allowClear
                placeholder="Account Label"
              >
                {accountLabels.value.map((ac, idx) => {
                  return (
                    <Select.Option value={ac} key={idx}>
                      {ac}
                    </Select.Option>
                  )
                })}
              </Select>
            </Col>
          </React.Fragment>
        )}

        {type &&
          ![
            ReportType.LoanInterest,
            ReportType.Loans,
            ReportType.NetworkFees
          ].includes(type) && (
            <React.Fragment key={type}>
              <Col>
                <Radio.Group
                  defaultValue={target}
                  buttonStyle="solid"
                  disabled={loading}
                  onChange={handleTargetChange}
                >
                  <Radio.Button value={ReportTarget.Participant}>
                    Participant
                  </Radio.Button>
                  <Radio.Button value={ReportTarget.Platform}>
                    Platform
                  </Radio.Button>
                </Radio.Group>
              </Col>
              <Col>
                <DatePicker.RangePicker
                  defaultValue={[from as any, to as any]}
                  format={'MM/DD/YYYY'}
                  disabled={loading}
                  onChange={handleFromToChange}
                />
                <ReportsLimitText
                  type={isFromToDatesValid ? 'secondary' : 'danger'}
                />
              </Col>
              <Col>
                <Select
                  style={{ width: 250 }}
                  onChange={setSelectedAccountLabel}
                  showSearch
                  allowClear
                  placeholder="Account Label"
                >
                  {accountLabels.value.map((ac, idx) => {
                    return (
                      <Select.Option value={ac} key={idx}>
                        {ac}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
            </React.Fragment>
          )}

        <Col style={{ textAlign: 'right' }}>
          {!type && (
            <Tooltip placement="top" title="Select report type first">
              <Button
                type="primary"
                icon={<CloudUploadOutlined />}
                disabled={true}
              >
                Generate report
              </Button>
            </Tooltip>
          )}

          {type && (
            <>
              {isFromToDatesValid && (
                <Button
                  type="primary"
                  icon={<CloudUploadOutlined />}
                  loading={loading}
                  onClick={handleGenerate}
                >
                  Generate report
                </Button>
              )}

              {!isFromToDatesValid && (
                <Tooltip
                  placement="top"
                  title="Dates range is not valid, look back limit: 2 months"
                >
                  <Button
                    type="primary"
                    icon={<CloudUploadOutlined />}
                    disabled={true}
                  >
                    Generate report
                  </Button>
                </Tooltip>
              )}
            </>
          )}
        </Col>
      </Row>
    </Card>
  )
}

export default GenerateReportForm
