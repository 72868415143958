import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import * as uuid from 'uuid'
import {
  IPortalApiWithdrawalRequest,
  IPortalApiWithdrawalAccount
} from '../withdrawalTypes'
import { notification, message } from 'antd'
import RestService from '../../RestService'

import RequestDetailsModal from '../components/RequestDetails'
import WithdrawalRequestsTable from '../components/WithdrawalRequestsTable'
import { assets as assetConfigs } from 'seed-shared-components/lib/static-data/assetConfig'

import Title from 'seed-shared-components/lib/components/Title'
import AssetsAndCurrenciesTab from '../../deposit/v2/AssetsAndCurrenciesTab'
import { IAsset } from 'seed-shared-components/lib/static-data/assetConfig'
import { useHistory, useParams } from 'react-router-dom'
import AuthUserContext from '../../auth/AuthUserContext'

interface IWithdrawalsPageProps {
  restService: RestService
  participantDetails: any
}

const NewAllWithdrawals = ({
  restService,
  participantDetails
}: IWithdrawalsPageProps) => {
  const history = useHistory()
  const authUser = useContext(AuthUserContext)

  const {
    withdrawal_account_required_approvers,
    withdrawal_request_required_approvers
  } = participantDetails
  const approves = {
    account: withdrawal_account_required_approvers,
    request: withdrawal_request_required_approvers
  }

  const params: { participantCode: string; asset: string } = useParams()
  const { participantCode, asset: assetPathParam } = params
  const [isLoadingRequests, setIsLoadingRequests] = useState(false)
  const [requests, setRequests] = useState([])
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 50,
    total: 0
  })
  const [accounts, setAccounts] = useState<IPortalApiWithdrawalAccount[]>([])
  const [requestDetailsModal, setRequestDetailsModal] = useState({
    visible: false,
    request: null
  })
  const [isAdmin, setIsAdmin] = useState(false)
  const [assets, setAssets] = useState<IAsset[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const fetchAssets = async () => {
    const cryptoAssets: IAsset[] = await restService
      .route(`assets?requestId=${uuid.v4()}&asset_type=CRYPTO`)
      .get()
    const fiatCurrencies = assetConfigs.filter((asset) => asset.type === 'Fiat')

    setAssets(
      [...cryptoAssets, ...fiatCurrencies].sort((a, b) =>
        a.asset.localeCompare(b.asset)
      )
    )
  }

  useEffect(() => {
    fetchAssets()
  }, [assetPathParam])

  const approve = async (id: number | string) => {
    setIsSubmitting(true)
    message.loading({ content: 'Approving...', key: 'approve-request' })
    try {
      await await restService
        .route(`withdrawal_requests/${id}/approve`)
        .post({}, { omitNotification: true })
      message.success({ content: 'Approved!', key: 'approve-request' })
      fetchRequests()
    } catch (e) {
      message.error({ content: 'Failed', key: 'approve-request' })
      notification.error({
        message: `There was an error while approving the withdrawal request. Status code: ${e.response.status}`,
        description: e.response?.data?.message,
        duration: 0
      })
    }
    setIsSubmitting(false)
  }

  const reject = async (id: number | string) => {
    setIsSubmitting(true)
    message.loading({ content: 'Rejecting...', key: 'reject-request' })
    try {
      await restService.route(`withdrawal_requests/${id}/reject`).post({})
      message.success({ content: 'Rejected!', key: 'reject-request' })
      fetchRequests()
    } catch (e) {
      message.error({ content: 'Failed', key: 'reject-request' })
      notification.error({
        message: `There was an error while rejecting withdrawal request. Status code: ${e.response.status}`,
        description: e.response?.data?.message
      })
    }
    setIsSubmitting(false)
  }

  const fetchRequests = async () => {
    setIsLoadingRequests(true)
    const { current, pageSize } = pagination
    try {
      const { result, total } = await restService
        .route(`withdrawal_requests?page=${current}&pageSize=${pageSize}`)
        .get<{ result: IPortalApiWithdrawalRequest[]; total: number }>()
      const wrWithHistory = await Promise.all([
        ...result.map(async (wr) => {
          if (wr.status === 'PENDING') {
            const { result: wrHistory } = await restService
              .route(
                `withdrawal_requests/${
                  wr.withdrawalRequestUuid ?? wr.withdrawalRequestId
                }/history`
              )
              .get()

            return {
              ...wr,
              history: wrHistory
            }
          }

          return wr
        })
      ])

      setRequests(wrWithHistory)
      setPagination({
        ...pagination,
        total
      })
    } catch (e) {
      notification.error({
        message: `There was an error while loading withdrawal requests. Status code: ${e.response.status}`,
        description: e.response?.data?.message
      })
    }
    setIsLoadingRequests(false)
  }

  const fetchAccounts = async () => {
    try {
      const { message } = await restService
        .route(`withdrawal_accounts`)
        .get<{ message: IPortalApiWithdrawalAccount[] }>()
      setAccounts(message)
    } catch (e) {
      notification.error({
        message: 'There was an error while loading withdrawal accounts',
        description: e.response?.data?.message
      })
    }
  }

  /* Request details modal stuff */
  const openRequestDetailsModal = (id: number) =>
    setRequestDetailsModal({
      visible: true,
      request: _.find(requests as any[], (r) => r.id === id) || null
    })

  const closeRequestDetailsModal = () =>
    setRequestDetailsModal({ visible: false, request: null })

  useEffect(() => {
    fetchRequests()
  }, [pagination.pageSize, pagination.current])

  useEffect(() => {
    fetchRequests()
    fetchAccounts()
    authUser.isAdmin(participantCode).then((isAdmin) => {
      setIsAdmin(isAdmin)
    })
  }, [])

  const { request: requestDetailsModalRequest } = requestDetailsModal

  return (
    <>
      <Title level={2}>Withdrawals</Title>
      <div style={{ marginBottom: 24 }}>
        All withdrawals must be made to whitelisted accounts. Please refer to
        our FAQ for more information:{' '}
        <a
          href="https://zerohash.zendesk.com/hc/en-us/articles/360008817314-How-are-withdrawal-accounts-set-up-"
          target="_blank"
        >
          How are withdrawal accounts set up?
        </a>{' '}
      </div>

      <AssetsAndCurrenciesTab
        includeAddCurrency={false}
        onChange={(key: string) =>
          history.push(`/${restService.participantCode}/withdrawals/${key}`)
        }
        assetsAndCurrenciesList={assets}
      />

      <Title style={{ marginTop: 24 }} level={3}>
        Withdrawal Requests
      </Title>

      <WithdrawalRequestsTable
        userEmail={authUser.email}
        requests={requests}
        accounts={accounts}
        onApprove={approve}
        onReject={reject}
        isSubmitting={isSubmitting}
        onDetails={(id) => openRequestDetailsModal(id)}
        approves={approves.request}
        isAdmin={isAdmin}
        loading={isLoadingRequests}
        pagination={pagination}
        setPagination={setPagination}
      />

      {requestDetailsModalRequest && (
        <RequestDetailsModal
          participantCode={participantCode}
          request={requestDetailsModalRequest}
          account={accounts.find((a) => {
            return (
              a.withdrawalAccountId ===
              requestDetailsModalRequest.withdrawalAccountId
            )
          })}
          onClose={closeRequestDetailsModal}
        />
      )}
    </>
  )
}

export default NewAllWithdrawals
