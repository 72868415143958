import * as React from 'react'
import { IWithParticipantComponentProps } from '../../../components/participant/WithParticipant'
import { ILoan } from '../../../loans/types'
import { ITrade } from 'seed-shared-components/lib/components/TradeBlotter/types'
import { Spin } from 'antd'
import TradeDetails from '../../../trades/TradeDetailsContainer'
import LoanDetails from '../../../loans/LoanDetails'

interface IMovementDetailProps
  extends IWithParticipantComponentProps<{
    id: string
    participantCode?: string
  }> {}

export default function MovementDetails(props: IMovementDetailProps) {
  const [loans, setLoans] = React.useState<ILoan[] | null>(null)
  const [trades, setTrades] = React.useState<ITrade[] | null>(null)
  const [loading, setLoading] = React.useState<boolean>(true)
  React.useEffect(() => {
    async function fetchLoansAndTrades() {
      await Promise.all([fetchLoans(), fetchTrades()])
      setLoading(false)
    }
    async function fetchLoans() {
      const { id } = props.match.params
      try {
        const res = await props.restService
          .route(`loans/${id}/history`)
          .get<{ message: ILoan[] }>()
        setLoans(res.message)
      } catch (e) {
        console.log(e)
      }
    }
    async function fetchTrades() {
      const { id } = props.match.params
      try {
        const res = await props.restService
          .route(`trades/${id}`)
          .get<ITrade[]>()
        setTrades(res)
      } catch (e) {
        console.log(e)
      }
    }
    if (loading) {
      fetchLoansAndTrades()
    }
  }, [loading])

  let content: React.ReactElement | undefined = undefined
  if (!loading) {
    if (trades && trades.length > 0) {
      content = (
        <TradeDetails
          participantCode={props.participant.code}
          match={props.match}
          restService={props.restService}
          {...props}
        />
      )
    } else if (loans && loans.length > 0) {
      content = (
        <LoanDetails
          participant={props.participant}
          match={props.match}
          restService={props.restService}
          loans={loans}
          {...props}
        />
      )
    }
  }

  return loading ? <Spin spinning={true} /> : content || null
}
