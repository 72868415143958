import * as React from 'react'
import Title from 'seed-shared-components/lib/components/Title'

const DepositInformation = () => {
  return (
    <>
      <Title level={2}>Deposits</Title>
      <div style={{ marginBottom: 10 }}>
        Zero Hash supports an ever-growing list of fiat and digital currencies.
        You can see the full list here:{' '}
        <a
          href="https://zerohash.zendesk.com/hc/en-us/articles/360018576254-What-assets-do-you-support-"
          target="_blank"
        >
          What assets do you support?
        </a>
      </div>

      <div style={{ marginBottom: 10 }}>
        Digital asset deposits into Zero Hash are processed 24 hours per day,
        365 days per year. Processing times are dependent on the number of
        confirms we require. Please refer to our FAQ for more information:{' '}
        <a
          href="https://zerohash.zendesk.com/hc/en-us/articles/360008975513-How-are-digital-asset-deposits-handled-"
          target="_blank"
        >
          How are digital asset deposits handled?
        </a>
      </div>

      <div style={{ marginBottom: 24 }}>
        Fiat deposits will be credited as soon as possible, with processing
        times dependent on your source bank and fiat deposit cutoff times. Note
        that the first deposit made from a new external fiat account will
        require manual review from the Zero Hash settlements team. Subsequent
        deposits from that same external account will be automatically credited
        to your Zero Hash account. Please refer to our FAQ for more info on
        deposit processing cutoff times:{' '}
        <a
          href="https://zerohash.zendesk.com/hc/en-us/articles/360008975633-How-are-fiat-asset-deposits-handled-"
          target="_blank"
        >
          How are fiat asset deposits handled?
        </a>
      </div>
    </>
  )
}

export default DepositInformation
