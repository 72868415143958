import * as React from 'react'

import { IParagraphItemConfig } from '../../configuration'
import Label from './Label'

export interface IParagraphFormItemProps extends IParagraphItemConfig {}

const ParagraphFormItem = (props: IParagraphFormItemProps) => (
  <>
    {props.label ? (
      <div style={{ marginBottom: 10 }}>
        <Label
          label={<b>{props.label}</b>}
          data-testid="paragraph-form-item-label"
        />
      </div>
    ) : undefined}
    <p>{props.text}</p>
  </>
)

export default ParagraphFormItem
