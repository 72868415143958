import { connect } from 'react-redux'

import { IDefaultComponentProps } from '../../App'
import { IApplicationState } from '../../store/state'
import * as FundsActions from '../../store/funds/actions'
import { IFund } from 'seed-shared-components/lib/static-data/assetConfig'
import { IFundsServiceOutputProps } from './props'

export default connect<
  null,
  IFundsServiceOutputProps,
  IDefaultComponentProps,
  IApplicationState
>(null, (dispatch, props) => ({
  loadFunds: () => {
    dispatch(FundsActions.loadFunds())
    return props.restService.route('funds?account_label=general').get<IFund[]>()
  },
  onFundsLoadSuccess: (funds, assets) =>
    dispatch(FundsActions.loadFundsSuccess({ funds, assets })),
  onFundsLoadFailure: () => dispatch(FundsActions.loadFundsFailure())
}))
