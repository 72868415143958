export type sendFunction = (data?: { [key: string]: any }) => Promise<any>

export interface ILoggerProps {
  baseData?: { [key: string]: any }
  send: sendFunction
}

export default class Logger {
  private readonly baseData: { url: string; [key: string]: any }

  private readonly send: sendFunction = (): Promise<any> => {
    throw new Error(
      `Use 'send' constructor param to define log function executor.`
    )
  }

  constructor({ baseData, send }: ILoggerProps) {
    this.baseData = {
      ...(baseData || {}),
      url: window?.location?.href || ''
    }

    this.send = send
  }

  async trace(data: object | Error, msg?: string) {
    console.trace(msg)

    try {
      await this.send({ ...this.baseData, level: 20, msg, data })
    } catch (e) {
      // our server-side alerting should let us know if logs are not posting
    }
  }

  async debug(data: object | Error, msg?: string) {
    console.debug(msg)

    try {
      await this.send({ ...this.baseData, level: 20, msg, data })
    } catch (e) {
      // our server-side alerting should let us know if logs are not posting
    }
  }

  async info(data: object | Error, msg?: string) {
    console.info(msg)

    try {
      await this.send({ ...this.baseData, level: 30, msg, data })
    } catch (e) {
      // our server-side alerting should let us know if logs are not posting
    }
  }

  async warn(data: object | Error, msg?: string) {
    console.warn(msg)

    try {
      await this.send({ ...this.baseData, level: 40, msg, data })
    } catch (e) {
      // our server-side alerting should let us know if logs are not posting
    }
  }

  async error(data: object | Error, msg?: string) {
    console.error(msg)

    try {
      const stacktrace = Error().stack
      await this.send({
        ...this.baseData,
        level: 50,
        msg,
        data,
        stacktrace: stacktrace && stacktrace.split('\n')
      })
    } catch (e) {
      // our server-side alerting should let us know if logs are not posting - we dont care
    }
  }
}
