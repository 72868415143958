import React from 'react'
import Decimal from 'decimal.js'
import NumberFormat from 'react-number-format'

export const newNumberFormatter = (
  value: number | string | undefined,
  minDecimalPlaces?: number,
  maxDecimalPlaces?: number,
  trimZeros?: boolean
) => {
  const floatValue = parseFloat(value.toString()) || 0
  if (trimZeros) {
    // trim the trailing zeros, skip decimal building based on precision
    return (
      <NumberFormat
        value={floatValue.valueOf()}
        thousandSeparator={true}
        displayType="text"
        fixedDecimalScale={true}
      />
    )
  }

  const valueDecimal = new Decimal(floatValue)
  const decimalPlaces = valueDecimal.decimalPlaces()

  /* Checks if the user provided a maxDecimalPlaces and uses it as the max number of decimal places
   * if the value has more than maxDecimalPlaces. ie: maxDecimalPlaces = 3, value = 1.1234
   * the result should be displayed as 1.123
   */
  const decimalScale =
    maxDecimalPlaces && decimalPlaces > maxDecimalPlaces
      ? maxDecimalPlaces
      : minDecimalPlaces || decimalPlaces
  return (
    <NumberFormat
      value={valueDecimal.valueOf()}
      thousandSeparator={true}
      displayType="text"
      decimalScale={decimalScale}
      fixedDecimalScale={true}
    />
  )
}
