import * as uuid from 'uuid'
import Logger from './utils/logger'
import portalApi from './api/portalApi'

const sessionId = uuid()

export default class RestService {
  apiUrl: string = window._env_.REACT_APP_PORTAL_API_URI as string
  options: {
    body?: object
    headers: {
      Authorization: string
    }
    json: boolean
    method?: 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE'
    url: string
  }
  participantCode?: string
  idToken: string
  logger: Logger

  constructor(authToken: string, participantCode?: string, route?: string) {
    this.idToken = authToken
    this.participantCode = participantCode
    this.options = {
      headers: {
        Authorization: `Bearer ${authToken}`
      },
      json: true,
      url: participantCode
        ? `${this.apiUrl}/${participantCode}/`
        : `${this.apiUrl}/`
    }

    if (route) {
      this.options.url = this.options.url + route
    }

    this.logger = new Logger({
      baseData: { participantCode, sessionId },
      send: (data) => this.route('logs').put(data)
    })
  }

  getUrlBase(): string {
    return this.options.url
  }

  route(route: string, noParticipant = false): RestService {
    return new RestService(
      this.idToken,
      noParticipant ? undefined : this.participantCode,
      route
    )
  }

  get<R = any>({
    omitNotification = false,
    customErrorMessage = undefined
  } = {}) {
    return portalApi
      .request<R>({
        ...this.options,
        method: 'GET',
        omitNotification,
        customErrorMessage
      })
      .then(({ data }) => data)
  }

  delete<R = any>(
    { omitNotification = false, customErrorMessage = undefined } = {},
    data = undefined
  ) {
    return portalApi.request<R>({
      ...this.options,
      method: 'DELETE',
      data,
      omitNotification,
      customErrorMessage
    })
  }

  patch<R = any>(
    data: { [key: string]: any },
    { omitNotification = false, customErrorMessage = undefined } = {}
  ) {
    return portalApi.request<R>({
      ...this.options,
      data,
      method: 'PATCH',
      omitNotification,
      customErrorMessage
    })
  }

  post<R = any>(
    data: { [key: string]: any },
    { omitNotification = false, customErrorMessage = undefined } = {}
  ) {
    return portalApi.request<R>({
      ...this.options,
      data,
      method: 'POST',
      omitNotification,
      customErrorMessage
    })
  }

  put<R = any>(
    data: { [key: string]: any },
    { omitNotification = false, customErrorMessage = undefined } = {}
  ) {
    return portalApi.request<R>({
      ...this.options,
      data,
      method: 'PUT',
      omitNotification,
      customErrorMessage
    })
  }

  sendDocument<R = any>(
    data: { [key: string]: any },
    { omitNotification = false, customErrorMessage = undefined } = {}
  ) {
    const formData = new FormData()

    formData.append('file', data.file)
    formData.append('document_type', data.documentType)
    formData.append('participant_code', data.participantCode)

    return portalApi.post<R>(this.options.url, formData, {
      headers: {
        ...this.options.headers,
        'Content-Type': 'multipart/form-data'
      },
      omitNotification,
      customErrorMessage
    })
  }
}
