import * as React from 'react'

import { Table, Spin, Pagination } from 'antd'

import { Link } from 'react-router-dom'

import { numberFormatter } from 'seed-shared-components/lib/utils/CurrencyFormatter'
import { DEFAULT_PAGE_SIZE } from '../../../constants'
import dateFormatter from '../../../utils/dateFormatter'

import DataDownloadButton from 'seed-shared-components/lib/components/DownloadDataButton'
import { AccountHistoryPageProps } from './props'
import connect from './connector'
import Title from 'seed-shared-components/lib/components/Title'
import {
  ISettlementRun,
  IAccountHistory
} from '../../../store/accountHistory/state'
export type AccountType =
  | 'available'
  | 'collateral'
  | 'collateral_deficiency'
  | 'payable'
  | 'receivable'

export const accountTypeRender = (
  accountType: AccountType | null
): string | null => {
  switch (accountType) {
    case 'available':
    case 'collateral':
    case 'payable':
    case 'receivable':
      return accountType.charAt(0).toUpperCase() + accountType.substr(1)
    case 'collateral_deficiency':
      return 'Collateral Payable'
    default:
      return accountType
  }
}

const runTypeRender = (type: string, run: ISettlementRun): string => {
  switch (type) {
    case 'deposit':
      return 'Deposit'
    case 'execution_fee':
      return 'Execution fee incurred'
    case 'network_fee':
      return 'Network fee incurred'
    case 'settlement':
      return 'Processed settlement run'
    case 'transfer':
      return run.isAdminRun
        ? 'Administrative account transfer'
        : 'Allocation account transfer'
    case 'withdrawal':
      return 'Withdrawal'
    default:
      return type
  }
}

const isOutstandingAccount = (accountHistory: IAccountHistory | null) => {
  return (
    accountHistory &&
    ['payable', 'receivable', 'collateral_deficiency'].includes(
      accountHistory.accountType
    )
  )
}

type State = {
  runs: { loading: boolean; value: ISettlementRun[]; total: number }
  pagination: { current: number; pageSize: number }
}

/**
 * Please note that this component fetches runs by itself
 * from paginated endpoint, while accountHistory stays in
 * the store for accountType and other components as well
 *
 * Changing accountHistory's settlementRuns
 * shouldn't affect this component's render
 */
class AccountHistoryPage extends React.PureComponent<
  AccountHistoryPageProps,
  State
> {
  state = {
    runs: { loading: false, value: [], total: 0 },
    pagination: { current: 0, pageSize: DEFAULT_PAGE_SIZE }
  }

  setPage = (page: number) => {
    this.setState(
      (state) => ({ pagination: { ...state.pagination, current: page - 1 } }),
      this.fetchRuns
    )
  }

  fetchRuns = async () => {
    const { pagination } = this.state
    const { accountId } = this.props.match.params

    if (!accountId) {
      return
    }

    this.setState((state) => ({ runs: { ...state.runs, loading: true } }))
    return this.props.restService
      .route(
        `accounts/${accountId}/runs?page=${pagination.current}&pageSize=${pagination.pageSize}`,
        true
      )
      .get()
      .then(({ body, count }) =>
        this.setState({ runs: { loading: false, value: body, total: count } })
      )
      .catch(() => {
        this.setState({ runs: { loading: false, value: [], total: 0 } })
      })
  }

  componentDidMount() {
    this.fetchRuns()
  }

  componentDidUpdate(prevProps: AccountHistoryPageProps) {
    if (this.props.funds !== prevProps.funds) {
      this.setAccount()
    }
  }

  setAccount() {
    const { funds, accountHistory } = this.props

    if (funds && accountHistory) {
      const { accountId } = this.props.match.params

      for (const fund of funds) {
        if (fund[`${accountHistory.accountType}_id`] === accountId) {
          this.props.onLoad({
            type: accountHistory.accountType,
            id: accountId,
            participantName: fund.name || null,
            account_group: fund.account_group || null
          })
          break
        }
      }
    }
  }

  render() {
    const { accountHistory } = this.props
    const accountId = this.props.match.params.accountId

    const columns = [
      {
        dataIndex: 'settleTimestamp',
        render: (item: string) => dateFormatter(item),
        title: 'Settlement Time'
      },
      {
        dataIndex: 'runType',
        render: (type: string, item: ISettlementRun) =>
          isOutstandingAccount(accountHistory)
            ? runTypeRender('settlement', item)
            : runTypeRender(type, item),
        title: 'Description'
      },
      {
        className: 'column-money',
        render: (item: ISettlementRun) => numberFormatter(item.change),
        title: 'Change',
        width: '20%'
      },
      {
        className: 'column-money',
        render: (item: ISettlementRun) => {
          if (
            this.props.accountHistory &&
            this.props.accountHistory.accountType !== 'payable' &&
            this.props.accountHistory.accountType !== 'receivable'
          ) {
            return (
              <Link to={`${accountId}/movements/${item.version}`}>
                {numberFormatter(item.amount)}
              </Link>
            )
          }
          return numberFormatter(item.amount)
        },
        title: 'Balance',
        width: '20%'
      }
    ]

    if (isOutstandingAccount(accountHistory)) {
      // remove the change column
      columns.splice(-2, 1)
    }

    return (
      <>
        <div className="space-between">
          <Title level={2}>Run History</Title>
          <div>
            <DataDownloadButton
              data={accountHistory ? accountHistory.settlementRuns : []}
              description={`${this.props.participantCode} ${this.props.match.params.asset} Account Run History`}
              style={{ zIndex: 1, marginBottom: -16 }}
            />
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={this.state.runs.value}
          loading={this.state.runs.loading}
          pagination={{
            current: this.state.pagination.current + 1,
            total: this.state.runs.total,
            pageSize: this.state.pagination.pageSize,
            onChange: this.setPage
          }}
        />
      </>
    )
  }
}

export default connect(AccountHistoryPage)
