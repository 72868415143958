import { INetDeliveryObligationsState } from './state'
import { createReducer } from '@reduxjs/toolkit'
import { ISetNetDeliveryObligationsAction } from './actions/types'
import * as actions from './actions'

const initialState: INetDeliveryObligationsState = {
  netDeliveryObligations: { value: [], loading: true }
}

export default createReducer(initialState, {
  [actions.setNetDeliveryObligations.type]: (
    state,
    action: ISetNetDeliveryObligationsAction
  ) => ({
    ...state,
    netDeliveryObligations: {
      ...state.netDeliveryObligations,
      ...action.payload
    }
  })
})
