import * as React from 'react'

import { Divider, Button, Checkbox } from 'antd'
import { Form } from '@ant-design/compatible'

import ReviewOnboardingApplication from '../review-onboarding-application'
import { SignPageProps } from './props'
import connect from './connector'
import FormItem from '@ant-design/compatible/es/form/FormItem'
import Title from 'seed-shared-components/lib/components/Title'
import { CheckOutlined } from '@ant-design/icons'

class SignPage extends React.Component<SignPageProps> {
  constructor(props: SignPageProps) {
    super(props)
    this.sign = this.sign.bind(this)
  }

  async componentDidMount() {
    const isAuthSigner = await this.props.authUser.isAuthSigner(
      this.props.participant.code
    )
    this.props.onLoadAuthSigner(isAuthSigner)
    /* Trigger validation exactly after page is loaded. */
    this.onChange()
  }

  sign(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    this.props.signAgreementsAndApplication()
  }

  onChange() {
    const errors: any = this.props.form.getFieldsError()
    this.props.onChange(!errors.agree)
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { agreement, application, valid } = this.props
    const disabledButton =
      agreement.loading ||
      (agreement.status === 'signed' && application.status === 'signed') ||
      !valid ||
      application.loading

    return (
      <div>
        <Title level={2} style={{ marginLeft: 16 }}>
          Authorized Signer Review
        </Title>
        <div style={{ margin: 24 }}>
          <Title level={3}>Onboarding Application</Title>
          <ReviewOnboardingApplication {...(this.props as any)} />
          <Divider />
          <Form onSubmit={this.sign} onChange={this.onChange.bind(this)}>
            {agreement.status === 'unsigned' &&
            application.status &&
            this.props.authSigner ? (
              <>
                <FormItem>
                  {getFieldDecorator(`agree`, {
                    valuePropName: 'checked',
                    rules: [
                      {
                        required: true,
                        validator: (rule: any, value: any, callback: any) =>
                          value
                            ? callback()
                            : callback('You must affirm to continue')
                      }
                    ]
                  })(
                    <Checkbox>
                      <span style={{ fontWeight: 700 }}>
                        I have reviewed and agree to the agreements above
                      </span>
                    </Checkbox>
                  )}
                </FormItem>{' '}
              </>
            ) : null}
            <Button
              style={{ margin: 24, marginRight: 96, float: 'right' }}
              type="primary"
              htmlType="submit"
              disabled={disabledButton}
            >
              {agreement.status === 'unsigned' ||
              application.status === 'unsigned'
                ? 'Sign'
                : null}
              {agreement.status === 'signed' &&
              application.status === 'signed' ? (
                <>
                  Signed
                  {` `}
                  <CheckOutlined />
                </>
              ) : null}
            </Button>
          </Form>
        </div>
      </div>
    )
  }
}
const SignPageWithForm = Form.create<SignPageProps>()(SignPage)
export default connect(SignPageWithForm)
