import { createReducer } from '@reduxjs/toolkit'
import { IAccountMovementsState } from './state'
import * as PlatformPositionsActions from './actions'
import {
  IFetchMovementsFulfillActionSuccess,
  IFetchMovementsFulfillActionFail
} from './actions/types'

const initialState: IAccountMovementsState = {
  accountType: null,
  movements: {
    value: {
      result: [],
      total: -Infinity,
      page: 0,
      pageSize: 100
    },
    loading: true
  }
}

export default createReducer(initialState, {
  [PlatformPositionsActions.fetchMovements.type]: (state) => {
    return {
      ...state,
      movements: {
        ...state.movements,
        loading: true
      }
    }
  },
  [PlatformPositionsActions.fetchMovementsFulfill.type]: (
    state,
    action:
      | IFetchMovementsFulfillActionSuccess
      | IFetchMovementsFulfillActionFail
  ) => {
    const nextState = {
      ...state,
      movements: {
        ...state.movements,
        loading: false
      }
    }

    if (action.error) {
      return nextState
    } else {
      const {
        accountType,
        movements
      } = (action as IFetchMovementsFulfillActionSuccess).payload

      return {
        ...nextState,
        accountType,
        movements: {
          value: movements,
          loading: false
        }
      }
    }
  }
})
