export type OptionType = {
  label: string
  value: any
  disabled?: boolean
}

export const STATE_FIELDS = [
  'org_address_state',
  'applicant_address_state',
  'address_state'
]

export const validateState = (value: string, subdivisions: OptionType[]) => {
  // if there are no subdivisions, then we don't need to validate
  if (!subdivisions || subdivisions.length === 0) {
    return Promise.resolve()
  }
  if (!value) {
    return Promise.reject(new Error('Please select a state'))
  }
  if (!subdivisions.find((c) => c.value === value)) {
    return Promise.reject(new Error('Please select a state from the list'))
  } else {
    return Promise.resolve()
  }
}
