import * as React from 'react'
import * as Redux from 'react-redux'
import * as participantSelectors from '../../store/participant/selectors'
import * as reportsActions from '../../store/reports/actions'
import * as reportsApi from '../../store/reports/api'
import * as reportsSelectors from '../../store/reports/selectors'

import * as appSelectors from '../../store/selectors'
import { usePullPendingReports } from './usePullPendingReports'

export function useConnect() {
  const dispatch = Redux.useDispatch()
  const { token } = Redux.useSelector(appSelectors.getUser)
  const { code: participant } = Redux.useSelector(
    participantSelectors.getParticipant
  )
  const reports = Redux.useSelector(reportsSelectors.getReports)
  const fetchReports = React.useCallback(() => {
    return dispatch(
      reportsActions.fetchReports({ participantCode: participant })
    )
  }, [participant])
  const generateReportSignedLink = React.useCallback(
    (report) => {
      return reportsApi.generateReportSignedLink({
        token,
        participantCode: participant,
        key: report.doc.location
      })
    },
    [participant]
  )

  usePullPendingReports({
    reports: reports.value,
    participantCode: participant
  })

  return {
    participant,
    reports,
    fetchReports,
    generateReportSignedLink
  }
}
