import { put, takeEvery, call, select } from 'typed-redux-saga'
import { notification } from 'antd'
import * as appSelectors from '../selectors'
import { IFetchPlatformPositionsAction } from './actions/types'
import * as actions from './actions'
import * as api from './api'

export function* fetchPlatformPositionsSaga(
  action: IFetchPlatformPositionsAction
) {
  const {
    payload: { platformCode }
  } = action
  const { token } = yield select(appSelectors.getUser)

  try {
    const positions = yield call(api.fetchPlatformPositions, {
      token,
      platformCode
    })

    yield put(
      actions.fetchPlatformPositionsFulfill({
        error: false,
        payload: positions
      })
    )
  } catch (error) {
    notification.error({
      duration: 5,
      message: 'Failed fetching positions data',
      description: 'Please try again or contact support.'
    })

    yield put(
      actions.fetchPlatformPositionsFulfill({
        error: true,
        payload: error
      })
    )
  }
}

export function* watchPlatformPositionsSaga() {
  yield takeEvery(
    actions.fetchPlatformPositions.type,
    fetchPlatformPositionsSaga
  )
}
