import * as React from 'react'
import * as Redux from 'react-redux'
import * as positionsActions from '../../../../store/participant-positions/actions'

import * as participantSelectors from '../../../../store/participant/selectors'
import * as positionsSelectors from '../../../../store/participant-positions/selectors'
import { usePullParticipantPositions } from './usePullParticipantPositions'

export function useConnect() {
  const dispatch = Redux.useDispatch()
  const { code: participantCode } = Redux.useSelector(
    participantSelectors.getParticipant
  )
  const {
    value: positions,
    loading: isPositionsBeingLoaded
  } = Redux.useSelector(positionsSelectors.getStatsSummary)

  React.useEffect(() => {
    dispatch(
      positionsActions.fetchParticipantPositions({
        participantCode,
        page: 1
      })
    )
  }, [participantCode])

  usePullParticipantPositions({
    participantCode,
    page: 1
  })

  return {
    positions,
    isSomeDataLoaded: positions.length > 0,
    isPositionsBeingLoaded
  }
}
