import { createSelector } from '@reduxjs/toolkit'
import { IApplicationState } from '../state'
import { IReportsState } from './state'

export const getRoot = (state: IApplicationState) => state.reports

export const getReports = createSelector(
  getRoot,
  ({ reports }): IReportsState['reports'] => reports
)

export const getAccountLabels = createSelector(
  getRoot,
  ({ accountLabels }): IReportsState['accountLabels'] => accountLabels
)

export const generateReport = createSelector(
  getRoot,
  ({ generateReport }): IReportsState['generateReport'] => generateReport
)
