import { connect } from 'react-redux'

import { IApplicationState } from '../../../store/state'

import * as ParticipantActions from '../../../store/participant/actions'
import {
  IParticipantMenuInputProps,
  IParticipantMenuOutputProps,
  IParticipantMenuExplicitProps
} from './props'
import selectors from '../../../store/selectors'
import { IParticipant } from 'seed-shared-components/lib/types'

export default connect<
  IParticipantMenuInputProps,
  IParticipantMenuOutputProps,
  IParticipantMenuExplicitProps,
  IApplicationState
>(
  (state) => ({
    participant: selectors.participant.getParticipant(state),
    details: selectors.participant.getDetails(state),
    approved: selectors.participant.isApproved(state),
    isPlatformOperator: selectors.participant.isPlatformOperator(state),
    loading: selectors.participant.isLoading(state)
  }),
  (dispatch) => ({
    onLoadDetails: (details) =>
      dispatch(ParticipantActions.loadParticipantDetails({ details })),
    onLoadParticipant: (participant?: IParticipant) =>
      dispatch(ParticipantActions.loadParticipant({ participant })),
    onLoadIsPlatformOperator: (isPlatformOperator: boolean) =>
      dispatch(
        ParticipantActions.loadIsPlatformOperator({ isPlatformOperator })
      )
  })
)
