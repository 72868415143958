import { createAction } from '@reduxjs/toolkit'
import {
  HISTORY_LOAD,
  HISTORY_LOAD_FAILURE,
  HISTORY_LOAD_SUCCESS,
  HISTORY_SELECT_ACCOUNT,
  HISTORY_UNMOUNT,
  ILoadHistorySuccessPayload,
  ISelectHistoryAccountPayload
} from './types'

export const loadHistory = createAction(HISTORY_LOAD)
export const loadHistoryFailure = createAction(HISTORY_LOAD_FAILURE)
export const loadHistorySuccess = createAction<ILoadHistorySuccessPayload>(
  HISTORY_LOAD_SUCCESS
)
export const selectAccount = createAction<ISelectHistoryAccountPayload>(
  HISTORY_SELECT_ACCOUNT
)
export const unmountHistory = createAction(HISTORY_UNMOUNT)
