import uuid from 'uuid'
import _ from 'lodash'

export interface ICountry {
  countryCode: string
  countryName: string
}

export const fetchCountries = async (restService) => {
  try {
    const { countriesList } = await restService
      .route(`countries?requestId=${uuid()}&page_size=1000`)
      .get()

    return _.sortBy(countriesList, ['countryName'])
  } catch (err) {
    console.error('Error fetching countries list', err)
    return []
  }
}
