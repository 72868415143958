import _ from 'lodash'
import { put, takeEvery, call, select } from 'typed-redux-saga'
import { notification } from 'antd'

import { fetchMultipleRates } from '../../api/marketData'
import {
  IFetchNetDeliveryObligationsAction,
  NetDeliveryObligation
} from './actions/types'

import * as actions from './actions'

import * as appSelectors from '../selectors'
import { requestSaga } from '../platform-balances/helpers'

function* fetchParticipantBasic(code: string) {
  return yield call(requestSaga, {
    method: 'GET',
    url: `/${code}/basic`,
    omitNotification: true
  })
}

function* fetchNetDeliveryObligationsSaga(
  action: IFetchNetDeliveryObligationsAction
) {
  const { token } = yield select(appSelectors.getUser)
  yield put(actions.setNetDeliveryObligations({ loading: true }))

  try {
    const { participantCode } = action.payload

    const value: NetDeliveryObligation[] = yield call(requestSaga, {
      method: 'GET',
      url: `/${participantCode}/net_delivery_obligations`
    })

    /* Fetch USD adjusted values */

    const assets = _(value)
      .map(({ asset }) => asset)
      .uniq()
      .filter((asset) => asset !== 'USD')
      .value()

    const indexesHash = yield fetchMultipleRates({ assets, token })

    const ndos = value
      .map((item) => {
        return {
          ...item,
          value: !!indexesHash[item.asset]?.value
            ? indexesHash[item.asset].value * Number(item.amount)
            : null
        }
      })
      .sort((a, b) => (a.value > b.value ? -1 : 1))

    yield put(
      actions.setNetDeliveryObligations({ value: ndos, loading: false })
    )
  } catch (e) {
    notification.error({
      duration: 5,
      message: 'Failed fetching Net Delivery Obligations',
      description: 'Please try again, contact support.'
    })

    yield put(actions.setNetDeliveryObligations({ loading: false }))
  }
}

function* watchFetchNetDeliveryObligations() {
  yield takeEvery(
    actions.fetchNetDeliveryObligations.type,
    fetchNetDeliveryObligationsSaga
  )
}

export { watchFetchNetDeliveryObligations }
