import React, { useCallback, useEffect, useState } from 'react'
import { IParticipantUser } from 'seed-shared-components/lib/components/ParticipantUsers/types'

import { IWithParticipantComponentProps } from '../components/participant/WithParticipant'
import { PendingParticipantUser } from './types'
import { message, Spin, Typography } from 'antd'
import ParticipantUsersTable from './ParticipantUsersTable'
import PendingParticipantUsersTable from './PendingParticipantUsersTable'
import AddParticipantUser from './AddParticipantUser'
import { deleteParticipantUser } from '../api/usersApi'
import { IUser } from 'seed-shared-components/lib/types'

const UsersPage = ({
  restService,
  participant,
  authUser
}: IWithParticipantComponentProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [pendingUsers, setPendingUsers] = useState<PendingParticipantUser[]>([])
  const [users, setUsers] = useState<IParticipantUser[]>([])

  const loadUsers = useCallback(async () => {
    setIsLoading(true)
    const resp = await restService.route('users').get<{ users: IUser[] }>()

    const mappedUsers = resp.users.map(({ id, name, email, roles }) => ({
      id,
      name,
      email,
      userCode: id,
      is_admin: roles.isAdmin,
      is_authorized_signer: roles.isAuthSigner,
      is_trade_submitter: roles.isTradeSubmitter
    }))

    setUsers(mappedUsers)
    setIsLoading(false)
  }, [restService])

  const loadPendingUsers = useCallback(async () => {
    setIsLoading(true)

    const pendingUsersResponse = await restService
      .route(`pending_users`)
      .get<PendingParticipantUser[]>()

    setPendingUsers(pendingUsersResponse)
    setIsLoading(false)
  }, [restService])

  useEffect(() => {
    loadUsers()
    loadPendingUsers()
  }, [loadUsers, loadPendingUsers])

  const addUser = async (name: string, email: string) => {
    const participantUsersEmails = users.map((m) => m.email)
    if (participantUsersEmails.includes(email)) {
      message.info(
        <>
          <i>{email}</i> has already been added.
        </>,
        5
      )
      throw 'participant_user exists'
    }
    await restService.route('users').post(
      {
        participant,
        email,
        name
      },
      { customErrorMessage: `Could not create user "${email}"` }
    )
    setIsLoading(false)
    loadPendingUsers()
  }

  const updateUser = async (
    user: Partial<IParticipantUser>,
    userId: number
  ) => {
    const body = {
      isAdmin: user.is_admin,
      isAuthSigner: user.is_authorized_signer,
      isTradeSubmitter: user.is_trade_submitter
    }
    await restService.route(`users/${userId}`).put(body)
    await loadUsers()
  }

  const deleteUser = async (user: IParticipantUser & { userCode: string }) => {
    deleteParticipantUser({
      token: authUser.idToken,
      userCode: user.userCode,
      participantCode: participant.code
    }).then(loadUsers)
  }

  return (
    <div className="container">
      <div className="row">
        <Spin spinning={isLoading}>
          <div style={{ marginRight: 14 }}>
            <div className="space-between">
              <Typography.Title level={2}>Users</Typography.Title>
            </div>
            <ParticipantUsersTable
              participantUsers={users}
              updateUser={updateUser}
              participantCode={participant.code}
              authUser={authUser}
              adminMode={participant.isAdmin}
              removeHandler={deleteUser}
            />
            <div className="space-between"></div>
            <div className="space-between">
              <Typography.Title level={2}>Pending Users</Typography.Title>
              {participant.isAdmin ? (
                <AddParticipantUser addUser={addUser} />
              ) : null}
            </div>
            <PendingParticipantUsersTable
              participantCode={participant.code}
              authUser={authUser}
              pendingUsers={pendingUsers}
              restService={restService}
              loadUsers={loadUsers}
              loadPendingUsers={loadPendingUsers}
            />
          </div>
        </Spin>
      </div>
    </div>
  )
}

export default UsersPage
