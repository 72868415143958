import * as React from 'react'
import { Row, Col } from 'antd'
import * as auth from '../auth/auth'

export default () => {
  const urlParams = new URLSearchParams(window.location.search)
  const success = urlParams.get('success') !== 'false'
  const email = String(urlParams.get('email'))
  if (success) {
    return renderEmailVerified()
  }

  return <EmailNotVerified email={email} />
}

const renderEmailVerified = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      padding: '20px',
      textAlign: 'center'
    }}
  >
    <a href="/">
      <img style={{ width: '360px' }} src="/portal-logo.png" />
    </a>

    <br />
    <br />

    <h2>
      <b>Verification complete!</b>
    </h2>
    <h2>
      <b>You can log into your newly activated user account below.</b>
    </h2>

    <br />

    <a
      style={{
        backgroundColor: '#00f197',
        border: '1px solid #00f197',
        color: '#FFFFFF',
        padding: '10px 40px',
        textAlign: 'center',
        fontSize: '18 px',
        transition: 'all 0.3s ease-in-out',
        width: 200
      }}
      href="/"
    >
      Login
    </a>
  </div>
)

interface EmailNotVerifiedProps {
  email: string
}

interface EmailNotVerifiedState {
  emailStatus: 'notSent' | 'sending' | 'failed' | 'sent'
}

class EmailNotVerified extends React.Component<
  EmailNotVerifiedProps,
  EmailNotVerifiedState
> {
  state = {
    emailStatus: 'notSent' as any
  }

  constructor(props: EmailNotVerifiedProps) {
    super(props)
    this.sendEmailVerification = this.sendEmailVerification.bind(this)
  }

  async sendEmailVerification(e: any) {
    e.preventDefault()
    if (
      this.state.emailStatus === 'sending' ||
      this.state.emailStatus === 'sent'
    ) {
      return
    }

    this.setState({ emailStatus: 'sending' })
    const sent = await auth.resendEmailVerification(this.props.email)
    if (sent) {
      this.setState({ emailStatus: 'sent' })
    } else {
      this.setState({ emailStatus: 'failed' })
      alert(
        'We had an issue resending the email, please reach out to us directly'
      )
    }
  }

  render() {
    let sendEmailBgColor = '#00f197'
    let resendButtonText = 'Resend Email'
    if (this.state.emailStatus === 'sending') {
      resendButtonText = 'Sending email...'
      sendEmailBgColor = '#ccc'
    } else if (this.state.emailStatus === 'sent') {
      resendButtonText = 'Email sent!'
      sendEmailBgColor = '#c9ca6b'
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          padding: '20px',
          textAlign: 'left'
        }}
      >
        <a href="/">
          <img style={{ width: '360px' }} src="/portal-logo.png" />
        </a>

        <br />
        <br />

        <h2 style={{ maxWidth: '1000px' }}>
          <b>Email verification was unsuccessful as your link expired.</b>
        </h2>

        <h2 style={{ maxWidth: '1000px' }}>
          <b>
            You can resend the verification email by clicking the link below.
          </b>
        </h2>

        <br />

        <p style={{ maxWidth: '1000px' }}>
          Having trouble? Check your spam folder, search your email for "Welcome
          to Seed" or{' '}
          <a
            href={`mailto:support@zerohash.com?subject=Assistance required verifying email address`}
            target="_blank"
          >
            {` `}
            contact support
          </a>{' '}
          for assistance. You can also review our{' '}
          <a
            href={`https://zerohash.zendesk.com/hc/en-us/sections/360003587273`}
            target="_blank"
          >
            {` `}
            FAQ
          </a>{' '}
          for more information.
        </p>

        <br />

        <Row
          style={{
            width: 600,
            textAlign: 'center'
          }}
        >
          <Col span={12}>
            <a
              onClick={this.sendEmailVerification}
              style={{
                backgroundColor: sendEmailBgColor,
                border: '1px solid #00f197',
                color: '#FFFFFF',
                padding: '10px 40px',
                textAlign: 'center',
                fontSize: '18 px',
                transition: 'all 0.3s ease-in-out',
                width: 200
              }}
              href="/"
            >
              {resendButtonText}
            </a>
          </Col>
          <Col span={12}>
            <a
              onClick={auth.logout}
              style={{
                backgroundColor: '#00f197',
                border: '1px solid #00f197',
                color: '#FFFFFF',
                padding: '10px 40px',
                textAlign: 'center',
                fontSize: '18 px',
                transition: 'all 0.3s ease-in-out',
                width: 200
              }}
            >
              Return to Login
            </a>
          </Col>
        </Row>
      </div>
    )
  }
}
