import React from 'react'
import { Form, Input } from 'antd'
import { Rule } from 'antd/lib/form'

import { IAsset } from 'seed-shared-components/lib/static-data/assetConfig'
import TAG_BASED_ASSETS from '../../../utils/tagBasedAssets'

interface CryptoFormItemsProps {
  validateAddressRule: (
    _: Rule,
    value: string,
    asset: string,
    destinationTag: string
  ) => Promise<void>
  asset: IAsset
  destinationTagValue: string
}

const CryptoFormItems = ({
  validateAddressRule,
  asset,
  destinationTagValue
}: CryptoFormItemsProps) => {
  return (
    <>
      <Form.Item
        name="address"
        rules={[
          {
            required: true,
            validator: (_, value) =>
              validateAddressRule(_, value, asset.asset, destinationTagValue)
          }
        ]}
        label="Wallet Address"
      >
        <Input />
      </Form.Item>
      {!!TAG_BASED_ASSETS[asset.asset] && (
        <Form.Item
          name="tag"
          label={
            TAG_BASED_ASSETS[asset.asset] && TAG_BASED_ASSETS[asset.asset].label
          }
        >
          <Input placeholder="0" />
        </Form.Item>
      )}
    </>
  )
}

export default CryptoFormItems
