import * as React from 'react'
import * as _ from 'lodash'
import { Modal, Form, Input, Row, Col, DatePicker, Tooltip, Switch } from 'antd'
import moment, { Moment } from 'moment'
import { ILoan } from '../types'
import NumberInput from 'seed-shared-components/lib/components/NumberInput'
import { QuestionCircleOutlined } from '@ant-design/icons'

type FormData = {
  maturityDate?: moment.Moment
  loanQuantity?: string
  collateralQuantity?: string
  collateralRequirementPercentage?: string
  collateralTopUpPercentage?: string
  collateralReleasePercentage?: string
  openTerm?: boolean
}

type Props = {
  loan: ILoan
  visible: boolean
  loading: boolean
  onClose: () => void
  onCreate: (data: any) => void
}

type State = {
  form: FormData
  validation: { [key: string]: string | null }
}

const LabelWithTooltip = ({
  label,
  tooltip
}: {
  label: string
  tooltip: string | JSX.Element
}) => (
  <Tooltip title={tooltip} placement="right">
    {label}
    &nbsp;
    <QuestionCircleOutlined />
  </Tooltip>
)

function range(start: number, end: number) {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}

export default class LoanEditModal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    const initialMaturityDate = props.loan.maturity_timestamp
      ? moment(props.loan.maturity_timestamp)
      : moment()

    this.state = {
      form: {
        maturityDate: initialMaturityDate,
        openTerm: !props.loan.maturity_timestamp,
        loanQuantity: props.loan.loan_quantity,
        collateralQuantity: props.loan.collateral_quantity,
        collateralRequirementPercentage:
          props.loan.collateral_requirement_percentage,
        collateralTopUpPercentage: props.loan.collateral_top_up_percentage,
        collateralReleasePercentage: props.loan.collateral_release_percentage
      },

      validation: {}
    }
  }

  get = (name: string) => this.state.form[name] || ''
  set = (name: string) => ({ target: { value } }: any) => {
    this.setState(
      (state) => ({ form: { ...state.form, [name]: value } }),
      () => this.clearValidation(name)
    )
  }

  valid = (name: string) => this.state.validation[name]
  clearValidation = (name: string) =>
    this.setState((state) => ({
      validation: { ...state.validation, [name]: null }
    }))

  formItemProps = (
    name: string
  ): { help: string | null; validateStatus: 'error' | 'validating' } => ({
    help: this.valid(name),
    validateStatus: !!this.valid(name) ? 'error' : 'validating'
  })

  hasValidationErrors = () =>
    _(this.state.validation).filter(_.negate(_.isNull)).size() > 0

  validate = async () => {
    this.setState({
      validation: {
        maturityDate: !this.state.form.maturityDate ? 'Required' : null,
        loanQuantity: !this.state.form.loanQuantity ? 'Required' : null,
        collateralQuantity: !this.state.form.collateralQuantity
          ? 'Required'
          : null,
        collateralRequirementPercentage: !this.state.form
          .collateralRequirementPercentage
          ? 'Required'
          : null,
        collateralTopUpPercentage: !this.state.form.collateralTopUpPercentage
          ? 'Required'
          : null,
        collateralReleasePercentage: !this.state.form
          .collateralReleasePercentage
          ? 'Required'
          : null
      }
    })
  }

  submit = async () => {
    await this.validate()

    if (this.hasValidationErrors()) {
      return false
    }

    const { form } = this.state

    this.props.onCreate({
      maturity_timestamp: form.openTerm ? null : form.maturityDate,
      loan_quantity: form.loanQuantity,
      collateral_quantity: form.collateralQuantity,
      collateral_requirement_percentage: form.collateralRequirementPercentage,
      collateral_top_up_percentage: form.collateralTopUpPercentage,
      collateral_release_percentage: form.collateralReleasePercentage
    })

    return false
  }

  render() {
    const { visible, loading, onClose, loan } = this.props
    const hasPortfolio = !!loan.loan_portfolio_id

    const disabledPortfolioWrap = hasPortfolio
      ? (content: any) => (
          <Tooltip title="Cannot change these portfolio-specific values">
            <div>{content}</div>
          </Tooltip>
        )
      : (content: any) => content

    return (
      <Modal
        title={`Edit loan terms`}
        visible={visible}
        confirmLoading={loading}
        onCancel={onClose}
        onOk={this.submit}
        width={700}
      >
        <Form
          layout="horizontal"
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 14 }}
        >
          <Form.Item
            label={
              <LabelWithTooltip
                tooltip="A flag to determine if there is no fixed maturity date. You can contact support at any time to edit the maturity date."
                label="Open Term"
              />
            }
          >
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={!loan.maturity_timestamp}
              onChange={(value) => {
                this.set('openTerm')({ target: { value } })
              }}
            />
          </Form.Item>
          <Row gutter={62} align="middle">
            <Col xs={24}>
              <Form.Item
                label={
                  <LabelWithTooltip
                    tooltip="The time at which the loan expires, loan proceeds are returned to the lender, and collateral is returned to the borrower."
                    label="Maturity Time"
                  />
                }
              >
                <DatePicker
                  value={this.get('openTerm') ? null : this.get('maturityDate')}
                  style={{ width: '100%' }}
                  disabled={!!this.get('openTerm')}
                  showTime
                  showToday={false}
                  placeholder="Select Time"
                  onChange={(value) => {
                    this.set('maturityDate')({ target: { value } })
                  }}
                  disabledDate={(current) => {
                    if (!current) {
                      return false
                    }
                    return current < moment().startOf('day')
                  }}
                  disabledTime={(current) => {
                    let disabledHours: number[] = []
                    let disabledMinutes: number[] = []
                    const now = moment()
                    if (
                      moment((current || undefined) as any).isSame(now, 'day')
                    ) {
                      disabledHours = range(0, now.hour())
                      if (
                        moment((current || undefined) as any).isSame(
                          now,
                          'minute'
                        )
                      ) {
                        disabledMinutes = range(0, now.minute() + 10)
                      }
                    }
                    return {
                      disabledHours: () => disabledHours,
                      disabledMinutes: () => disabledMinutes,
                      disabledSeconds: () => []
                    }
                  }}
                />
                {/* )} */}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {this.get('maturityDate') && !this.get('openTerm') && (
              <Col xs={24}>
                <Row style={{ marginBottom: '24px' }}>
                  <Col
                    xs={9}
                    style={{
                      textAlign: 'right',
                      fontWeight: 'bold',
                      paddingRight: '8px'
                    }}
                  >
                    UTC Time:
                  </Col>
                  <Col xs={14}>
                    {moment(this.get('maturityDate')).toDate().toUTCString()}
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <Form.Item
            required
            label="Loan Quantity"
            {...this.formItemProps('loanQuantity')}
          >
            <NumberInput
              acceptDecimal={true}
              onChange={(value: any) => {
                this.set('loanQuantity')({ target: { value } })
              }}
              value={this.get('loanQuantity')}
            />
          </Form.Item>
          <Form.Item
            required
            label="Collateral Quantity"
            {...this.formItemProps('collateralQuantity')}
          >
            <NumberInput
              acceptDecimal={true}
              onChange={(value: any) => {
                this.set('collateralQuantity')({ target: { value } })
              }}
              value={this.get('collateralQuantity')}
            />
          </Form.Item>
          <Form.Item
            required
            label="Collateral Requirement %"
            {...this.formItemProps('collateralRequirementPercentage')}
          >
            {disabledPortfolioWrap(
              <NumberInput
                disabled={hasPortfolio}
                acceptDecimal={true}
                onChange={(value: any) => {
                  this.set('collateralRequirementPercentage')({
                    target: { value }
                  })
                }}
                value={this.get('collateralRequirementPercentage')}
              />
            )}
          </Form.Item>
          <Form.Item
            required
            label="Collateral Top Up %"
            {...this.formItemProps('collateralTopUpPercentage')}
          >
            {disabledPortfolioWrap(
              <NumberInput
                disabled={hasPortfolio}
                acceptDecimal={true}
                onChange={(value: any) => {
                  this.set('collateralTopUpPercentage')({ target: { value } })
                }}
                value={this.get('collateralTopUpPercentage')}
              />
            )}
          </Form.Item>
          <Form.Item
            required
            label="Collateral Release %"
            {...this.formItemProps('collateralReleasePercentage')}
          >
            {disabledPortfolioWrap(
              <NumberInput
                acceptDecimal={true}
                disabled={hasPortfolio}
                onChange={(value: any) => {
                  this.set('collateralReleasePercentage')({ target: { value } })
                }}
                value={this.get('collateralReleasePercentage')}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}
