import * as React from 'react'
import FormItem from '@ant-design/compatible/es/form/FormItem'
import { Checkbox } from 'antd'
import { FormInputItem } from '.'
import { ICheckboxItemConfig } from '../../configuration'

export interface ICheckboxFormItemProps
  extends FormInputItem<ICheckboxItemConfig> {}

const CheckboxFormItem = (props: ICheckboxFormItemProps) => {
  const label =
    props.label instanceof Function ? props.label(props.answers) : props.label

  return (
    <FormItem {...props.formItemLayout}>
      {props.form.getFieldDecorator(props.itemKey, {
        valuePropName: 'checked',
        initialValue: props.value ? props.value.true || props.value : false,
        rules: props.rules
      })(<Checkbox>{label}</Checkbox>)}
    </FormItem>
  )
}

export default CheckboxFormItem
