import _ from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import { Select, Table } from 'antd'
import { IWithParticipantComponentProps } from '../../components/participant/WithParticipant'
import {
  assets as assetConfigs,
  IAsset,
  IFund
} from 'seed-shared-components/lib/static-data/assetConfig'
import Title from 'seed-shared-components/lib/components/Title'
import DownloadData from '../components/DownloadData'

import * as actions from '../../store/platform-balances/actions'
import {
  getPlatformParticipants,
  getFunds
} from '../../store/platform-balances/selectors'
import { newNumberFormatter } from '../utils'
import * as uuid from 'uuid'
import { useCallback, useState } from 'react'

const BALANCES_CONFIG = [
  { id: 'available_id', value: 'available', title: 'Available' },
  { id: 'receivable_id', value: 'receivable', title: 'Receivable' },
  { id: 'payable_id', value: 'payable', title: 'Payable' },
  { id: 'collateral_id', value: 'collateral', title: 'Collateral' },
  {
    id: 'collateral_deficiency_id',
    value: 'collateral_deficiency',
    title: 'Collateral Payable'
  }
]

const mapStateToProps = (state) => ({
  participants: getPlatformParticipants(state),
  funds: getFunds(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchPlatformParticipants: (participantCode: string, searchString?: string) =>
    dispatch(
      actions.fetchPlatformParticipants({
        participantCode,
        searchString,
        basic: true,
        relationshipsTypes: ['operates_platform_for']
      })
    ),
  fetchFunds: (platformCode: string) =>
    dispatch(actions.fetchFunds({ platformCode }))
})

type PlatformBalancesProps = IWithParticipantComponentProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

function PlatformBalances(props: PlatformBalancesProps) {
  const { participants, funds, restService } = props
  const participantCode = props.participant.code
  const [assets, setAssets] = useState<IAsset[]>([])

  React.useEffect(() => {
    props.fetchPlatformParticipants(participantCode)
    fetchAssets()
  }, [participantCode])

  const fetchAssets = useCallback(async () => {
    try {
      const responseCryptoAssets: IAsset[] = await restService
        .route(`assets?requestId=${uuid.v4()}&asset_type=CRYPTO`)
        .get()
      const fiatCurrencies = assetConfigs.filter(
        (asset) => asset.type === 'Fiat'
      )
      setAssets([...responseCryptoAssets, ...fiatCurrencies])
    } catch (e) {
      console.error('Failed to fetch assets for Platform Balances', e)
    }
  }, [])

  const { code: selectedParticipant = null } = props.match.params as any
  const handleParticipantChange = React.useCallback(
    (value: string) => {
      if (!!value) {
        props.history.push(`/${participantCode}/platform_balances/${value}`)
      } else {
        props.history.push(`/${participantCode}/platform_balances`)
      }
    },
    [props.history, participantCode]
  )
  const handleParticipantSearch = React.useCallback(
    _.debounce((searchString: string) => {
      props.fetchPlatformParticipants(participantCode, searchString)
    }, 400),
    [props.fetchPlatformParticipants, participantCode]
  )

  /**
   * Retrieve seleсted participant's funds
   */
  React.useEffect(() => {
    props.fetchFunds(selectedParticipant)
  }, [selectedParticipant])

  const navigateToAccount = (asset, id) =>
    props.history.push(
      `/${participantCode}/platform_balances/${selectedParticipant}/accounts/${asset}/${id}`
    )

  const TABLE_COLUMNS = [
    {
      render: (item) => item.asset.toUpperCase(),
      title: 'Asset',
      key: 'asset'
    },
    {
      dataIndex: 'account_label',
      title: 'Account Label',
      key: 'account_label'
    },
    ...BALANCES_CONFIG.map(({ id, value, title }) => ({
      align: 'right',
      title,
      width: '15%',
      key: value,
      render: (item: IFund) => {
        return renderBalanceWithAccountLink(
          item[id],
          item[value],
          (id) => navigateToAccount(item.asset, id),
          getPrecision(item.asset)
        )
      }
    })),
    {
      align: 'right',
      title: 'Net Delivery Obligation',
      width: '20%',
      key: 'ndo',
      render: (item: IFund) => {
        const ndo = item.ndo || 0
        const precision = getPrecision(item.asset)

        return (
          <span className={ndo > 0 ? 'ndo-exception' : ''}>
            {newNumberFormatter(ndo, precision || 2, precision || 5)}
          </span>
        )
      }
    }
  ]

  const getPrecision = (asset: string) => {
    return assets.filter((e) => e.asset === asset).pop()?.precision
  }

  const filteredAndSortedFunds = sortWithPriorities(
    funds.value
      .filter((a) => a.account_group === participantCode)
      .filter((a) => selectedParticipant !== '00SCXM' || a.asset === 'USD'),
    (v) => ({ USD: 2, BTC: 1 }[v.asset] || 0),
    (a, b) => (a.asset < b.asset ? -1 : 1)
  )

  return (
    <div>
      <Title level={2}>Platform Balances</Title>
      <div style={{ marginBottom: 10 }}>
        Here is a snapshot of the balances that your customers currently have
        allocated to your platform. Please refer to our FAQ to find information
        on general account management at Zero Hash, to find definitions for
        account types and account groups, and to learn how your customers can
        manage their allocations:{' '}
        <a
          href="https://zerohash.zendesk.com/hc/en-us/sections/360007876873-Account-Management"
          target="_blank"
        >
          Account Management
        </a>{' '}
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Select
          showSearch
          allowClear
          style={{ width: 400 }}
          value={selectedParticipant || undefined}
          onChange={handleParticipantChange}
          onSearch={handleParticipantSearch}
          placeholder="Start typing to find Participant by code or name"
          loading={participants.loading}
          filterOption={() => true}
        >
          {participants.value.map((participant) => {
            const participantCode = participant.registration_code
            const participantName = participant.name

            return (
              <Select.Option key={participant.id} value={participantCode}>
                {participantName} ({participantCode})
              </Select.Option>
            )
          })}
        </Select>

        <DownloadData
          disabled={funds.loading}
          onData={() => filteredAndSortedFunds}
          description={`Platform Balances for ${selectedParticipant}`}
        />
      </div>

      <Table
        style={{ marginTop: 24 }}
        columns={TABLE_COLUMNS as any}
        dataSource={filteredAndSortedFunds}
        pagination={false}
        loading={funds.loading}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformBalances)

/**
 * Table render helpers
 */
export const renderBalanceWithAccountLink = (
  id: string | null,
  value: number,
  onClick?: any,
  precision?: number,
  trimZeros = false
) => {
  const Component = !!id ? 'a' : 'div'

  return (
    <Component onClick={() => onClick && onClick(id)}>
      {newNumberFormatter(value, precision || 2, precision || 5, trimZeros)}
    </Component>
  )
}

export const sortWithPriorities = (
  collection,
  prioritiesPredicate,
  fallback
) => {
  return collection.slice().sort((a, b) => {
    const aPriority = prioritiesPredicate(a)
    const bPriority = prioritiesPredicate(b)

    if (aPriority === bPriority) {
      return fallback(a, b)
    } else {
      return aPriority > bPriority ? -1 : 1
    }
  })
}
