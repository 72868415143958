import * as _ from 'lodash'
import * as React from 'react'

import { IWithParticipantComponentProps } from '../components/participant/WithParticipant'
import Settings, { ParticipantSettings } from './Settings'
import { notification, Spin } from 'antd'

interface ISettingsPageState {
  loading: boolean
  accountGroups: { value: string; label: string }[]
  isAccountGroupsLoading: boolean
  settings: ParticipantSettings
}

export default class SettingsPage extends React.Component<
  IWithParticipantComponentProps,
  ISettingsPageState
> {
  constructor(props: IWithParticipantComponentProps) {
    super(props)
    this.state = {
      loading: true,
      accountGroups: [],
      isAccountGroupsLoading: false,
      settings: {
        primaryAccountGroup: null,
        canSubmitCustomers: false,
        canSubmitWithdrawalsWithAddress: false,
        withdrawalRequestsInstantSettleOption: 'no',
        autoTransferDepositsToAccountGroup: false,
        allocations_require_permission: false,
        withdrawal_account_required_approvers: 1,
        withdrawal_request_required_approvers: 1,
        withdrawal_address_approvers_threshold: 0,
        automatically_create_transfers_relationship: false
      }
    }
  }

  static getDerivedStateFromProps(
    nextProps: IWithParticipantComponentProps,
    prevState: ISettingsPageState
  ) {
    const {
      withdrawal_account_required_approvers,
      withdrawal_request_required_approvers,
      withdrawal_address_approvers_threshold,
      automatically_create_transfers_relationship
    } = nextProps.participantDetails

    return {
      settings: {
        ...prevState.settings,
        withdrawal_account_required_approvers,
        withdrawal_request_required_approvers,
        withdrawal_address_approvers_threshold,
        automatically_create_transfers_relationship
      }
    }
  }

  async componentDidMount() {
    this.setState(() => ({
      loading: true
    }))

    const [primaryAccountGroup] = await Promise.all([
      this.getPrimaryAccountGroup(),
      this.loadAccountGroups()
    ])

    this.setState((state) => ({
      loading: false,
      settings: { ...state.settings, primaryAccountGroup }
    }))
  }

  async getPrimaryAccountGroup() {
    const participant = await this.props.restService.route('basic').get<any>()
    return participant.primary_account_group
  }

  onAccountGroupsSearch = _.debounce(async (searchString: string) => {
    await this.loadAccountGroups(searchString)
  })

  onChange = async (settings: ParticipantSettings) => {
    // restore account groups list after selection to initial state
    this.loadAccountGroups()

    try {
      const primaryAccountGroup = settings.primaryAccountGroup
      const withdrawalAddressApproversThreshold =
        settings.withdrawal_address_approvers_threshold

      await this.props.restService.route('participants').put({
        primary_account_group: primaryAccountGroup,
        withdrawalAddressApproversThreshold
      })

      notification.success({
        message: 'Successfully Updated',
        duration: 2
      })
    } catch (e) {
      // do nothing
    }
  }

  async loadPlatformCustomers(searchString = '') {
    const { message } = await this.props.getPaginatedPlatformParticipants({
      page: 1,
      pageSize: 50,
      searchString,
      includePlatformParticipant: true,
      relationshipsTypes: ['customer_of_platform']
    })

    return message
  }

  async loadAccountGroups(searchString = '') {
    this.setState(() => ({
      isAccountGroupsLoading: true
    }))

    const platformCustomers = await this.loadPlatformCustomers(searchString)

    this.setState(() => ({
      accountGroups: [
        ...platformCustomers.map(
          ({ registration_code: participantCode, name }) => {
            return {
              value: participantCode,
              label: `${name} (${participantCode})`
            }
          }
        ),
        {
          value: 'UNALLOCATED',
          label: 'UNALLOCATED'
        }
      ],
      isAccountGroupsLoading: false
    }))
  }

  render() {
    return (
      <Spin spinning={this.state.loading}>
        {!this.state.loading && (
          <Settings
            disabled={!this.props.participant.isAdmin}
            accountGroups={this.state.accountGroups}
            settings={this.state.settings}
            onChange={this.onChange}
            onAccountGroupsSearch={this.onAccountGroupsSearch}
            isAccountGroupsBusy={this.state.isAccountGroupsLoading}
          />
        )}
      </Spin>
    )
  }
}
