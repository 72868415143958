import { Button, message, notification, Spin, Table, Tabs } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import * as React from 'react'
import Title from 'seed-shared-components/lib/components/Title'
import '@ant-design/compatible/assets/index.css'
import { IFund } from 'seed-shared-components/lib/static-data/assetConfig'
import { numberFormatter } from 'seed-shared-components/lib/utils/CurrencyFormatter'

import { IWithParticipantComponentProps } from '../../components/participant/WithParticipant'
import { ITransferRequestWithNames } from '../../platform'
import dateFormatter from '../../utils/dateFormatter'
import SubmitTransfer from './SubmitTransfer'

const { TabPane } = Tabs

export interface ITransferRequest {
  id: number
  created_at: string
  updated_at: string
  from_participant_code: string
  from_participant_name: string
  from_account_type: string
  from_account_group: string
  from_account_label: string
  to_participant_code: string
  to_participant_name: string
  to_account_type: string
  to_account_group: string
  to_account_label: string
  status: string
  amount: string
  currency: string
  memo: string
  submitter_email: string
  submitter_username: string
  approver_email: string
  approver_username: string
  rejector_email: string
  rejector_username: string
  admin_transfer: boolean
  platform_transfer_direction: string
}

interface ITransfersPageState {
  transfers: ITransferRequestWithNames[]
  pendingTransfersCount?: number
  funds: IFund[]
  loading: boolean
  updating: boolean
  isAllowedToSubmitTransfer: boolean
}

export default class TransfersPage extends React.Component<
  IWithParticipantComponentProps,
  ITransfersPageState
> {
  constructor(props: IWithParticipantComponentProps) {
    super(props)
    this.state = {
      transfers: [],
      funds: [],
      loading: true,
      updating: false,
      isAllowedToSubmitTransfer: false
    }
    this.reset = this.reset.bind(this)
    this.cancelTransfer = this.cancelTransfer.bind(this)
  }

  async componentDidMount() {
    const { authUser, participant } = this.props

    this.reset()

    const isAllowedToSubmitTransfer = await authUser.isAdmin(participant.code)
    this.setState({ isAllowedToSubmitTransfer })
  }

  async reset() {
    const [transfers, funds] = await Promise.all([
      this.loadTransfers(),
      this.loadFunds()
    ])
    this.setState({
      transfers: transfers.message.sort(
        (a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at)
      ),
      funds,
      pendingTransfersCount: transfers.pendingTransfers,
      loading: false
    })
  }

  loadFunds() {
    return this.props.restService
      .route('funds?account_label=general')
      .get<IFund[]>()
  }

  async cancelTransfer(id: number) {
    if (!this.state.updating) {
      this.setState({ updating: true })
      const closeLoading = message.loading('Canceling transfer...', 0)
      try {
        await this.props.restService
          .route(`transfers/${id}`)
          .put({ status: 'canceled' })
        closeLoading()
        message.success('Update Successful', 2.5)
      } catch (e) {
        closeLoading()
        console.error(e)
        notification.error({
          description: e,
          duration: 0,
          message: 'Update failed, please try again later.'
        })
      }
      this.setState({ updating: false })
    }
  }

  async loadTransfers(): Promise<{
    message: ITransferRequest[]
    pendingTransfers: number
  }> {
    try {
      return await this.props.restService
        .route('portal-participant-allocation-transfers')
        .get<{ message: ITransferRequest[]; pendingTransfers: number }>()
    } catch (e) {
      if (e === 'Request failed with status code 404') {
        return { message: [], pendingTransfers: 0 }
      }
      throw e
    }
  }

  get columns() {
    return [
      {
        title: 'ID',
        dataIndex: 'id'
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        render: (item: number) => dateFormatter(item)
      },
      {
        title: 'Updated At',
        dataIndex: 'updated_at',
        render: (item: number) => dateFormatter(item)
      },
      {
        title: 'Administrative',
        dataIndex: 'admin_transfer',
        render: (text: boolean) => (text === true ? 'Yes' : 'No')
      },
      {
        title: 'Asset',
        dataIndex: 'currency'
      },
      {
        title: 'From',
        render: (row: ITransferRequestWithNames) => {
          return (
            <ParticipantItem
              name={row.from_participant_name}
              code={row.from_participant_code}
              group={row.from_account_group}
              label={row.from_account_label}
            />
          )
        }
      },
      {
        title: 'To',
        render: (row: ITransferRequestWithNames) => {
          return (
            <ParticipantItem
              name={row.to_participant_name}
              code={row.to_participant_code}
              group={row.to_account_group}
              label={row.to_account_label}
            />
          )
        }
      },
      {
        title: 'Amount',
        render: (row: ITransferRequestWithNames) => numberFormatter(row.amount)
      },
      {
        title: 'Status',
        dataIndex: 'status'
      },
      {
        title: 'Movement ID',
        dataIndex: 'movement_id'
      }
    ]
  }

  get pendingColumns() {
    const columns = this.columns

    return [
      ...columns,
      {
        title: 'Action',
        render: (transfer: ITransferRequest) => (
          <>
            {this.props.participant.isAdmin ? (
              <div>
                <Button
                  disabled={this.state.updating}
                  type="link"
                  onClick={() => this.cancelTransfer(transfer.id)}
                >
                  Cancel
                </Button>
              </div>
            ) : null}
          </>
        )
      }
    ]
  }

  render() {
    const { isAllowedToSubmitTransfer } = this.state

    return (
      <Spin spinning={this.state.loading}>
        <Title level={2}>Allocations</Title>
        <div style={{ marginBottom: 10 }}>
          Allocate your funds to different trading platforms on Zero Hash
          instantly and easily. All assets stay in your name, but are simply
          allocated for use on different trading venues and against different
          counterparties. Please refer to our FAQ for more info on allocations:{' '}
          <a
            href="https://zerohash.zendesk.com/hc/en-us/articles/360044662113"
            target="_blank"
          >
            How do I allocate funds for different purposes?
          </a>{' '}
        </div>

        <div style={{ marginBottom: 24 }}>
          Reach out to{' '}
          <a href="mailto:sales@zerohash.com" target="_blank">
            sales@zerohash.com
          </a>{' '}
          if you’d like to learn more about leveraging your Zero Hash account to
          connect to new trading platforms.
        </div>

        <SubmitTransfer
          isAllowedToSubmitTransfer={isAllowedToSubmitTransfer}
          onSuccess={this.reset}
          funds={this.state.funds}
          {...this.props}
        />
        <div>
          <Title level={3}>History</Title>
          <div>
            Below is the history of all allocations made between your accounts,
            plus any administrative transfers initiated by Zero Hash.
            Administrative transfers are flagged.
          </div>
          <br />
          <Tabs defaultActiveKey="approved">
            <TabPane tab="Approved" key="approved">
              <TransferRequestTable
                dataSource={this.state.transfers.filter((transfer) =>
                  ['approved', 'settled'].includes(transfer.status)
                )}
                columns={this.columns}
              />
            </TabPane>
            <TabPane
              tab={
                this.state.pendingTransfersCount
                  ? `Pending (${this.state.pendingTransfersCount})`
                  : 'Pending'
              }
              key="pending"
            >
              <TransferRequestTable
                dataSource={this.state.transfers.filter(
                  (transfer) => transfer.status === 'pending'
                )}
                columns={this.pendingColumns}
              />
            </TabPane>
            <TabPane tab="Rejected" key="rejected">
              <TransferRequestTable
                dataSource={this.state.transfers.filter((transfer) =>
                  ['rejected', 'canceled'].includes(transfer.status)
                )}
                columns={this.columns}
              />
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    )
  }
}

const ParticipantItem = ({ name, code, group, label }) => (
  <div>
    {name ? (
      <React.Fragment>
        <div>
          {name} ({group}) {label ? `[${label}]` : ''}
        </div>
        <div style={{ opacity: 0.5, fontSize: 12 }}>Participant: {code}</div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div>
          {group} {label ? `[${label}]` : ''}
        </div>
        <div style={{ opacity: 0.5, fontSize: 12 }}>Participant: {code}</div>
      </React.Fragment>
    )}
  </div>
)

function TransferRequestTable({
  dataSource,
  columns
}: {
  dataSource: ITransferRequestWithNames[] | undefined
  columns: ColumnProps<ITransferRequestWithNames>[] | undefined
}): JSX.Element {
  return (
    <Table
      dataSource={dataSource}
      rowKey={(transfer: ITransferRequestWithNames) => String(transfer.id)}
      columns={columns}
      pagination={false}
    />
  )
}
