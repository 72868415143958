import { connect } from 'react-redux'
import { IApplicationState } from '../../store/state'
import {
  IWithParticipantInputProps,
  IWIthParticipantExplicitProps
} from './props'

import selectors from '../../store/selectors'

export default connect<
  IWithParticipantInputProps,
  null,
  IWIthParticipantExplicitProps
>((state: IApplicationState) => ({
  participant: selectors.participant.getParticipant(state),
  loading: selectors.participant.isLoading(state),
  approved: selectors.participant.isApproved(state),
  participantDetails: selectors.participant.getDetails(state)
}))
