import { Decimal } from 'decimal.js'
import * as React from 'react'
import { IWithParticipantComponentProps } from '../../components/participant/WithParticipant'
import * as uuid from 'uuid'
import { IFund } from 'seed-shared-components/lib/static-data/assetConfig'
import {
  Select,
  Button,
  Row,
  Col,
  message,
  notification,
  Checkbox,
  Tooltip,
  Alert
} from 'antd'
import '@ant-design/compatible/assets/index.css'
import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/es/form'
import FormItem from '@ant-design/compatible/es/form/FormItem'
import { currencySymbol } from '../../utils/currencySymbols'
import NumberInput from 'seed-shared-components/lib/components/NumberInput'

import { assets as assetConfigs } from 'seed-shared-components/lib/static-data/assetConfig'

interface ISubmitTransferProps
  extends IWithParticipantComponentProps,
    FormComponentProps {
  funds: IFund[]
  onSuccess: () => void
  isAllowedToSubmitTransfer: boolean
}

interface ISubmitTransferState {
  asset: string | null
  accountSearchList: IFund[]
  fromAccountSearchResults: IFund[]
  toAccountSearchResults: IFund[]
  funds: IFund[]
  maxTransferAmount: number
  submitting: boolean
  toAccount?: IFund
  fromAccount?: IFund
  assets: any[]
  assetSymbols: string[]
}
const Option = Select.Option

class SubmitTransfer extends React.Component<
  ISubmitTransferProps,
  ISubmitTransferState
> {
  constructor(props: ISubmitTransferProps) {
    super(props)

    this.state = {
      asset: null,
      funds: [],
      accountSearchList: [],
      fromAccountSearchResults: [],
      toAccountSearchResults: [],
      maxTransferAmount: 0,
      submitting: false,
      assets: [],
      assetSymbols: []
    }
  }

  loadAssets = async () => {
    try {
      const assetsResponse = await this.props.restService
        .route(`assets?requestId=${uuid.v4()}&asset_type=CRYPTO`)
        .get()
      const fiatCurrencies = assetConfigs
        .filter((asset) => asset.type === 'Fiat')
        .map((a) => a.asset)

      this.setState({
        assets: assetsResponse,
        assetSymbols: [...fiatCurrencies, ...assetsResponse.map((a) => a.asset)]
      })
    } catch (e) {
      console.warn('could not fetch assets')
    }
  }

  async componentDidMount() {
    await this.loadAssets()
  }

  static getDerivedStateFromProps(props: ISubmitTransferProps) {
    return {
      funds: props.funds
    }
  }

  handleAssetChange = (value: string) => {
    // cleanup `from` and `to` participants
    this.props.form.setFieldsValue(
      {
        from: '',
        to: '',
        amount: ''
      },
      () => {
        const filteredFunds = this.state.funds.filter((f) => f.asset === value)
        this.setState({
          asset: value,
          accountSearchList: filteredFunds,
          fromAccountSearchResults: filteredFunds,
          toAccountSearchResults: filteredFunds,
          fromAccount: undefined,
          toAccount: undefined,
          maxTransferAmount: -1
        })
      }
    )
  }

  handleFromAccountSearch = (value: string) => {
    this.setState({
      fromAccountSearchResults: this.state.fromAccountSearchResults.filter(
        (x) =>
          x.account_group
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase())
      ),
      toAccountSearchResults:
        value === ''
          ? this.state.accountSearchList
          : this.state.toAccountSearchResults
    })
  }

  handleFromAccountSet = (value: string) => {
    const fromAccount = this.state.funds.find(
      (f) =>
        f.account_group.toLocaleLowerCase() === value.toLocaleLowerCase() &&
        f.asset === this.state.asset
    )
    const maxTransferAmount = fromAccount?.available || 0

    this.setState({
      maxTransferAmount,
      toAccountSearchResults: fromAccount
        ? this.state.accountSearchList.filter(
            (account) => account.account_group !== fromAccount.account_group
          )
        : this.state.accountSearchList,
      fromAccount: fromAccount
    })
  }

  handleToAccountSet = (value: string) => {
    const toAccount = this.state.funds.find(
      (f) =>
        f.account_group.toLocaleLowerCase() === value.toLocaleLowerCase() &&
        f.asset === this.state.asset
    )
    this.setState({
      fromAccountSearchResults: toAccount
        ? this.state.accountSearchList.filter(
            (account) => account.account_group !== toAccount.account_group
          )
        : this.state.accountSearchList,
      toAccount: toAccount
    })
  }

  handleToAccountSearch = (value: string) => {
    this.setState({
      toAccountSearchResults: this.state.toAccountSearchResults.filter((x) =>
        x.account_group.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      ),
      fromAccountSearchResults:
        value === ''
          ? this.state.accountSearchList
          : this.state.fromAccountSearchResults
    })
  }

  getAssetPrecision = (assetName: string) =>
    this.state.assets.find((a) => a.asset === assetName.toUpperCase())
      ?.precision

  submitTransfer = (e: React.FormEvent) => {
    e.preventDefault()

    this.props.form.validateFields((err: any, values: any) => {
      if (err) {
        return
      }

      const body = {
        currency: values.asset,
        amount: parseFloat(values.amount),
        fromAccountGroup: values.from,
        toAccountGroup: values.to
      }

      this.setState({ submitting: true })
      const hide: any = message.loading('Allocating', 0)
      this.props.restService
        .route('transfers')
        .post(body)
        .then(
          () => {
            this.props.form.resetFields()
            hide()
            message.success('Allocation transfer successful', 1)
            this.setState({
              submitting: false,
              fromAccount: undefined,
              toAccount: undefined,
              asset: undefined,
              maxTransferAmount: -1
            })
            this.props.onSuccess()
          },
          (error) => {
            hide()
            notification.error({
              description: error,
              duration: 0,
              message: 'Allocation transfer failed'
            })
            this.setState({ submitting: false })
          }
        )
    })
  }

  static getFundDisplayName(fund?: IFund) {
    if (!fund) {
      return ''
    }

    return fund.name
      ? `${fund.name} (${fund.account_group})`
      : fund.account_group
  }

  getAccountValueLabel(fund: IFund) {
    const assetPrecision = this.getAssetPrecision(fund.asset.toUpperCase())

    const fundName = SubmitTransfer.getFundDisplayName(fund)
    const amount = new Decimal(fund.available || '0')
      .toDecimalPlaces(assetPrecision)
      .toString()
    const label = fund.account_label

    let ndo

    if (!isNaN(parseFloat(`${fund.ndo}`))) {
      const ndoDecimal = new Decimal(fund.ndo).toDecimalPlaces(assetPrecision)

      ndo = (
        <span style={{ color: 'red' }}>
          &nbsp;NDO:&nbsp;{ndoDecimal.toString()}
        </span>
      )
    }

    const currencyJsx = currencySymbol[fund.asset] ? (
      <>&nbsp;{currencySymbol[fund.asset]}</>
    ) : null
    const labelJsx = label ? ` [${label}]` : null

    return (
      <span>
        {fundName}&nbsp;{labelJsx}&nbsp;-{currencyJsx}&nbsp;{amount}
        {ndo}
      </span>
    )
  }

  renderAllocationsSelectOption(fund: IFund) {
    return (
      <Select.Option key={fund.account_group} value={fund.account_group}>
        {this.getAccountValueLabel(fund)}
      </Select.Option>
    )
  }

  render() {
    const { form, isAllowedToSubmitTransfer } = this.props
    const { getFieldDecorator } = form
    const {
      fromAccount,
      toAccount,
      fromAccountSearchResults,
      toAccountSearchResults,
      submitting,
      assetSymbols
    } = this.state
    const isAccountLimitPositive = this.state.maxTransferAmount > 0
    const formItemStyle = { marginBottom: 0 }
    const isSubmitAllowed =
      !submitting &&
      isAllowedToSubmitTransfer &&
      isAccountLimitPositive &&
      fromAccount &&
      toAccount

    return (
      <Row>
        <Col md={24}>
          <Form hideRequiredMark={true} layout="vertical">
            <Row gutter={12}>
              <Col span={8} xxl={6}>
                <FormItem
                  style={{ ...formItemStyle }}
                  label="Select the asset you wish to re-allocate"
                >
                  {getFieldDecorator('asset', { rules: [{ required: true }] })(
                    <Select
                      onChange={this.handleAssetChange}
                      disabled={submitting}
                    >
                      {assetSymbols.map((asset) => (
                        <Option key={asset} value={asset}>
                          {asset}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={8} xxl={6}>
                <FormItem style={{ ...formItemStyle }} label="Allocate from">
                  {getFieldDecorator('from', { rules: [{ required: true }] })(
                    <Select
                      showSearch
                      disabled={!this.state.asset || submitting}
                      placeholder="Select participant"
                      onChange={this.handleFromAccountSet}
                      onSearch={this.handleFromAccountSearch}
                    >
                      {fromAccountSearchResults.map((fund) => {
                        return this.renderAllocationsSelectOption(fund)
                      })}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8} xxl={6}>
                <FormItem style={{ ...formItemStyle }} label="Allocate to">
                  {getFieldDecorator('to', { rules: [{ required: true }] })(
                    <Select
                      showSearch
                      disabled={!this.state.asset || submitting}
                      placeholder="Select participant"
                      onChange={this.handleToAccountSet}
                      onSearch={this.handleToAccountSearch}
                    >
                      {toAccountSearchResults.map((fund) => {
                        return this.renderAllocationsSelectOption(fund)
                      })}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={8} xxl={6}>
                <FormItem label="Amount">
                  {!isAccountLimitPositive || !fromAccount || !toAccount ? (
                    <Tooltip
                      placement="top"
                      title={`Select an account with a positive balance from which you want to allocate funds.`}
                    >
                      <span>
                        <NumberInput
                          disabled={true}
                          suffix={this.state.asset}
                        />
                      </span>
                    </Tooltip>
                  ) : (
                    <>
                      {getFieldDecorator('amount', {
                        rules: [
                          {
                            required: true
                          },
                          {
                            validator: (rule, value) => {
                              const numberValue = value || 0
                              return numberValue <= this.state.maxTransferAmount
                            },
                            message: 'Not enough funds'
                          }
                        ]
                      })(
                        <NumberInput
                          disabled={submitting}
                          acceptDecimal={true}
                          suffix={this.state.asset}
                          allowLeadingZero={true}
                          decimalScale={
                            this.state.asset
                              ? this.getAssetPrecision(this.state.asset)
                              : 2
                          }
                        />
                      )}
                    </>
                  )}
                </FormItem>
              </Col>
            </Row>

            {this.state.toAccount && this.state.toAccount.requiresApproval && (
              <FormItem>
                {getFieldDecorator('acknowledge', {
                  rules: [
                    {
                      required: true,
                      message:
                        'You must acknowledge approval is required for allocation.'
                    }
                  ]
                })(
                  <Checkbox style={{ lineHeight: 1.5 }} disabled={submitting}>
                    I understand that assets allocated to{' '}
                    <em>{`${SubmitTransfer.getFundDisplayName(
                      this.state.toAccount
                    )}`}</em>{' '}
                    will be locked for the purposes of trading and settling, and
                    to unallocate funds from{' '}
                    <em>{`${SubmitTransfer.getFundDisplayName(
                      this.state.toAccount
                    )}`}</em>{' '}
                    in the future, approval will be needed by{' '}
                    <em>{`${SubmitTransfer.getFundDisplayName(
                      this.state.toAccount
                    )}`}</em>
                    .
                  </Checkbox>
                )}
              </FormItem>
            )}

            {this.state.fromAccount && this.state.fromAccount.requiresApproval && (
              <Alert
                style={{ marginBottom: 24 }}
                message={
                  <>
                    Approval will be needed from{' '}
                    <em>{`${SubmitTransfer.getFundDisplayName(
                      this.state.fromAccount
                    )}`}</em>{' '}
                    before this allocation transfer request will be processed.
                  </>
                }
                type="warning"
              />
            )}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.submitTransfer}
                disabled={!isSubmitAllowed}
                loading={submitting}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default Form.create<ISubmitTransferProps>()(SubmitTransfer)
