import * as _ from 'lodash'
import React, { useCallback } from 'react'
import { DEFAULT_PAGE_SIZE } from '../constants'
import { ILoan } from './types'
import { Table, Spin, notification, Row, Col, Card, Button } from 'antd'
import * as jsonDiffPatch from 'jsondiffpatch'
import {
  ObjectDetailRow,
  GeneralDetailRow,
  FormattedNumberRow
} from 'seed-shared-components/lib/components/DataDisplay'
import LoanEditModal from './components/LoanEditModal'
import { dateFormatterStringPrecise } from 'seed-shared-components/lib/formatters/date'
import { IMovement } from 'seed-shared-components/lib/components/TradeBlotter/types'
import { IWithParticipantComponentProps } from '../components/participant/WithParticipant'
import { movementStepTypeMap } from 'seed-shared-components/lib/components/TradeBlotter/MovementsMapping'
import { currencyFormatter } from 'seed-shared-components/lib/utils/CurrencyFormatter'
import Title from 'seed-shared-components/lib/components/Title'

const { Column } = Table

interface ILoanDetailsProps
  extends IWithParticipantComponentProps<{ id: string }> {
  loans?: ILoan[]
}

export default function LoanDetails(
  props: ILoanDetailsProps
): React.ReactElement {
  const [movements, setMovements] = React.useState<IMovement[]>([])
  const [loan, setLoan] = React.useState<ILoan | null>(null)
  const [diffs, setDiffs] = React.useState<any[] | null>(null)
  const [
    editLoanModalVisible,
    setEditLoanModalVisible
  ] = React.useState<boolean>(false)
  const [historyLoading, setHistoryLoading] = React.useState<boolean>(true)
  const [loanUpdating, setLoanUpdating] = React.useState<boolean>(false)
  const [loanLoading, setLoanLoading] = React.useState<boolean>(true)
  const [movementsLoading, setMovementsLoading] = React.useState<boolean>(true)

  const closeLoanModal = useCallback(() => {
    setEditLoanModalVisible(false)
  }, [setEditLoanModalVisible])

  const openLoanModal = useCallback(() => {
    setEditLoanModalVisible(true)
  }, [setEditLoanModalVisible])

  const fetchLoansAndMovements = useCallback(() => {
    setLoanLoading(true)
    setMovementsLoading(true)
    setHistoryLoading(true)

    fetchLoanHistory()
    fetchMovements()
    fetchLoans()
  }, [])

  const fetchLoanHistory = useCallback(async () => {
    const { loans } = props
    if (loans) {
      const diffs = []
      for (let i = 0; i < loans.length; i++) {
        const newDiff = jsonDiff.diff(loans[i], loans[i + 1])

        if (Object.keys(newDiff).length > 1) {
          diffs.push(newDiff)
        }
      }
      setDiffs(diffs)
    } else {
      try {
        const { id } = props.match.params
        const res = await props.restService
          .route(`loans/${id}/history`)
          .get<{ message: ILoan[] }>()
        const diffs = []
        for (let i = 0; i < res.message.length; i++) {
          const newDiff = jsonDiff.diff(res.message[i], res.message[i + 1])

          if (Object.keys(newDiff).length > 1) {
            diffs.push(newDiff)
          }
        }
        setDiffs(diffs)
      } catch (e) {
        console.log(e)
        notification.error({
          description: e,
          duration: 0,
          message: 'Failed to load loan details'
        })
      }
    }

    setLoanLoading(false)
  }, [])

  const fetchLoans = useCallback(async () => {
    try {
      const { id } = props.match.params
      const res = await props.restService.route(`loans/${id}`).get<any>()
      setLoan(res.message)
    } catch (e) {
      console.log(e)
      notification.error({
        description: e,
        duration: 0,
        message: 'Failed to load loan'
      })
    }

    setHistoryLoading(false)
  }, [])

  const fetchMovements = useCallback(async () => {
    try {
      const { id } = props.match.params
      const movements = await props.restService
        .route(`trades/${id}/movements`)
        .get<IMovement[]>()
      setMovements(movements)
    } catch (e) {
      console.log(e)
      notification.error({
        description: e,
        duration: 0,
        message: 'Failed to load movements'
      })
    }

    setMovementsLoading(false)
  }, [])

  React.useEffect(() => {
    fetchLoansAndMovements()
  }, [])

  const updateLoan = useCallback(async (data) => {
    setLoanUpdating(true)

    try {
      const { id } = props.match.params
      await props.restService.route(`loans/${id}`).put<ILoan>(data)

      fetchLoansAndMovements()
    } catch (e) {
      notification.error({
        message: 'There was an error while updating loan terms'
      })
    }

    setEditLoanModalVisible(false)
    setLoanUpdating(false)
  }, [])

  const [portfolios, setPortfolios] = React.useState([])

  React.useEffect(() => {
    props.restService
      .route(`loan-portfolios`)
      .get()
      .then(({ message }) => {
        setPortfolios(message)
      })
  }, [])

  return (
    <Spin spinning={loanLoading}>
      {!loanLoading && loan && (
        <LoanEditModal
          visible={editLoanModalVisible}
          loading={loanUpdating}
          loan={loan}
          onClose={closeLoanModal}
          onCreate={updateLoan}
        />
      )}

      <LoanDetailsView
        loan={loan}
        diffs={diffs}
        movements={movements}
        participantCode={props.participant.code}
        historyLoading={historyLoading}
        loanLoading={loanLoading}
        openLoanEditModal={openLoanModal}
        movementsLoading={movementsLoading}
        history={props.history}
        portfolios={portfolios}
      />
    </Spin>
  )
}

interface ILoanDetailsViewProps {
  loan: ILoan | null
  diffs: any[] | null
  movements: IMovement[]
  participantCode: string
  historyLoading: boolean
  loanLoading: boolean
  movementsLoading: boolean
  openLoanEditModal: () => void
  history: any
  portfolios: any[]
}

const getDiffKey = (record) => {
  if (record.create_at && Array.isArray(record.create_at)) {
    return `${record.create_at[0] - record.create_at[1]}`
  }

  if (Array.isArray(record) && record[0].id) {
    return record[0].id
  }
}

class LoanDetailsView extends React.PureComponent<ILoanDetailsViewProps, null> {
  render() {
    const {
      loan,
      diffs,
      historyLoading,
      loanLoading,
      movementsLoading,
      openLoanEditModal
    } = this.props
    const width = { label: 12, value: 12 }

    if (!loan) {
      return <></>
    }

    const navigateToPortfolio = (portfolioId) => () => {
      this.props.history.push(
        `/${this.props.participantCode}/loan-portfolios/${portfolioId}`
      )
    }

    const getPortfolioName = (portfolioId) => {
      const portfolio = _.find(
        this.props.portfolios,
        (portfolio) => portfolio.loan_portfolio_id === portfolioId
      )

      return portfolio ? portfolio.name : portfolioId
    }

    return (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Title level={2}>Loan Details</Title>
          {loan.loan_state !== 'terminated' && (
            <Button onClick={openLoanEditModal}>Edit Loan Terms</Button>
          )}
        </div>
        <Spin spinning={loanLoading}>
          <Row>
            <Col span={24}>
              <Card title="Loan Terms" style={{ background: 'inherit' }}>
                <Col xl={12} lg={24}>
                  <ObjectDetailRow object={loan} property="loan_id" />
                  <ObjectDetailRow
                    object={loan}
                    property="client_loan_id"
                    overrideLabel="Platform Loan ID"
                  />
                  <GeneralDetailRow
                    value={
                      <a onClick={navigateToPortfolio(loan.loan_portfolio_id)}>
                        {getPortfolioName(loan.loan_portfolio_id)}
                      </a>
                    }
                    label="Portfolio ID"
                  />
                  <ObjectDetailRow
                    object={{
                      created_at: dateFormatterStringPrecise(loan.created_at)
                    }}
                    property="created_at"
                    overrideLabel="Last Update"
                  />
                  <ObjectDetailRow
                    object={loan}
                    property="platform_code"
                    overrideLabel="Platform Code"
                  />
                  <ObjectDetailRow object={loan} property="platform_name" />
                  <ObjectDetailRow
                    object={loan}
                    property="loan_reporter"
                    overrideLabel="Loan Reporter"
                  />
                  <ObjectDetailRow object={loan} property="loan_state" />
                  <ObjectDetailRow object={loan} property="symbol" />
                  <ObjectDetailRow object={loan} property="loan_term" />
                  <ObjectDetailRow object={loan} property="spot_rate" />
                  <ObjectDetailRow
                    object={{
                      transaction_timestamp: dateFormatterStringPrecise(
                        loan.transaction_timestamp
                      )
                    }}
                    property="transaction_timestamp"
                  />
                  <ObjectDetailRow
                    object={{
                      maturity_timestamp: dateFormatterStringPrecise(
                        loan.maturity_timestamp
                      )
                    }}
                    property="maturity_timestamp"
                  />
                  <ObjectDetailRow object={loan} property="loan_asset" />
                  <FormattedNumberRow
                    label="Loan Quantity"
                    value={loan.loan_quantity}
                  />
                  <FormattedNumberRow
                    label="Initial Loan Value (USD)"
                    value={loan.initial_loan_value_usd}
                  />
                  <FormattedNumberRow
                    label="Current Loan Value (USD)"
                    value={loan.current_loan_value_usd}
                  />
                  <FormattedNumberRow
                    label="Current Loan Value Mark Price (USD)"
                    value={loan.principal_rate}
                  />
                </Col>
                <Col xl={12} lg={24}>
                  <ObjectDetailRow
                    object={loan}
                    property="loan_rate"
                    overrideLabel="Loan Interest Rate (%)"
                  />
                  <ObjectDetailRow
                    object={loan}
                    property="accrual_amount"
                    overrideLabel="Acccrued Interest"
                  />
                  <ObjectDetailRow
                    object={loan}
                    property="loan_rate_accrual_asset"
                    overrideLabel="Accrue Interest in Asset"
                  />
                  <ObjectDetailRow
                    object={loan}
                    property="loan_rate_asset"
                    overrideLabel="Pay Interest in Asset"
                  />
                  <ObjectDetailRow object={loan} property="accrual_method" />
                  <ObjectDetailRow
                    object={{
                      accrual_start_date: dateFormatterStringPrecise(
                        loan.accrual_start_date
                      )
                    }}
                    property="accrual_start_date"
                  />
                  <ObjectDetailRow
                    object={{
                      accrue_interest_type:
                        loan.accrue_interest_type === 'usd'
                          ? 'USD'
                          : loan.accrue_interest_type
                    }}
                    property="accrue_interest_type"
                  />
                  <ObjectDetailRow object={loan} property="collateral_assets" />
                  <ObjectDetailRow
                    object={loan}
                    property="collateral_quantity"
                  />
                  <FormattedNumberRow
                    label="Current Collateral Coverage (%)"
                    value={(
                      (Number(loan.collateral_value) * 100) /
                      Number(loan.current_loan_value_usd)
                    ).toFixed(2)}
                  />
                  <ObjectDetailRow
                    object={loan}
                    property="collateral_requirement_percentage"
                    overrideLabel="Collateral Requirement Percentage (%)"
                  />
                  <ObjectDetailRow
                    object={loan}
                    property="collateral_release_percentage"
                    overrideLabel="Collateral Release Percentage (%)"
                  />
                  <ObjectDetailRow
                    object={loan}
                    property="collateral_top_up_percentage"
                    overrideLabel="Collateral Top Up Percentage (%)"
                  />
                  <FormattedNumberRow
                    label="Current Collateral Value (USD)"
                    value={loan.collateral_value}
                  />
                  <FormattedNumberRow
                    label="Current Collateral Value Mark Price (USD)"
                    value={loan.collateral_rate}
                  />
                  <FormattedNumberRow
                    label="Settled Collateral Quantity (USD)"
                    value={loan.settled_collateral_quantity}
                  />
                </Col>
              </Card>
            </Col>
          </Row>
        </Spin>
        <br />
        <Spin spinning={loanLoading}>
          <Row>
            <Col span={24}>
              <Card title="Loan Parties" style={{ background: 'inherit' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 12
                  }}
                >
                  <div style={{ display: 'flex', marginBottom: 2 }}>
                    <div style={{ fontWeight: 700, marginRight: 18 }}>
                      Parties Anonymous?
                    </div>
                    <div>{loan.parties_anonymous ? 'True' : 'False'}</div>
                  </div>
                </div>
                <Row style={{ marginBottom: 12 }}>
                  <Card style={{ background: 'inherit' }}>
                    <Row>
                      <Col xl={12} lg={24}>
                        <ObjectDetailRow
                          object={loan}
                          property="lender_participant_name"
                          overrideLabel="Participant Name"
                          width={width}
                        />
                        <ObjectDetailRow
                          object={loan}
                          property="lender_participant_code"
                          overrideLabel="Participant Code"
                          width={width}
                          notOptional
                        />
                        <ObjectDetailRow
                          object={{ side: 'Lender' }}
                          property="side"
                          width={width}
                        />
                      </Col>
                      <Col xl={12} lg={24}>
                        <ObjectDetailRow
                          object={loan}
                          property="lender_settlement_state"
                          overrideLabel="Settlement State"
                          width={width}
                        />
                        <ObjectDetailRow
                          object={
                            {
                              lender_obligations_outstanding_timestamp: dateFormatterStringPrecise(
                                loan.lender_obligations_outstanding_timestamp
                              )
                            } as { [key: string]: string | null }
                          }
                          property="lender_obligations_outstanding_timestamp"
                          overrideLabel="Obligations Outstanding Timestamp"
                          width={width}
                        />
                        <ObjectDetailRow
                          object={
                            {
                              lender_current_obligations_met_timestamp: dateFormatterStringPrecise(
                                loan.lender_current_obligations_met_timestamp
                              )
                            } as { [key: string]: string | null }
                          }
                          property="lender_current_obligations_met_timestamp"
                          overrideLabel="Current Obligations Met Timestamp"
                          width={width}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Row>
                <Row style={{ marginBottom: 12 }}>
                  <Card style={{ background: 'inherit' }}>
                    <Row>
                      <Col xl={12} lg={24}>
                        <ObjectDetailRow
                          object={loan}
                          property="borrower_participant_name"
                          overrideLabel="Participant Name"
                          width={width}
                        />
                        <ObjectDetailRow
                          object={loan}
                          property="borrower_participant_code"
                          overrideLabel="Participant Code"
                          width={width}
                          notOptional
                        />
                        <ObjectDetailRow
                          object={{ side: 'Borrower' }}
                          property="side"
                          width={width}
                        />
                      </Col>
                      <Col xl={12} lg={24}>
                        <ObjectDetailRow
                          object={loan}
                          property="borrower_settlement_state"
                          overrideLabel="Settlement State"
                          width={width}
                        />
                        <ObjectDetailRow
                          object={
                            {
                              borrower_obligations_outstanding_timestamp: dateFormatterStringPrecise(
                                loan.borrower_obligations_outstanding_timestamp
                              )
                            } as { [key: string]: string | null }
                          }
                          property="borrower_obligations_outstanding_timestamp"
                          overrideLabel="Obligations Outstanding Timestamp"
                          width={width}
                        />
                        <ObjectDetailRow
                          object={
                            {
                              borrower_current_obligations_met_timestamp: dateFormatterStringPrecise(
                                loan.borrower_current_obligations_met_timestamp
                              )
                            } as { [key: string]: string | null }
                          }
                          property="borrower_current_obligations_met_timestamp"
                          overrideLabel="Current Obligations Met Timestamp"
                          width={width}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Row>
              </Card>
            </Col>
          </Row>
        </Spin>
        <br />
        <Spin spinning={loanLoading}>
          <Row>
            <Col span={24}>
              <Card
                title="Settlement Details"
                style={{ background: 'inherit' }}
              >
                <Col xl={12} lg={24}>
                  <GeneralDetailRow
                    value="Zero Hash LLC"
                    label="Settlement Agent Name"
                  />
                  <GeneralDetailRow
                    value="DIYD8K"
                    label="Settlement Agent Code"
                  />
                  <GeneralDetailRow
                    value="ZERO"
                    label="Settlement Agent Market Identifier Code"
                  />
                  <GeneralDetailRow
                    value="0.000000"
                    label="Settlement / Transfer Fees"
                  />
                  <GeneralDetailRow value="0.000000" label="Transfer Taxes" />
                  <GeneralDetailRow value={'N/A'} label="Exchange Rate" />
                  <GeneralDetailRow value="0.000000" label="Other Fees" />
                </Col>
                <Col xl={12} lg={24}>
                  <ObjectDetailRow
                    object={
                      {
                        accepted_timestamp: dateFormatterStringPrecise(
                          loan.accepted_timestamp
                        )
                      } as { [key: string]: string | null }
                    }
                    property="accepted_timestamp"
                  />
                  <ObjectDetailRow
                    object={
                      {
                        settled_timestamp: dateFormatterStringPrecise(
                          loan.settled_timestamp
                        )
                      } as { [key: string]: string | null }
                    }
                    property="settled_timestamp"
                  />
                  <ObjectDetailRow
                    object={
                      {
                        defaulted_timestamp: dateFormatterStringPrecise(
                          loan.defaulted_timestamp
                        )
                      } as { [key: string]: string | null }
                    }
                    property="defaulted_timestamp"
                  />
                  <ObjectDetailRow
                    object={
                      {
                        principal_swap_default_timestamp: dateFormatterStringPrecise(
                          loan.principal_swap_default_timestamp
                        )
                      } as { [key: string]: string | null }
                    }
                    property="principal_swap_default_timestamp"
                    overrideLabel="Princicpal Swap Due Timestamp"
                  />
                  <ObjectDetailRow
                    object={
                      {
                        collateral_default_timestamp: dateFormatterStringPrecise(
                          loan.collateral_default_timestamp
                        )
                      } as { [key: string]: string | null }
                    }
                    property="collateral_default_timestamp"
                    overrideLabel="Collateral Due Timestamp"
                  />
                  <ObjectDetailRow
                    object={
                      {
                        repayment_default_timestamp: dateFormatterStringPrecise(
                          loan.repayment_default_timestamp
                        )
                      } as { [key: string]: string | null }
                    }
                    property="repayment_default_timestamp"
                    overrideLabel="Principal Repayment Due Timestamp"
                  />
                  <ObjectDetailRow
                    object={
                      {
                        interest_default_timestamp: dateFormatterStringPrecise(
                          loan.interest_default_timestamp
                        )
                      } as { [key: string]: string | null }
                    }
                    property="interest_default_timestamp"
                    overrideLabel="Interest Due Timestamp"
                  />
                  <ObjectDetailRow
                    object={
                      {
                        last_interest_calculation_timestamp: dateFormatterStringPrecise(
                          loan.last_interest_calculation_timestamp
                        )
                      } as { [key: string]: string | null }
                    }
                    property="last_interest_calculation_timestamp"
                    overrideLabel="Most Recent Interest Accrual Timestamp"
                  />
                </Col>
                <Col span={24}>
                  <DisclosureRow
                    label="Settlement Agent Contact"
                    disclosure="To ask questions or register complaints related to trading on Zero Hash, please call (855) 744-7333."
                  />
                  <DisclosureRow
                    label="Settlement Agent Statement of Liability"
                    disclosure="Zero Hash is not liable for non delivery or delayed delivery of any digital asset."
                  />
                  <DisclosureRow
                    label="Settlement Agent Refund Policy"
                    disclosure="Zero Hash is not liable for any refunds for any fees or charges imposed by Zero Hash or any of its affiliates or partners."
                  />
                  <DisclosureRow
                    label="Settlement Agent Address"
                    disclosure="327 N. Aberdeen St., FL 1, Chicago, IL 60607"
                  />
                </Col>
              </Card>
            </Col>
          </Row>
        </Spin>
        <br />
        <Spin spinning={historyLoading}>
          <Row>
            <Col span={24}>
              <Card title="Loan History">
                <Table
                  dataSource={diffs}
                  pagination={{ defaultPageSize: DEFAULT_PAGE_SIZE }}
                  showHeader={false}
                  rowKey={(record) => getDiffKey(record)}
                >
                  <Column
                    title="Diff"
                    // dataIndex="settle_timestamp"
                    key="Diff"
                    render={(diff: any) => (
                      <DiffRow
                        key={diff.created_at ? diff.created_at[0] : Date.now()}
                        diff={diff}
                      />
                    )}
                  />
                </Table>
              </Card>
            </Col>
          </Row>
        </Spin>
        <br />
        {this.props.movements.length && this.props.movements.length > 0 ? (
          <Spin spinning={movementsLoading}>
            <Row>
              <Col span={24}>
                <Card title="Movements" style={{ background: 'inherit' }}>
                  <Table
                    dataSource={this.props.movements}
                    pagination={{ defaultPageSize: DEFAULT_PAGE_SIZE }}
                    rowKey={(record) =>
                      `${record.settle_timestamp}-${record.step_id}`
                    }
                  >
                    <Column
                      title="Movement Timestamp"
                      dataIndex="settle_timestamp"
                      key="Movement Timestamp"
                      render={(item: number) =>
                        dateFormatterStringPrecise(item)
                      }
                    />
                    <Column title="Run ID" dataIndex="version" key="Run ID" />
                    <Column
                      title="Movement ID"
                      dataIndex="step_id"
                      key="Movement ID"
                      render={(text, movement: IMovement) => {
                        const url = this.props.participantCode
                          ? `/${this.props.participantCode}/balances/${movement.currency}/history/${movement.account_id}/movements/${movement.version}#${movement.step_id}`
                          : undefined
                        return <a href={url}>{text}</a>
                      }}
                    />
                    <Column
                      title="Movement Type"
                      dataIndex="step_type"
                      key="Movement Type"
                      render={(stepType: string) => {
                        return movementStepTypeMap[stepType]
                      }}
                    />
                    <Column
                      title="Account Type"
                      dataIndex="account_type"
                      key="Account Type"
                    />
                    <Column title="Asset" dataIndex="currency" key="Asset" />
                    <Column
                      title="Change"
                      key="Change"
                      dataIndex="change"
                      render={(text, movement: IMovement) =>
                        currencyFormatter(text, movement.currency)
                      }
                    />
                  </Table>
                </Card>
              </Col>
            </Row>
          </Spin>
        ) : undefined}
      </>
    )
  }
}

const jsonDiff = (jsonDiffPatch as any).create({
  propertyFilter(name: any, context: any) {
    return !['id', 'ledger_version'].includes(name)
  }
})

const LoanCreated = ({ diff }: { diff: any }) => (
  <Row>
    <Col span={8}>
      {dateFormatterStringPrecise(new Date(diff[0].created_at).getTime())}
    </Col>
    <Col span={16}>
      <span style={{ color: 'green' }}>Loan Created</span>
    </Col>
  </Row>
)

export const DiffDetails = ({ diff }: { diff: any }) => {
  return Object.keys(diff).length > 1 ? (
    <Row>
      <>
        <Col span={8}>
          {dateFormatterStringPrecise(new Date(diff.created_at[0]).getTime())}
        </Col>
        <Col span={16}>
          {Object.keys(diff).map((key, index) => {
            if (key === 'created_at') {
              return <span key={index} />
            }
            const newVal = diff[key][0]
            const oldVal = diff[key][1]

            return (
              <Row key={index}>
                <span>{key}: </span>
                <span style={{ color: 'red' }}>{String(oldVal)}</span>
                {' => '}
                <span style={{ color: 'green' }}>{String(newVal)}</span>
              </Row>
            )
          })}
        </Col>
      </>
    </Row>
  ) : (
    <span />
  )
}

const DiffRow = ({ diff }: { diff: any }) => {
  if (Array.isArray(diff)) {
    return <LoanCreated diff={diff} />
  } else {
    if (!diff) {
      return <></>
    }
    return <DiffDetails diff={diff} />
  }
}

const DisclosureRow = ({
  label,
  disclosure
}: {
  label: string
  disclosure: string | JSX.Element
}) => {
  return (
    <GeneralDetailRow
      label={label}
      value={disclosure}
      width={{ label: 6, value: 18 }}
    />
  )
}
