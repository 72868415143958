import * as auth0 from 'auth0-js'
import portalApi from '../api/portalApi'

const auth = new auth0.WebAuth({
  audience: window._env_.REACT_APP_AUTH0_AUDIENCE as string,
  clientID: window._env_.REACT_APP_AUTH0_CLIENT_ID as string,
  domain: window._env_.REACT_APP_AUTH0_DOMAIN as string,
  redirectUri: window._env_.REACT_APP_AUTH0_REDIRECT_URI as string,
  responseType: 'token id_token',
  scope: 'openid profile email'
})

// return the user to the app after logout.
export const logout = () => {
  auth.logout({ returnTo: window._env_.REACT_APP_AUTH0_REDIRECT_URI })
}
export const login = (options: auth0.AuthorizeOptions) =>
  auth.authorize({ ...options, state: window.location.pathname })
export const authenticate = () => tryTokensFromUrl().catch(trySingleSignOn)

export const changePassword = async (email: string) =>
  new Promise((resolve, reject) => {
    auth.changePassword(
      {
        connection: window._env_.REACT_APP_AUTH0_CONNECTION as string,
        email
      },
      (err, resp) => {
        if (err) {
          reject(err)
        } else {
          resolve(resp)
        }
      }
    )
  })

const trySingleSignOn = () =>
  new Promise((resolve, reject) => {
    auth.checkSession(
      {
        audience: window._env_.REACT_APP_AUTH0_AUDIENCE
      },
      (err: any, authResult: any) => {
        if (err) {
          console.log(`[FundingApp] SSO failed`, err)
          reject(err)
        } else {
          resolve(authResult)
        }
      }
    )
  })

const tryTokensFromUrl = () =>
  new Promise((resolve, reject) => {
    if (window._env_.REACT_APP_UI_TEST_MODE === 'true') {
      console.log('Running app in UI test mode')
      resolve({
        accessToken: 'UI_TEST_ACCESS_TOKEN',
        idToken: 'UI_TEST_ID_TOKEN',
        idTokenPayload: {
          expiry: Date.now() + 10000000,
          email: 'ui_test_user@seedcx.com'
        },
        state: null
      })
      return
    }
    auth.parseHash((err: any, authResult: any) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        resolve(authResult)
      } else {
        console.log(`[FundingApp] JWT from URL failed`, err)
        reject(err)
      }
    })
  })

export const resendEmailVerification = async (email: string) => {
  try {
    await portalApi.request<boolean>({
      url: 'send_email_verification',
      method: 'POST',
      data: {
        email
      }
    })
    return true
  } catch (e) {
    console.error('error sending email verification!', { err: e })
    return false
  }
}
