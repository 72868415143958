import { FSA, IFSAData } from '../FSA'

const domain = `reports`

export enum ReportType {
  AccountBalances = 'account-balances',
  Deposits = 'deposits',
  LoanInterest = 'loan-interest',
  Loans = 'loans',
  NetworkFees = 'network-fees',
  Trades = 'trades',
  Withdrawals = 'withdrawals'
}

export interface IReportParams {
  participant?: string
  platform?: string
  from: string
  to: string
}

export interface IReport {
  id: string
  type: keyof typeof ReportType
  created_at: string
  status: 'pending' | 'failed' | 'completed'
  params: IReportParams
}

export const FETCH_REPORT = `${domain}/FETCH_REPORT`
export const FETCH_REPORT_FULFILL = `${domain}/FETCH_REPORT_FULFILL`

export interface IFetchReportActionPayload {
  participantCode: string
  type: string
  id: string
}

export interface IFetchReportAction
  extends FSA<string, IFetchReportActionPayload> {
  type: typeof FETCH_REPORTS
}

export interface IFetchReportFulfillActionSuccess extends IFSAData<IReport> {
  type: typeof FETCH_REPORT_FULFILL
  error: false
}

export interface IFetchReportFulfillActionFail extends IFSAData<Error> {
  type: typeof FETCH_REPORT_FULFILL
  error: true
}

export const FETCH_REPORTS = `${domain}/FETCH_REPORTS`
export const FETCH_REPORTS_FULFILL = `${domain}/FETCH_REPORTS_FULFILL`

export interface IFetchReportsActionPayload {
  participantCode: string
}

export interface IFetchReportsAction
  extends FSA<string, IFetchReportsActionPayload> {
  type: typeof FETCH_REPORTS
}

export interface IFetchReportsFulfillActionSuccess extends IFSAData<IReport[]> {
  type: typeof FETCH_REPORTS_FULFILL
  error: false
}

export interface IFetchReportsFulfillActionFail extends IFSAData<Error> {
  type: typeof FETCH_REPORTS_FULFILL
  error: true
}

export const FETCH_ACCOUNT_LABELS = `${domain}/FETCH_ACCOUNT_LABELS`
export const FETCH_ACCOUNT_LABELS_FULFILL = `${domain}/FETCH_ACCOUNT_LABELS_FULFILL`

export interface IFetchAccountLabelsActionPayload {
  participantCode: string
}

export interface IFetchAccountLabelsAction
  extends FSA<string, IFetchAccountLabelsActionPayload> {
  type: typeof FETCH_ACCOUNT_LABELS
}

export interface IFetchAccountLabelsFulfillActionSuccess
  extends IFSAData<string[]> {
  type: typeof FETCH_ACCOUNT_LABELS_FULFILL
  error: false
}

export interface IFetchAccountLabelsFulfillActionFail extends IFSAData<Error> {
  type: typeof FETCH_ACCOUNT_LABELS_FULFILL
  error: true
}

export const GENERATE_REPORT = `${domain}/GENERATE_REPORT`
export const GENERATE_REPORT_FULFILL = `${domain}/GENERATE_REPORT_FULFILL`

export interface IGenerateReportActionPayload {
  type: ReportType
  target: string
  participant: string
  from?: string
  to?: string
  timestamp?: string
  accountLabel?: string
}

export interface IGenerateReportAction
  extends FSA<string, IGenerateReportActionPayload> {
  type: typeof GENERATE_REPORT
}

export interface IGenerateReportFulfillActionSuccess
  extends IFSAData<IReport[]> {
  type: typeof GENERATE_REPORT_FULFILL
  error: false
}

export interface IGenerateReportFulfillActionFail extends IFSAData<Error> {
  type: typeof GENERATE_REPORT_FULFILL
  error: true
}
