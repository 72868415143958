import * as React from 'react'

import { IWithParticipantComponentProps } from '../components/participant/WithParticipant'
import { Route, Switch } from 'react-router-dom'
import TradeBlotter from './TradeBlotterContainer'
import TradeSubmission from './TradeSubmission'
import TradeDetails from './TradeDetailsContainer'

const TradesMasterPage = (props: IWithParticipantComponentProps) => {
  const {
    authUser,
    participant,
    restService,
    getPaginatedPlatformParticipants
  } = props
  const genericPropsForChildComponents = {
    authUser,
    participant,
    restService,
    getPaginatedPlatformParticipants
  }

  if (!authUser.idToken) {
    return null
  }

  return (
    <div>
      <Route
        path={`${props.match.path}`}
        exact={true}
        render={(routeProps: any) => (
          <TradeBlotter {...genericPropsForChildComponents} {...routeProps} />
        )}
      />
      <Switch>
        <Route
          path={`${props.match.path}/trade_submission`}
          render={(routeProps: any) => (
            <TradeSubmission
              {...genericPropsForChildComponents}
              {...routeProps}
            />
          )}
        />
        <Route
          path={`${props.match.path}/:tradeId`}
          render={(routeProps: any) => (
            <TradeDetails {...genericPropsForChildComponents} {...routeProps} />
          )}
        />
      </Switch>
    </div>
  )
}

export default TradesMasterPage
