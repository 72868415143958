import { createAction, PrepareAction } from '@reduxjs/toolkit'
import fsaPrepareActionHandler from '../../fsaPrepareActionHandler'
import {
  FETCH_PLATFORM_POSITIONS,
  FETCH_PLATFORM_POSITIONS_FULFILL,
  IFetchPlatformPositionsAction,
  IFetchPlatformPositionsFulfillActionSuccess,
  IFetchPlatformPositionsFulfillActionFail
} from './types'

export const fetchPlatformPositions = createAction<
  IFetchPlatformPositionsAction['payload']
>(FETCH_PLATFORM_POSITIONS)
export const fetchPlatformPositionsFulfill = createAction<
  PrepareAction<
    | IFetchPlatformPositionsFulfillActionSuccess
    | IFetchPlatformPositionsFulfillActionFail
  >
>(FETCH_PLATFORM_POSITIONS_FULFILL, fsaPrepareActionHandler)
