import {
  IAsset,
  IFund
} from 'seed-shared-components/lib/static-data/assetConfig'
import { ITotalAssetBalance } from './state'
import Decimal from 'decimal.js'

export function computeAssetBalances(funds: IFund[], assets: IAsset[]) {
  const totalFunds = {}

  assets.forEach((value) => {
    totalFunds[value.asset] = {
      accrued_network_fees: 0,
      asset: value.asset,
      available: 0,
      collateral: 0,
      collateral_deficiency: 0,
      payable: 0,
      receivable: 0,
      key: value.asset
    }
  })

  funds.forEach((fund) => {
    const currentTotal = totalFunds[fund.asset]
    if (!currentTotal) {
      return
    }
    currentTotal.accrued_network_fees = Decimal.add(
      currentTotal.accrued_network_fees,
      fund.accrued_network_fees ?? 0
    ).toString()
    currentTotal.available = Decimal.add(
      currentTotal.available,
      fund.available ?? 0
    ).toString()
    currentTotal.collateral = Decimal.add(
      currentTotal.collateral,
      fund.collateral ?? 0
    ).toString()
    currentTotal.collateral_deficiency = Decimal.add(
      currentTotal.collateral_deficiency,
      fund.collateral_deficiency ?? 0
    ).toString()
    currentTotal.payable = Decimal.add(
      currentTotal.payable,
      fund.payable ?? 0
    ).toString()
    currentTotal.receivable = Decimal.add(
      currentTotal.receivable,
      fund.receivable ?? 0
    ).toString()

    if (!currentTotal.fund && !currentTotal.children) {
      currentTotal.fund = fund
      currentTotal.ndo = fund.ndo
    } else {
      if (!currentTotal.children) {
        currentTotal.children = []
        currentTotal.children.push({
          ...currentTotal.fund,
          child: true,
          key: `${currentTotal.fund.asset}-${currentTotal.fund.account_group}`
        })
        currentTotal.fund = undefined
        currentTotal.ndo = undefined
      }

      currentTotal.children.push({
        ...fund,
        child: true,
        key: `${fund.asset}-${fund.account_group}`
      })
    }
  })

  return Object.values(totalFunds) as ITotalAssetBalance[]
}
