import { createReducer } from '@reduxjs/toolkit'
import { IReportsState } from './state'
import * as reportsActions from './actions'
import {
  IFetchAccountLabelsFulfillActionFail,
  IFetchAccountLabelsFulfillActionSuccess,
  IFetchReportFulfillActionFail,
  IFetchReportFulfillActionSuccess,
  IFetchReportsFulfillActionFail,
  IFetchReportsFulfillActionSuccess
} from './types'

const initialState: IReportsState = {
  reports: {
    value: [],
    loading: true
  },
  accountLabels: {
    value: [],
    loading: true
  },
  generateReport: {
    loading: false
  }
}

export default createReducer(initialState, {
  [reportsActions.fetchReports.type]: (state) => {
    return {
      ...state,
      reports: {
        ...state.reports,
        loading: true
      }
    }
  },
  [reportsActions.fetchReportFulfill.type]: (
    state,
    action: IFetchReportFulfillActionSuccess | IFetchReportFulfillActionFail
  ) => {
    if (action.error) {
      return state
    } else {
      const curReports = state.reports
      const actionReport = (action as IFetchReportFulfillActionSuccess).payload
      const nextReportsValue = curReports.value.map((report) => {
        if (report.id === actionReport.id) {
          return { ...report, ...action.payload }
        }

        return report
      })

      return {
        ...state,
        reports: {
          ...curReports,
          value: nextReportsValue
        }
      }
    }
  },
  [reportsActions.fetchReportsFulfill.type]: (
    state,
    action: IFetchReportsFulfillActionSuccess | IFetchReportsFulfillActionFail
  ) => {
    const nextState = {
      ...state,
      reports: {
        ...state.reports,
        loading: false
      }
    }

    if (action.error) {
      return nextState
    } else {
      return {
        ...nextState,
        reports: {
          value: (action as IFetchReportsFulfillActionSuccess).payload,
          loading: false
        }
      }
    }
  },
  [reportsActions.fetchAccountLabelsFulfill.type]: (
    state,
    action:
      | IFetchAccountLabelsFulfillActionSuccess
      | IFetchAccountLabelsFulfillActionFail
  ) => {
    const nextState = {
      ...state,
      accountLabels: {
        ...state.accountLabels,
        loading: false
      }
    }

    if (action.error) {
      return nextState
    } else {
      return {
        ...nextState,
        accountLabels: {
          value: (action as IFetchAccountLabelsFulfillActionSuccess).payload,
          loading: false
        }
      }
    }
  },
  [reportsActions.generateReport.type]: (state) => {
    return {
      ...state,
      generateReport: {
        loading: true
      }
    }
  },
  [reportsActions.generateReportFulfill.type]: (state) => {
    return {
      ...state,
      generateReport: {
        loading: false
      }
    }
  }
})
