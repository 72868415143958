import * as uuid from 'uuid'
import axios, { AxiosResponse } from 'axios'
import * as _ from 'lodash'
import qs from 'qs'

import { logger } from './portalApi'
import handleError from './handleError'

const marketData = axios.create({
  baseURL: window._env_.REACT_APP_PORTAL_API_URI
})

const onRejected = (error) => {
  handleError(logger, error, true)
  return Promise.reject(error)
}

marketData.interceptors.request.use((config) => {
  config.headers.common = config.headers.common || {}
  config.headers.common['X-Request-Id'] = uuid()
  return config
}, onRejected)

marketData.interceptors.response.use((res) => res, onRejected)

export interface IRates {
  index?: string
  quoted?: string
  timestamp?: string
  underlying?: string
  value: number
}

interface IMultipleRates {
  [key: string]: IRates
}

export const fetchMultipleRates = ({
  assets,
  token
}: {
  assets: string[]
  token?: string
}): Promise<IMultipleRates> => {
  if (_.isEmpty(assets)) {
    return new Promise((resolve, reject) => {
      resolve({ USD: { value: 1 } })
    })
  }

  return marketData
    .get(`index/usd?${qs.stringify({ assets })}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(({ data }: AxiosResponse) => ({ ...data, USD: { value: 1 } }))
}

export const fetchRates = ({
  quoted,
  underlying,
  token
}: {
  quoted: string
  underlying: string
  token?: string
}): Promise<IRates> => {
  return marketData
    .get(`index`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: { underlying, quoted_currency: quoted }
    })
    .then(({ data }: AxiosResponse) => data)
}
