import { createAction, PrepareAction } from '@reduxjs/toolkit'
import fsaPrepareActionHandler from '../../fsaPrepareActionHandler'
import {
  FETCH_MOVEMENTS,
  FETCH_MOVEMENTS_FULFILL,
  IFetchMovementsAction,
  IFetchMovementsFulfillActionSuccess,
  IFetchMovementsFulfillActionFail
} from './types'

export const fetchMovements = createAction<IFetchMovementsAction['payload']>(
  FETCH_MOVEMENTS
)
export const fetchMovementsFulfill = createAction<
  PrepareAction<
    IFetchMovementsFulfillActionSuccess | IFetchMovementsFulfillActionFail
  >
>(FETCH_MOVEMENTS_FULFILL, fsaPrepareActionHandler)
