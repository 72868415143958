import React from 'react'

import ErrorBoundary from './ErrorBoundary'

import { IAuthUser } from '../auth/WithAuthentication'

import RestService from '../RestService'
import store from '../store/store'
import * as userSettingsSelectors from '../store/user-settings/selectors'
import * as userSettingsActions from '../store/user-settings/actions'

import FundsMasterPage from '../funds/FundsMasterPage'
import ReportsPage from '../reports'
import DocumentsPage from '../documents/DocumentsPage'
import ChangePassword from './ChangePassword'
import ApiKeysPage from '../apiKeys/components/ApiKeysPage'
import UsersPage from '../users/UsersPage'
import Trades from '../trades/TradesMasterPage'
import CreateParticipantSinglePage from '../create-participant/components/CreateParticipantSinglePage'
import withParticipant from './participant/WithParticipant'
import DashboardPage from '../dashboard/components/DashboardPage'
import { OnboardingApplicationPage } from '../onboarding-application/components/OnboardingApplicationPage'
import SignPage from '../sign/components/sign-page'
import TransfersPage from '../transfers/components/TransfersPage'
import {
  withRouter,
  Switch,
  Route,
  RouteComponentProps
} from 'react-router-dom'

import Title from 'seed-shared-components/lib/components/Title'
import SettingsPage from '../settings/SettingsPage'
import RelationshipsPage from '../relationships/RelationshipsPage'
import PlatformPage from '../platform'
import PlatformBalances from '../platform-balances/pages/balances'
import PlatformAccount from '../platform-balances/pages/accounts'
import PlatformMovements from '../platform-balances/pages/movements'
import PlatformPositions from '../platform/pages/positions'
import ParticipantPositions from '../participant/pages/positions'
import DepositPageNew from '../deposit/v2/DepositPage'
import NewAllWithdrawals from '../withdrawal/v2/NewAllWithdrawals'
import NewAssetWithdrawals from '../withdrawal/v2/NewAssetWithdrawals'
import Theme from '../theme/Theme'

interface IMasterPageProps extends RouteComponentProps {
  authUser: IAuthUser
}

const MasterPage = withRouter<IMasterPageProps, any>(
  (props: IMasterPageProps) => {
    const { authUser } = props
    const { location } = props

    const isParticipantCodeValid = (code: string) =>
      !!authUser.participants.find((c) => c.code === code)

    if (location.pathname === '/') {
      /**
       * It looks like auth0 redirects us to / TWICE when this component
       * is loaded. Probably an issue on auth0 side, need to investigate
       *
       * If you remove the timeout you should see XXXX/dashboard url being
       * replaced with just `/` after logging in. The problem will be gone
       * when you reload the page
       */
      setTimeout(() => {
        const settings = userSettingsSelectors.getSettings(store.getState())
        const defaultParticipantCode = authUser.participants[0]?.code || null

        if (isParticipantCodeValid(settings.preferredParticipant)) {
          return props.history.push(
            `/${settings.preferredParticipant}/dashboard`
          )
        }
        if (defaultParticipantCode) {
          store.dispatch(
            userSettingsActions.setUserSetting({
              preferredParticipant: defaultParticipantCode
            })
          )
          return props.history.push(`/${defaultParticipantCode}/dashboard`)
        }
        return props.history.push('/dashboard')
      })

      return null
    }

    if (!authUser.idToken) {
      return null
    }

    const maybeParticipantCode = props.location.pathname.split('/')[1]
    const maybeParticipant = props.authUser.participants.find(
      (p) => p.code === maybeParticipantCode
    )

    if (!!maybeParticipant) {
      /*
        This setTimeout should be removed whenever possible.
        I investigated it but could not find a working solution without using it.
        This catches the case where the user has an invalid participantCode on
        localStorage.
      */
      setTimeout(() => {
        store.dispatch(
          userSettingsActions.setUserSetting({
            preferredParticipant: maybeParticipantCode
          })
        )
      })
    }

    const restService = new RestService(
      authUser.idToken,
      maybeParticipant ? maybeParticipant.code : undefined
    )

    const pathSnippets = location.pathname.split('/').filter((i: string) => i)

    if (pathSnippets.length === 1 && maybeParticipant) {
      props.history.push(`/${maybeParticipant.code}/dashboard`)
      return null
    }

    const participantRouteToComponents = {
      '/:participantCode/dashboard': withParticipant({
        authUser,
        restService,
        skipApproval: true
      })(DashboardPage),
      '/:participantCode/balances': withParticipant({ authUser, restService })(
        FundsMasterPage
      ),
      '/:participantCode/deposits/:asset?': withParticipant({
        authUser,
        restService
      })(DepositPageNew),
      '/:participantCode/withdrawals/:asset': withParticipant({
        authUser,
        restService
      })(NewAssetWithdrawals),
      '/:participantCode/withdrawals': withParticipant({
        authUser,
        restService
      })(NewAllWithdrawals),
      '/:participantCode/allocations': withParticipant({
        authUser,
        restService
      })(TransfersPage),
      '/:participantCode/reports': withParticipant({ authUser, restService })(
        ReportsPage
      ),
      '/:participantCode/users': withParticipant({ authUser, restService })(
        UsersPage
      ),
      '/:participantCode/api_keys': withParticipant({ authUser, restService })(
        ApiKeysPage
      ),
      '/:participantCode/theme': withParticipant({ authUser, restService })(
        Theme
      ),
      '/:participantCode/documents': withParticipant({ authUser, restService })(
        DocumentsPage
      ),
      '/:participantCode/settings': withParticipant({
        authUser,
        restService
      })(SettingsPage),
      '/:participantCode/relationships': withParticipant({
        authUser,
        restService
      })(RelationshipsPage),
      '/:participantCode/trades': withParticipant({ authUser, restService })(
        Trades
      ),
      '/:participantCode/application': withParticipant({
        authUser,
        restService,
        skipApproval: true
      })(OnboardingApplicationPage),
      '/:participantCode/sign': withParticipant({
        authUser,
        restService,
        skipApproval: true
      })(SignPage),
      '/:participantCode/platform_transfers': withParticipant({
        authUser,
        restService
      })(PlatformPage),
      '/:participantCode/platform_balances/:code/accounts/:asset/:accountId/movements/:version': withParticipant(
        {
          authUser,
          restService
        }
      )(PlatformMovements),
      '/:participantCode/platform_balances/:code/accounts/:asset/:accountId': withParticipant(
        {
          authUser,
          restService
        }
      )(PlatformAccount),
      '/:participantCode/platform_balances/:code?': withParticipant({
        authUser,
        restService
      })(PlatformBalances),
      '/:participantCode/platform_positions': withParticipant({
        authUser,
        restService
      })(PlatformPositions),
      '/:participantCode/participant_positions': withParticipant({
        authUser,
        restService
      })(ParticipantPositions)
    }

    return (
      <ErrorBoundary restService={restService}>
        <div style={{ minWidth: 800 }}>
          <div style={{ padding: 8, minHeight: 360 }}>
            <Switch>
              {Object.keys(participantRouteToComponents).map((route) => (
                <Route
                  key={route}
                  path={route}
                  component={participantRouteToComponents[route]}
                />
              ))}
              <Route
                path={`/logout`}
                render={() => {
                  props.authUser.logout()
                  return null
                }}
              />
              <Route
                path={`/:participantCode/change_password`}
                // tslint:disable-next-line
                render={(routeProps: any) => (
                  <ChangePassword {...props} {...routeProps} />
                )}
              />
              <Route
                path={`/change_password`}
                // tslint:disable-next-line
                render={(routeProps: any) => (
                  <ChangePassword {...props} {...routeProps} />
                )}
              />

              <Route
                path={`/create`}
                render={(routeProps: any) => (
                  <CreateParticipantSinglePage
                    restService={restService}
                    onSave={props.authUser.refreshUser}
                    user={props.authUser}
                    {...props}
                    {...routeProps}
                  />
                )}
              />

              <Route
                path={`/dashboard`}
                // tslint:disable-next-line
                render={(routeProps: any) => {
                  return (
                    <DashboardPage
                      participantCode={null}
                      restService={restService}
                      {...props}
                      {...routeProps}
                    />
                  )
                }}
              />
              <Route
                path="*"
                exact={true}
                component={() => (
                  <Title level={3}>Sorry this page does not exist</Title>
                )}
              />
            </Switch>
          </div>
        </div>
      </ErrorBoundary>
    )
  }
)

export default MasterPage
