import _ from 'lodash'
import * as React from 'react'

import { Col, Form, InputNumber, Row, Select } from 'antd'
import Title from 'seed-shared-components/lib/components/Title'
import { IParticipantRecord } from 'seed-ts/types/participants'

const { Option } = Select

export interface IAccountLabel {
  value: string
  label: string
}

export interface ParticipantSettings {
  primaryAccountGroup: string | null
  primaryAccountLabel?: string | null
  canSubmitCustomers: boolean
  canSubmitWithdrawalsWithAddress: boolean
  autoTransferDepositsToAccountGroup: boolean
  withdrawalRequestsInstantSettleOption: IParticipantRecord['withdrawal_requests_instant_settle_option']
  allocations_require_permission: boolean
  withdrawal_account_required_approvers: number
  withdrawal_request_required_approvers: number
  automatically_create_transfers_relationship: boolean
  withdrawal_address_approvers_threshold: number
}

interface ISettingsProps {
  disabled: boolean
  accountGroups: { value: string; label: string }[]
  isAccountGroupsBusy: boolean
  settings: ParticipantSettings
  onChange: (settings: ParticipantSettings) => void
  onAccountGroupsSearch: (searchString: string) => void
}

export default class Settings extends React.Component<ISettingsProps> {
  state = {
    withdrawal_request_required_approvers: this.props.settings
      .withdrawal_request_required_approvers,
    withdrawal_account_required_approvers: this.props.settings
      .withdrawal_account_required_approvers,
    withdrawal_address_approvers_threshold: this.props.settings
      .withdrawal_address_approvers_threshold
  }

  onChange = _.debounce((setting: keyof ParticipantSettings, value: any) => {
    const settings: ParticipantSettings = {
      ...this.props.settings,
      [setting]: value
    }

    this.props.onChange(settings)
  }, 800)

  renderWithdrawalAccountRequiredApprovers(offset = 0) {
    const withdrawalAccountRequiredApproversNum = +this.props.settings
      .withdrawal_account_required_approvers
    return (
      <strong>
        {Math.max(0, withdrawalAccountRequiredApproversNum + offset)}
      </strong>
    )
  }

  renderWithdrawalRequestRequiredApprovers(offset = 0) {
    const withdrawalRequestRequiredApproversNum = +this.props.settings
      .withdrawal_request_required_approvers
    return (
      <strong>
        {Math.max(0, withdrawalRequestRequiredApproversNum + offset)}
      </strong>
    )
  }

  render() {
    return (
      <Row>
        <Title level={2}>Settings</Title>
        <Row>
          <>
            <Row style={{ marginBottom: 24, width: '100%' }}>
              <Col xs={4}>Withdrawal Account Approvals:</Col>
              <Col xs={20}>
                {this.renderWithdrawalAccountRequiredApprovers()}{' '}
                administrator(s) are required to whitelist new withdrawal
                accounts. Note that this means that{' '}
                {this.renderWithdrawalAccountRequiredApprovers(-1)} approvals
                are required from other administrators to whitelist new
                withdrawal accounts. Reach out to{' '}
                <a
                  href="mailto:support@zerohash.com?subject=Requesting Change to Withdrawal Approval Settings"
                  target="_blank"
                >
                  support@zerohash.com
                </a>{' '}
                to change this setting.
              </Col>
            </Row>
            <Row style={{ marginBottom: 24, width: '100%' }}>
              <Col xs={4}>Withdrawal Request Approvals:</Col>
              <Col xs={20}>
                {this.renderWithdrawalRequestRequiredApprovers()}{' '}
                administrator(s) are required to process new withdrawal
                requests. Note that this means that{' '}
                {this.renderWithdrawalRequestRequiredApprovers(-1)} approvals
                are required from other administrators on new withdrawal
                requests. Reach out to{' '}
                <a
                  href="mailto:support@zerohash.com?subject=Requesting Change to Withdrawal Request Settings"
                  target="_blank"
                >
                  support@zerohash.com
                </a>{' '}
                to change this setting.
              </Col>
            </Row>
            <Row style={{ marginBottom: 24, width: '100%' }}>
              <Col xs={4}>Waiting Period for New Approved Addresses:</Col>
              <Col xs={20}>
                Represents the waiting period (number of days) before address
                will be fully whitelisted automatically.
                <Form.Item style={{ maxWidth: 100 }}>
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled={this.props.disabled}
                    value={this.state.withdrawal_address_approvers_threshold}
                    onChange={(value: number) => {
                      this.onChange(
                        'withdrawal_address_approvers_threshold',
                        value
                      )
                      this.setState((state) => ({
                        withdrawal_address_approvers_threshold: value
                      }))
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>

          <Row style={{ width: '100%' }}>
            <Col xs={4}>Primary Account Group:</Col>
            <Col xs={20}>
              <p>
                This is where deposits and withdrawals will be processed to and
                from, by default. It may be a specific platform you are
                connected to, or you may simply set this to UNALLOCATED.
                Unallocated balances are not assigned to any platform, and offer
                you the flexibility to allocate when and how you choose.
              </p>
              <Form.Item style={{ maxWidth: 400 }}>
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  disabled={this.props.disabled}
                  placeholder="Start typing to find Account Group"
                  defaultValue={
                    this.props.settings.primaryAccountGroup || 'UNALLOCATED'
                  }
                  onSearch={this.props.onAccountGroupsSearch}
                  onChange={(value: string) =>
                    this.onChange('primaryAccountGroup', value)
                  }
                  loading={this.props.isAccountGroupsBusy ?? false}
                  filterOption={() => true}
                >
                  {this.props.accountGroups.map((accountGroup) => (
                    <Option key={accountGroup.value} value={accountGroup.value}>
                      {accountGroup.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Row>
      </Row>
    )
  }
}
