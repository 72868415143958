import {
  ApplicationPayload,
  AuthorizedSigner,
  BeneficialOwner
} from '../../onboarding-application/participant'

export const getPeopleFromPayload = (
  payload: ApplicationPayload
): {
  authorized_signers: AuthorizedSigner[]
  beneficial_owners: BeneficialOwner[]
} => {
  if (payload.authorized_signers && payload.beneficial_owners) {
    const { authorized_signers, beneficial_owners } = payload
    return {
      authorized_signers,
      beneficial_owners
    }
  }
  return Object.keys(payload).reduce(
    (accum: any, key) => {
      if (
        key.startsWith('authorized_signer[') ||
        key.startsWith('beneficial_owner[')
      ) {
        const openSplit = key.split('[')

        const type = openSplit[0]
        const index = openSplit[1].split(']')[0]
        const property = openSplit[2].split(']')[0]
        // could use `suppressImplicitAnyIndexErrors: true`, but hopefully
        //   this can just be fixed with better typing
        const value = (payload as any)[key]

        // if this the first property for this index
        accum[`${type}s`][index] = { ...(accum[`${type}s`][index] || {}) }
        // assign the property/value
        accum[`${type}s`][index][property] = value
      }
      return accum
    },
    { authorized_signers: [], beneficial_owners: [] }
  )
}
