import {
  EntityType,
  ApplicationPayload
} from '../../onboarding-application/participant'

export interface ApplicationPropertyDef {
  label: string
  property: keyof ApplicationPayload
  entityTypes?: EntityType[]
  dataType?: 'map' | 'enum' | 'file'
}

const applicationPropertyLabels: ApplicationPropertyDef[] = [
  {
    label: 'Entity type',
    property: 'entity_type'
  },
  {
    label: 'Legal name of applicant',
    property: 'legal_name'
  },
  {
    label: 'Address Line 1',
    property: 'address_line1'
  },
  {
    label: 'Address Line 2',
    property: 'address_line2'
  },
  {
    label: 'City',
    property: 'address_city'
  },
  {
    label: 'State',
    property: 'address_state'
  },
  {
    label: 'Zip',
    property: 'address_zip'
  },
  {
    label: 'Country',
    property: 'address_country'
  },
  // if individual
  {
    label: 'Contact Email',
    property: 'email',
    entityTypes: ['individual']
  },
  {
    label: 'Occupation',
    property: 'ind_occupation',
    entityTypes: ['individual']
  },
  {
    label: 'SSN',
    property: 'tax_id_i',
    entityTypes: ['individual']
  },
  {
    label: 'Contact Email',
    property: 'email',
    entityTypes: ['individual']
  },
  {
    label: 'Contact Number',
    property: 'contact_number',
    entityTypes: ['individual']
  },

  {
    label: 'Date of Birth',
    property: 'ind_dob',
    entityTypes: ['individual']
  },
  {
    label: 'LEI',
    property: 'individual_lei',
    entityTypes: ['individual']
  },
  {
    label: 'Passport, State or National ID Number',
    property: 'ind_photo_id',
    entityTypes: ['individual']
  },
  {
    label: 'Passport Country (if non-US)',
    property: 'passport_country',
    entityTypes: ['individual']
  },
  // end if individual
  // if business
  {
    label: 'Business Website',
    property: 'org_website',
    entityTypes: ['business']
  },
  {
    label: 'Organization Type',
    property: 'org_type',
    entityTypes: ['business']
  },
  {
    label: 'State of Organization',
    property: 'org_state',
    entityTypes: ['business']
  },
  {
    label: 'Date Established',
    property: 'org_established',
    entityTypes: ['business']
  },
  {
    label: 'Employer Identification Number',
    property: 'tax_id_b',
    entityTypes: ['business']
  },
  {
    label: 'LEI',
    property: 'business_lei',
    entityTypes: ['business']
  },
  // end if business
  {
    label:
      'Has the Applicant, by any name, filed for bankruptcy within the past three (3) years?',
    property: 'bankruptcy'
  },
  {
    label: 'Year(s) of bankruptcy',
    property: 'bankruptcy_year'
  },
  {
    label: "Applicant's annual income",
    property: 'fin_income',
    dataType: 'enum'
  },
  {
    label: "Applicant's net assets",
    property: 'net_assets',
    dataType: 'enum'
  },
  {
    label: "Applicant's source of funds",
    property: 'source_of_funds'
  },
  {
    label:
      'Is the Applicant subject to any statutory disqualification under CEA Section 8(a)2 or 8(a)3?',
    property: 'legal_disqual'
  },
  {
    label:
      'Is the Applicant an Officer or employee of Seed SEF or one of its Affiliates?',
    property: 'legal_officer'
  },
  {
    label: 'Is the Applicant acting as a U.S. person under Dodd-Frank?',
    property: 'us_person_dodd'
  },
  {
    label: 'Is the Applicant a Swap Dealer, as defined in 17 CFR § 1.3?',
    property: 'swap_dealer'
  },
  {
    label:
      'Is the Applicant a Major Swap Participant, as defined in 17 CFR § 1.3?',
    property: 'major_swap_participant'
  },
  {
    label:
      'Is the Applicant a Financial Entity, as defined in CEA section 2(h)(7)(C)?',
    property: 'entity_dodd'
  },
  {
    label: 'Is the Applicant registered with NFA?',
    property: 'nfa'
  },
  {
    label: 'NFA ID',
    property: 'nfa_id'
  },
  {
    label: 'Is the Applicant registered with FINRA?',
    property: 'finra'
  },
  {
    label: 'CRD ID',
    property: 'CRD_id'
  },

  // these are objects, properties are enum values, vales are `true`
  {
    label: 'Eligible Contract Participant Qualifications',
    property: 'eligibility',
    dataType: 'map'
  },

  // documents
  {
    label: 'Passport Document (if non-US)',
    property: 'passport_document',
    entityTypes: ['individual'],
    dataType: 'file'
  },
  {
    label: 'Eligible Contract Participant Qualifications Proof',
    property: 'eligibility_proof',
    dataType: 'file'
  },
  {
    label: 'Proof of Address',
    property: 'proof_address',
    dataType: 'file'
  },
  {
    label: 'Photo ID',
    property: 'photo_id',
    entityTypes: ['individual'],
    dataType: 'file'
  },
  {
    label: 'Articles of Incorporation',
    property: 'articles_incorporation',
    entityTypes: ['business'],
    dataType: 'file'
  },
  {
    label: 'Certificate of Good Standing',
    property: 'cert_good_standing',
    entityTypes: ['business'],
    dataType: 'file'
  },
  {
    label: 'Corporate Bylaws',
    property: 'bylaws',
    entityTypes: ['business'],
    dataType: 'file'
  }
  // end if business
]

const propertyToLabelMap = applicationPropertyLabels.reduce(
  (accum: { [property: string]: string }, item) => {
    accum[item.property] = item.label
    return accum
  },
  {}
)

export { applicationPropertyLabels, propertyToLabelMap }
