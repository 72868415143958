import { createAction } from '@reduxjs/toolkit'
import {
  PARTICIPANT_LOAD,
  PARTICIPANT_LOAD_IS_PLATFORM_OPERATOR,
  PARTICIPANT_DETAILS_LOAD,
  PARTICIPANT_SELECT,
  ILoadParticipantDetailsPayload,
  ILoadParticipantPayload,
  ILoadPlatformOperatorsPayload
} from './types'

export const loadParticipant = createAction<ILoadParticipantPayload>(
  PARTICIPANT_LOAD
)

export const loadIsPlatformOperator = createAction<ILoadPlatformOperatorsPayload>(
  PARTICIPANT_LOAD_IS_PLATFORM_OPERATOR
)

export const loadParticipantDetails = createAction<ILoadParticipantDetailsPayload>(
  PARTICIPANT_DETAILS_LOAD
)

export const selectParticipant = createAction(PARTICIPANT_SELECT)
