import { useContext } from 'react'

import { FlagContext } from './FlagContext'

const useFlag = (name: string) => {
  const flagProviderContext = useContext(FlagContext)
  return !!flagProviderContext.toggles.find((flag) => flag.name === name)
}

export default useFlag
