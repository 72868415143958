import { Status } from '../state'

export const SIGNATURE_AUTH_USER_ROLE_GET = 'SIGNATURE/AUTH_USER_ROLE_GET'
export const SIGNATURE_AGREEMENTS_LOAD = 'SIGNATURE/AGREEMENTS_LOAD'
export const SIGNATURE_AGREEMENTS_LOAD_FAILURE =
  'SIGNATURE/AGREEMENTS_LOAD_FAILURE'
export const SIGNATURE_AGREEMENTS_LOAD_SUCCESS =
  'SIGNATURE/AGREEMENTS_LOAD_SUCCESS'
export const SIGNATURE_APPLICATION_LOAD = 'SIGNATURE/APPLICATION_LOAD'
export const SIGNATURE_APPLICATION_LOAD_SUCCESS =
  'SIGNATURE/APPLICATION_LOAD_SUCCESS'
export const SIGNATURE_APPLICATION_LOAD_FAILURE =
  'SIGNATURE/APPLICATION_LOAD_FAILURE'
export const SIGNATURE_SIGN = 'SIGNATURE/SIGN'
export const SIGNATURE_AGREEMENTS_SIGN_FAILURE =
  'SIGNATURE/AGREEMENTS_SIGN_FAILURE'
export const SIGNATURE_AGREEMENTS_SIGN_SUCCESS =
  'SIGNATURE/AGREEMENTS_SIGN_SUCCESS'
export const SIGNATURE_APPLICATION_SIGN_FAILURE =
  'SIGNATURE/APPLICATION_SIGN_FAILURE'
export const SIGNATURE_APPLICATION_SIGN_SUCCESS =
  'SIGNATURE/APPLICATION_SIGN_SUCCESS'
export const SIGNATURE_AGREEMENTS_VALIDATE = 'SIGNATURE/AGREEMENTS_VALIDATE'

export interface IGetAuthUserRolePayload {
  isAuthSigner: boolean
}

export interface ILoadAgreementsSuccessPayload {
  status: Status
}

export interface ILoadApplicationSuccessPayload {
  status: Status
}

export interface IValidateAgreementsFormPayload {
  isValid: boolean
}

export interface IGetAuthUserRoleAction {
  type: typeof SIGNATURE_AUTH_USER_ROLE_GET
  payload: IGetAuthUserRolePayload
}

export interface ILoadAgreementsSuccessAction {
  type: typeof SIGNATURE_AGREEMENTS_LOAD_SUCCESS
  payload: ILoadAgreementsSuccessPayload
}

export interface ILoadApplicationSuccessAction {
  type: typeof SIGNATURE_APPLICATION_LOAD_SUCCESS
  payload: ILoadApplicationSuccessPayload
}

export interface IValidateAgreementsFormAction {
  type: typeof SIGNATURE_AGREEMENTS_VALIDATE
  payload: IValidateAgreementsFormPayload
}
