import * as React from 'react'
import { personTypes } from './participant'
import RestService from '../RestService'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { WrappedFormUtils } from '@ant-design/compatible/es/form/Form'
import { ValidationRule } from '@ant-design/compatible/es/form'
import { ICountry } from './fetchCountries'
import { ISubdivision } from './fetchSubdivisions'
import { IParticipant } from 'seed-shared-components/lib/types'

export type CustomValidator = (rule: any, value: any, callback: Function) => any

export interface IOnboardingApplicationStepConfig {
  stepId: string
  label: string
  route: string
  items: AnyOnboardingApplicationItemConfig[]
  readonly?: boolean
  type: 'step'
}

export interface IOnboardingApplicationCustomStepConfig {
  stepId: string
  label: string
  route: string
  customForm: typeof React.Component
  items: AnyOnboardingApplicationItemConfig[]
  type: 'custom'
  readonly?: boolean // just to bail out of TS issue
}

export type OnboardingApplicationStepConfig =
  | IOnboardingApplicationStepConfig
  | IOnboardingApplicationCustomStepConfig

export type AnyOnboardingApplicationItemConfig =
  | IParagraphItemConfig
  | IOrderedListItemConfig
  | ICheckboxItemConfig
  | ITextInputItemConfig
  | IRadioGroupItemConfig
  | IDropdownItemConfig
  | ISelectItemConfig
  | IDocumentItemConfig
  | IListFormItemConfig
  | ICheckboxGroupItemConfig
  | IPasswordInputItemConfig
  | IButtonItemConfig
  | ICustomRequestDocumentItemConfig
  | ITitleItemConfig

export interface IOnboardingApplicationItemConfig {
  itemKey: string
  type:
    | 'paragraph'
    | 'orderedList'
    | 'text'
    | 'radio'
    | 'checkbox'
    | 'dropdown'
    | 'select'
    | 'document'
    | 'listform'
    | 'checkboxgroup'
    | 'password'
    | 'button'
    | 'document_new'
    | 'title'

  displayIf?: (
    answers: Object,
    group?: {
      index: number
      key?: string
    }
  ) => boolean
  extraInstructions?: string
  rules?: ValidationRule[]
  tooltip?: string | JSX.Element | undefined
  // use if the value coming from Ant needs to be
  transformValue?: (value: any) => any
  parentKey?: string
  index?: number
}

export interface IParagraphItemConfig extends IOnboardingApplicationItemConfig {
  type: 'paragraph'
  text: string
  label?: string | JSX.Element
}

export interface IOrderedListItemConfig
  extends IOnboardingApplicationItemConfig {
  type: 'orderedList'
  items: string[]
}

export interface ICheckboxItemConfig extends IOnboardingApplicationItemConfig {
  type: 'checkbox'
  label: string | JSX.Element | ((answers: any) => string)
}

export interface IRadioGroupItemConfig
  extends IOnboardingApplicationItemConfig {
  type: 'radio'
  orientation: 'vertical' | 'horizontal'
  label: string | JSX.Element
  rules?: ValidationRule[]
  options: {
    label: string
    value: any
  }[]
}

export interface ICheckboxGroupItemConfig
  extends IOnboardingApplicationItemConfig {
  type: 'checkboxgroup'
  label: string | JSX.Element
  rules?: ValidationRule[]
  children: (
    | {
        label: string | JSX.Element
        value: any
        type: 'option'
        displayIf?: (
          answers: Object,
          group?: {
            index: number
            key?: string
          }
        ) => boolean
        disabled?: boolean
        onChange?: (form: WrappedFormUtils) => (e: CheckboxChangeEvent) => void
      }
    | {
        label: string | JSX.Element
        type: 'label'
      }
  )[]
}

export interface IDropdownItemConfig extends IOnboardingApplicationItemConfig {
  type: 'dropdown'
  label: string | JSX.Element
  rules?: ValidationRule[]
  isState?: boolean
  options?: {
    label: string
    value: any
    disabled?: boolean
  }[]
  fetchOptions?: (
    restService: RestService,
    answers: any,
    itemKey: string,
    additional?: {
      [key: string]: any
    }
  ) => Promise<any>
  normalize?: (value: any, prevValue: any) => any
  additional?: { [key: string]: any }
}

export interface ISelectItemConfig extends IOnboardingApplicationItemConfig {
  type: 'select'
  label: string | JSX.Element
  rules?: ValidationRule[]
  isState?: boolean
  options?: {
    label: string
    value: any
    disabled?: boolean
  }[]
  fetchOptions?: (
    restService: RestService,
    answers: any,
    itemKey: string,
    additional?: {
      [key: string]: any
    }
  ) => Promise<any>
  normalize?: (value: any, prevValue: any) => any
  additional?: { [key: string]: any }
}
export interface IDocumentItemConfig extends IOnboardingApplicationItemConfig {
  type: 'document'
  label: string | JSX.Element | undefined
  rules?: ValidationRule[]
  minimumCount: (answers: any) => number
  restService?: RestService
}

export interface ITextInputItemConfig extends IOnboardingApplicationItemConfig {
  type: 'text'
  label: string | JSX.Element
  dateString?: boolean
  displayIf?: (answers: Object) => boolean
  rules?: ValidationRule[]
  mask?: string
  conditionallyRequired?: (answers: Object) => false | ValidationRule
}

export interface IListFormItemConfig extends IOnboardingApplicationItemConfig {
  type: 'listform'
  label: string | JSX.Element
  items: AnyOnboardingApplicationItemConfig[]
  fixed?: boolean
  minimumCount: (answers: any) => number
  initialCount: (answers: any) => number
  onAdd?: () => void
  onRemove?: (index: number) => void
}

export interface IPasswordInputItemConfig
  extends IOnboardingApplicationItemConfig {
  type: 'password'
  label: string | JSX.Element
  displayIf?: (answers: Object) => boolean
  rules?: ValidationRule[]
}

export interface IButtonItemConfig extends IOnboardingApplicationItemConfig {
  type: 'button'
  label?: string | JSX.Element
  onClick: () => void
  style: Object
}

export interface ICustomRequestDocumentItemConfig
  extends IOnboardingApplicationItemConfig {
  type: 'document_new'
  label: string | JSX.Element | undefined
  rules?: ValidationRule[]
  minimumCount: (answers: any) => number
  restService?: RestService
  customRequest?: () => void
}

export interface ITitleItemConfig extends IOnboardingApplicationItemConfig {
  type: 'title'
  level?: 1 | 2 | 3 | 4
  label?: string | JSX.Element
}

export const isValidURL = (s: string) => {
  if (!s) {
    return false
  }
  const res = s.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  )
  if (res == null) {
    return false
  } else {
    return true
  }
}

export const validateDropdown = (
  rule: { field: string },
  value: string,
  callback: (message?: string) => void
) => {
  if (!value) {
    callback()
    return
  }

  callback()
}

export const validateCountry = (value: string, countries: ICountry[]) => {
  if (!value) {
    return Promise.reject(new Error('Please select a country'))
  }
  if (!countries.find((c) => c.countryName === value)) {
    return Promise.reject(new Error('Please select a country from the list'))
  } else {
    return Promise.resolve()
  }
}

export const isValidDate = (s: string) => {
  if (!s) {
    return true
  }
  const splitDob = s.split('/')
  if (splitDob.length < 3) {
    return false
  }
  const m = splitDob[0]
  const d = splitDob[1]
  const y = splitDob[2]
  // validate each chunk's length
  if (m.length < 2 || d.length < 2 || y.length < 4) {
    return false
  }
  // validate each chunk is a number
  if (!Number(m) || !Number(d) || !Number(y)) {
    return false
  }
  // validate chunks are within proper bounds
  if (
    Number(m) > 12 ||
    Number(d) > 31 ||
    Number(y) > new Date().getFullYear()
  ) {
    return false
  }
  return true
}

const transformDateToParts = (dateString: string) => {
  if (!dateString) {
    return undefined
  }
  const dateParts = dateString.split('/')
  return {
    month: dateParts[0],
    day: dateParts[1],
    year: dateParts[2]
  }
}

export const isBusiness = (answers: any) => answers.entity_type === 'business'
export const isOrgType = (answers: any, type: any) => answers?.org_type === type
export const isIndividual = (answers: any) =>
  answers.entity_type === 'individual'
export const anyChecked = (itemKey: string, answers: any, values: string[]) =>
  answers[itemKey]
    ? Object.keys(answers[itemKey])
        .filter((k) => values.includes(k))
        .some((k) => answers[itemKey][k] === true)
    : false

export const isEntityTypeSelected = (answers: any) =>
  isIndividual(answers) || isBusiness(answers)

export function listFieldMatches<T>(
  itemKey: string,
  index: number,
  answers: any,
  value: T,
  parentKey: string
) {
  return (
    answers[parentKey] &&
    answers[parentKey][index] &&
    answers[parentKey][index][itemKey] === value
  )
}

// Match an itemKey within a checkboxGroup
function fieldContainsAndMatches<T>(
  groupKey: string,
  itemKeys: string[],
  index: number,
  answers: any,
  value: T,
  parentKey: string
) {
  return (
    answers[parentKey] &&
    answers[parentKey][index] &&
    answers[parentKey][index][groupKey] &&
    itemKeys.some(
      (itemKey) => answers[parentKey][index][groupKey][itemKey] === value
    )
  )
}

export enum IdNumberType {
  ID_NUMBER_TYPE_UNKNOWN = 0,
  ID_NUMBER_TYPE_SSN = 1,
  ID_NUMBER_TYPE_EIN = 2,
  ID_NUMBER_TYPE_US_DRIVERS_LICENSE = 3,
  ID_NUMBER_TYPE_US_PASSPORT = 4,
  ID_NUMBER_TYPE_US_PASSPORT_CARD = 5,
  ID_NUMBER_TYPE_US_PERMANENT_RESIDENT_CARD = 6,
  ID_NUMBER_TYPE_US_BORDER_CROSSING_CARD = 7,
  ID_NUMBER_TYPE_US_ALIEN_CARD = 8,
  ID_NUMBER_TYPE_US_ID_CARD = 9,
  ID_NUMBER_TYPE_NON_US_PASSPORT = 10,
  ID_NUMBER_TYPE_NON_US_OTHER = 11
}

export enum EntityType {
  ENTITY_TYPE_UNKNOWN = 0,
  ENTITY_TYPE_CORPORATION = 1,
  ENTITY_TYPE_LLC = 2,
  ENTITY_TYPE_PARTNERSHIP = 3,
  ENTITY_TYPE_SOLE_PROPRIETORSHIP = 4,
  ENTITY_TYPE_INDIVIDUAL = 5
}

export enum DocumentType {
  DOCUMENT_TYPE_UNKNOWN = 0,
  DOCUMENT_PROOF_OF_ADDRESS = 1,
  DOCUMENT_CERTIFICATE_OF_GOOD_STANDING = 2,
  DOCUMENT_ARTICLES_OF_INCORPORATION = 3,
  DOCUMENT_BY_LAWS = 4,
  DOCUMENT_US_DRIVERS_LICENSE = 5,
  DOCUMENT_US_PASSPORT = 6,
  DOCUMENT_US_PASSPORT_CARD = 7,
  DOCUMENT_US_PERMANENT_RESIDENT_CARD = 8,
  DOCUMENT_US_BORDER_CROSSING_CARD = 9,
  DOCUMENT_US_ALIEN_CARD = 10,
  DOCUMENT_US_ID_CARD = 11,
  DOCUMENT_NON_US_PASSPORT = 12,
  DOCUMENT_NON_US_OTHER = 13,
  DOCUMENT_SSN = 14,
  DOCUMENT_BUSINESS_LICENSE = 15,
  DOCUMENT_BUSINESS_NAME_FILLING_DOCUMENT = 16,
  DOCUMENT_PARTNERSHIP_AGREEMENT = 17,
  DOCUMENT_OPERATING_AGREEMENT = 18,
  DOCUMENT_ARTICLES_OF_ORGANIZATION = 19,
  DOCUMENT_CERTIFICATE_OF_ORGANIZATION = 20,
  DOCUMENT_CERTIFICATE_OF_INCORPORATION = 21,
  DOCUMENT_TYPE_LEGACY_DOCUMENT = 22
}

export enum BeneficialOwner {
  BENEFICIAL_OWNER_OVER_UNKNOWN = 0,
  BENEFICIAL_OWNER_OVER_10_PERCENT = 1,
  BENEFICIAL_OWNER_OVER_25_PERCENT = 2
}

const getApplicationSteps = async (
  countries: ICountry[],
  fetchSubdivisions: (
    restService: RestService,
    formAnswers: any,
    itemKey: string,
    additional: { [key: string]: any }
  ) => Promise<ISubdivision[]>,
  platforms: IParticipant[]
): Promise<OnboardingApplicationStepConfig[]> => {
  return [
    {
      stepId: 'directions',
      label: 'Directions',
      route: `directions`,
      readonly: true,
      type: 'step',
      items: [
        {
          itemKey: 'directions_1',
          text:
            'Thank you for your interest in becoming a Participant with Zero Hash!',
          type: 'paragraph'
        },
        {
          itemKey: 'directions_2',
          text:
            'Once the application is submitted, we will review the information provided, and may require additional information before approving or rejecting the application. While you wait for full approval, you can get started by adding users and generally get familiar with the system, but you will not be able to transact until the Applicant is approved.',
          type: 'paragraph'
        },
        {
          itemKey: 'directions_3',
          text:
            'We look forward to getting your application and having you become a Participant!',
          type: 'paragraph'
        },
        {
          itemKey: 'directions_4',
          text:
            'ATTENTION: Please make and retain a copy of this entire account application for your records.',
          type: 'paragraph'
        }
      ]
    },
    {
      stepId: 'applicant-information',
      label: 'Application Information',
      route: 'applicant_information',
      type: 'step',
      items: [
        {
          itemKey: 'participant_type_title',
          label: 'Participant Type',
          level: 2,
          type: 'title'
        },
        {
          itemKey: 'entity_type',
          label: 'What type of Participant are you?',
          type: 'radio',
          orientation: 'horizontal',
          options: [
            { label: 'Individual', value: 'individual' },
            { label: 'Business', value: 'business' }
          ],
          rules: [
            { required: true, message: 'Please choose a participant type' }
          ]
        },
        {
          itemKey: 'business_information_title',
          label: 'Business Information',
          level: 2,
          type: 'title',
          displayIf: isBusiness
        },
        {
          itemKey: 'org_legal_name',
          label: 'Business Name',
          type: 'text',
          displayIf: isBusiness,
          rules: [
            {
              required: true,
              max: 50,
              whitespace: true,
              message: 'Please enter a business name'
            }
          ]
        },
        {
          itemKey: 'org_type',
          label: 'Entity Type',
          type: 'radio',
          orientation: 'horizontal',
          displayIf: isBusiness,
          options: [
            {
              label: 'Sole Proprietorship',
              value: EntityType.ENTITY_TYPE_SOLE_PROPRIETORSHIP
            },
            { label: 'Partnership', value: EntityType.ENTITY_TYPE_PARTNERSHIP },
            {
              label: 'Limited Liability Company',
              value: EntityType.ENTITY_TYPE_LLC
            },
            { label: 'Corporation', value: EntityType.ENTITY_TYPE_CORPORATION }
          ],
          rules: [{ required: true, message: 'Please choose an entity type' }]
        },
        {
          itemKey: 'org_contact_number',
          label: 'Contact Number',
          type: 'text',
          rules: [
            {
              required: true,
              message: 'Please enter a phone number',
              whitespace: true
            }
          ],
          displayIf: isEntityTypeSelected && isBusiness
        },
        {
          itemKey: 'org_address_line1',
          label: 'Address Line 1',
          extraInstructions: 'P.O. Boxes are not permitted',
          type: 'text',
          rules: [
            {
              required: true,
              message: 'Please enter an address',
              whitespace: true
            },
            { min: 5, message: 'Please enter at least 5 characters' }
          ],
          displayIf: isBusiness
        },
        {
          itemKey: 'org_address_line2',
          label: 'Address Line 2',
          type: 'text',
          displayIf: isBusiness
        },
        {
          itemKey: 'org_address_city',
          label: 'City',
          type: 'text',
          rules: [
            { required: true, message: 'Please enter a city', whitespace: true }
          ],
          displayIf: isBusiness
        },
        {
          itemKey: 'org_address_country',
          label: 'Country',
          type: 'dropdown',
          options: countries.map((s) => ({
            label: s.countryName,
            value: s.countryName
          })),
          rules: [
            {
              required: true,
              validator: (_, value) => validateCountry(value, countries)
            }
          ],
          displayIf: isBusiness
        },
        {
          itemKey: 'org_address_state',
          label: 'State',
          type: 'select',
          isState: true,
          fetchOptions: fetchSubdivisions,
          additional: {
            countries: countries,
            countryDropdownItemKey: 'org_address_country'
          },
          rules: [
            { required: true, message: 'Please select a state' },
            { validator: validateDropdown }
          ],
          displayIf: isBusiness,
          options: []
        },
        {
          itemKey: 'org_address_zip',
          label: 'Zip',
          type: 'text',
          rules: [
            {
              required: true,
              message: 'Please enter a zip code',
              whitespace: true
            }
          ],
          displayIf: isBusiness
        },
        {
          itemKey: 'org_website',
          label: 'Business Website',
          type: 'text',
          displayIf: isBusiness,
          rules: [
            { required: true, message: 'Please enter a website' },
            {
              validator: (rule, value, callback) =>
                !value || isValidURL(value)
                  ? callback()
                  : callback('Please enter a valid URL')
            }
          ]
        },
        {
          itemKey: 'org_date_established',
          dateString: true,
          label: 'Date Established (MM/DD/YYYY)',
          type: 'text',
          mask: '99/99/9999',
          displayIf: isBusiness,
          rules: [
            { required: true, message: 'Please enter an established date' },
            {
              validator: (rule, value, callback) =>
                isValidDate(value)
                  ? callback()
                  : callback('Please enter a valid date')
            }
          ]
        },
        {
          itemKey: 'org_ein',
          label: 'EIN (Employee Identification Number)',
          type: 'password',
          rules: [
            {
              required: true,
              message:
                'Please enter the Employer Identification Number of the applicant',
              whitespace: true
            },
            { min: 9, message: 'Please enter at least 9 characters' }
          ],
          displayIf: (answers: any) =>
            isBusiness(answers) &&
            answers.org_address_country === 'United States of America'
        },
        {
          itemKey: 'org_non_usa_entity_tax_id',
          label: 'Non USA Entity Tax ID Number',
          type: 'password',
          rules: [
            {
              required: true,
              message:
                'Please enter the entity tax identification number of given entity country',
              whitespace: true
            },
            { min: 9, message: 'Please enter at least 9 characters' }
          ],
          displayIf: (answers: any) =>
            isBusiness(answers) &&
            answers.org_address_country &&
            answers.org_address_country !== 'United States of America'
        },
        {
          itemKey: 'org_has_beneficial_owner',
          label: 'Does this entity have at least 1 beneficial owner?',
          type: 'radio',
          orientation: 'horizontal',
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ],
          rules: [{ required: true, message: 'Please choose an option' }],
          displayIf: isBusiness,
          extraInstructions:
            'This is defined as a single person owning more than 10% of the business'
        },
        {
          itemKey: 'business_documents_title',
          label: 'Business Documents',
          level: 2,
          type: 'title',
          displayIf: isBusiness
        },
        {
          itemKey: 'business_license',
          label: 'Business License',
          displayIf: (answers) =>
            isBusiness(answers) &&
            isOrgType(answers, EntityType.ENTITY_TYPE_SOLE_PROPRIETORSHIP),
          type: 'document_new',
          minimumCount: () => 1,
          rules: [
            { required: true, message: 'Please upload a business license' }
          ]
        },
        {
          itemKey: 'business_name_filing_document',
          label: 'Business Name Filing',
          displayIf: (answers) =>
            isBusiness(answers) &&
            isOrgType(answers, EntityType.ENTITY_TYPE_SOLE_PROPRIETORSHIP),
          type: 'document_new',
          minimumCount: () => 1,
          rules: [
            { required: true, message: 'Please upload a business name filing' }
          ]
        },
        {
          itemKey: 'partnership_agreement',
          label: 'Partnership Agreement',
          displayIf: (answers) =>
            isBusiness(answers) &&
            isOrgType(answers, EntityType.ENTITY_TYPE_PARTNERSHIP),
          type: 'document_new',
          minimumCount: () => 1,
          rules: [
            { required: true, message: 'Please upload a partnership agreement' }
          ]
        },
        {
          itemKey: 'operating_agreement',
          label: 'Operating Agreement',
          displayIf: (answers) =>
            isBusiness(answers) &&
            isOrgType(answers, EntityType.ENTITY_TYPE_LLC),
          type: 'document_new',
          minimumCount: () => 1,
          rules: [
            { required: true, message: 'Please upload a operating agreement' }
          ]
        },
        {
          itemKey: 'articles_of_organization',
          label: 'Articles of Organization',
          displayIf: (answers) =>
            isBusiness(answers) &&
            isOrgType(answers, EntityType.ENTITY_TYPE_LLC),
          type: 'document_new',
          minimumCount: () => 1,
          rules: [
            {
              required: true,
              message: 'Please upload the articles of organization'
            }
          ]
        },
        {
          itemKey: 'certificate_of_organization',
          label: 'Certificate of Organization',
          displayIf: (answers) =>
            isBusiness(answers) &&
            isOrgType(answers, EntityType.ENTITY_TYPE_LLC),
          type: 'document_new',
          minimumCount: () => 1,
          rules: [
            {
              required: true,
              message: 'Please upload a certificate of organization'
            }
          ]
        },
        {
          itemKey: 'certificate_of_incorporation',
          label: 'Certificate of Incorporation',
          displayIf: (answers) =>
            isBusiness(answers) &&
            isOrgType(answers, EntityType.ENTITY_TYPE_CORPORATION),
          type: 'document_new',
          minimumCount: () => 1,
          rules: [
            {
              required: true,
              message: 'Please upload a certificate of incorporation'
            }
          ]
        },
        {
          itemKey: 'articles_of_incorporation',
          label: 'Articles of Incorporation',
          displayIf: (answers) =>
            isBusiness(answers) &&
            isOrgType(answers, EntityType.ENTITY_TYPE_CORPORATION),
          type: 'document_new',
          minimumCount: () => 1,
          rules: [
            {
              required: true,
              message: 'Please upload the articles of incorporation'
            }
          ]
        },
        {
          itemKey: 'corporate_bylaws',
          label: 'Corporate Bylaws',
          displayIf: (answers) =>
            isBusiness(answers) &&
            isOrgType(answers, EntityType.ENTITY_TYPE_CORPORATION),
          type: 'document_new',
          minimumCount: () => 1,
          rules: [
            {
              required: true,
              message: 'Please upload the corporate bylaws'
            }
          ]
        },
        {
          itemKey: 'org_proof_address',
          label: 'Proof of Address',
          type: 'document_new',
          minimumCount: () => 1,
          rules: [
            { required: true, message: 'Please upload a proof of address' }
          ],
          displayIf: isBusiness,
          tooltip:
            'Please provide proof of address (acceptable forms of proof may be a bank statement or utility bill in the name of the applicant entity)'
        },
        {
          itemKey: 'applicant_information_title',
          label: 'Applicant Information',
          level: 2,
          type: 'title',
          displayIf: isEntityTypeSelected
        },
        {
          itemKey: 'applicant_name',
          label: 'Applicant Name',
          type: 'text',
          rules: [
            {
              required: true,
              max: 50,
              whitespace: true,
              message: 'Please enter a name'
            }
          ],
          displayIf: isEntityTypeSelected
        },
        {
          itemKey: 'applicant_email',
          label: 'Email',
          type: 'text',
          rules: [
            { required: true, message: 'Please enter an email' },
            { type: 'email', message: 'Please enter a valid email address' }
          ],
          displayIf: isEntityTypeSelected
        },
        {
          itemKey: 'applicant_dob',
          dateString: true,
          label: 'Date of Birth (MM/DD/YYYY)',
          type: 'text',
          mask: '99/99/9999',
          rules: [
            { required: true, message: 'Please enter a date of birth' },
            {
              validator: (rule, value, callback) =>
                isValidDate(value)
                  ? callback()
                  : callback('Please enter a valid date')
            }
          ],
          displayIf: isEntityTypeSelected
        },
        {
          itemKey: 'applicant_contact_number',
          label: 'Contact Number',
          type: 'text',
          rules: [
            {
              required: true,
              message: 'Please enter a phone number',
              whitespace: true
            }
          ],
          displayIf: isEntityTypeSelected
        },
        {
          itemKey: 'applicant_address_line1',
          label: 'Address Line 1',
          extraInstructions: 'P.O. boxes are not permitted',
          type: 'text',
          rules: [
            {
              required: true,
              message: 'Please enter an address',
              whitespace: true
            },
            { min: 5, message: 'Please enter at least 5 characters' }
          ],
          displayIf: isEntityTypeSelected
        },
        {
          itemKey: 'applicant_address_line2',
          label: 'Address Line 2',
          type: 'text',
          displayIf: isEntityTypeSelected
        },
        {
          itemKey: 'applicant_address_city',
          label: 'City',
          type: 'text',
          rules: [
            { required: true, message: 'Please enter a city', whitespace: true }
          ],
          displayIf: isEntityTypeSelected
        },
        {
          itemKey: 'applicant_address_country',
          label: 'Country',
          type: 'dropdown',
          options: countries.map((s) => ({
            label: s.countryName,
            value: s.countryName
          })),
          rules: [
            {
              required: true,
              validator: (_, value) => validateCountry(value, countries)
            }
          ],
          displayIf: isEntityTypeSelected
        },
        {
          itemKey: 'applicant_address_state',
          label: 'State',
          type: 'select',
          isState: true,
          fetchOptions: fetchSubdivisions,
          additional: {
            countries: countries,
            countryDropdownItemKey: 'applicant_address_country'
          },
          rules: [
            { required: true, message: 'Please select a state' },
            { validator: validateDropdown }
          ],
          displayIf: isEntityTypeSelected
        },
        {
          itemKey: 'applicant_address_zip',
          label: 'Zip',
          type: 'text',
          rules: [
            {
              required: true,
              message: 'Please enter a zip code',
              whitespace: true
            }
          ],
          displayIf: isEntityTypeSelected
        },
        {
          itemKey: 'applicant_has_ssn',
          label: <span>Does this person have a United States SSN?</span>,
          type: 'radio',
          orientation: 'horizontal',
          rules: [{ required: true, message: 'Please choose an option' }],
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ],
          displayIf: isEntityTypeSelected
        },
        {
          itemKey: 'applicant_ssn',
          label: 'United States SSN',
          type: 'password',
          rules: [
            {
              required: true,
              message:
                'Please enter the US Social Security Number of the individual',
              whitespace: true
            },
            { min: 9, message: 'Please enter at least 9 characters' }
          ],
          displayIf: (answers: any) =>
            isEntityTypeSelected(answers) && answers.applicant_has_ssn === true
        },
        {
          itemKey: 'applicant_citizenship',
          label: 'Citizenship',
          type: 'dropdown',
          options: countries.map((s) => ({
            label: s.countryName,
            value: s.countryName
          })),
          rules: [
            {
              required: true,
              validator: (_, value) => validateCountry(value, countries)
            }
          ],
          displayIf: (answers: any) =>
            isEntityTypeSelected(answers) && answers.applicant_has_ssn === false
        },
        {
          itemKey: 'applicant_non_usa_tax_id_number',
          label: 'Non USA Tax ID Number',
          type: 'password',
          displayIf: (answers: any) => answers.applicant_has_ssn === false,
          rules: [
            {
              required: true,
              message: 'Please enter the Passport number of the individual.',
              whitespace: true
            },
            { min: 8, message: 'Please enter at least 8 characters' }
          ]
        },
        {
          itemKey: 'applicant_non_usa_tax_id_country',
          label: 'Non USA Tax ID Country',
          type: 'dropdown',
          options: countries.map((s) => ({
            label: s.countryName,
            value: s.countryName
          })),
          displayIf: (answers: any) =>
            isEntityTypeSelected(answers) &&
            answers.applicant_has_ssn === false,
          rules: [
            {
              required: true,
              validator: (_, value) => validateCountry(value, countries)
            }
          ]
        },
        {
          itemKey: 'applicant_non_usa_tax_id_document',
          label: 'Non USA Tax ID Document',
          type: 'document_new',
          minimumCount: () => 1,
          displayIf: (answers: any) =>
            isEntityTypeSelected(answers) &&
            answers.applicant_has_ssn === false,
          rules: [{ required: true, message: 'Please upload a document' }]
        },
        {
          itemKey: 'beneficial_owner',
          label: 'Beneficial Owner',
          type: 'radio',
          orientation: 'horizontal',
          displayIf: (answers: any) =>
            isBusiness(answers) && answers.org_has_beneficial_owner === true,
          options: [
            {
              label: 'More than 10%',
              value: BeneficialOwner.BENEFICIAL_OWNER_OVER_10_PERCENT
            },
            {
              label: 'More than 25%',
              value: BeneficialOwner.BENEFICIAL_OWNER_OVER_25_PERCENT
            },
            {
              label: 'Not Applicable',
              value: BeneficialOwner.BENEFICIAL_OWNER_OVER_UNKNOWN
            }
          ]
        },
        {
          itemKey: 'applicant_proof_address',
          label: 'Proof of Address',
          type: 'document_new',
          minimumCount: () => 1,
          rules: [{ required: true, message: 'Please upload a document' }],
          tooltip:
            'Please provide proof of address (acceptable forms of proof may be a bank statement or utility bill in the name of the applicant entity).',
          displayIf: (answers: any) => isEntityTypeSelected(answers)
        },
        {
          itemKey: 'persons_title',
          label: 'Persons',
          level: 2,
          type: 'title',
          displayIf: isBusiness
        },
        {
          itemKey: 'persons',
          type: 'listform',
          label: 'Person',
          minimumCount: () => 1,
          initialCount: () => 1,
          displayIf: isBusiness,
          items: [
            {
              itemKey: 'name',
              label: 'Name',
              type: 'text',
              rules: [
                {
                  required: true,
                  max: 50,
                  whitespace: true,
                  message: 'Please enter a name'
                }
              ],
              displayIf: isEntityTypeSelected
            },
            {
              itemKey: 'email',
              label: 'Email',
              type: 'text',
              rules: [
                { required: true, message: 'Please enter an email' },
                { type: 'email', message: 'Please enter a valid email address' }
              ],
              displayIf: isEntityTypeSelected
            },
            {
              itemKey: 'dob',
              dateString: true,
              label: 'Date of Birth (MM/DD/YYYY)',
              type: 'text',
              mask: '99/99/9999',
              rules: [
                { required: true, message: 'Please enter a date of birth' },
                {
                  validator: (rule, value, callback) =>
                    isValidDate(value)
                      ? callback()
                      : callback('Please enter a valid date')
                }
              ],
              displayIf: isEntityTypeSelected
            },
            {
              itemKey: 'contact_number',
              label: 'Contact Number',
              type: 'text',
              rules: [
                {
                  required: true,
                  message: 'Please enter a phone number',
                  whitespace: true
                }
              ],
              displayIf: isEntityTypeSelected
            },
            {
              itemKey: 'address_line1',
              label: 'Address Line 1',
              extraInstructions: 'P.O. boxes are not permitted',
              type: 'text',
              rules: [
                {
                  required: true,
                  message: 'Please enter an address',
                  whitespace: true
                },
                { min: 5, message: 'Please enter at least 5 characters' }
              ],
              displayIf: isEntityTypeSelected
            },
            {
              itemKey: 'address_line2',
              label: 'Address Line 2',
              type: 'text',
              displayIf: isEntityTypeSelected
            },
            {
              itemKey: 'address_city',
              label: 'City',
              type: 'text',
              rules: [
                {
                  required: true,
                  message: 'Please enter a city',
                  whitespace: true
                }
              ],
              displayIf: isEntityTypeSelected
            },
            {
              itemKey: 'address_country',
              label: 'Country',
              type: 'dropdown',
              options: countries.map((s) => ({
                label: s.countryName,
                value: s.countryName
              })),
              rules: [
                {
                  required: true,
                  validator: (_, value) => validateCountry(value, countries)
                }
              ],
              displayIf: isEntityTypeSelected
            },
            {
              itemKey: 'address_state',
              isState: true,
              label: 'State',
              type: 'select',
              additional: {
                countries: countries,
                countryDropdownItemKey: 'address_country'
              },
              fetchOptions: fetchSubdivisions,
              rules: [
                { required: true, message: 'Please select a state' },
                { validator: validateDropdown }
              ],
              displayIf: isEntityTypeSelected
            },
            {
              itemKey: 'address_zip',
              label: 'Zip',
              type: 'text',
              rules: [
                {
                  required: true,
                  message: 'Please enter a zip code',
                  whitespace: true
                }
              ],
              displayIf: isEntityTypeSelected
            },
            {
              itemKey: 'has_ssn',
              label: <span>Does this person have a United States SSN?</span>,
              type: 'radio',
              orientation: 'horizontal',
              rules: [{ required: true, message: 'Please choose an option' }],
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ],
              displayIf: isEntityTypeSelected
            },
            {
              itemKey: 'ssn',
              label: 'United States SSN',
              type: 'password',
              rules: [
                {
                  required: true,
                  message:
                    'Please enter the US Social Security Number of the individual',
                  whitespace: true
                },
                { min: 9, message: 'Please enter at least 9 characters' }
              ],
              displayIf: (
                answers: any,
                group?: {
                  index: number
                  key: string
                }
              ) => {
                if (group) {
                  return listFieldMatches(
                    'has_ssn',
                    group.index,
                    answers,
                    true,
                    group.key
                  )
                }
                return true
              }
            },
            {
              itemKey: 'non_usa_tax_id_number',
              label: 'Non USA Tax ID Number',
              type: 'password',
              rules: [
                {
                  required: true,
                  message:
                    'Please enter the Passport number of the individual.',
                  whitespace: true
                },
                { min: 8, message: 'Please enter at least 8 characters' }
              ],
              displayIf: (
                answers: any,
                group?: {
                  index: number
                  key: string
                }
              ) => {
                if (group) {
                  return listFieldMatches(
                    'has_ssn',
                    group.index,
                    answers,
                    false,
                    group.key
                  )
                }
                return true
              }
            },
            {
              itemKey: 'non_usa_tax_id_country',
              label: 'Non USA Tax ID Country',
              type: 'dropdown',
              options: countries.map((s) => ({
                label: s.countryName,
                value: s.countryName
              })),
              rules: [
                {
                  required: true,
                  validator: (_, value) => validateCountry(value, countries)
                }
              ],
              displayIf: (
                answers: any,
                group?: {
                  index: number
                  key: string
                }
              ) => {
                if (group) {
                  return listFieldMatches(
                    'has_ssn',
                    group.index,
                    answers,
                    false,
                    group.key
                  )
                }
                return true
              }
            },
            {
              itemKey: 'non_usa_tax_id_document',
              label: 'Non USA Tax ID Document',
              type: 'document_new',
              minimumCount: () => 1,
              rules: [{ required: true, message: 'Please upload a document' }],
              displayIf: (
                answers: any,
                group?: {
                  index: number
                  key: string
                }
              ) => {
                if (group) {
                  return listFieldMatches(
                    'has_ssn',
                    group.index,
                    answers,
                    false,
                    group.key
                  )
                }
                return true
              }
            },
            {
              itemKey: 'type',
              label: 'What roles will this person have, as defined above?',
              type: 'checkboxgroup',
              children: [
                {
                  label: personTypes.beneficial_owner,
                  value: personTypes.beneficial_owner,
                  type: 'option'
                },
                {
                  label: personTypes.control_person,
                  value: personTypes.control_person,
                  type: 'option'
                }
              ]
            },
            {
              itemKey: 'beneficial_owner',
              label: 'Beneficial Owner',
              type: 'radio',
              orientation: 'horizontal',
              displayIf: (answers: any) =>
                isBusiness(answers) &&
                answers.org_has_beneficial_owner === true,
              options: [
                {
                  label: 'More than 10%',
                  value: BeneficialOwner.BENEFICIAL_OWNER_OVER_10_PERCENT
                },
                {
                  label: 'More than 25%',
                  value: BeneficialOwner.BENEFICIAL_OWNER_OVER_25_PERCENT
                },
                {
                  label: 'Not Applicable',
                  value: BeneficialOwner.BENEFICIAL_OWNER_OVER_UNKNOWN
                }
              ]
            },
            {
              itemKey: 'title',
              label: 'Title',
              type: 'text',
              rules: [
                {
                  required: true,
                  message: 'Please enter a title',
                  whitespace: true
                }
              ]
            },
            {
              itemKey: 'proof_address',
              label: 'Proof of Address',
              type: 'document_new',
              minimumCount: () => 1,
              rules: [{ required: true, message: 'Please upload a document' }],
              tooltip:
                'Please provide proof of address (acceptable forms of proof may be a bank statement or utility bill in the name of the applicant entity).'
            }
          ]
        },
        {
          itemKey: 'platform_code',
          label: 'Platform',
          type: 'dropdown',
          options: platforms.map((p) => ({
            label: p.name + ' (' + p.code + ')',
            value: p.code
          })),
          displayIf: isIndividual
        },
        {
          itemKey: 'review_head',
          type: 'paragraph',
          text:
            'Please review and confirm all information provided in the application before submitting.',
          displayIf: isEntityTypeSelected
        }
      ]
    }
  ]
}

export default getApplicationSteps
