import { IWithdrawalState } from './state'
import { createReducer } from '@reduxjs/toolkit'
import {
  IGetWithdrawalAccountsSuccessAction,
  IGetWithdrawalsSuccessAction,
  IGetPlatformTransfersSuccessAction,
  IFetchWithdrawalRequestHistoryAction,
  IFetchWithdrawalRequestHistoryFulfillActionSuccess,
  IFetchWithdrawalRequestHistoryFulfillActionFail
} from './actions/types'
import * as WithdrawalActions from './actions'

const withdrawalInitialState: IWithdrawalState = {
  accounts: [],
  withdrawals: [],
  withdrawalRequestsHistory: {},
  platformTransfers: []
}

const withdrawal = createReducer(withdrawalInitialState, {
  [WithdrawalActions.getAccountsSuccess.type]: (
    state,
    action: IGetWithdrawalAccountsSuccessAction
  ) => ({
    ...state,
    accounts: action.payload.accounts
  }),
  [WithdrawalActions.getWithdrawalsSuccess.type]: (
    state,
    action: IGetWithdrawalsSuccessAction
  ) => ({
    ...state,
    withdrawals: action.payload.withdrawals
  }),
  [WithdrawalActions.getplatformTransfersSuccess.type]: (
    state,
    action: IGetPlatformTransfersSuccessAction
  ) => ({
    ...state,
    platformTransfers: action.payload.platformTransfers
  }),
  [WithdrawalActions.fetchWithdrawalRequestHistory.type]: (
    state,
    action: IFetchWithdrawalRequestHistoryAction
  ) => {
    return {
      ...state,
      withdrawalRequestsHistory: {
        ...state.withdrawalRequestsHistory,
        [action.payload.withdrawalRequestId]: {
          loading: true,
          result: []
        }
      }
    }
  },
  [WithdrawalActions.fetchWithdrawalRequestHistoryFulfill.type]: (
    state,
    action:
      | IFetchWithdrawalRequestHistoryFulfillActionSuccess
      | IFetchWithdrawalRequestHistoryFulfillActionFail
  ) => {
    if (action.error) {
      const {
        meta: { withdrawalRequestId }
      } = action as IFetchWithdrawalRequestHistoryFulfillActionFail

      return {
        ...state,
        withdrawalRequestsHistory: {
          ...state.withdrawalRequestsHistory,
          [withdrawalRequestId]: {
            loading: false,
            result: []
          }
        }
      }
    } else {
      const {
        payload: history,
        meta: { withdrawalRequestId }
      } = action as IFetchWithdrawalRequestHistoryFulfillActionSuccess

      return {
        ...state,
        withdrawalRequestsHistory: {
          ...state.withdrawalRequestsHistory,
          [withdrawalRequestId]: {
            loading: false,
            result: history
          }
        }
      }
    }
  }
})

export default withdrawal
