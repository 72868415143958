import { put, takeEvery, call, select, all } from 'typed-redux-saga'
import { notification } from 'antd'
import * as appSelectors from '../selectors'
import {
  IFetchReportAction,
  IFetchReportsAction,
  IFetchAccountLabelsAction,
  IGenerateReportAction
} from './types'
import * as actions from './actions'
import * as api from './api'
import { fetchAccountLabels } from './api'

export function* fetchReportsSaga(action: IFetchReportsAction) {
  const {
    payload: { participantCode }
  } = action
  const { token } = yield select(appSelectors.getUser)

  try {
    const reports = yield call(api.fetchReports, {
      token,
      participantCode
    })

    yield put(
      actions.fetchReportsFulfill({
        error: false,
        payload: reports
      })
    )
  } catch (error) {
    yield put(
      actions.fetchReportsFulfill({
        error: true,
        payload: error
      })
    )
  }
}

export function* fetchReportsAccountLabelsSaga(
  action: IFetchAccountLabelsAction
) {
  const {
    payload: { participantCode }
  } = action
  const { token } = yield select(appSelectors.getUser)

  try {
    const reports = yield call(api.fetchAccountLabels, {
      token,
      participantCode
    })

    yield put(
      actions.fetchAccountLabelsFulfill({
        error: false,
        payload: reports
      })
    )
  } catch (error) {
    yield put(
      actions.fetchAccountLabelsFulfill({
        error: true,
        payload: error
      })
    )
  }
}

export function* fetchReportSaga(action: IFetchReportAction) {
  const {
    payload: { participantCode, type, id }
  } = action
  const { token } = yield select(appSelectors.getUser)

  try {
    const report = yield call(api.fetchReport, {
      token,
      participantCode,
      type,
      id
    })

    yield put(
      actions.fetchReportFulfill({
        error: false,
        payload: report
      })
    )
  } catch (error) {
    yield put(
      actions.fetchReportFulfill({
        error: true,
        payload: error
      })
    )
  }
}

export function* generateReportSaga(action: IGenerateReportAction) {
  const {
    payload: { type, target, participant, from, to, timestamp, accountLabel }
  } = action
  const { token } = yield select(appSelectors.getUser)

  try {
    const { id: reportId } = yield call(api.generateReport, {
      token,
      participantCode: participant,
      reportParams: {
        type,
        participant: target === 'participant' ? participant : undefined,
        platform: target === 'platform' ? participant : undefined,
        from,
        to,
        timestamp,
        account_label: accountLabel
      }
    })

    yield put(
      actions.generateReportFulfill({
        error: false
      })
    )

    notification.success({
      duration: 10,
      message: `Your report is being generated. You’ll be notified shortly when it is ready for download.`,
      description: `Request ID: ${reportId}`
    })

    // refresh reports after successful generate
    yield put(actions.fetchReports({ participantCode: participant }))
  } catch (error) {
    yield put(
      actions.generateReportFulfill({
        error: true,
        payload: error
      })
    )
  }
}

export function* watchReportsSagas() {
  yield all([
    takeEvery(actions.fetchReport.type, fetchReportSaga),
    takeEvery(actions.fetchReports.type, fetchReportsSaga),
    takeEvery(actions.fetchAccountLabels.type, fetchReportsAccountLabelsSaga),
    takeEvery(actions.generateReport.type, generateReportSaga)
  ])
}
