export default (input): boolean => {
  if (typeof input === 'undefined') {
    return true
  }

  const parsedInput = `${input}`

  if (parsedInput.length === 0) {
    return true
  }

  return !(
    /^[a-z|0-9-|_|:]+$/i.test(parsedInput) === false ||
    /^\s+$/.test(parsedInput) ||
    parsedInput.startsWith('_') ||
    parsedInput.endsWith('_') ||
    parsedInput.startsWith(':') ||
    parsedInput.endsWith(':') ||
    parsedInput.startsWith('-') ||
    parsedInput.endsWith('-') ||
    parsedInput.length > 40
  )
}
