import * as React from 'react'
import { Table } from 'antd'
import { connect } from 'react-redux'

import { IWithParticipantComponentProps } from '../../components/participant/WithParticipant'
import { numberFormatter } from 'seed-shared-components/lib/utils/CurrencyFormatter'
import Title from 'seed-shared-components/lib/components/Title'
import { DEFAULT_PAGE_SIZE_OPTIONS } from '../../constants'
import dateFormatter from '../../utils/dateFormatter'
import DownloadData from '../components/DownloadData'
import { movementTypeRender } from '../../funds/history/movements'

import * as actions from '../../store/platform-balances/actions'
import * as selectors from '../../store/platform-balances/selectors'

const mapStateToProps = (state) => ({
  movements: selectors.getMovements(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchMovements: ({ accountId, version, page, pageSize }) =>
    dispatch(actions.fetchMovements({ accountId, version, page, pageSize }))
})

type PlatformAccountProps = IWithParticipantComponentProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

function PlatformAccount(props: PlatformAccountProps) {
  const accountId = (props.match.params as any).accountId
  const asset = (props.match.params as any).asset
  const version = (props.match.params as any).version

  const movements = props.movements

  React.useEffect(() => {
    props.fetchMovements({
      accountId,
      version,
      page: movements.value.page,
      pageSize: movements.value.pageSize
    })
  }, [accountId, version])

  const handleTableChange = React.useCallback(
    ({ current: page, pageSize }) => {
      props.fetchMovements({
        accountId,
        version,
        page: Math.max(0, page - 1),
        pageSize
      })
    },
    [accountId, version]
  )

  const TABLE_COLUMNS = [
    {
      render: (item) => dateFormatter(item.settleTimestamp),
      title: 'Settlement Time'
    },
    {
      render: (item) => renderMovementType(item.stepType),
      title: 'Description'
    },
    {
      render: (item) => renderAmount(asset, item.change),
      title: 'Change'
    },
    {
      render: (item) => renderAmount(asset, item.amount),
      title: 'Balance'
    }
  ]

  return (
    <div>
      <div className="space-between">
        <Title level={2}>Movements</Title>
        <div>
          <DownloadData
            disabled={movements.loading}
            onData={() => movements.value}
            description={`Movements for ${accountId} / ${version}`}
          />
        </div>
      </div>

      <Table
        columns={TABLE_COLUMNS as any}
        dataSource={movements.value.result}
        loading={movements.loading}
        pagination={{
          total: movements.value.total,
          current: movements.value.page + 1,
          pageSize: movements.value.pageSize,
          pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
          showSizeChanger: true
        }}
        onChange={handleTableChange}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformAccount)

/**
 * Table render helpers
 */
const renderMovementType = (item: string): string =>
  movementTypeRender(item, false)

const renderAmount = (asset, change) => {
  return <div>{numberFormatter(change)}</div>
}
