import React from 'react'
import { Button, Popconfirm, Table } from 'antd'
import _ from 'lodash'

import { IPortalApiWithdrawalAccount } from '../withdrawalTypes'
import { IAsset } from 'seed-shared-components/lib/static-data/assetConfig'

interface IAccountsTableProps {
  loading: boolean
  accounts: IPortalApiWithdrawalAccount[]
  userEmail: string
  onApprove: (id: number) => void
  onReject: (id: number) => void
  onDetails: (id: number) => void
  onDelete: (id: number) => void
  onCancel: (id: number) => void
  approves: number
  isAdmin: boolean
  isSubmitting: boolean
  selectedAsset: IAsset
}

const AccountsTable = ({
  loading,
  accounts,
  userEmail,
  onApprove,
  onReject,
  onDetails,
  onDelete,
  onCancel,
  approves,
  isAdmin,
  isSubmitting,
  selectedAsset
}: IAccountsTableProps) => {
  const fiatColumns = [
    {
      title: 'Recipient Instructions',
      key: 'recipientInstructions',
      dataIndex: 'recipientInstructions',
      render: (value) => value
    }
  ]
  const columns = () => {
    return [
      { title: 'Account ID', dataIndex: 'withdrawalAccountId' },
      { title: 'Asset', dataIndex: 'currency' },
      { title: 'Nickname', dataIndex: 'name' },
      ...(selectedAsset?.type === 'Fiat' ? fiatColumns : []),
      {
        title: 'Status',
        dataIndex: 'status',
        render: (a: any, b: any) => renderStatus(approves, a, b)
      },
      ...(isAdmin
        ? [
            {
              title: 'Actions',
              key: 'actions',
              render: (_: any, record: IPortalApiWithdrawalAccount) =>
                renderActions(
                  userEmail,
                  record,
                  isSubmitting,
                  () => onDetails(record.id),
                  () => onApprove(record.withdrawalAccountId),
                  () => onReject(record.withdrawalAccountId),
                  () => onDelete(record.withdrawalAccountId),
                  () => onCancel(record.withdrawalAccountId)
                )
            }
          ]
        : [])
    ]
  }

  return (
    <Table
      loading={loading}
      dataSource={accounts}
      pagination={
        accounts.length > 5 ? { total: accounts.length, pageSize: 5 } : false
      }
      rowKey="id"
    >
      {columns().map((column) => (
        <Table.Column
          title={column.title}
          dataIndex={column.dataIndex}
          key={column.key || column.dataIndex}
          render={column.render}
        />
      ))}
    </Table>
  )
}

/**
 * Rendering helpers
 */
const ONE_TIME_ACTIONS = [
  'CREATION_REQUEST',
  'CREATION_APPROVAL',
  'CHANGE_APPROVAL'
]

const renderActions = (
  userEmail: string,
  record: IPortalApiWithdrawalAccount,
  isSubmitting: boolean,
  onDetails: any,
  onApprove: any,
  onReject: any,
  onDelete: any,
  onCancel: any
) => {
  const actions: any[] = [{ label: 'Details', onClick: onDetails }]

  if (
    record.status.startsWith('PENDING') &&
    record.status !== 'PENDING_WHITELIST' &&
    userEmail !== record.userEmail
  ) {
    const actionsDisabled = _.find(
      record.history,
      (entry) =>
        ONE_TIME_ACTIONS.includes(entry.action) && entry.userEmail === userEmail
    )

    actions.unshift({
      label: 'Reject',
      disabled: actionsDisabled,
      onClick: onReject
    })
    actions.unshift({
      label: 'Approve',
      disabled: actionsDisabled,
      onClick: onApprove
    })
  }

  if (userEmail === record.userEmail && record.status === 'PENDING_CREATION') {
    actions.push({ label: 'Cancel', disabled: false, onClick: onCancel })
  }

  if (record.status !== 'DELETED') {
    actions.push({
      label: 'Delete',
      disabled: false,
      onClick: onDelete,
      confirmable: true
    })
  }

  return (
    <>
      {actions.map((action, index) =>
        action.confirmable ? (
          <Popconfirm
            key={index}
            title="This action cannot be undone. Confirm to proceed."
            onConfirm={action.onClick}
            okText="Proceed"
            cancelText="Cancel"
            disabled={action.disabled || isSubmitting}
          >
            <Button
              size="small"
              style={{ marginRight: 12 }}
              disabled={action.disabled || isSubmitting}
            >
              {action.label}
            </Button>
          </Popconfirm>
        ) : (
          <Button
            onClick={action.onClick}
            key={index}
            size="small"
            style={{ marginRight: 12 }}
            disabled={action.disabled || isSubmitting}
          >
            {action.label}
          </Button>
        )
      )}
    </>
  )
}

const renderStatus = (
  approvalsRequred: number,
  status: IPortalApiWithdrawalAccount['status'],
  record: IPortalApiWithdrawalAccount
) => {
  if (status === 'PENDING_WHITELIST') {
    return status
  }
  if (status.startsWith('PENDING')) {
    const approvalsMade =
      record.history
        .slice()
        .sort((a, b) =>
          new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
            ? 1
            : -1
        )
        .reduce(
          (acc, entry) =>
            ({
              CREATION_REQUEST: 0,
              CHANGE_REQUEST: 0,
              CREATION_APPROVAL: acc + 1
            }[entry.action] || acc),
          0
        ) - 1

    return `PENDING (${approvalsMade}/${Math.max(
      approvalsMade,
      approvalsRequred - 1
    )})`
  }

  return status
}

export default AccountsTable
