import { QuestionCircleOutlined } from '@ant-design/icons'
import * as React from 'react'

export interface ILabelProps {
  itemKey?: string
  label: JSX.Element | string
  tooltip?: JSX.Element | string
}

const Label = (props: ILabelProps) =>
  props.tooltip ? (
    <span data-testid={props.itemKey} style={{ whiteSpace: 'normal' }}>
      {props.label}
      &nbsp;
      <QuestionCircleOutlined data-testid="question-circle-outlined" />
    </span>
  ) : (
    <span data-testid={props.itemKey} style={{ whiteSpace: 'normal' }}>
      {props.label}
    </span>
  )

export default Label
