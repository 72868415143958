import React from 'react'
import { ThemeColors } from './types'
import KycTypographyTitle from './kyc-customized-components/KycTypographyTitle'
import KycTypographyText from './kyc-customized-components/KycTypographyText'
import { Col, Row } from 'antd'
import KycInput from './kyc-customized-components/KycInput'
import KycButton from './kyc-customized-components/KycButton'

interface BasicInfoProps {
  colors: ThemeColors
  fontSize: number
  fontFamily: string
}
const BasicInfo = ({ colors, fontSize, fontFamily }: BasicInfoProps) => {
  return (
    <div
      style={{
        maxWidth: '500px',
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        padding: '1rem',
        minHeight: '540px',
        fontFamily: fontFamily,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <KycTypographyTitle
        fontSize={fontSize}
        colorText={colors.colorText || '#303133'}
      >
        Your basic information
      </KycTypographyTitle>
      <KycTypographyText
        colorText={colors.colorText || '#303133'}
        fontSize={fontSize ? fontSize - 2 : 10}
      >
        Legal name
      </KycTypographyText>
      <Row gutter={4}>
        <Col xs={12}>
          <KycInput
            primaryColor={colors.colorPrimary || undefined}
            textColor={colors.colorText}
            placeholder="First name"
            style={{ fontSize: fontSize ? fontSize : '12px' }}
          />
        </Col>
        <Col xs={12}>
          <KycInput
            primaryColor={colors.colorPrimary || undefined}
            textColor={colors.colorText}
            placeholder="Last name"
            style={{ fontSize: fontSize ? fontSize : '12px' }}
          />
        </Col>
      </Row>

      <Row
        style={{
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'flex-end'
        }}
      >
        <KycButton
          primaryColor={colors.colorPrimary}
          textColor={colors.colorText}
          style={{
            width: '100%',
            marginTop: '3rem',
            fontSize: fontSize ? fontSize * 1.33 : '16px',
            height: '3rem', // 48px,
            fontWeight: 800
          }}
        >
          Continue
        </KycButton>
      </Row>
    </div>
  )
}

export default BasicInfo
