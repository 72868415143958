import { createSelector } from '@reduxjs/toolkit'
import { IApplicationState } from '../state'
import { IWithdrawalState, IWithdrawalRequestHistoryByIdState } from './state'

export const getRoot = (state: IApplicationState) => state.withdrawal

export const getAccounts = createSelector(
  getRoot,
  ({ accounts }): IWithdrawalState['accounts'] => accounts
)

export const getWithdrawals = createSelector(
  getRoot,
  ({ withdrawals }): IWithdrawalState['withdrawals'] => withdrawals
)

export const getplatformTransfers = createSelector(
  getRoot,
  ({ platformTransfers }): IWithdrawalState['platformTransfers'] =>
    platformTransfers
)

export const getWithdrawalRequestHistory = createSelector(
  getRoot,
  (_, { withdrawalRequestId }) => withdrawalRequestId,
  (
    { withdrawalRequestsHistory },
    withdrawalRequestId
  ): IWithdrawalRequestHistoryByIdState => {
    return (
      withdrawalRequestsHistory[withdrawalRequestId] || {
        loading: false,
        result: []
      }
    )
  }
)
