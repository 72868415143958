import { IParticipantPositionsResult } from './types'
import portalApi from '../../api/portalApi'

export const fetchParticipantPositions = async ({
  token = '',
  participantCode
}: {
  token: string
  participantCode: string
}): Promise<IParticipantPositionsResult> => {
  const { data } = await portalApi.get(
    `/participants/${participantCode}/positions`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}
