import * as React from 'react'
import { Modal, Divider } from 'antd'

import participantUserRoleText from './ParticipantUserRoleText'
import { IParticipantUser } from 'seed-shared-components/lib/components/ParticipantUsers/types'
import Title from 'seed-shared-components/lib/components/Title'

const getTitle = (cap: string) =>
  (participantUserRoleText as any)[cap].displayName
const getValue = (cap: string, newRecord: IParticipantUser) =>
  (newRecord as any)[cap] ? 'true' : 'false'
const getMessage = (cap: string, newRecord: IParticipantUser) =>
  (participantUserRoleText as any)[cap][
    (newRecord as any)[cap] ? 'onMessage' : 'offMessage'
  ]

const CapDescription = ({
  newRecord,
  cap
}: {
  newRecord: IParticipantUser
  cap: string
}) => (
  <>
    <Title level={4}>{getTitle(cap)}</Title>
    <div style={{ margin: 8 }}>
      <p>
        <span style={{ fontSize: 16 }}>
          New Value:{' '}
          <em style={{ fontWeight: 700 }}>{getValue(cap, newRecord)}</em>
        </span>
      </p>

      <p className="justify-text">{getMessage(cap, newRecord)}</p>
    </div>
  </>
)

interface PermissionsAlertProps {
  caps: string[]
  newRecord: IParticipantUser
  onOk: () => void
  onCancel: () => void
}

export const PermissionsChangingConfirmation = ({
  newRecord,
  caps,
  onOk,
  onCancel
}: PermissionsAlertProps) => (
  <Modal
    title={<Title level={3}>{`Updating ${newRecord.email}`}</Title>}
    onCancel={onCancel}
    visible={true}
    width={650}
    onOk={onOk}
  >
    <div>
      {caps.map((cap, index) => (
        <>
          <CapDescription key={cap} newRecord={newRecord} cap={cap} />
          {index < caps.length - 1 && <Divider />}
        </>
      ))}
    </div>
  </Modal>
)
