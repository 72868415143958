import { put, takeEvery, select } from 'typed-redux-saga'
import { notification } from 'antd'
import { IApplicationState } from '../state'
import * as actions from './actions'
import * as userActions from '../user/actions'
import * as selectors from './selectors'

const getUser = (state: IApplicationState) => state.user

const jsonParse = <T>(s: string) =>
  new Promise<T>((resolve, reject) => {
    try {
      resolve(JSON.parse(s))
    } catch (err) {
      reject(err)
    }
  })

function* fetchSettingsSaga() {
  const { userIdentifier } = yield select(getUser)
  const key = `${userIdentifier}-settings`

  try {
    /* Retrieve all settings from localStorage */
    const settings = yield jsonParse(localStorage.getItem(key) || 'null')

    /* Save them to store */
    yield put(actions.setUserSettings({ settings: settings || {} }))
  } catch (e) {
    notification.error({
      description: 'Please try again, contact support.',
      duration: 0,
      message: 'Failed fetching User Settings.'
    })

    /* Attempt to clear settings here */
    localStorage.removeItem(key)
  }
}

function* setSettingSaga() {
  const { userIdentifier } = yield select(getUser)
  const key = `${userIdentifier}-settings`
  const settings = yield select(selectors.getSettings)

  /* Save all settings to local storage */
  localStorage.setItem(key, JSON.stringify(settings))
}

function* watchInit() {
  yield takeEvery(userActions.setUser.type, fetchSettingsSaga)
}

function* watchSet() {
  yield takeEvery(
    [actions.setUserSetting.type, actions.setUserSettings.type],
    setSettingSaga
  )
}

export { watchInit, watchSet }
