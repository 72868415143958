import { createAction } from '@reduxjs/toolkit'
import {
  FETCH_PORTFOLIO,
  SET_PORTFOLIO,
  IFetchPortfolio,
  ISetPortfolio
} from './types'

export const fetchPortfolio = createAction<IFetchPortfolio['payload']>(
  FETCH_PORTFOLIO
)

export const setPortfolio = createAction<ISetPortfolio['payload']>(
  SET_PORTFOLIO
)
