import * as React from 'react'

import { Table, Spin, Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { numberFormatter } from 'seed-shared-components/lib/utils/CurrencyFormatter'
import { IFund } from 'seed-shared-components/lib/static-data/assetConfig'

import { AssetBalancesProps } from './props'
import connect from './connector'
import dateFormatter from '../../utils/dateFormatter'
import AssetBalancesDownload from './asset-balances-download'
import PageTitle from 'seed-shared-components/lib/components/Title'
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons'

// this interface is just to satisfy TS. Otherwise it rejects align:'right
interface ICol {
  dataIndex?: string
  title: string
  className?: string
  render?: any
  align?: 'right'
  defaultSortOrder?: 'descend'
  sorter?: any
  width?: string
  key: string
}

const getLastUpdated = (updated: string, lastUpdated?: Date) => {
  if (!lastUpdated) {
    return new Date(updated)
  }
  if (updated) {
    const date = new Date(updated)
    return date > lastUpdated ? date : lastUpdated
  }
  return lastUpdated
}

interface AssetBalanceState {
  expandedRowKeys: string[]
}

class FundingPage extends React.Component<
  AssetBalancesProps,
  AssetBalanceState
> {
  state = {
    expandedRowKeys: this.props.assetBalances
      .filter((item) => item.children && item.children.length)
      .map((asset) => asset.asset)
  }

  getPrecision = (asset: string) => {
    return (
      this.props.assets.filter((e) => e.asset === asset).pop()?.precision ?? 2
    )
  }

  columns: ICol[] = [
    {
      render: (item: IFund) => {
        if (item.child) {
          if (item.name) {
            return `${item.name} (${item.account_group})`
          } else {
            return item.account_group
          }
        } else {
          return item.asset.toUpperCase()
        }
      },
      title: 'Asset',
      key: 'Asset'
    },
    {
      align: 'right',
      className: 'column-money highlight',
      render: (item: IFund) => {
        const getLink = (fund: IFund) => {
          if (!fund.available_id) {
            return (
              <div>
                {numberFormatter(
                  fund.available,
                  false,
                  this.getPrecision(fund.asset)
                )}
              </div>
            )
          }
          return (
            <Link
              to={`${this.props.match.url}/${fund.asset}/history/${fund.available_id}`}
            >
              <div>
                {numberFormatter(
                  fund.available,
                  false,
                  this.getPrecision(fund.asset)
                )}
              </div>
            </Link>
          )
        }

        if (item.fund) {
          return getLink(item.fund)
        } else if (item.child || !item.children) {
          return getLink(item)
        }
        return (
          <div>
            {numberFormatter(
              item.available,
              false,
              this.getPrecision(item.asset)
            )}
          </div>
        )
      },
      title: 'Available',
      width: '10%',
      key: 'Available'
    },
    {
      align: 'right',
      className: 'column-money',
      render: (item: IFund) => {
        const getLink = (fund: IFund) => {
          if (!fund.receivable_id) {
            return (
              <div>
                {numberFormatter(
                  item.receivable,
                  false,
                  this.getPrecision(item.asset)
                )}
              </div>
            )
          }
          return (
            <Link
              to={`${this.props.match.url}/${fund.asset}/history/${fund.receivable_id}`}
            >
              {numberFormatter(
                fund.receivable,
                false,
                this.getPrecision(fund.asset)
              )}
            </Link>
          )
        }
        if (item.fund) {
          return getLink(item.fund)
        } else if (item.child || !item.children) {
          return getLink(item)
        }
        return (
          <div>
            {numberFormatter(
              item.receivable,
              false,
              this.getPrecision(item.asset)
            )}
          </div>
        )
      },

      title: 'Receivable',
      width: '10%',
      key: 'Receivable'
    },
    {
      align: 'right',
      className: 'column-money',
      render: (item: IFund) => {
        const getLink = (fund: IFund) => {
          if (!fund.payable_id) {
            return (
              <div>
                {numberFormatter(
                  item.payable,
                  false,
                  this.getPrecision(item.asset)
                )}
              </div>
            )
          }
          return (
            <Link
              to={`${this.props.match.url}/${fund.asset}/history/${fund.payable_id}`}
            >
              {numberFormatter(
                fund.payable,
                false,
                this.getPrecision(fund.asset)
              )}
            </Link>
          )
        }

        if (item.fund) {
          return getLink(item.fund)
        } else if (item.child || !item.children) {
          return getLink(item)
        }
        return (
          <div>
            {numberFormatter(
              item.payable,
              false,
              this.getPrecision(item.asset)
            )}
          </div>
        )
      },

      title: 'Payable',
      width: '10%',
      key: 'Payable'
    },
    {
      align: 'right',
      className: 'column-money',
      render: (item: IFund) => {
        const getLink = (fund: IFund) => {
          if (!fund.collateral_id) {
            return (
              <div>
                {numberFormatter(
                  item.collateral,
                  false,
                  this.getPrecision(item.asset)
                )}
              </div>
            )
          }
          return (
            <Link
              to={`${this.props.match.url}/${fund.asset}/history/${fund.collateral_id}`}
            >
              {numberFormatter(
                fund.collateral,
                false,
                this.getPrecision(fund.asset)
              )}
            </Link>
          )
        }
        if (item.fund) {
          return getLink(item.fund)
        } else if (item.child !== null || !item.children) {
          return getLink(item)
        }
        return (
          <div>
            {numberFormatter(
              item.collateral,
              false,
              this.getPrecision(item.asset)
            )}
          </div>
        )
      },

      title: 'Collateral',
      width: '10%',
      key: 'Collateral'
    },
    {
      align: 'right',
      className: 'column-money',
      render: (item: IFund) => {
        const getLink = (fund: IFund) => {
          if (!fund.collateral_deficiency_id) {
            return (
              <div>
                {numberFormatter(
                  item.collateral_deficiency,
                  false,
                  this.getPrecision(item.asset)
                )}
              </div>
            )
          }
          return (
            <Link
              to={`${this.props.match.url}/${fund.asset}/history/${fund.collateral_deficiency_id}`}
            >
              {numberFormatter(
                fund.collateral_deficiency,
                false,
                this.getPrecision(fund.asset)
              )}
            </Link>
          )
        }

        if (item.fund) {
          return getLink(item.fund)
        } else if (item.child || !item.children) {
          return getLink(item)
        }
        return (
          <div>
            {numberFormatter(
              item.collateral_deficiency,
              false,
              this.getPrecision(item.asset)
            )}
          </div>
        )
      },
      title: 'Collateral Payable',
      width: '10%',
      key: 'CollateralPayable'
    },
    {
      align: 'right',
      render: (item: IFund) => {
        return item ? (
          item.ndo > 0 ? (
            <span className="ndo-exception">{item.ndo}</span>
          ) : (
            numberFormatter(item.ndo, false, this.getPrecision(item.asset))
          )
        ) : (
          numberFormatter(0, false, this.getPrecision(item.asset))
        )
      },
      title: 'Net Delivery Obligation',
      width: '12%',
      key: 'ndo',
      className: 'ant-table-column-sort highlight'
    },
    {
      render: (item: IFund) =>
        item.child ? (
          ''
        ) : (
          <>
            <Link to={`deposits/${item.asset}`}>Deposit</Link>{' '}
            <Link to={`withdrawals/${item.asset}`}>Withdraw</Link>
          </>
        ),

      title: 'Actions',
      key: 'actions'
    }
  ]
  componentDidMount() {
    this.props.viewAssetBalances()
  }
  componentDidUpdate(prevProps: AssetBalancesProps) {
    if (this.props.assetBalances !== prevProps.assetBalances) {
      this.setState(() => ({
        expandedRowKeys: this.props.assetBalances
          .filter((item) => item.children && item.children.length)
          .map((asset) => asset.asset)
      }))
    }
  }

  componentWillUnmount() {
    this.props.leaveAssetBalances()
  }
  toggleRowExpand(rowKey: string) {
    if (this.state.expandedRowKeys.includes(rowKey)) {
      const newState = this.state.expandedRowKeys.filter(
        (key) => key !== rowKey
      )
      this.setState(() => ({
        expandedRowKeys: newState
      }))
    } else {
      this.setState(() => ({
        expandedRowKeys: [...this.state.expandedRowKeys, rowKey]
      }))
    }
  }

  render() {
    let lastUpdated: Date | undefined = undefined
    this.props.assetBalances.forEach((asset) => {
      if (asset.children && asset.children.length) {
        asset.children.forEach((fund) => {
          lastUpdated = getLastUpdated(fund.updated, lastUpdated)
        })
      } else if (asset.fund) {
        lastUpdated = getLastUpdated(asset.fund.updated, lastUpdated)
      }
    })
    return this.props.loading ? (
      <Spin spinning />
    ) : (
      <>
        <Row
          justify="space-between"
          align="middle"
          gutter={[16, 16]}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <Col style={{ padding: 0 }}>
            <PageTitle level={2}>Balances</PageTitle>
          </Col>
          <Col style={{ padding: 0 }}>
            <AssetBalancesDownload
              participantCode={this.props.participantCode}
            />
          </Col>
        </Row>

        <div style={{ marginBottom: 10 }}>
          Here is a snapshot of all of your account balances. Please refer to
          our FAQ to find information on general account management at Zero
          Hash, to find definitions for account types and account groups, and to
          learn how to manage your allocations:{' '}
          <a
            href="https://zerohash.zendesk.com/hc/en-us/sections/360007876873-Account-Management"
            target="_blank"
          >
            Account Management
          </a>{' '}
        </div>

        {lastUpdated ? (
          <div
            style={{
              marginBottom: 24,
              fontWeight: 'bold'
            }}
          >
            <span>
              Last account update occurred at {dateFormatter(lastUpdated)}
            </span>
          </div>
        ) : undefined}

        <Table
          columns={this.columns}
          dataSource={this.props.assetBalances}
          pagination={false}
          expandedRowKeys={this.state.expandedRowKeys || []}
          expandIcon={(props: any) => {
            if (
              props.expandable &&
              props.record &&
              props.record.children &&
              props.record.children.length >= 1
            ) {
              if (props.expanded) {
                return (
                  <DownCircleOutlined
                    style={{ marginRight: 5 }}
                    onClick={this.toggleRowExpand.bind(this, props.record.key)}
                  />
                )
              }
              return (
                <UpCircleOutlined
                  style={{ marginRight: 5 }}
                  onClick={this.toggleRowExpand.bind(this, props.record.key)}
                />
              )
            }

            return <></>
          }}
        />
      </>
    )
  }
}

export default connect(FundingPage)
